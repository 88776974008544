import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import basic from "./modules/basic";
import auth from "./modules/auth";
import join from "./modules/join";
import util from "./modules/util";
import training from "./modules/training";
import evaluate from "./modules/evaluate";
import consultation from "./modules/consultation";
import life from "./modules/life";
import feeding from "./modules/feeding";

const modules = {
  basic,
  auth,
  join,
  util,
  training,
  evaluate,
  consultation,
  life,
  feeding
};

const plugins = [
  createPersistedState({
    paths: ["auth", "join", "util"]
  })
];
Vue.use(Vuex);
export const store = new Vuex.Store({
  modules,
  plugins
});
