import { feeding } from '@/api';

const state = {
  feeding: {
    /**
    drId: localStorage.getItem('drId') || '',
     */
  },
};

const mutations = {
  /**
  SET_PERSONALINFO(state, info) {
    state.join.personalInfo = info;
  }
   */
};

const getters = {
  /**
  GET_PERSONALINFO: state => state.join.personalInfo
   */
};

const actions = {
  FETCH_FEEDING_LIST({}) {
    return feeding.fetchFeedingList();
  },
  FETCH_FEEDING_LIST_PAGE({}, listOpt) {
    return feeding.fetchFeedingListPage(listOpt);
  },
  FEEDING_DETAIL({}, id) {
    return feeding.feedingDetail(id);
  },
  EXECUTE_FEEDING({}, dataSet) {
    return feeding.executeFeeding(dataSet);
  },
  FEEDING_DETAIL_SHARE({}, identification) {
    return feeding.feedingDetailShare(identification);
  },
  DELETE_FEEDING({}, dataSet) {
    return feeding.deleteFeeding(dataSet);
  },
  FETCH_POSITION_LIST({}) {
    return feeding.fetchPositionList();
  },
  FETCH_POSITION_LIST_PAGE({}, listOpt) {
    return feeding.fetchPositionListPage(listOpt);
  },
  POSITION_DETAIL({}, id) {
    return feeding.positionDetail(id);
  },
  EXECUTE_POSITION({}, dataSet) {
    return feeding.executePosition(dataSet);
  },
  POSITION_DETAIL_SHARE({}, identification) {
    return feeding.positionDetailShare(identification);
  },
};
export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
