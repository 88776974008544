<template>
  <modal
    :destination="destination"
    :huge="huge"
    :isDietInfo="isDietInfo"
    :isProtocol="isProtocol"
    :popType="popType"
    :popView="popView"
    @forced-nextlink="goNextLink()"
    @start-protocol-modal="doStartProtocol"
    :class="{ inShare: popType === 'share' }"
  >
    <template v-slot:body>
      <div class="share-pop" v-if="popType === 'share'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/close.svg" />
        </div>
        <h3 class="s-title">공유하기</h3>
        <div class="s-content">
          <div class="item" @click="share(0)">
            <div class="img-wrap">
              <img src="@assets/images/util/kakao_icon.png" />
            </div>
            <span class="name">카카오톡</span>
          </div>
        </div>
        <div class="link-box">
          <div class="input-wrap">
            <input
              type="text"
              id="shareUrl"
              v-model="param"
              readonly
              v-clipboard="param"
            />
          </div>
          <div class="btn-wrap">
            <button type="button" @click.stop @click="copyUrl()">URL 복사</button>
          </div>
        </div>
      </div>
      <div
        class="default-pop"
        :class="{ searching: popView === 'end', training: popView === 'start' }"
        v-if="popType === 'endTraining'"
      >
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
        <div class="information-pop-wrap end">
          <h3 class="title" v-text="title" />
          <div class="audio-dummy" @click="audioToggle()">
            <img src="@/assets/images/util/icon_sound.svg" v-if="view.isPlaying" />
            <img src="@/assets/images/util/icon_sound_mute.svg" v-else />
          </div>
          <div class="content">
            <p v-html="contentMutated"></p>
          </div>
          <div class="audio-player" :class="{ activated: plyrValue.isPrepared }">
            <vue-plyr :options="plyrOption" ref="plyrAudio">
              <audio controls crossorigin playsinline>
                <source
                  size="1080"
                  :src="require(`@/assets/audio/${plyrValue.src}`)"
                  type="audio/aac"
                />
              </audio>
            </vue-plyr>
          </div>
        </div>
      </div>
      <div class="default-pop diet-card" v-if="popType === 'dietCard1'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
        <div class="diet-card-pop-wrap">
          <h3 class="pop-title">대체식이란?</h3>
          <div class="pop-content">
            <p class="paragraph">
              일반 음식을 씹거나 삼키기 어려운 경우, 특수의료용 식품 등으로 일반식을
              대체할 수 있습니다. 시중에 판매되고 있는 특수의료용 식품은 영양분이 골고루
              들어 있고 다양한 농도와 맛을 선택할 수 있습니다.
            </p>
            <div class="meal-item-wrap">
              <div class="item">
                <div class="img-wrap">
                  <img src="@assets/images/diet/cardItem01.png" />
                </div>
                <p>푸딩 농도 / 감귤유자맛</p>
              </div>
              <div class="item">
                <div class="img-wrap">
                  <img src="@assets/images/diet/cardItem02.png" />
                </div>
                <p>우유 농도 / 바나나 맛</p>
              </div>
              <div class="item">
                <div class="img-wrap">
                  <img src="@assets/images/diet/cardItem03.png" />
                </div>
                <p>우유 농도 / 커피맛</p>
              </div>
            </div>
            <p class="notification">
              * 식이조절은 반드시 삼킴 전문가나 의료진과 상담하시길 바랍니다.
            </p>
          </div>
        </div>
      </div>
      <div class="default-pop diet-card" v-if="popType === 'dietCard2'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
        <div class="diet-card-pop-wrap">
          <h3 class="pop-title">점도증진제란?</h3>
          <div class="pop-content">
            <p class="paragraph">
              음식의 농도를 높이는 특수의료용 식품입니다. 일반적으로 음식이 묽을수록
              사레에 걸릴 위험이 높습니다. 사레를 방지하기 위해서는 점도증진제로 음식의
              농도를 조절해줍니다.
            </p>
            <div class="potion-item-wrap">
              <p class="pre-notification">
                점도증진제를 이용하면 농도를 크게 3단계로 조절할 수 있습니다.
              </p>
              <div class="item">
                <div class="img-wrap">
                  <img src="@assets/images/diet/cardItem04.png" />
                </div>
              </div>
              <div class="item">
                <div class="img-wrap">
                  <img src="@assets/images/diet/cardItem05.png" />
                </div>
              </div>
              <div class="item">
                <div class="img-wrap">
                  <img src="@assets/images/diet/cardItem06.png" />
                </div>
              </div>
            </div>
            <p class="notification">
              * 점도증진제 사용은 개인의 삼킴 문제에 따라 정도가 다를 수 있으므로 반드시
              삼킴 전문가나 의료진과 상담하시길 바랍니다.
            </p>
          </div>
        </div>
      </div>
      <div class="default-pop training" v-if="popType === 'needTraining'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
        <div class="information-pop-wrap">
          <h3 class="title">안내</h3>
          <div class="content">
            <p>완료되지 않은 훈련이 있어요.</p>
            <p><b>삼킴 훈련</b>을 시작해 볼까요?</p>
          </div>
          <div class="btn-wrap">
            <button type="button" @click="lifeTraining()">시작하기</button>
          </div>
        </div>
      </div>
      <div class="default-pop checking" v-if="popType === 'checkPw'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
        <div class="check-password">
          <h3 class="title">비밀번호 입력</h3>
          <div class="content">
            <p>삼킴 임상평가는 관리자만 사용할 수 있습니다.</p>
            <p>비밀번호를 입력해주세요.</p>
            <div class="input-wrap">
              <input type="password" v-model="password" @keyup.enter="checkpw()" />
            </div>
            <div class="btn-wrap">
              <button type="button" @click="checkpw()">확인</button>
            </div>
          </div>
        </div>
      </div>
      <div class="default-pop frame" v-if="popType === 'warn'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
        <div class="pop-img">
          <img :src="require(`@/assets/images/popup/${popImg}`)" />
        </div>
        <div class="content">
          <p v-html="contentMutated"></p>
        </div>
        <div
          class="btn-wrap"
          :class="{ alone: confirmBtnText === null || cancelBtnText === null }"
        >
          <button
            v-text="cancelBtnText"
            type="button"
            @click="close()"
            v-if="cancelBtnText !== null"
          />
          <button
            class="active"
            v-text="confirmBtnText"
            type="button"
            @click="action()"
            v-if="confirmBtnText !== null"
          />
        </div>
      </div>
      <div class="default-pop frame" v-if="popType === 'write'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
        <div class="content">
          <p v-html="contentMutated"></p>
        </div>
        <div class="input-wrap">
          <input type="text" v-model="dataValue" @keyup.enter="write()" />
        </div>
        <div class="btn-wrap">
          <button v-text="cancelBtnText" type="button" @click="close()" />
          <button class="active" v-text="confirmBtnText" type="button" @click="write()" />
        </div>
      </div>
      <div class="default-pop frame resv" v-if="popType === 'resv'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
        <div class="pop-img">
          <img :src="require(`@/assets/images/popup/${popImg}`)" />
        </div>
        <div class="content">
          <p v-html="contentMutated"></p>
        </div>
        <div class="resv-info">
          <div class="article">
            <p>상담 유형</p>
            <p class="bold" v-text="resvConts.type"></p>
          </div>
          <div class="article">
            <p>상담 날짜</p>
            <p class="bold" v-text="resvConts.date"></p>
          </div>
          <div class="article">
            <p>상담 시간</p>
            <p class="bold" v-text="resvConts.time"></p>
          </div>
        </div>
        <div
          class="btn-wrap"
          :class="{ alone: confirmBtnText === null || cancelBtnText === null }"
        >
          <button
            v-text="cancelBtnText"
            type="button"
            @click="close()"
            v-if="cancelBtnText !== null"
          />
          <button
            class="active"
            v-text="confirmBtnText"
            type="button"
            @click="reservation()"
            v-if="confirmBtnText !== null"
          />
        </div>
      </div>
      <div class="default-pop frame wish" v-if="popType === 'wish'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
        <h3 class="title">상담 희망 내용</h3>
        <div class="textarea-wrap">
          <textarea readonly v-text="wishContent"></textarea>
        </div>
        <div
          class="btn-wrap"
          :class="{ alone: confirmBtnText === null || cancelBtnText === null }"
        >
          <button
            v-text="cancelBtnText"
            type="button"
            @click="close()"
            v-if="cancelBtnText !== null"
          />
          <button
            class="active"
            v-text="confirmBtnText"
            type="button"
            @click="action()"
            v-if="confirmBtnText !== null"
          />
        </div>
      </div>
      <div class="default-pop frame alerm" v-if="popType === 'alerm'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
        <div class="alerm-header">
          <h3 class="title">알림 내용</h3>
          <p class="date" v-text="alermDate" />
        </div>
        <div class="textarea-wrap">
          <textarea v-text="alermContent" readonly></textarea>
        </div>
        <div class="btn-wrap">
          <button v-text="cancelBtnText" type="button" class="active" @click="close()" />
        </div>
      </div>
      <div class="default-pop frame alerm" v-if="popType === 'notice'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
        <div class="alerm-header">
          <h3 class="title">공지사항</h3>
          <p class="date" v-text="alermDate" />
        </div>
        <div class="textarea-wrap styles" v-html="alermContent">
<!--          <textarea v-model="alermContent" readonly></textarea>-->
        </div>
        <div class="btn-wrap">
          <button v-text="cancelBtnText" type="button" class="active" @click="close()" />
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import Modal from './Modal.vue';

export default {
  props: {
    popupSet: {
      historyCall: {
        type: Boolean,
      },
      title: {
        type: String,
      },
      content: {
        type: String,
      },
      nextLink: {
        type: String,
      },
      confirmBtnText: {
        type: String,
      },
      cancelBtnText: {
        type: String,
      },
      popType: {
        type: String,
        default: 'warn',
      },
      popImg: {
        type: String,
        default: 'warning.svg',
      },
      destination: {
        type: Boolean,
      },
      huge: {
        type: Boolean,
        default: false,
      },
      isDietInfo: {
        type: Boolean,
        default: false,
      },
      param: {
        type: String,
      },
      paramObj: {
        type: Object,
      },
      resvConts: {
        type: Object,
      },
      wishContent: {
        type: String,
      },
      isApiCall: {
        type: Boolean,
      },
      isRecordCall: {
        type: Boolean,
      },
      isProtocol: {
        type: Boolean,
      },
      isLastTrainingCount: {
        type: Boolean,
        default: false,
      },
      trainingName: {
        type: String,
      },
      popView: {
        type: String,
      },
      alermContent: {
        type: String,
      },
      alermDate: {
        type: String,
      },
      popDay: {
        type: Number,
        default: 0,
      },
    },
  },
  components: {
    Modal,
  },
  data() {
    return {
      popType: this.popupSet.popType || 'warn',
      popImg: this.popupSet.popImg || 'warning.svg',
      title: this.popupSet.title,
      content: this.popupSet.content,
      nextLink: this.popupSet.nextLink,
      cancelBtnText: this.popupSet.cancelBtnText,
      confirmBtnText: this.popupSet.confirmBtnText,
      destination: this.popupSet.destination,
      huge: this.popupSet.huge,
      isDietInfo: this.popupSet.isDietInfo,
      param: this.popupSet.param,
      paramObj: this.popupSet.paramObj,
      resvConts: this.popupSet.resvConts,
      isApiCall: this.popupSet.isApiCall,
      isRecordCall: this.popupSet.isRecordCall,
      isProtocol: this.popupSet.isProtocol,
      historyCall: this.popupSet.historyCall,
      dataValue: '',
      password: '',
      wishContent: this.popupSet.wishContent,
      alermContent: this.popupSet.alermContent,
      alermDate: this.popupSet.alermDate,
      trainingName: this.popupSet.trainingName,
      popView: this.popupSet.popView,
      popDay: this.popupSet.popDay,
      plyrValue: {
        duration: '',
        player: '',
        src: 'sampleAudio.aac',
        isPrepared: false,
        isPlay: false,
      },
      plyrOption: {
        autoplay: false,
        /**
          controls: ['play', 'fullscreen'],
           */
        fullscreen: {
          enabled: true,
          fallback: true,
          iosNative: true,
          container: 'audio-player',
        },
      },
      view: {
        isPlaying: false,
      },
    };
  },
  watch: {},
  mounted() {
    if (this.popupSet.popType === 'endTraining') {
      this.plyrValue.player = this.$refs.plyrAudio.player;
      this.initSource('training', this.popDay, this.popView, Number(this.popupSet.param));
      /** 시간업데이트 */
      this.$refs.plyrAudio.player.on('timeupdate', (data) => {
        const value = data.detail.plyr;
      });
      /** 오디오 초기시작 */
      this.$refs.plyrAudio.player.on('playing', (data) => {});

      /** 오디오 재개 */
      this.$refs.plyrAudio.player.on('play', (data) => {});
      /** 오디오 중지 */
      this.$refs.plyrAudio.player.on('pause', (data) => {});

      /** 오디오 완료 */
      this.$refs.plyrAudio.player.on('ended', (data) => {
        this.close();
      });
      /** 오디오 전체화면 시작 */
      this.$refs.plyrAudio.player.on('enterfullscreen', (data) => {});

      /** 오디오 전체화면 중지 */
      this.$refs.plyrAudio.player.on('exitfullscreen', (data) => {});
    }
  },
  computed: {
    transRefImg() {
      return (order, isDiet) => {
        console.log('order :', order);
        const materialLink = isDiet
          ? 'http://20.194.31.39:8000/information/diet/'
          : 'http://20.194.31.39:8000/information/posture/';
        return `${materialLink}representativeImage.png`;
        /**
        if (isDiet) {
          if (order === 1) {
            return materialLink + "big_referenceImgA01.png";
          } else if (order === 2) {
            return materialLink + "big_referenceImgB01.png";
          } else if (order === 3) {
            return materialLink + "big_referenceImgC01.png";
          } else if (order === 4) {
            return materialLink + "big_referenceImgD01.png";
          } else if (order === 5) {
            return materialLink + "big_referenceImgE01.png";
          } else if (order === 6) {
            return materialLink + "big_referenceImgF01.png";
          } else if (order === 7) {
            return materialLink + "big_referenceImgF01.png";
          } else if (order === 8) {
            return materialLink + "big_referenceImgA01.png";
          } else if (order === 9) {
            return materialLink + "big_referenceImgB01.png";
          } else if (order === 10) {
            return materialLink + "big_referenceImgG01.png";
          } else if (order === 11) {
            return materialLink + "big_referenceImgF01.png";
          } else if (order === 12) {
            return materialLink + "big_referenceImgH01.png";
          } else if (order === 13) {
            return materialLink + "big_referenceImgF01.png";
          } else if (order === 14) {
            return materialLink + "big_referenceImgI01.png";
          } else if (order === 15) {
            return materialLink + "big_referenceImgB01.png";
          } else if (order === 16) {
            return materialLink + "big_referenceImgJ01.png";
          } else if (order === 17) {
            return materialLink + "big_referenceImgK01.png";
          } else if (order === 18) {
            return materialLink + "big_referenceImgD01.png";
          } else if (order === 19) {
            return materialLink + "big_referenceImgE01.png";
          } else if (order === 20) {
            return materialLink + "big_referenceImgL01.png";
          } else if (order === 21) {
            return materialLink + "big_referenceImgL01.png";
          } else if (order === 22) {
            return materialLink + "big_referenceImgG01.png";
          } else if (order === 23) {
            return materialLink + "big_referenceImgA01.png";
          } else if (order === 24) {
            return materialLink + "big_referenceImgB01.png";
          } else if (order === 25) {
            return materialLink + "big_referenceImgM01.png";
          } else if (order === 26) {
            return materialLink + "big_referenceImgN01.png";
          } else if (order === 27) {
            return materialLink + "big_referenceImgO01.png";
          }
        } else {
          if (order === 1) {
            return materialLink + "referenceImgA.png";
          } else if (order === 2) {
            return materialLink + "referenceImgA.png";
          } else if (order === 3) {
            return materialLink + "referenceImgB.png";
          } else if (order === 4) {
            return materialLink + "referenceImgC.png";
          } else if (order === 5) {
            return materialLink + "referenceImgC.png";
          } else if (order === 6) {
            return materialLink + "referenceImgC.png";
          } else if (order === 7) {
            return materialLink + "referenceImgC.png";
          } else if (order === 8) {
            return materialLink + "referenceImgC.png";
          } else if (order === 9) {
            return materialLink + "referenceImgD.png";
          } else if (order === 10) {
            return materialLink + "referenceImgE.png";
          } else if (order === 11) {
            return materialLink + "referenceImgF.png";
          } else if (order === 12) {
            return materialLink + "referenceImgA.png";
          } else if (order === 13) {
            return materialLink + "referenceImgG.png";
          } else if (order === 14) {
            return materialLink + "referenceImgH.png";
          } else if (order === 15) {
            return materialLink + "referenceImgI.png";
          } else if (order === 16) {
            return materialLink + "referenceImgJ.png";
          } else if (order === 17) {
            return materialLink + "referenceImgK.png";
          } else if (order === 18) {
            return materialLink + "referenceImgL01.png";
          } else if (order === 19) {
            return materialLink + "referenceImgM01.png";
          } else if (order === 20) {
            return materialLink + "referenceImgN01.png";
          } else if (order === 21) {
            return materialLink + "referenceImgN01.png";
          } else if (order === 22) {
            return materialLink + "referenceImgO.png";
          } else if (order === 23) {
            return materialLink + "referenceImgO.png";
          }
        }
         */
        return text.split('\n').join('');
      };
    },
    contentMutated() {
      console.log('코멘트 검색 :', this.content);
      if (this.content !== undefined && this.content.indexOf('\n') !== 1) {
        return this.content.split('\n').join('<br />');
      }
      return this.content;
    },
  },
  methods: {
    ...mapMutations('basic', ['SET_POPUP']),
    checkpw() {
      if (this.password === 'swallow') {
        this.SET_POPUP(false);
        this.$emit('pw-passed');
      } else {
        alert('비밀번호가 일치하지 않습니다.');
      }
    },
    doStartProtocol() {
      if (this.popupSet.popView === 'start') {
        this.$emit('start-protocol');
      }
    },
    copyUrl() {
      alert('URL이 복사되었습니다.');
      document.querySelector('#shareUrl').click();
    },
    share(order) {
      console.log('obj :', this.paramObj);
      console.log('공유에 쓰일 파람', this.param);
      /** 카카오톡 공유 */
      if (order === 0) {
        const isDiet = this.param.indexOf('diet') !== -1;
        const isPosture = this.param.indexOf('posture') !== -1;
        const isLife = this.param.indexOf('life?') !== -1;
        const isLifeList = this.param.indexOf('lifelist') !== -1;
        console.log('isDiet :', isDiet);
        console.log('isPosture :', isPosture);
        console.log('isLife :', isLife);
        console.log('isLifeList :', isLifeList);
        let transImg;
        if (isDiet) {
          transImg = 'http://20.194.31.39:8000/information/diet/kakao_diet.png';
        } else if (isPosture) {
          transImg = 'http://20.194.31.39:8000/information/posture/kakao_posture.png';
        } else {
          transImg = 'http://20.194.31.39:8000/information/life/kakao_smile.png';
        }
        if (isDiet) {
          Kakao.Link.sendDefault({
            objectType: 'feed',
            content: {
              title: this.paramObj.title,
              imageUrl: transImg,
              description: '식이법 공유 페이지입니다.',
              link: {
                mobileWebUrl: this.param,
                webUrl: this.param,
              },
            },
          });
        } else if (isPosture) {
          Kakao.Link.sendDefault({
            objectType: 'feed',
            content: {
              title: this.paramObj.title,
              imageUrl: transImg,
              description: '자세법 공유 페이지입니다.',
              link: {
                mobileWebUrl: this.param,
                webUrl: this.param,
              },
            },
          });
        }
      }
      this.$emit('share-clicked');
    },
    initSource(type, day, time, order) {
      console.log(
        'initSouce - type :',
        type,
        'day :',
        day,
        'time :',
        time,
        'order :',
        order,
        /** [type] -> training */
        /** [day] -> 1 ~ 40 */
        /** [time] -> start, end */
        /** [order] 아침 점심 저녁 -> 1,2,3 */
      );
      if (this.popupSet.isLastTrainingCount) {
        this.$refs.plyrAudio.player.source = {
          type: 'audio',
          title: 'Example title',
          sources: [
            {
              src: require(`@/assets/audio/${type}/day${day}/${time}/evening.mp3`),
            },
          ],
        };
      } else if (order === 1) {
        this.$refs.plyrAudio.player.source = {
          type: 'audio',
          title: 'Example title',
          sources: [
            {
              src: require(`@/assets/audio/${type}/day${day}/${time}/morning.mp3`),
            },
          ],
        };
      } else if (order === 2) {
        this.$refs.plyrAudio.player.source = {
          type: 'audio',
          title: 'Example title',
          sources: [
            {
              src: require(`@/assets/audio/${type}/day${day}/${time}/lunch.mp3`),
            },
          ],
        };
      } else if (order === 3) {
        this.$refs.plyrAudio.player.source = {
          type: 'audio',
          title: 'Example title',
          sources: [
            {
              src: require(`@/assets/audio/${type}/day${day}/${time}/evening.mp3`),
            },
          ],
        };
      }

      this.plyrValue.isPrepared = true;
    },
    audioToggle() {
      if (this.$refs.plyrAudio.player.playing) {
        this.view.isPlaying = false;
        this.$refs.plyrAudio.player.pause();
      } else {
        this.view.isPlaying = true;
        this.$refs.plyrAudio.player.play();
      }
    },
    lifeTraining() {
      this.SET_POPUP(false);
      this.$router.push('/trainingDate');
    },
    lifeSearching() {
      this.SET_POPUP(false);
      this.$router.push('/choiceMeaning');
    },
    reservation() {
      this.$emit('resv-call');
      this.SET_POPUP(false);
    },
    goNextLink() {
      this.$router.push(this.nextLink);
    },
    write() {
      this.$emit('transed-pop-data', this.dataValue);
      this.SET_POPUP(false);
    },
    action() {
      console.log('action에 진입');
      this.SET_POPUP(false);
      if (this.historyCall) {
        this.$emit('history-called');
      } else if (this.isRecordCall) {
        this.$emit('record-api-called');
      } else if (this.isApiCall) {
        console.log('암살자 파람 :', this.param);
        this.$emit('api-called', this.param);
      } else if (this.nextLink !== undefined) {
        this.$router.push(this.nextLink);
      }
    },
    close() {
      if (
        this.popupSet.popType === 'endTraining'
        && this.$refs.plyrAudio.player.playing
      ) {
        this.$refs.plyrAudio.player.stop();
      }
      if (this.destination && this.nextLink !== undefined) {
        this.$router.push(this.nextLink);
      }
      if (this.isRecordCall) {
        this.$emit('record-api-called-cancle');
      }
      if (this.isProtocol) {
        this.doStartProtocol();
      }
      this.SET_POPUP(false);
    },
  },
  created() {
    console.log('가져온 팝업아이템', this.popupSet);
  },
};
</script>

<style lang="scss" scoped>
.frame {
  padding: 30px;
  min-height: 300px;
}
.share-pop {
  width: 480px;
  height: 480px;
  padding: 20px 18.5px 0 18.5px;
  position: relative;
  box-shadow: 0 3px 30px 0 rgba(44, 57, 55, 0.3);
  background-color: #ffffff;
  .close {
    position: absolute;
    top: 18px;
    right: 18px;
    cursor: pointer;
    z-index: 0 1;
  }
  h3.s-title {
    margin: 11.5px 0 0 0;
    border-bottom: 1px solid #eaebf1;
    padding: 0 0 11.5px 0;
    @include initfontKor(
      $font-size: 26px,
      $line-height: 34px,
      $font-family: "AppleSDGothicNeoM00",
      $color: #363739,
      $letter-spacing: -0.52px,
      $text-align: center
    );
  }
  .s-content {
    padding: 33px 14.5px;
    .item {
      width: 105px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      cursor: pointer;
      .img-wrap {
        width: inherit;
        margin: 0 0 5px 0;
        display: flex;
        justify-content: center;
        img {
          max-width: 80px;
        }
      }
      span.name {
        @include initfontKor(
          $font-size: 24px,
          $line-height: 34px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #8b8d94,
          $letter-spacing: -0.48px,
          $text-align: center
        );
      }
    }
  }
  .link-box {
    border: 1px solid #d2d3d8;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 44px;
    width: calc(100% - 66px);
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    .input-wrap {
      padding: 0 15px;
      background: #eaebf1;
      width: calc(100% - 120px);
      input {
        background: transparent;
        width: 100%;
        @include initfontKor(
          $font-size: 19px,
          $line-height: 57px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #8b8d94,
          $letter-spacing: -0.52px,
          $text-align: left
        );
      }
    }
    .btn-wrap {
      width: 120px;
      border-left: 1px solid #d2d3d8;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      button {
        width: inherit;
        border: 0;
        padding: 0;
        background: transparent;
        cursor: pointer;
        @include initfontKor(
          $font-size: 26px,
          $line-height: 57px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #fd8b11,
          $letter-spacing: -0.52px,
          $text-align: center
        );
      }
    }
  }
}
.default-pop {
  width: inherit;
  &.checking {
    .close {
      position: absolute;
      top: 20px;
      right: 20px;
    }
    .check-password {
      padding: 30px 0;
      h3.title {
        margin: 0 0 15px 0;
        @include initfontKor(
          $font-size: 26px,
          $line-height: 32px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #363739,
          $letter-spacing: -0.48px,
          $text-align: center
        );
      }
      .content {
        p {
          font-size: 20px;
          line-height: 28px;
          letter-spacing: -0.32px;
          color: #363739;
          font-family: "AppleSDGothicNeoR00";
          &:first-of-type {
            margin: 0;
          }
        }

        .input-wrap {
          padding: 0 15px;
          background: #eaebf1;
          width: calc(100% - 120px);
          margin: 0 auto;
          input {
            background: transparent;
            width: 100%;
            @include initfontKor(
              $font-size: 19px,
              $line-height: 48px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #8b8d94,
              $letter-spacing: -0.52px,
              $text-align: left
            );
          }
        }
        .btn-wrap {
          width: 240px;
          background: #315754;
          border-radius: 8.5px;
          margin: 25px auto 0;
          border-left: 0px solid transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          button {
            width: inherit;
            border: 0;
            padding: 0;
            background: transparent;
            cursor: pointer;
            @include initfontKor(
              $font-size: 26px,
              $line-height: 57px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #fff,
              $letter-spacing: -0.52px,
              $text-align: center
            );
          }
        }
      }
    }
  }
  &.diet-card {
    .close {
      position: absolute;
      top: 20px;
      right: 20px;
    }
    .diet-card-pop-wrap {
      h3.pop-title {
        background: #315754;
        @include initfontKor(
          $font-size: 26px,
          $line-height: 60px,
          $font-family: "AppleSDGothicNeoB00",
          $color: #fff,
          $letter-spacing: -0.52px,
          $text-align: center
        );
      }
      .pop-content {
        padding: 30px;
        p {
          @include initfontKor(
            $font-size: 26px,
            $line-height: 32px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #363739,
            $letter-spacing: -0.48px,
            $text-align: center
          );
          &.paragraph {
            margin: 0 0 45px 0;
            text-align: left;
          }
          &.notification {
            font-size: 16px;
            line-height: 28px;
            letter-spacing: -0.32px;
            color: #315754;
            font-family: "AppleSDGothicNeoR00";
          }
        }
        .potion-item-wrap {
          display: flex;
          flex-wrap: wrap;
          padding: 30px;
          margin: 0 0 10px 0;
          border: 1px solid #707070;
          border-radius: 5px;
          position: relative;
          justify-content: center;

          &:before {
            content: "";
            top: -16px;
            left: 20px;
            content: "농도조절";
            background: #fff;
            position: absolute;
            width: 105px;
            @include initfontKor(
              $font-size: 24px,
              $line-height: 32px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #363739,
              $letter-spacing: -0.48px,
              $text-align: center
            );
          }
          p.pre-notification {
            width: 100%;
            position: relative;
            padding: 0 0 0 20px;
            text-align: left;
            &:before {
              position: absolute;
              content: "";
              width: 8px;
              height: 8px;
              background: #375754;
              border-radius: 50%;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          .item {
            margin: 0 0 0 45px;
            .img-wrap {
              display: flex;
              justify-content: center;
              img {
              }
            }
            p {
              font-size: 24px;
              line-height: 32px;
              margin: 10px 0 0 0;
              color: #315754;
            }
            &:first-of-type {
              margin: 0;
            }
          }
        }
        .meal-item-wrap {
          display: flex;
          padding: 30px;
          margin: 0 0 10px 0;
          border: 1px solid #707070;
          border-radius: 5px;
          position: relative;
          justify-content: center;
          &:before {
            top: -16px;
            left: 20px;
            content: "제품예시";
            background: #fff;
            position: absolute;
            width: 105px;
            @include initfontKor(
              $font-size: 24px,
              $line-height: 32px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #363739,
              $letter-spacing: -0.48px,
              $text-align: center
            );
          }
          .item {
            margin: 0 0 0 45px;
            .img-wrap {
              display: flex;
              justify-content: center;
              img {
              }
            }
            p {
              font-size: 24px;
              line-height: 32px;
              margin: 10px 0 0 0;
              color: #315754;
            }
            &:first-of-type {
              margin: 0;
            }
          }
        }
        p.notification {
          @include initfontKor(
            $font-size: 16px,
            $line-height: 28px,
            $font-family: "AppleSDGothicNeoR00",
            $color: #315754,
            $letter-spacing: -0.32px,
            $text-align: right
          );
        }
      }
    }
  }
  .information-pop-wrap {
    padding: 40px;
    height: inherit;
    position: relative;
    &.end {
      h3.title {
        margin: 5px 0 0 0;
      }
      .content {
        top: calc(50% + 10px);
        p {
          &.seperated {
            margin: 10px 0 0 0;
          }
        }
      }
    }
    .audio-dummy {
      text-align: center;
      cursor: pointer;
      padding: 10px;
      -webkit-tap-highlight-color: transparent;
      &:focus {
        outline: none;
      }
      &:active {
        outline: none;
      }
    }
    .audio-player {
      -webkit-tap-highlight-color: transparent;
      &:focus {
        outline: none;
      }
      &:active {
        outline: none;
      }
    }
    .content {
      -webkit-tap-highlight-color: transparent;
      &:focus {
        outline: none;
      }
      &:active {
        outline: none;
      }
    }
    .audio-player {
      position: absolute;
      opacity: 0;
      &.activated {
        opacity: 0;
        position: relative;
      }
    }
    h3.title {
      @include initfontKor(
        $font-size: 28px,
        $line-height: 38px,
        $font-family: "AppleSDGothicNeoB00",
        $color: #363739,
        $letter-spacing: -0.56px,
        $text-align: center
      );
    }
    .content {
      position: absolute;
      left: 50%;
      top: calc(50% - 15px);
      transform: translate(-50%, -50%);
      width: 400px;
      p {
        margin: 0;
        @include initfontKor(
          $font-size: 30px,
          $line-height: 42px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #363739,
          $letter-spacing: -1.5px,
          $text-align: center
        );
        b {
          font-family: "AppleSDGothicNeoB00";
          font-weight: normal;
          color: #fd8b11;
        }
      }
    }
    .btn-wrap {
      bottom: 70px;
      width: 200px;
      border-radius: 10px;
      background: #315754;
      left: 50%;
      transform: translateX(-50%);
      button {
        width: 100%;
        background: transparent;
        border: 0;
        padding: 0;
        @include initfontKor(
          $font-size: 30px,
          $line-height: 36px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #fff,
          $letter-spacing: -0.6px,
          $text-align: center
        );
      }
    }
  }
  &.alerm {
    padding: 30px 25px;
    .alerm-header {
      padding: 0 0 20px 0;
      border-bottom: solid 1px #363739;
      margin: 0 0 20px 0;
      h3.title {
        @include initfontKor(
          $font-size: 26px,
          $line-height: 36px,
          $font-family: "AppleSDGothicNeoB00",
          $color: #363739,
          $letter-spacing: -0.52px,
          $text-align: left
        );
      }
      p.date {
        @include initfontKor(
          $font-size: 18px,
          $line-height: 32px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #8b8d94,
          $letter-spacing: -0.36px,
          $text-align: left
        );
      }
    }

    .textarea-wrap {
      width: 100%;
      min-height: 270px;
      border-radius: 5px;
      border: solid 1px #eaebf1;
      margin: 0 0 85px 0;
      textarea {
        padding: 20px;
        width: inherit;
        min-height: 270px;
        display: block;
        resize: none;
        @include initfontKor(
          $font-size: 24px,
          $line-height: 32px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #363739,
          $letter-spacing: -0.48px,
          $text-align: left
        );
      }
    }
    .btn-wrap {
      button {
        width: 100%;
      }
    }
  }
  &.searching {
    background-image: url("~@assets/images/util/needLife.png");
    height: 470px;
  }
  &.training {
    background-image: url("~@assets/images/util/needTraining.png");
    height: 470px;
  }
  &.wish {
    padding: 25px 40px;
    h3.title {
      @include initfontKor(
        $font-size: 26px,
        $line-height: 36px,
        $font-family: "AppleSDGothicNeoB00",
        $color: #363739,
        $letter-spacing: -0.52px,
        $text-align: center
      );
      margin: 0 0 12px 0;
    }
    .textarea-wrap {
      width: 100%;
      min-height: 270px;
      border-radius: 5px;
      border: solid 1px #eaebf1;
      margin: 0 0 85px 0;
      textarea {
        padding: 20px;
        width: inherit;
        min-height: 270px;
        display: block;
        resize: none;
        @include initfontKor(
          $font-size: 24px,
          $line-height: 32px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #363739,
          $letter-spacing: -0.48px,
          $text-align: left
        );
      }
    }
  }
  &.resv {
    padding: 30px 65px;
    .pop-img {
      margin: 0 auto 45px auto;
    }
    .content {
      margin: 0 0 20px 0;
    }
    .resv-info {
      margin: 0 0 80px 0;
      .article {
        padding: 20px 0;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(49, 87, 84, 0.2);
        &:last-of-type {
          border-bottom: 0;
        }
        p {
          @include initfontKor(
            $font-size: 26px,
            $line-height: 34px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #363739,
            $letter-spacing: -0.52px,
            $text-align: left
          );
          &.bold {
            font-family: "AppleSDGothicNeoB00";
          }
        }
      }
    }
  }
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 1;
    &.hide {
      opacity: 0;
    }
  }
  .pop-img {
    max-width: 105px;
    margin: 0 auto 32px auto;
    display: flex;
    justify-content: center;
    img {
    }
  }
  .content {
    p {
      @include initfontKor(
        $font-size: 24px,
        $line-height: 30px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #363739,
        $letter-spacing: -0.6px,
        $text-align: right
      );
      text-align: center;
      margin: 0 0 20px 0;
    }
  }
  .btn-wrap {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    &.alone {
      button {
        width: 100%;
        background: #fd8b11;
        color: #fff;
      }
    }
    button {
      width: 50%;
      height: 60px;
      margin: 0;
      background: #eaebf1;
      border: 0;
      cursor: pointer;
      @include initfontKor(
        $font-size: 26px,
        $line-height: 36px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #363739,
        $letter-spacing: -0.52px,
        $text-align: center
      );
      &:first-of-type {
        margin: 0;
      }
      &.active {
        background: $pointcolor__orange;
        color: #fff;
      }
    }
  }
}
.styles {
  padding: 20px;
  color: #363739;
}
</style>
