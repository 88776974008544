import axios from 'axios';

const domain = process.env.VUE_APP_API_URL;
const domainDev = process.env.VUE_APP_VERSION;

const request = {
  get2(path, header) {
    const currentUrl = document.location.href;
    console.log('get Api Current URL', currentUrl);
    console.log('get Path', path);
    return axios
      .get(`${domainDev + path}`, header)
      .catch((error) => {
        console.log('domainDev', domainDev);
        if (error.response) {
          console.log('error', error);
          console.log('error response', error.response);
          if (error.response.status === 500) {
            console.log('500 ERROR 진입:');
            console.log('path:', path);
          }
          if (error.response.statusText === 'Unauthorized') {
            window.app.$children[0].$children[0].$children[0].callInUnAuth();
            /** 로그아웃처리 */
            localStorage.removeItem('token');
            localStorage.removeItem('id');
            localStorage.removeItem('userName');
            localStorage.removeItem('storeId');
            const url = 'samkim://logout';
            document.location.href = url;
          }
          return Promise.reject(error.response);
        }
        if (error.request) {
          // 요청은 성공적으로 생성되었으나 서버로부터 아무 응답이 없는 경우
          console.log('err request', error.request);
        } else {
          // 어떤 이유인지는 모르겠지만 에러가 발생한 경우
          console.log('Error', error.message);
        }
      })
      .finally(() => {});
  },
  get(path, header) {
    const currentUrl = document.location.href;
    console.log('get Api Current URL', currentUrl);
    console.log('get Path', path);
    return axios
      .get(`${domain + path}`, header)
      .catch((error) => {
        console.log('domain', domain);
        console.log('error-path', path);
        if (error.response) {
          console.log('error', error);
          console.log('error response!', error.response.message);
          if (error.response.status === 500) {
            console.log('500 ERROR 진입:');
            console.log('path:', path);
          }
          if (error.response.statusText === 'Unauthorized') {
            window.app.$children[0].$children[0].$children[0].callInUnAuth();
            /** 로그아웃처리 */
            localStorage.removeItem('token');
            localStorage.removeItem('id');
            localStorage.removeItem('userName');
            localStorage.removeItem('storeId');
            const url = 'samkim://logout';
            document.location.href = url;
          }
          return Promise.reject(error.response);
        }
        if (error.request) {
          // 요청은 성공적으로 생성되었으나 서버로부터 아무 응답이 없는 경우
          console.log('err request', error.request);
        } else {
          // 어떤 이유인지는 모르겠지만 에러가 발생한 경우
          console.log('Error', error.message);
        }
      })
      .finally(() => {});
  },
  patch(path, data, header) {
    const currentUrl = document.location.href;
    console.log('patch Api Current URL', currentUrl);
    console.log('patch Path', path);
    return axios
      .patch(`${domain + path}`, data, header)
      .catch((error) => {
        if (error.response) {
          console.log('error', error);
          console.log('error response', error.response);
          if (error.response.status === 500) {
            console.log('500 ERROR 진입:');
            console.log('path:', path);
          }
          if (error.response.statusText === 'Unauthorized') {
            window.app.$children[0].$children[0].$children[0].callInUnAuth();
            /** 로그아웃처리 */
            localStorage.removeItem('token');
            localStorage.removeItem('id');
            localStorage.removeItem('userName');
            localStorage.removeItem('storeId');
            const url = 'samkim://logout';
            document.location.href = url;
          }
          return Promise.reject(error.response);
        }
        if (error.request) {
          // 요청은 성공적으로 생성되었으나 서버로부터 아무 응답이 없는 경우
          console.log('err request', error.request);
        } else {
          // 어떤 이유인지는 모르겠지만 에러가 발생한 경우
          console.log('Error', error.message);
        }
      })
      .finally(() => {});
  },
  post(path, data, header) {
    const currentUrl = document.location.href;
    console.log('post Api Current URL', currentUrl);
    console.log('post Path', path);
    return axios
      .post(`${domain + path}`, data, header)
      .catch((error) => {
        console.log('찐에러', error);
        if (error.response) {
          console.log('error', error);
          console.log('error response', error.response);
          if (error.response.status === 500) {
            console.log('500 ERROR 진입:');
            console.log('path:', path);
          }
          if (error.response.statusText === 'Unauthorized') {
            window.app.$children[0].$children[0].$children[0].callInUnAuth();
            /** 로그아웃처리 */
            localStorage.removeItem('token');
            localStorage.removeItem('id');
            localStorage.removeItem('userName');
            localStorage.removeItem('storeId');
            const url = 'samkim://logout';
            document.location.href = url;
          }
          return Promise.reject(error.response);
        }
        if (error.request) {
          // 요청은 성공적으로 생성되었으나 서버로부터 아무 응답이 없는 경우
          console.log('err request', error.request);
        } else {
          // 어떤 이유인지는 모르겠지만 에러가 발생한 경우
          console.log('Error', error.message);
        }
      })
      .finally(() => {});
  },
  delete(path, header) {
    const currentUrl = document.location.href;
    console.log('delete Api Current URL', currentUrl);
    console.log('delete Path', path);
    return axios
      .delete(`${domain + path}`, header)
      .catch((error) => {
        if (error.response) {
          console.log('error', error);
          console.log('error response', error.response);
          if (error.response.status === 500) {
            console.log('500 ERROR 진입:');
            console.log('path:', path);
          }
          if (error.response.statusText === 'Unauthorized') {
            window.app.$children[0].$children[0].$children[0].callInUnAuth();
            /** 로그아웃처리 */
            localStorage.removeItem('token');
            localStorage.removeItem('id');
            localStorage.removeItem('userName');
            localStorage.removeItem('storeId');
            const url = 'samkim://logout';
            document.location.href = url;
          }
          return Promise.reject(error.response);
        }
        if (error.request) {
          // 요청은 성공적으로 생성되었으나 서버로부터 아무 응답이 없는 경우
          console.log('err request', error.request);
        } else {
          // 어떤 이유인지는 모르겠지만 에러가 발생한 경우
          console.log('Error', error.message);
        }
      })
      .finally(() => {});
  },
  put(path, data, header) {
    const currentUrl = document.location.href;
    console.log('put Api Current URL', currentUrl);
    console.log('put Path', path);
    return axios
      .put(`${domain + path}`, data, header)
      .catch((error) => {
        if (error.response) {
          console.log('error', error);
          console.log('error response', error.response);
          if (error.response.status === 500) {
            console.log('500 ERROR 진입:');
            console.log('path:', path);
          }
          if (error.response.statusText === 'Unauthorized') {
            window.app.$children[0].$children[0].$children[0].callInUnAuth();
            /** 로그아웃처리 */
            localStorage.removeItem('token');
            localStorage.removeItem('id');
            localStorage.removeItem('userName');
            localStorage.removeItem('storeId');
            const url = 'samkim://logout';
            document.location.href = url;
          }
          return Promise.reject(error.response);
        }
        if (error.request) {
          // 요청은 성공적으로 생성되었으나 서버로부터 아무 응답이 없는 경우
          console.log('err request', error.request);
        } else {
          // 어떤 이유인지는 모르겠지만 에러가 발생한 경우identitySuccess
          console.log('Error', error.message);
        }
      })
      .finally(() => {});
  },
};

export const auth = {
  loginAuto(payload) {
    return request
      .post('/account/login/auto', payload, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
};
export const join = {
  checkDuplicate(userInfo) {
    return request
      .post('/account/check', userInfo, {
        headers: {},
      })
      .then(({ data }) => data);
  },
  fetchJoinQuestionList() {
    return request
      .get('/account/question', {
        headers: {},
      })
      .then(({ data }) => data);
  },
  accountJoin(joinData) {
    return request
      .post('/account/join', joinData, {
        headers: {},
      })
      .then(({ data }) => data);
  },
  login(userInfo) {
    return request
      .post('/account/login', userInfo, {
        headers: {},
      })
      .then(({ data }) => data);
  },
  logOut(userInfo) {
    return request
      .post('/account/logout', userInfo, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  accountInfo() {
    return request
      .get('/account/info', {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
};
export const evaluate = {
  fetchEvaluateList(info) {
    return request
      .get(`/evaluation?type=${info.type}&id=${info.id}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  checkingEvaluate(info) {
    return request
      .get(`/evaluation/check?type=${info.type}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  submitEvaluate(list) {
    return request
      .post('/evaluation', list, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  modifyEvaluate(list) {
    return request
      .put('/evaluation', list, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  fetchExplainList(payload) {
    return request
      .get2(`/evaluation/survey/?userId=${payload.userId}&get_type=${payload.getType}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  // fetchSurveyDetail(apiParam) {
  //   return request
  //     .get2(`/evaluation/survey/${apiParam}`, {
  //       headers: {
  //         Authorization: `Token ${localStorage.getItem('token')}`,
  //       },
  //     })
  //     .then(({ data }) => data);
  // },
  fetchMonthList(dateOpt) {
    return request
      .get(`/evaluation/check/monthly?type=${dateOpt.type}&gte=${dateOpt.gte}&lte=${dateOpt.lte}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
};
export const util = {
  fetchUserInfo() {
    return request
      .get('/account/info', {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  fetchAppVersion() {
    return request.get('/setting/app').then(({ data }) => data);
  },
  fetchComment(info) {
    return request
      .get(`/setting/comment?type=${info.type}&time=${info.time}&day=${info.day}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  fetchCommentTraining(info) {
    return request
      .get(
        `/setting/comment?type=${info.type}&time=${info.time}&day=${info.day}&trainingTime=${info.trainingTime}`,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
      )
      .then(({ data }) => data);
  },
  /** 널대용 만들기 */
  fetchEntry() {
    return request
      .get('/account/entry', {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  fetchAlermListPage(listOpt) {
    return request
      .get(`/push/list?page=${listOpt.page}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  fetchAlermList() {
    return request
      .get('/push/list', {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  readPush() {
    return request
      .put(
        '/push',
        {},
        {
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
        },
      )
      .then(({ data }) => data);
  },
  changePush(list) {
    return request
      .put('/push/setting', list, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  fetchNoticeListPage(listOpt) {
    return request
      .get(`/setting/notice/list?page=${listOpt.page}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  fetchNoticeList() {
    return request
      .get('/setting/notice/list', {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  fetchNoticeDetail(id) {
    return request
      .get(`/setting/notice?id=${id}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  fetchEntryFcm(fcmToken) {
    return request
      .get(`/account/entry?fcmToken=${fcmToken}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
};
export const training = {
  checkTraining(info) {
    return request
      .get(`/training/check?time=${info.time}&day=${info.day}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  startTraining(info) {
    return request
      .post('/training', info, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  fetchTraining(id) {
    return request
      .get(`/training?detailId=${id}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  fetchTrainingInfo() {
    return request
      .get('/training/check/number', {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  sendTrainingData(info) {
    return request
      .put('/training', info, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  fetchMonthList(dateOpt) {
    return request
      .get(`/training/check/monthly?gte=${dateOpt.gte}&lte=${dateOpt.lte}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  fetchTrainingDate(id) {
    return request
      .get(`/training/date?id=${id}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
};
export const consultation = {
  submitConsultation(data) {
    return request
      .post('/consultation', data, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  checkConsultationTime(date) {
    return request
      .get(`/consultation/check/time?date=${date}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  fetchConsultationListDefault() {
    return request
      .get('/consultation/list', {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  fetchConsultationList(listOpt) {
    return request
      .get(`/consultation/list?gte=${listOpt.gte}&lte=${listOpt.lte}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  fetchConsultationListPage(listOpt) {
    return request
      .get(`/consultation/list?gte=${listOpt.gte}&lte=${listOpt.lte}&page=${listOpt.page}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  fetchConsultationListPageDefault(listOpt) {
    return request
      .get(`/consultation/list?page=${listOpt.page}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  cancleConsultation(dataSet) {
    return request
      .put('/consultation', dataSet, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  checkConsultation() {
    return request
      .get('/consultation/check', {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
};
export const life = {
  fetchShareLife(id) {
    return request
      .get(`/activity/life/share/list?userId=${id}&maxPage=40`)
      .then(({ data }) => data);
  },
  fetchLife(id) {
    return request.get(`/activity/life/share?id=${id}`).then(({ data }) => data);
  },
  fetchLifeList() {
    return request
      .get('/activity/life/list', {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  fetchLifeListPage(page) {
    return request
      .get(`/activity/life/list?page=${page}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  fetchLifeListFull() {
    return request
      .get('/activity/life/list?maxPage=40', {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
};
export const feeding = {
  fetchFeedingList() {
    return request
      .get('/activity/feeding/list', {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  fetchFeedingListPage(page) {
    return request
      .get(`/activity/feeding/list?page=${page}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  feedingDetail(id) {
    return request
      .get(`/activity/feeding?id=${id}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  executeFeeding(feedingOpt) {
    return request
      .post('/activity/feeding', feedingOpt, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ data }) => data);
  },
  deleteFeeding(feedingOpt) {
    return request
      .put('/activity/feeding', feedingOpt, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  feedingDetailShare(feedingOpt) {
    return request
      .get(`/activity/feeding/share?id=${feedingOpt.id}&userId=${feedingOpt.userId}`)
      .then(({ data }) => data);
  },
  fetchPositionList() {
    return request
      .get('/activity/position/list', {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  fetchPositionListPage(page) {
    return request
      .get(`/activity/position/list?page=${page}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  positionDetail(id) {
    return request
      .get(`/activity/position?id=${id}`, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  executePosition(feedingOpt) {
    return request
      .post('/activity/position', feedingOpt, {
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
      .then(({ data }) => data);
  },
  positionDetailShare(feedingOpt) {
    return request
      .get(`/activity/position/share?id=${feedingOpt.id}&userId=${feedingOpt.userId}`)
      .then(({ data }) => data);
  },
};
