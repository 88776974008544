<template>
  <div class="pagination">
    <div class="nav-btn prev" @click="prev()" :class="{ hide: paginationStatus.currentPage === 1 }">
      <img src="@assets/images/util/icon_before.svg" />
    </div>
    <div
      class="nav-btn next"
      @click="next()"
      :class="{ hide: paginationStatus.currentPage === paginationStatus.totalPage }"
    >
      <img src="@assets/images/util/icon_next.svg" />
    </div>
    <p class="count">
      <b>{{ paginationStatus.currentPage }}</b
      >/<b>{{ paginationStatus.totalPage }}</b>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    paginationInfo: {
      type: Object
    }
  },
  data() {
    return {
      paginationStatus: {
        totalDataCnt: this.paginationInfo.totalDataCnt,
        dataOnPage: this.paginationInfo.dataOnPage,
        currentPage: this.paginationInfo.currentPage,
        totalPage: this.paginationInfo.totalPage
      }
    };
  },
  created() {},
  methods: {
    initCurrentPageNum(num) {
      this.paginationStatus.currentPage = num;
    },
    prev() {
      if (this.paginationStatus.currentPage !== 1) {
        const { currentPage } = this.paginationStatus;
        this.paginationStatus.currentPage = currentPage - 1;
        this.$emit("btn-click", this.paginationStatus.currentPage);
      }
    },
    next() {
      if (this.paginationStatus.currentPage !== this.paginationStatus.totalPage) {
        const { currentPage } = this.paginationStatus;
        this.paginationStatus.currentPage = currentPage + 1;
        this.$emit("btn-click", this.paginationStatus.currentPage);
      }
    }
  },
  computed: {},
  components: {}
};
</script>
<style lang="scss" scoped>
.pagination {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 170px;
  margin: 20px auto 0 auto;
  .nav-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    &.hide {
      opacity: 0;
      cursor: default;
    }
  }
  p.count {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    b {
      font-weight: normal;
    }
  }
}
</style>
