<template>
  <div class="app-context">
    <side-menu
      :sideMenuOption="sideMenuOption"
      @is-unauthorized="isUnAuthorized"
    ></side-menu>
    <div class="wrap-context">
      <h5 class="page-title">상담이력</h5>
      <select-range-date
        @call-calendar-pop="callCalendarPop"
        @reload-result="loadResult"
        ref="selectRangeDate"
      ></select-range-date>
      <result-list
        :consultList="consultList"
        :resultListCate="resultListCate"
        @call-wish-pop="callWishPop"
        @call-cancel-pop="callCancelPop"
        ref="consultant"
        v-if="view.isListLoaded"
      ></result-list>
      <pagination
        @btn-click="paginationClick"
        :paginationInfo="paginationInfo"
        v-if="view.isListLoaded"
      ></pagination>
    </div>
    <calendar-popup
      @selected-date="selectedDate"
      v-if="checkCalendarPopup"
      :popupSetCalendar="popupSetCalendar"
    ></calendar-popup>
    <default-popup v-if="checkPopup" :popupSet="popupSet" @api-called="cancleConsult" />
  </div>
</template>

<script>
import errorParser from '@/utils/error-parser';
import SelectRangeDate from '@/components/SelectRangeDate';
import DefaultPopup from '@/components/modal/DefaultPopup';
import CalendarPopup from '@/components/modal/CalendarPopup';
import SideMenu from '@/components/SideMenu.vue';
import ResultList from '@/components/ResultListDate.vue';
import Pagination from '@/components/Pagination.vue';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  data() {
    return {
      resultListCate: 'consultantList',
      paginationInfo: {
        totalDataCnt: null,
        dataOnPage: null,
        totalPage: null,
        currentPage: null,
        prevApiUrl: null,
        nextApiUrl: null,
      },
      apiStatus: {
        beginDate: null,
        endDate: null,
        currentPage: 1,
      },
      consultList: {
        header: ['번호', '날짜', '시간', '희망내용', '상담자', '상태', '예약취소'],
        list: [],
        /**
         *  date: "1921.03.11",
            time: "10:30",
            consultant: "배정전",
            resvStatus: 0
         */
      },
      sideMenuOption: [
        {
          img: 'sidebar_item01.png',
          img_active: 'sidebar_menu1_active.png',
          img_active_deep: 'sidebar_menu1_active_deep.png',
          title: '메인',
          isActive: false,
          link: '',
        },
        {
          img: 'sidebar_item02.png',
          img_active: 'sidebar_menu2_active.png',
          img_active_deep: 'sidebar_menu2_active_deep.png',
          title: '삼킴 평가',
          isActive: false,
          link: 'evaluate',
        },
        {
          img: 'sidebar_item03.png',
          img_active: 'sidebar_menu3_active.png',
          img_active_deep: 'sidebar_menu3_active_deep.png',
          title: '삼킴 훈련',
          isActive: false,
          link: 'trainingDate',
        },

        {
          img: 'sidebar_item05.png',
          img_active: 'sidebar_menu5_active.png',
          img_active_deep: 'sidebar_menu5_active_deep.png',
          title: '자세법ㆍ식이법',
          isActive: false,
          link: 'choiceType',
        },
        {
          img: 'sidebar_item06.png',
          img_active: 'sidebar_menu6_active.png',
          img_active_deep: 'sidebar_menu6_active_deep.png',
          title: '원격 상담',
          isActive: true,
          link: 'reservationType',
        },
        {
          img: 'sidebar_mypage.png',
          img_active: 'sidebar_mypage_active.png',
          img_active_deep: 'sidebar_mypage_active.png',
          title: '마이페이지',
          isActive: false,
          link: 'mypage',
        },
      ],
      popupSet: {},
      popupSetCalendar: {},
      view: {
        isListLoaded: false,
      },
    };
  },
  created() {
    if (history.pushState !== undefined) {
      history.pushState({}, '', location.href);
      window.onpopstate = () => {
        history.pushState({}, '', location.href);
        this.handleGoback();
      };
    }
    this.getConsultationList(null, null, null);
  },
  mounted() {
    /**
    this.popTest();
     */
  },
  methods: {
    ...mapMutations('basic', ['SET_POPUP', 'SET_CALENDAR_POPUP']),
    ...mapActions('consultation', [
      'FETCH_CONSULTATION_LIST_PAGE',
      'FETCH_CONSULTATION_LIST_PAGE_DEFAULT',
      'FETCH_CONSULTATION_LIST_DEFAULT',
      'FETCH_CONSULTATION_LIST',
      'CANCLE_CONSULTATION',
    ]),
    async getConsultationList(gte, lte, page) {
      console.log('list-api호출', 'gte :', gte, 'lte :', lte, 'page :', page);
      this.view.isListLoaded = false;
      if (page !== null) {
        if (gte !== null) {
          try {
            const payload = {
              gte,
              lte,
              page,
            };
            await this.FETCH_CONSULTATION_LIST_PAGE(payload).then((data) => {
              this.consultList.list = data.response.list;
              this.paginationInfo.totalDataCnt = data.response.totalCount;
              this.paginationInfo.dataOnPage = 5;
              this.paginationInfo.totalPage = data.response.endPage;
              this.paginationInfo.currentPage = Number(page);
              this.paginationInfo.prevApiUrl = data.response.previous;
              this.paginationInfo.nextApiUrl = data.response.next;
              /**  */
              this.view.isListLoaded = true;
            });
          } catch (error) {
            console.log('error :', errorParser.parse(error));
            console.log('error :', error);
          }
        } else {
          try {
            const payload = {
              page,
            };
            await this.FETCH_CONSULTATION_LIST_PAGE_DEFAULT(payload).then((data) => {
              this.consultList.list = data.response.list;
              this.paginationInfo.totalDataCnt = data.response.totalCount;
              this.paginationInfo.dataOnPage = 5;
              this.paginationInfo.totalPage = data.response.endPage;
              this.paginationInfo.currentPage = Number(page);
              this.paginationInfo.prevApiUrl = data.response.previous;
              this.paginationInfo.nextApiUrl = data.response.next;
              /**  */
              this.view.isListLoaded = true;
            });
          } catch (error) {
            console.log('error :', errorParser.parse(error));
            console.log('error :', error);
          }
        }
      } else if (gte !== null) {
        /** GTE,LTE 일반 */
        try {
          const payload = {
            gte,
            lte,
          };
          await this.FETCH_CONSULTATION_LIST(payload).then((data) => {
            this.consultList.list = data.response.list;
            this.paginationInfo.totalDataCnt = data.response.totalCount;
            this.paginationInfo.dataOnPage = 5;
            this.paginationInfo.totalPage = data.response.endPage;
            this.paginationInfo.currentPage = 1;
            this.paginationInfo.prevApiUrl = data.response.previous;
            this.paginationInfo.nextApiUrl = data.response.next;
            /**  */
            this.view.isListLoaded = true;
          });
        } catch (error) {
          console.log('error :', errorParser.parse(error));
          console.log('error :', error);
        }
      } else {
        /** 디폴트 */
        try {
          await this.FETCH_CONSULTATION_LIST_DEFAULT().then((data) => {
            this.consultList.list = data.response.list;
            this.paginationInfo.totalDataCnt = data.response.totalCount;
            this.paginationInfo.dataOnPage = 5;
            this.paginationInfo.totalPage = data.response.endPage;
            this.paginationInfo.currentPage = 1;
            this.paginationInfo.prevApiUrl = data.response.previous;
            this.paginationInfo.nextApiUrl = data.response.next;
            this.view.isListLoaded = true;
          });
        } catch (error) {
          console.log('error :', errorParser.parse(error));
          console.log('error :', error);
        }
      }
    },
    handleGoback() {
      if (this.checkPopup) {
        this.SET_POPUP(false);
      } else {
        this.$router.push('/reservationType');
      }
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = '로그인 권한이 필요합니다.';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    async cancleConsult(order) {
      console.log('취소할 리스트 순번', order);

      const payload = {
        id: this.consultList.list[order].id,
        state: 1,
      };
      await this.CANCLE_CONSULTATION(payload).then(() => {
        this.SET_POPUP(true);
        this.popupSet.popType = 'warn';
        this.popupSet.popImg = 'warning.svg';
        this.popupSet.content = '예약이 취소되었습니다.';
        this.popupSet.cancelBtnText = '확인';
        this.popupSet.nextLink = null;
        this.popupSet.confirmBtnText = null;
        this.getConsultationList(
          this.apiStatus.beginDate,
          this.apiStatus.endDate,
          this.apiStatus.currentPage,
        );
      });
    },
    callWishPop(comment) {
      /** api 조회한 값을  wishContent에 대입  */
      this.SET_POPUP(true);
      this.popupSet.popType = 'wish';
      this.popupSet.cancelBtnText = '확인';
      this.popupSet.wishContent = comment;
      this.popupSet.nextLink = null;
      this.popupSet.confirmBtnText = null;
    },
    callCancelPop(order) {
      /** api 조회 */
      this.SET_POPUP(true);
      this.popupSet.popType = 'warn';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '예약을 취소하시겠습니까?';
      this.popupSet.cancelBtnText = '아니오';
      this.popupSet.nextLink = null;
      this.popupSet.param = order;
      this.popupSet.isApiCall = true;
      this.popupSet.confirmBtnText = '예';
    },
    paginationClick(pageNum) {
      /** 리스트를 다시그리는 API 수행 */
      console.log('페이지네이션 클릭수신 :', pageNum);
      this.apiStatus.currentPage = pageNum;
      this.getConsultationList(this.apiStatus.beginDate, this.apiStatus.endDate, pageNum);
    },
    callCalendarPop(order) {
      this.SET_CALENDAR_POPUP(true);
      this.popupSetCalendar.content = '테스트';
      this.popupSetCalendar.cancelBtnText = '취소';
      this.popupSetCalendar.nextLink = '/';
      this.popupSetCalendar.confirmBtnText = '실행';
      this.popupSetCalendar.rangeOrder = order;
      this.popupSetCalendar.calendarType = 3;
    },
    loadResult(dateOpt) {
      if (dateOpt !== undefined) {
        this.apiStatus.beginDate = dateOpt.gte;
        this.apiStatus.endDate = dateOpt.lte;
        this.getConsultationList(dateOpt.gte, dateOpt.lte, null);
      } else {
        this.SET_POPUP(true);
        this.popupSet.popType = 'warn';
        this.popupSet.popImg = 'success.svg';
        this.popupSet.content = '날짜가 선택되지 않았습니다.';
        this.popupSet.cancelBtnText = '확인';
        this.popupSet.nextLink = null;
        this.popupSet.confirmBtnText = null;
      }
    },
    selectedDate(date, order) {
      if (order === 1) {
        this.$refs.selectRangeDate.initBeginDate(date);
      } else if (order === 2) {
        this.$refs.selectRangeDate.initEndDate(date);
      }
    },
    removeA(arr) {
      let what;
      const a = arguments;
      let L = a.length;
      let ax;
      while (L > 1 && arr.length) {
        what = a[--L];
        while ((ax = arr.indexOf(what)) !== -1) {
          arr.splice(ax, 1);
        }
      }
      return arr;
    },
  },
  computed: {
    ...mapState('basic', ['checkPopup', 'checkCalendarPopup']),
  },
  components: {
    SideMenu,
    DefaultPopup,
    CalendarPopup,
    ResultList,
    Pagination,
    SelectRangeDate,
  },
};
</script>
<style lang="scss" scoped>
.app-context {
  .wrap-context {
    width: 934px;
    margin: 22px 90px 0 auto;
    h5.page-title {
      margin: 0 0 37px 0;
    }
  }
}
</style>
