<template>
  <div class="app-context flex deep">
    <side-menu
      :sideMenuOption="sideMenuOption"
      :sideMenuDeep="sideMenuDeep"
      @is-unauthorized="isUnAuthorized"
    ></side-menu>
    <div class="wrap-context directiveType1">
      <directive
        :directiveTxt="directiveTxt"
        class="directive low"
        :directiveType="1"
      ></directive>
      <div class="camera-context" v-if="view.dataLoaded">
        <div
          class="training-notification"
          :class="{
            active: animate.value !== null,
            prepare: animate.value === '준비',
            start: animate.value === '시작',
          }"
        >
          <span v-text="animate.value" />
        </div>
        <div class="item-box">
          <div
            class="animator-wrap"
            :class="{ active: animate.value !== null, pause: plyrValue.isViewMilestone }"
          >
            <div class="animator" v-if="plyrValue.isViewMilestone">
              <div class="btn-wrap">
                <div class="item">
                  <button type="button" @click="btnClick('prepare')">
                    연습 음성 다시듣기
                  </button>
                </div>
              </div>
            </div>
            <div class="animator" v-else>
              <!--
              <div class="half-circle-timer">
                <span v-text="animate.value" />
              </div>
              <div class="info-wrap">
                <div class="holder">
                  <h4
                    class="title"
                    v-text="
                      plyrValue.currentRepeatCount === 1 && plyrValue.currentRepeatCount === 5
                        ? '물'
                        : '침'
                    "
                  ></h4>
                  <div class="content">
                    <div class="img-wrap">
                      <img
                        src="@assets/images/training/cup.png"
                        v-if="plyrValue.currentRepeatCount >= 2"
                      />
                      <img src="@assets/images/training/mouse.png" v-else />
                    </div>
                    <p v-if="plyrValue.currentRepeatCount >= 2">5ml</p>
                  </div>
                </div>
              </div>
              -->
            </div>
          </div>
          <div class="video-player" v-if="view.loadDone">
            <vue-plyr :options="plyrOption" ref="plyr">
              <audio controls crossorigin playsinline>
                <source
                  size="1080"
                  :src="require(`@/assets/audio/raising/${plyrValue.src}`)"
                  type="audio/mp3"
                />
              </audio>
            </vue-plyr>
          </div>
        </div>
        <div class="item-box activated">
          <div class="info first">
            <div class="bg-img">
              <img src="@assets/images/util/information.svg" />
            </div>
            <div class="item-wrap">
              <!--
              <div class="item">
                <h3 class="title">확인해주세요</h3>
                <div class="orderList">
                  <p>가장 편안한 높이에서 "이~~~" 소리를 내세요.</p>
                  <p>조금씩 음을 높여 가장 높은 음까지 최대한 끌어 올리세요.</p>
                  <p>최대한 길게 내세요</p>
                </div>
              </div>
              -->
              <div class="item">
                <h3 class="title">확인해주세요</h3>
                <div class="orderList">
                  <p>가장 편안한 높이에서 "이~~~" 소리를 내세요.</p>
                  <p>조금씩 음을 높여 최대한 끌어 올리세요.</p>
                  <p>최대한 길게 내세요.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="info">
            <div class="bg-img">
              <img src="@assets/images/util/information.svg" />
            </div>
            <div class="item-wrap">
              <!--
              <div class="item">
                <h3 class="title">확인해주세요</h3>
                <div class="orderList">
                  <p>가장 편안한 높이에서 "이~~~" 소리를 내세요.</p>
                  <p>조금씩 음을 높여 가장 높은 음까지 최대한 끌어 올리세요.</p>
                  <p>최대한 길게 내세요</p>
                </div>
              </div>
              -->
              <div class="item">
                <h3 class="title">주의사항</h3>
                <div class="orderList">
                  <p>빨간 선에 맞추지 않아도 됩니다.</p>
                  <p>최대한 본인이 낼 수 있는 높은 음을 내세요.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <default-popup
      v-if="checkPopup"
      :popupSet="popupSet"
      @start-protocol="initProtocol"
    />
  </div>
</template>

<script>
import errorParser from '@/utils/error-parser';
import DefaultPopup from '@/components/modal/DefaultPopup';
import SideMenu from '@/components/SideMenu.vue';
import Directive from '@/components/Directive.vue';
import Notification from '@/components/Notification.vue';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  data() {
    return {
      plyrOption: {
        autoplay: false,
        controls: ['play', 'mute', 'volume'],
        fullscreen: {
          enabled: true,
          fallback: true,
          iosNative: true,
          container: 'audio-player',
        },
      },
      plyrValue: {
        duration: '',
        player: '',
        src: 'announcer_ment_information_1.mp3',
        isPrepare: true,
        currentRepeatCount: null,
        repeatCount: 3,
        setCount: 3,
        currentSetCount: 1,
        isViewMilestone: false,
        setDone: false,
        allSetClear: false,
        raisingList: [],
      },
      directiveTxt: {
        point: '힘껏 음도 높이기',
        normal: null,
        small: '성대 운동',
      },
      sideMenuDeep: true,
      notificationInfo: {
        title: '힘껏 음도 높이기',
        btnSet: [
          {
            img: 'icon_information.svg',
            name: '훈련안내',
            routerUrl: 'trainingInfo',
            isActionBtn: false,
          },
          {
            img: 'icon_record.svg',
            name: '훈련기록',
            routerUrl: 'trainingRecord',
            isActionBtn: false,
          },
        ],
      },
      sideMenuOption: [
        {
          img: 'sidebar_item01.png',
          img_active: 'sidebar_menu1_active.png',
          img_active_deep: 'sidebar_menu1_active_deep.png',
          title: '메인',
          isActive: false,
          link: '',
        },
        {
          img: 'sidebar_item02.png',
          img_active: 'sidebar_menu2_active.png',
          img_active_deep: 'sidebar_menu2_active_deep.png',
          title: '삼킴 평가',
          isActive: false,
          link: 'evaluate',
        },
        {
          img: 'sidebar_item03.png',
          img_active: 'sidebar_menu3_active.png',
          img_active_deep: 'sidebar_menu3_active_deep.png',
          title: '삼킴 훈련',
          isActive: true,
          link: 'trainingDate',
        },
        {
          img: 'sidebar_item05.png',
          img_active: 'sidebar_menu5_active.png',
          img_active_deep: 'sidebar_menu5_active_deep.png',
          title: '자세법ㆍ식이법',
          isActive: false,
          link: 'choiceType',
        },
        {
          img: 'sidebar_item06.png',
          img_active: 'sidebar_menu6_active.png',
          img_active_deep: 'sidebar_menu6_active_deep.png',
          title: '원격 상담',
          isActive: false,
          link: 'reservationType',
        },
        {
          img: 'sidebar_mypage.png',
          img_active: 'sidebar_mypage_active.png',
          img_active_deep: 'sidebar_mypage_active.png',
          title: '마이페이지',
          isActive: false,
          link: 'mypage',
        },
      ],
      popupSet: {},
      animate: {
        intervalObj: null,
        initValue: null,
        value: null,
        isPause: false,
        isDone: false,
      },
      apiStatus: {
        detailId: null,
        currentOrder: null,
        isFemale: null,
      },
      view: {
        isFirst: false,
        isRaisingPaused: false,
        comment: '',
        isFirstTraining: false,
        isLastTraining: false,
        loadDone: false,
        isRaisingOn: true,
        isRaisingLoaded: false,
        pastCurrentRepeat: null,
        dataLoaded: false,
        limitCall: false,
        loadAudio: {
          clientTraining: false,
        },
      },
    };
  },
  created() {
    if (history.pushState !== undefined) {
      history.pushState({}, '', location.href);
      window.onpopstate = () => {
        history.pushState({}, '', location.href);
        this.handleGoback();
      };
    }
  },
  mounted() {
    this.view.dataLoaded = true;
    this.initProtocol();
    this.initAudio();
  },
  methods: {
    ...mapMutations('basic', ['SET_POPUP']),
    ...mapActions('training', [
      'CHECK_TRAINING',
      'START_TRAINING',
      'FETCH_TRAINING',
      'SEND_TRAINING_DATA',
    ]),
    ...mapActions('util', ['FETCH_COMMENT_TRAINING']),
    initProtocol() {
      console.log('[프로토콜] samkim://raisingPageStart');
      const url = `samkim://raisingPageStart?detailId=${0}&isFemale=${false}`;
      document.location.href = url;
    },
    async loadPop(type, time, day, trainingTime) {
      console.log('로드팝 재료: ');
      console.log('type - tr :', type);
      console.log('time - tr :', time);
      console.log('day - tr :', day);
      console.log('trainingTime - tr :', trainingTime);
      try {
        const info = {
          type,
          time,
          day,
          trainingTime,
        };
        await this.FETCH_COMMENT_TRAINING(info).then((data) => {
          console.log('fetchComment : ', data);
          console.log('fetchComment - inner : ', data.response.comment);
          if (data.response.comment !== null) {
            this.view.comment = data.response.comment;
          } else {
            this.view.comment = '멘트 점검중';
          }
          if (Number(this.$route.query.time) === 0 && time === 'start') {
            console.log('팝업이 없음');
          } else {
            console.log('[프로토콜] samkim://raisingPageEnd');
            const url = 'samkim://raisingPageEnd';
            document.location.href = url;
            setTimeout(() => {
              this.SET_POPUP(true);
              this.popupSet.popType = 'endTraining';
              this.popupSet.title = `삼킴 훈련 ${this.getCountToOrder(
                this.$route.query.time,
              )}회`;
              this.popupSet.popImg = 'warning.svg';
              this.popupSet.param = this.getCountToOrder(this.$route.query.time);
              this.popupSet.trainingName = 'raising';
              this.popupSet.content = this.view.comment;
              this.popupSet.popView = time;
              this.popupSet.isProtocol = true;
              this.popupSet.popDay = this.$route.query.day;
              if (time !== 'end') {
                this.popupSet.cancelBtnText = '확인';
              } else {
                console.log(
                  '중대점 체크1 true? :',
                  this.$route.query.isLastCount === true,
                );
                console.log('중대점 체크2 값:', typeof this.$route.query.isLastCount);
                if (this.$route.query.isLastCount) {
                  this.popupSet.isLastTrainingCount = true;
                }
                this.popupSet.cancelBtnText = null;
                this.popupSet.nextLink = '/trainingDate';
                this.popupSet.confirmBtnText = '확인';
                this.popupSet.destination = true;
              }
            }, 150);
          }
          /** 메인페이지 진입시 삶의의미 팝업을 띄울 수 있게
          if (this.$route.query.isLastCount) {
            if (time === "end") {
              localStorage.removeItem("passedDailyComment");
            }
          }
           */
        });
      } catch (error) {
        console.log('error :', errorParser.parse(error));
        console.log('error :', error);
      }
    },
    async callbackHerzList(list) {
      console.log('음도주입 진입');
      console.log('list : ', list);
      console.log('원본리스트 :', typeof list);
      const mutateArr = JSON.parse(list);
      console.log('변경된리스트 :', typeof mutateArr);
      console.log('target 0:', mutateArr[0]);
      console.log('target hertz:', mutateArr[0].hertz);
      console.log('mutateArr', JSON.parse(list));

      this.plyrValue.raisingList = mutateArr;
      this.sendTrainingData(
        this.apiStatus.detailId,
        this.plyrValue.currentRepeatCount,
        this.plyrValue.currentSetCount,
        this.plyrValue.raisingList,
      );
    },
    async sendTrainingData(detailId, num, set, list) {
      console.log('서버로 데이터 보내는 로직 진입');
      console.log('detailId :', detailId);
      console.log('num :', num);
      console.log('set :', set);
      console.log('hertzList :', JSON.stringify(list));
      console.log('hertzList_Length :', list.length);
      if (list.length > 0) {
        try {
          const payload = {
            detailId,
            list: [
              {
                number: num,
                set,
                hertzList: list,
              },
            ],
          };
          await this.SEND_TRAINING_DATA(payload).then((data) => {
            console.log(
              '[프로토콜 x 서버저장완료]',
              'num :',
              num,
              'set :',
              set,
              'list :',
              JSON.stringify(payload),
              data.response.isCompleted,
            );
          });
        } catch (error) {
          console.log('error :', errorParser.parse(error));
          console.log('error :', JSON.stringify(error));
        }
      } else {
        console.log('hertzList의 길이가 0');
      }
      /*
      console.log("detailId :", detailId);
      console.log("num :", num);
      console.log("set :", set);
      console.log("list :", list);
       */
    },
    setTrainingStatus(number, set) {
      /**
      console.log("training-status -number", number);
      console.log("training-status -set", set);
       */
      this.plyrValue.repeatCount = Number(number);
      this.plyrValue.setCount = Number(set);
    },
    async fetchTraining(id) {
      try {
        await this.FETCH_TRAINING(id).then((data) => {
          /**  시간세팅 */

          this.setTrainingStatus(this.$route.query.number, this.$route.query.set);
          /**
          console.log("시간세팅-data :", data);
          console.log("시간 포문", data.response.list.length);
           */
          for (let i = data.response.list.length - 1; i >= 0; i--) {
            if (data.response.list[i].number > 0) {
              console.log('i 진입점 :', i, ' 현재 데이터', data.response.list[i]);
              this.plyrValue.currentSetCount = data.response.list[i].set;
              this.plyrValue.currentRepeatCount = data.response.list[i].number;
              break;
            }
          }
          console.log(
            '포문결과 사용할 값 currentRepeatCount :',
            this.plyrValue.currentRepeatCount,
          );
          console.log('포문결과 사용할 값 repeatCount :', this.plyrValue.repeatCount);
          if (this.plyrValue.currentRepeatCount === null) {
            console.log('이미 설정된 연습파일을 실행');
            this.apiStatus.currentOrder = 0;
          } else if (this.plyrValue.currentRepeatCount === this.plyrValue.repeatCount) {
            this.view.isRaisingOn = false;
            this.plyrValue.isViewMilestone = true;
            this.plyrValue.setDone = true;
          } else {
            console.log('fetch - training');
            this.plyrValue.isViewMilestone = true;
            this.view.isFirst = true;
            this.view.isRaisingOn = false;
            this.apiStatus.currentOrder = 4;
            this.view.loadAudio.clientTraining = true;
            this.view.pastCurrentRepeat = this.plyrValue.currentRepeatCount;
          }

          if (
            this.plyrValue.isViewMilestone
            && this.plyrValue.currentSetCount === this.plyrValue.setCount
            && this.plyrValue.currentRepeatCount === this.plyrValue.repeatCount
          ) {
            this.plyrValue.allSetClear = true;
            this.view.isRaisingOn = false;
          }
          if (this.view.isFirstTraining) {
            this.loadPop(
              'training',
              'start',
              this.$route.query.day,
              this.getTimeToOrder(this.$route.query.time),
            );
          }
          if (this.view.isRaisingOn) {
            console.log('음도 처음 연결!');
            console.log('넘기는 값: ', this.apiStatus.detailId);
            console.log('[프로토콜] samkim://raisingPageStart');
            this.view.isRaisingLoaded = true;
            const url = `samkim://raisingPageStart?detailId=${
              this.apiStatus.detailId
            }&isFemale=${
              this.apiStatus.isFemale}`;
            document.location.href = url;
          }
          this.view.dataLoaded = true;
          this.$nextTick((_) => {
            this.initAudio();
          });
        });
      } catch (error) {
        console.log('error :', errorParser.parse(error));
        console.log('error :', error);
      }
    },
    handleGoback() {
      if (this.checkPopup) {
        this.SET_POPUP(false);
      } else {
        this.$router.push('/trainingDate');
      }
    },
    async trainingStart(type, time, day) {
      const factor = {
        type: this.getTypeToOrder(type),
        time: this.getTimeToOrder(time),
        day,
      };
      try {
        await this.START_TRAINING(factor).then((data) => {
          this.setTrainingStatus(this.$route.query.number, this.$route.query.set);
          this.apiStatus.detailId = data.response.detailId;
          this.fetchTraining(data.response.detailId);
        });
      } catch (error) {
        console.log('error :', errorParser.parse(error));
        console.log('error :', error);
      }
    },
    async checkTraining(type, time, day) {
      /**
      console.log("type", type);
      console.log("time", time);
      console.log("day", day);
       */
      const factor = {
        time: this.getTimeToOrder(time),
        day,
      };
      /**
      console.log("factor :", factor);
       */
      try {
        await this.CHECK_TRAINING(factor).then((data) => {
          /**
          console.log("data :", data);
          console.log('type : ',this.getTypeToOrder(type));
           */
          console.log('삼킴 처리여부 :', data.response.swallow.isCompleted);
          console.log('혀돌리기 처리여부 :', data.response.turn.isCompleted);
          window.callbackHerzList = this.callbackHerzList;
          this.apiStatus.detailId = data.response.raising.detailId;
          if (data.response.swallow.isCompleted && data.response.turn.isCompleted) {
            this.view.isLastTraining = true;
          }
          if (!data.response.raising.isExist) {
            if (!data.response.swallow.isExist && !data.response.turn.isExist) {
              this.view.isFirstTraining = true;
            }
            this.trainingStart(
              this.$route.query.type,
              this.$route.query.time,
              this.$route.query.day,
            );
          } else {
            this.fetchTraining(data.response.raising.detailId);
          }
        });
      } catch (error) {
        console.log('error :', errorParser.parse(error));
      }
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = '로그인 권한이 필요합니다.';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    btnClick(comment) {
      /**
      console.log("comment :", comment);
       */
      this.initProtocol();
      if (comment === 'prepare') {
        if (
          this.plyrValue.currentSetCount !== this.plyrValue.setCount
          || this.plyrValue.currentRepeatCount !== this.plyrValue.repeatCount
        ) {
          if (this.view.pastCurrentRepeat === null) {
            this.plyrValue.currentRepeatCount = null;
          }
        } else {
          this.plyrValue.allSetClear = true;
        }

        const { currentSetCount } = this.plyrValue;
        if (!this.plyrValue.allSetClear && this.view.pastCurrentRepeat === null) {
          this.plyrValue.currentSetCount = currentSetCount + 1;
        }

        this.initSource('announcer_ment_information_1.mp3');
        this.apiStatus.currentOrder = 0;
        this.plyrValue.isPrepare = true;
        this.$refs.plyr.player.play();
      } else if (comment === 'training') {
        if (this.view.pastCurrentRepeat !== null) {
          const returnValue = this.view.pastCurrentRepeat;
          this.plyrValue.currentRepeatCount = returnValue;
          this.view.pastCurrentRepeat = null;
        } else {
          const { currentSetCount } = this.plyrValue;
          if (!this.plyrValue.allSetClear && this.view.pastCurrentRepeat === null) {
            this.plyrValue.currentSetCount = currentSetCount + 1;
          }
          this.plyrValue.currentRepeatCount = 0;
        }

        this.apiStatus.currentOrder = 4;
        this.initSource('client_training.mp3');
        this.callCountAnimation();

        /**
        const { currentSetCount } = this.plyrValue;
         if (!this.plyrValue.allSetClear && this.view.pastCurrentRepeat === null) {
          this.plyrValue.currentSetCount = currentSetCount + 1;
         }
         */
      }

      this.plyrValue.isViewMilestone = false;
      this.plyrValue.setDone = false;
    },
    initSource(file) {
      this.$refs.plyr.player.source = {
        type: 'audio',
        title: 'Example title',
        sources: [
          {
            src: require(`@/assets/audio/raising/${file}`),
          },
        ],
      };
      this.plyrValue.isPrepare = false;
    },
    initAudio() {
      this.view.loadDone = true;
      setTimeout(() => {
        this.plyrValue.player = this.$refs.plyr.player;
        if (this.apiStatus.currentOrder === null) {
          this.apiStatus.currentOrder = 0;
        }

        /** 초기 그래프 공간 띄워주는 */
        /** 시간업데이트 */
        this.$refs.plyr.player.on('timeupdate', (data) => {
          /**
      const value = data.detail.plyr;
       */
        });
        /** 오디오 초기시작 */
        this.$refs.plyr.player.on('playing', (data) => {});

        /** 오디오 재개 */
        this.$refs.plyr.player.on('play', (data) => {
          /**
      console.log("오디오 시작");
       */
          console.log('current-order :', this.apiStatus.currentOrder);
          console.log('현재 반복수치 :', this.plyrValue.currentRepeatCount);
          console.log('현재 반복수치 - 세트 :', this.plyrValue.currentSetCount);
          console.log('절대 반복수치 :', this.plyrValue.repeatCount);
          console.log('절대 반복수치 - 세트 :', this.plyrValue.setCount);
          if (!this.view.isFirst) {
            clearInterval(this.animate.intervalObj);
            this.animate.value = null;
            this.animate.isDone = false;
            if (this.view.isRaisingPaused) {
              this.view.isRaisingPaused = false;
              console.log('[음도그래프] 중지된 음도그래프 재개함');
              console.log('[프로토콜] samkim://raisingPlay');
              /** 음도 */
              const url = 'samkim://raisingPlay';
              document.location.href = url;
            } else if (this.apiStatus.currentOrder === 1) {
              console.log(
                '[음도그래프] 아나운서 더미 음도그래프 호출요청 :',
                this.apiStatus.currentOrder,
              );
              /** 음도 */
              const url = 'samkim://raisingSample1';
              console.log('[프로토콜] samkim://raisingSample1');
              document.location.href = url;
            } else if (this.apiStatus.currentOrder === 3) {
              console.log(
                '[음도그래프] 환자 더미 음도그래프 호출요청 :',
                this.apiStatus.currentOrder,
              );
              /** 음도 */
              console.log('[프로토콜] samkim://raisingSample2');
              const url = 'samkim://raisingSample2';
              document.location.href = url;
            } else if (
              !this.plyrValue.allSetClear
              && this.plyrValue.currentRepeatCount !== null
              && this.apiStatus.currentOrder > 3
            ) {
              if (this.view.limitCall) {
                console.log('4번 이후 음도 스타트 시작예정');
              } else {
                console.log(
                  '[음도그래프] 음도그래프 시작호출요청 :',
                  this.apiStatus.currentOrder,
                );
                /** 음도 */
                console.log('[프로토콜] samkim://raisingStart');
                const url = 'samkim://raisingStart';
                document.location.href = url;
              }
            }
            /** 음도 */
          } else {
            /**
        console.log("처음진입 애니메이션 뜸");
         */ if (
              !this.plyrValue.isPrepare
            ) {
              this.$refs.plyr.player.pause();
              this.callCountAnimation();
            }
          }
        });
        /** 오디오 중지 */
        this.$refs.plyr.player.on('pause', (data) => {
          console.log('pause 에서 리밋콜:', this.view.limitCall);
          /**
      console.log("오디오 중지버튼");
       */
          if (!this.view.isFirst) {
            if (data.detail.plyr.currentTime <= 14) {
              if (this.apiStatus.currentOrder === 1) {
                console.log('[음도그래프] 그래프 중지요청함 (아나운서 더미 그래프)');
                this.view.isRaisingPaused = true;
                /** 음도 */
                console.log('[프로토콜] samkim://raisingPause');
                const url = 'samkim://raisingPause';
                document.location.href = url;
              } else if (this.apiStatus.currentOrder === 3) {
                console.log('[음도그래프] 그래프 중지요청함 (환자 더미 그래프)');
                this.view.isRaisingPaused = true;
                /** 음도 */
                console.log('[프로토콜] samkim://raisingPause');
                const url = 'samkim://raisingPause';
                document.location.href = url;
              } else if (this.apiStatus.currentOrder > 3 && this.view.limitCall) {
                console.log('[특수프로토콜 리밋콜]');
              } else if (
                !this.plyrValue.allSetClear
                && this.plyrValue.currentRepeatCount !== null
                && this.apiStatus.currentOrder > 3
              ) {
                console.log('[프로토콜] samkim://raisingPause');
                console.log('[음도그래프] 일반 훈련의경우');
                this.view.isRaisingPaused = true;
                /** 음도 */

                const url = 'samkim://raisingPause';
                document.location.href = url;
              }
            }
            clearInterval(this.animate.intervalObj);
          }
        });

        /** 오디오 완료 */
        this.$refs.plyr.player.on('ended', (data) => {
          console.log('엔드진입: ', this.view.isFirst);
          const order = this.apiStatus.currentOrder;
          console.log('찐오더 :', order);
          if (this.apiStatus.currentOrder > 3) {
            console.log('마일스톤 진입');
          } else {
            if (this.apiStatus.currentOrder === 0) {
              if (this.apiStatus.isFemale) {
                this.initSource('announcer_prepare.mp3');
              } else {
                /** 남자 */ this.initSource('announcer_prepare.mp3');
              }
            } else if (this.apiStatus.currentOrder === 1) {
              console.log('[음도그래프] 아나운서 더미 그래프 종료됨 (프로토콜 호출x)');
              console.log('[음도그래프 오더] 현재 수치:', this.apiStatus.currentOrder);
              this.initSource('announcer_ment_3.mp3');
            } else if (this.apiStatus.currentOrder === 2) {
              console.log('[음도그래프 오더] 현재 수치:', this.apiStatus.currentOrder);
              this.initSource('client_training.mp3');
            } else if (this.apiStatus.currentOrder === 3) {
              console.log('[음도그래프] 환자 더미 그래프 종료됨 (프로토콜 호출x)');
              console.log('[음도그래프 오더] 현재 수치:', this.apiStatus.currentOrder);
              this.initSource('announcer_ment_information_5.mp3');
              this.view.limitCall = true;
            }
            setTimeout(() => {
              this.$refs.plyr.player.play();
            }, 500);
            this.apiStatus.currentOrder = order + 1;
          }
        });
        /** 비디오 전체화면 시작 */
        this.$refs.plyr.player.on('enterfullscreen', (data) => {});

        /** 비디오 전체화면 중지 */
        this.$refs.plyr.player.on('exitfullscreen', (data) => {});
        if (this.view.clientTraining) {
          this.initSource('client_training.mp3');
        }
        if (this.view.isRaisingOn) {
          this.$refs.plyr.player.play();
        }
      }, 500);
    },
    callCountAnimation() {
      let currentValue;
      this.animate.intervalObj = setInterval(() => {
        currentValue = this.animate.value;
        if (currentValue === null) {
          this.animate.value = '준비';
        } else if (currentValue === '준비') {
          this.animate.value = '시작';
        } else if (currentValue === '시작') {
          this.animate.isDone = true;
          clearInterval(this.animate.intervalObj);
          if (this.view.isFirst) {
            this.view.isFirst = false;
          }
          this.$refs.plyr.player.play();
        }
      }, 1000);
    },
    popTest() {
      this.SET_POPUP(true);
      this.popupSet.content = '테스트';
      this.popupSet.cancelBtnText = '취소';
      this.popupSet.nextLink = '/';
      this.popupSet.confirmBtnText = '실행';
    },
  },
  computed: {
    ...mapState('basic', ['checkPopup']),
    getCommentTypeToOrder() {
      return (order) => {
        console.log('getCommentTypeToOrder -order :', order);
        console.log('getCommentTypeToOrder -type :', typeof order);
        if (Number(order) === 0) {
          return 'total';
        }
        if (Number(order) === 1) {
          return 'life';
        }
        if (Number(order) === 2) {
          return 'training';
        }
      };
    },
    getTypeToOrder() {
      return (order) => {
        /**
        console.log("getTypeToOrder -order :", order);
        console.log("getTypeToOrder -type :", typeof order);
         */
        if (Number(order) === 0) {
          return 'swallow';
        }
        if (Number(order) === 1) {
          return 'raising';
        }
        if (Number(order) === 2) {
          return 'turn';
        }
      };
    },
    getTimeToOrder() {
      return (order) => {
        console.log('getTimeToOrder -order :', order);
        console.log('getTimeToOrder -type :', typeof order);
        if (Number(order) === 0) {
          return 'morning';
        }
        if (Number(order) === 1) {
          return 'lunch';
        }
        if (Number(order) === 2) {
          return 'evening';
        }
      };
    },
    getCountToOrder() {
      return (order) => {
        /**
        console.log("getCountToOrder -order :", order);
        console.log("getCountToOrder -type :", typeof order);
         */
        if (Number(order) === 0) {
          return '1';
        }
        if (Number(order) === 1) {
          return '2';
        }
        if (Number(order) === 2) {
          return '3';
        }
      };
    },
  },
  components: {
    SideMenu,
    Notification,
    DefaultPopup,
    Directive,
  },
  beforeDestroy() {
    console.log('[프로토콜] samkim://raisingPageEnd');
    const url = 'samkim://raisingPageEnd';
    document.location.href = url;
  },
};
</script>
<style lang="scss" scoped>
.app-context {
  .wrap-context {
    width: 934px;
    margin: 22px 90px 0 auto;
    .training-notification {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 85px;
      height: 38px;
      border-radius: 19px;
      border: 1px solid #fd8b11;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      opacity: 0;
      z-index: 5;
      top: -45px;
      &.active {
        opacity: 1;
      }
      &.prepare {
        background: #fff;
        span {
          color: #fd8b11;
        }
      }
      &.start {
        background: #fd8b11;
        span {
          color: #fff;
        }
      }
      span {
        display: block;
        @include initfontKor(
          $font-size: 26px,
          $line-height: 24px,
          $font-family: "AppleSDGothicNeoB00",
          $letter-spacing: -0.52px,
          $text-align: left
        );
      }
    }
    .low-margin {
      margin: 0 0 56px 0;
    }

    .directive.low {
      margin: 0 0 56px 0;
    }

    .camera-context {
      position: relative;
      .item-box {
        position: relative;
        min-height: 343px;
        background: transparent;
        .info {
          position: relative;
          width: calc(50% - 5px);
          border-radius: 2px;
          padding: 30px;
          border: solid 1px #bbbcc1;
          background-color: #ffffff;
          margin: 0;
          &.first {
            margin: 0 10px 0 0;
          }
          .bg-img {
            position: absolute;
            left: 0;
            top: 0;
            img {
            }
          }
          .item-wrap {
            .item {
              margin: 25px 0 0 0;
              &:first-of-type {
                margin: 0;
              }
              &.flex {
                margin: 0;
              }
              h3.title {
                margin: 0 0 10px 0;
                @include initfontKor(
                  $font-size: 30px,
                  $line-height: 36px,
                  $font-family: "AppleSDGothicNeoB00",
                  $color: #315754,
                  $letter-spacing: -0.6px,
                  $text-align: left
                );
              }
              .orderList {
                p {
                  padding: 0 0 0 15px;
                  position: relative;
                  @include initfontKor(
                    $font-size: 22px,
                    $line-height: 28px,
                    $font-family: "AppleSDGothicNeoM00",
                    $color: #363739,
                    $letter-spacing: -0.44px,
                    $text-align: left
                  );
                  &:after {
                    position: absolute;
                    content: "-";
                    left: 0;
                    top: 0;
                  }
                }
              }
            }
          }
        }
        .animator-wrap {
          z-index: 2;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: transparent;
          transition: 0.5s;
          display: none;
          &.active {
            display: block;
          }
          &.pause {
            display: block;
            background: rgba(54, 61, 65, 0.5);
          }
          .animator {
            .btn-wrap {
              display: flex;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              flex-wrap: wrap;
              .item {
                width: 100%;
                &:first-of-type {
                  margin: 0;
                  button {
                    background: #21978c;
                    &:after {
                      background-image: url("~@assets/images/util/icon_replay.svg");
                    }
                  }
                }
                margin: 20px 0 0 0;
                button {
                  width: 290px;
                  border: 0;
                  padding: 0 40px 0 17.5px;
                  @include initfontKor(
                    $font-size: 30px,
                    $line-height: 52px,
                    $font-family: "AppleSDGothicNeoM00",
                    $color: #fff,
                    $letter-spacing: -0.6px,
                    $text-align: left
                  );
                  position: relative;
                  background: #fd8b11;
                  cursor: pointer;
                  &:after {
                    content: "";
                    width: 36px;
                    height: 36px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 11px;
                    background-image: url("~@assets/images/util/icon_nextplay.svg");
                  }
                }
              }
            }
            .half-circle-timer {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 80px;
              height: 80px;
              border-radius: 50%;
              overflow: hidden;
              display: flex;
              align-items: center;
              justify-content: center;
              background: rgb(54, 61, 65);
              span {
                @include initfontKor(
                  $font-size: 19px,
                  $line-height: 24px,
                  $font-family: "AppleSDGothicNeoL00",
                  $color: #fff,
                  $letter-spacing: -0.6px,
                  $text-align: center
                );
              }
            }
            .info-wrap {
              width: 95px;
              height: 95px;
              background: rgb(54, 61, 65);
              border-radius: 50%;
              left: 0;
              position: absolute;
              top: 0;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: center;
              .holder {
              }
              h4.title {
                @include initfontKor(
                  $font-size: 19px,
                  $line-height: 24px,
                  $font-family: "AppleSDGothicNeoL00",
                  $color: #fff,
                  $letter-spacing: -0.6px,
                  $text-align: center
                );
                margin: 0 0 8px 0;
                width: 100%;
              }
              .content {
                display: flex;
                align-items: center;
                justify-content: center;
                .img-wrap {
                  img {
                    max-width: 34px;
                  }
                }
                p {
                  @include initfontKor(
                    $font-size: 19px,
                    $line-height: 24px,
                    $font-family: "AppleSDGothicNeoL00",
                    $color: #fff,
                    $letter-spacing: -0.6px,
                    $text-align: center
                  );
                  margin: 0 0 0 5px;
                }
              }
            }
          }
        }
        &:nth-of-type(3) {
          margin: 10px 0 0 0;
          background: transparent;
          min-height: inherit;
          display: flex;
        }
        &.activated {
          .camera-attach {
            height: 100%;
            background: #676767;
          }
          .training-status {
            padding: 15px;
            background: rgb(54, 61, 65);
            width: 100%;
            bottom: 0;
            border: solid 1px #bbbcc1;
            background: #fff;
            width: calc(50% - 5px);
            margin: 0 0 0 10px;
            .item {
              margin: 10px 0 0 0;
              display: flex;
              border-radius: 4px;
              overflow: hidden;
              background: #e9edec;
              h3.i-header {
                width: 60px;
                @include initfontKor(
                  $font-size: 20px,
                  $line-height: 38px,
                  $font-family: "AppleSDGothicNeoB00",
                  $color: #315754,
                  $letter-spacing: -0.4px,
                  $text-align: center
                );
                background: #e9edec;
              }
              .i-content {
                width: calc(100% - 60px);
                display: flex;
                &.relative {
                  position: relative;
                }
                .input-wrap {
                  min-width: 120px;
                  height: 30px;
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  border: solid 1px #315754;
                }
                p.unit {
                  @include initfontKor(
                    $font-size: 20px,
                    $line-height: 36px,
                    $font-family: "AppleSDGothicNeoM00",
                    $color: #315754,
                    $letter-spacing: -0.4px,
                    $text-align: center
                  );
                  padding-left: 125px;
                }
                span.num {
                  display: block;
                  background: #e9edec;
                  border: 0px solid #fff;
                  border-width: 0 1px;
                  min-width: 37px;
                  @include initfontKor(
                    $font-size: 20px,
                    $line-height: 36px,
                    $font-family: "AppleSDGothicNeoM00",
                    $color: #315754,
                    $letter-spacing: -0.4px,
                    $text-align: center
                  );
                  &.active {
                    background: #315754;
                    color: #fff;
                    font-family: "AppleSDGothicNeoB00";
                  }
                }
              }
            }
            &:first-of-type {
            }
          }
        }
      }
    }
  }
}
.video-player {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 2;
}
</style>
