<template>
  <div class="app-context flex">
    <side-menu
      :sideMenuOption="sideMenuOption"
      @is-unauthorized="isUnAuthorized"
    ></side-menu>
    <div class="wrap-context">
      <h5 class="page-title">공지사항</h5>
      <result-list
        :resultList="resultList"
        :resultType="3"
        @call-alerm-pop="callAlermPop"
        ref="resultList"
        v-if="view.isListLoaded"
      ></result-list>
      <pagination
        @btn-click="paginationClick"
        :paginationInfo="paginationInfo"
        v-if="view.isListLoaded"
      ></pagination>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import errorParser from '@/utils/error-parser';
import DefaultPopup from '@/components/modal/DefaultPopup';
import SideMenu from '@/components/SideMenu.vue';
import ResultList from '@/components/ResultList.vue';
import Pagination from '@/components/Pagination.vue';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  data() {
    return {
      paginationInfo: {
        totalDataCnt: null,
        dataOnPage: null,
        totalPage: null,
        currentPage: null,
        prevApiUrl: null,
        nextApiUrl: null,
      },
      apiStatus: {
        currentPage: 1,
      },
      resultList: {
        header: ['번호', '내용', '날짜'],
        list: [],
      },
      sideMenuOption: [
        {
          img: 'sidebar_item01.png',
          img_active: 'sidebar_menu1_active.png',
          img_active_deep: 'sidebar_menu1_active_deep.png',
          title: '메인',
          isActive: false,
          link: '',
        },
        {
          img: 'sidebar_item02.png',
          img_active: 'sidebar_menu2_active.png',
          img_active_deep: 'sidebar_menu2_active_deep.png',
          title: '삼킴 평가',
          isActive: false,
          link: 'evaluate',
        },
        {
          img: 'sidebar_item03.png',
          img_active: 'sidebar_menu3_active.png',
          img_active_deep: 'sidebar_menu3_active_deep.png',
          title: '삼킴 훈련',
          isActive: false,
          link: 'trainingDate',
        },

        {
          img: 'sidebar_item05.png',
          img_active: 'sidebar_menu5_active.png',
          img_active_deep: 'sidebar_menu5_active_deep.png',
          title: '자세법ㆍ식이법',
          isActive: false,
          link: 'choiceType',
        },
        {
          img: 'sidebar_item06.png',
          img_active: 'sidebar_menu6_active.png',
          img_active_deep: 'sidebar_menu6_active_deep.png',
          title: '원격 상담',
          isActive: false,
          link: 'reservationType',
        },
        {
          img: 'sidebar_mypage.png',
          img_active: 'sidebar_mypage_active.png',
          img_active_deep: 'sidebar_mypage_active.png',
          title: '마이페이지',
          isActive: true,
          link: 'mypage',
        },
      ],
      popupSet: {},
      view: {
        isListLoaded: false,
      },
    };
  },
  created() {
    if (history.pushState !== undefined) {
      history.pushState({}, '', location.href);
      window.onpopstate = () => {
        history.pushState({}, '', location.href);
        this.handleGoback();
      };
    }
    this.getResultList(null);
  },
  mounted() {
    /**
    this.popTest();
     */
  },
  methods: {
    ...mapMutations('basic', ['SET_POPUP']),
    ...mapActions('util', [
      'FETCH_NOTICE_LIST',
      'FETCH_NOTICE_LIST_PAGE',
      'FETCH_NOTICE_DETAIL',
    ]),

    async getResultList(page) {
      this.view.isListLoaded = false;
      if (page !== null) {
        try {
          const payload = {
            page,
          };
          await this.FETCH_NOTICE_LIST_PAGE(payload).then((data) => {
            this.resultList.list = data.response.list;
            this.paginationInfo.totalDataCnt = data.response.totalCount;
            this.paginationInfo.dataOnPage = 5;
            this.paginationInfo.totalPage = data.response.endPage;
            this.paginationInfo.currentPage = Number(page);
            this.paginationInfo.prevApiUrl = data.response.previous;
            this.paginationInfo.nextApiUrl = data.response.next;
            this.view.isListLoaded = true;
          });
        } catch (error) {
          console.log('error :', errorParser.parse(error));
          console.log('error :', error);
        }
      } else {
        /** 디폴트 */
        try {
          await this.FETCH_NOTICE_LIST().then((data) => {
            this.resultList.list = data.response.list;
            this.paginationInfo.totalDataCnt = data.response.totalCount;
            this.paginationInfo.dataOnPage = 5;
            this.paginationInfo.totalPage = data.response.endPage;
            this.paginationInfo.currentPage = 1;
            this.paginationInfo.prevApiUrl = data.response.previous;
            this.paginationInfo.nextApiUrl = data.response.next;
            this.view.isListLoaded = true;
          });
        } catch (error) {
          console.log('error :', errorParser.parse(error));
          console.log('error :', error);
        }
      }
    },
    handleGoback() {
      if (this.checkPopup) {
        this.SET_POPUP(false);
      } else {
        this.$router.push('/mypage');
      }
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = '로그인 권한이 필요합니다.';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    async callAlermPop(day, id) {
      /** api 조회한 값을  wishContent에 대입  */
      console.log('day :', day);
      console.log('id :', id);
      try {
        await this.FETCH_NOTICE_DETAIL(id).then((data) => {
          console.log('data :', data);
          this.SET_POPUP(true);
          this.popupSet.popType = 'notice';
          this.popupSet.cancelBtnText = '확인';
          this.popupSet.alermContent = data.response.content;
          this.popupSet.alermDate = day;
          this.popupSet.nextLink = null;
          this.popupSet.confirmBtnText = null;
        });
      } catch (error) {
        console.log('error :', error);
        console.log('error :', errorParser.parse(error));
      }
    },
    paginationClick(pageNum) {
      /** 리스트를 다시그리는 API 수행 */
      console.log('페이지네이션 클릭수신 :', pageNum);
      this.apiStatus.currentPage = pageNum;
      this.getResultList(pageNum);
    },
    removeA(arr) {
      let what;
      const a = arguments;
      let L = a.length;
      let ax;
      while (L > 1 && arr.length) {
        what = a[--L];
        while ((ax = arr.indexOf(what)) !== -1) {
          arr.splice(ax, 1);
        }
      }
      return arr;
    },
  },
  computed: {
    ...mapState('basic', ['checkPopup']),
  },
  components: {
    SideMenu,
    DefaultPopup,
    ResultList,
    Pagination,
  },
};
</script>
<style lang="scss" scoped>
.app-context {
  .wrap-context {
    width: 934px;
    margin: 22px 90px 0 auto;
    .notification-alerm {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 0 0 10px 0;
      h4.title {
        margin: 0 7px 0 0;
        @include initfontKor(
          $font-size: 24px,
          $line-height: 30px,
          $font-family: "AppleSDGothicNeoB00",
          $color: #315754,
          $letter-spacing: -0.48px,
          $text-align: center
        );
      }
    }
  }
}
</style>
