import { training } from "@/api";

const state = {
  training: {
    /**
    drId: localStorage.getItem('drId') || '',
     */
  }
};

const mutations = {
  /**
  SET_FCMTOKEN(state, fcmToken) {
    state.auth.fcmToken = fcmToken;
  },
   */
};

const getters = {
  /**
  GET_FCMTOKEN: state => state.auth.fcmToken,
   */
};

const actions = {
  /**
  LOGIN_AUTO({}, loginInfo) {
    return auth.loginAuto(loginInfo);
  }
   */
  CHECK_TRAINING({}, info) {
    return training.checkTraining(info);
  },
  START_TRAINING({}, info) {
    return training.startTraining(info);
  },
  FETCH_TRAINING({}, detailId) {
    return training.fetchTraining(detailId);
  },
  FETCH_TRAINING_INFO({}) {
    return training.fetchTrainingInfo();
  },
  SEND_TRAINING_DATA({}, trainingData) {
    return training.sendTrainingData(trainingData);
  },
  FETCH_MONTHLIST_TRAINING({}, dateOpt) {
    return training.fetchMonthList(dateOpt);
  },
  FETCH_TRAINING_DATE({}, id) {
    return training.fetchTrainingDate(id);
  }
};
export default {
  strict: process.env.NODE_ENV !== "production",
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
