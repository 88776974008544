<template>
  <div class="app-context flex">
    <side-menu
      :sideMenuOption="sideMenuOption"
      @is-unauthorized="isUnAuthorized"
    ></side-menu>
    <div class="wrap-context">
      <h5 class="page-title complex">평가조회</h5>
      <survey-navigation
        :dateList="dateList"
        @open-calendar="calendarPop"
        @call-list-date="callListInfo"
        @call-list-fail="callListFail"
        ref="surveyNav"
        v-if="view.isSurveyNavigationVisible"
      >
      </survey-navigation>
      <!-- 탭메뉴 시작 -->
      <tabs ref="tabs" :tabType="tabType" @tab-selected="tabSelected">
        <tab :tabData="tab[0]">
          <div class="list-wrap inquiry" v-if="evaluateList.functional.length > 0">
            <div class="main-top">
              <h3>순서</h3>
              <h3>설문내용</h3>
              <h3>답변</h3>
            </div>
            <div
              class="item"
              v-for="(listItem, index) in evaluateList.functional"
              :key="index + 'A'"
            >
              <h3 class="index" v-text="`${index + 1}`"/>
              <h4 class="header" v-text="listItem.questionText" />
              <h4 class="content" v-text="getAnswer(listItem)" />
            </div>
          </div>
          <div class="list-wrap inquiry alone" v-else>
            <p>평가내용이 없습니다.</p>
          </div>
        </tab>
        <tab :tabData="tab[1]" v-if="evaluateList.chewing.length > 0">
          <div class="list-wrap inquiry" v-if="evaluateList.chewing.length > 0">
            <div
              class="item"
              v-for="(listItem, index) in evaluateList.chewing"
              :key="index + 'A'"
            >
              <h3 class="index" v-text="`${index + 1}`"/>
              <h4 class="header" v-text="listItem.questionText" />
              <h4 class="content" v-text="getAnswer(listItem)" />
            </div>
          </div>
          <div class="list-wrap inquiry alone" v-else>
            <p>평가내용이 없습니다.</p>
          </div>
        </tab>
        <tab :tabData="tab[2]" v-if="evaluateList.swallow.length > 0">
          <div class="list-wrap inquiry" v-if="evaluateList.swallow.length > 0">
            <div
              class="item"
              v-for="(listItem, index) in evaluateList.swallow"
              :key="index + 'A'"
            >
              <h3 class="index" v-text="`${index + 1}`"/>
              <h4 class="header" v-text="listItem.questionText" />
              <h4 class="content" v-text="getAnswer(listItem)" />
            </div>
          </div>
          <div class="list-wrap inquiry alone" v-else>
            <p>평가내용이 없습니다.</p>
          </div>
        </tab>
        <tab :tabData="tab[3]">
          <div class="list-wrap inquiry" v-if="evaluateList.gds.length > 0">
            <div
              class="item"
              v-for="(listItem, index) in evaluateList.gds"
              :key="index + 'A'"
            >
              <h3 class="index" v-text="`${index + 1}`"/>
              <h4 class="header" v-text="listItem.questionText" />
              <h4 class="content" v-text="getAnswer(listItem)" />
            </div>
          </div>
          <div class="list-wrap inquiry alone" v-else>
            <p>평가내용이 없습니다.</p>
          </div>
        </tab>
      </tabs>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
    <calendar-popup
      @range-setted="changeDateRange"
      v-if="checkCalendarPopup"
      :popupSetCalendar="popupSetCalendar"
    ></calendar-popup>
  </div>
</template>

<script>
import errorParser from '@/utils/error-parser';
import CalendarPopup from '@/components/modal/CalendarPopup';
import SideMenu from '@/components/SideMenu.vue';
import SurveyNavigation from '@component/SurveyNavigation.vue';
import Tabs from '@/components/Tabs.vue';
import Tab from '@/components/Tab.vue';
import { mapMutations, mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      dateList: [],
      evaluateList: {
        functional: [],
        chewing: [],
        swallow: [],
        gds: [],
      },
      tabType: 2,
      selectOrder: 4,
      apiStatus: {
        currentYear: '',
        currentMonth: '',
        currentType: '',
        currentDate: '',
      },
      fetchOpt: {
        id: null,
        date: null,
        firstDay: null,
        lastDay: null,
      },
      tab: [
        {
          title: '삼킴 기능',
        },
        {
          title: '씹기 삶의 질',
        },
        {
          title: '삼키 삶의 질',
        },
        {
          title: 'GDS',
        },
      ],
      sideMenuDeep: true,
      sideMenuOption: [
        {
          img: 'sidebar_item01.png',
          img_active: 'sidebar_menu1_active.png',
          img_active_deep: 'sidebar_menu1_active_deep.png',
          title: '메인',
          isActive: false,
          link: '',
        },
        {
          img: 'sidebar_item02.png',
          img_active: 'sidebar_menu2_active.png',
          img_active_deep: 'sidebar_menu2_active_deep.png',
          title: '삼킴 평가',
          isActive: true,
          link: 'evaluate',
        },
        {
          img: 'sidebar_item03.png',
          img_active: 'sidebar_menu3_active.png',
          img_active_deep: 'sidebar_menu3_active_deep.png',
          title: '삼킴 훈련',
          isActive: false,
          link: 'trainingDate',
        },

        {
          img: 'sidebar_item05.png',
          img_active: 'sidebar_menu5_active.png',
          img_active_deep: 'sidebar_menu5_active_deep.png',
          title: '자세법ㆍ식이법',
          isActive: false,
          link: 'choiceType',
        },
        {
          img: 'sidebar_item06.png',
          img_active: 'sidebar_menu6_active.png',
          img_active_deep: 'sidebar_menu6_active_deep.png',
          title: '원격 상담',
          isActive: false,
          link: 'reservationType',
        },
        {
          img: 'sidebar_mypage.png',
          img_active: 'sidebar_mypage_active.png',
          img_active_deep: 'sidebar_mypage_active.png',
          title: '마이페이지',
          isActive: false,
          link: 'mypage',
        },
      ],
      popupSet: {},
      popupSetCalendar: {},
      view: {
        isSurveyNavigationVisible: false,
        passedFirstTab: false,
      },
    };
  },
  created() {
    if (history.pushState !== undefined) {
      history.pushState({}, '', location.href);
      window.onpopstate = () => {
        history.pushState({}, '', location.href);
        this.handleGoback();
      };
    }

    /** 날짜조회후 gte - lte 데이터 불러옴 */
    this.getDateRange();
  },
  mounted() {
    /**
    this.popTest();
     */
  },
  methods: {
    ...mapMutations('basic', ['SET_POPUP', 'SET_CALENDAR_POPUP']),
    ...mapActions('evaluate', ['FETCH_EVALUATE_LIST', 'FETCH_MONTHLIST']),
    callListFail() {
      console.log('진행한 평가가 없음', this.transToType(this.selectOrder));
      this.$nextTick((_) => {
        this.view.passedFirstTab = true;
      });
    },
    handleGoback() {
      if (this.checkPopup) {
        this.SET_POPUP(false);
      } else {
        this.$router.push('/evaluateExplain');
      }
    },
    initEvaluateList() {
      const arrayInit = [];
      const commonObj = {
        list: [],
        audioList: [],
      };
      this.evaluateList.structure = arrayInit;
      this.evaluateList.water = commonObj;
      this.evaluateList.solid = arrayInit;
      this.evaluateList.vocalization = arrayInit;
    },
    callListInfo(info) {
      console.log('info 정보받음 :', info);
      this.view.isSurveyNavigationVisible = true;
      this.fetchOpt.date = info.date;
      this.fetchOpt.id = info.id;
      this.selectOrder = 4;
      console.log(this.selectOrder, ' : selectOrder ');
      this.getEvaluateList(this.selectOrder);
    },
    async changeDateRange(gte, lte, goalDate) {
      console.log('찾기', goalDate);
      console.log('범위 바꾸는 곳에 들어감');
      this.fetchOpt.firstDay = gte;
      this.fetchOpt.lastDay = lte;
      try {
        const payload = {
          type: 'survey',
          gte,
          lte,
        };
        await this.FETCH_MONTHLIST(payload).then((data) => {
          if (goalDate !== null) {
            this.$refs.surveyNav.initList(data.response.list, goalDate);
          } else {
            this.$refs.surveyNav.initList(data.response.list);
          }
        });
      } catch (error) {
        console.log('error :', String(errorParser.parse(error)));
      }
    },
    async getDateRange() {
      /** select-order를 타입으로 */
      const date = new Date();
      const currentMonth = date.getMonth() + 1;
      const currentYear = date.getFullYear();
      let monthVal;
      let yearVal;
      if (currentMonth < 3) {
        monthVal = currentMonth + 12;
        yearVal = currentYear - 1;
      } else if (currentMonth < 10) {
        monthVal = currentMonth - 12;
        yearVal = currentYear + 1;
      } else {
        monthVal = currentMonth;
        yearVal = currentYear;
      }
      const firstDay = this.$moment(new Date(yearVal, monthVal - 4, 1)).format(
        'YYYY-MM-DD',
      );
      const lastDay = this.$moment(new Date(yearVal, monthVal + 3, 0)).format(
        'YYYY-MM-DD',
      );
      this.fetchOpt.firstDay = firstDay;
      this.fetchOpt.lastDay = lastDay;
      try {
        const payload = {
          type: 'survey',
          gte: firstDay,
          lte: lastDay,
        };
        await this.FETCH_MONTHLIST(payload).then((data) => {
          this.dateList = data.response.list;
          this.view.isSurveyNavigationVisible = true;
        });
      } catch (error) {
        console.log('error :', String(errorParser.parse(error)));
      }
      console.log('firstDay :', firstDay);
      console.log('lastDay :', lastDay);
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = '로그인 권한이 필요합니다.';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    async tabSelected(order) {
      this.selectOrder = order;
      console.log('tab-order :', order);
      if (this.view.passedFirstTab) {
        this.changeDateRange(
          this.fetchOpt.firstDay,
          this.fetchOpt.lastDay,
          this.fetchOpt.date,
        );
      }
    },
    async getEvaluateList(order) {
      /**
       * API수신 후 list 정보를 구한다.
       */
      this.initEvaluateList();
      let payload;
      if (this.fetchOpt.id !== null) {
        try {
          payload = {
            type: 'survey',
            id: this.fetchOpt.id,
          };
          await this.FETCH_EVALUATE_LIST(payload).then((data) => {
            this.evaluateList.functional = data.response.list.slice(0, 18);
            this.evaluateList.chewing = data.response.list.slice(18, 28);
            this.evaluateList.swallow = data.response.list.slice(28, 38);
            this.evaluateList.gds = data.response.list.slice(38, 54);
            if (this.evaluateList.chewing[this.evaluateList.chewing.length - 1].answerText === '') {
              this.evaluateList.chewing = [];
            }
            if (this.evaluateList.swallow[this.evaluateList.swallow.length - 1].answerText === '') {
              this.evaluateList.swallow = [];
            }
          });
        } catch (error) {
          console.log('error: ', errorParser.parse(error));
        }
      }
      this.view.passedFirstTab = true;
    },
    calendarPop() {
      this.SET_CALENDAR_POPUP(true);
      this.popupSetCalendar.calendarType = 0;
      this.popupSetCalendar.selectOrder = this.selectOrder;
    },
  },
  computed: {
    ...mapState('basic', ['checkPopup', 'checkCalendarPopup']),
    getAnswer() {
      return (item) => {
        if (item.etcText === null) {
          return item.answerText;
        }
        return item.etcText;
      };
    },
    makeZero() {
      return (timeValue) => `0${timeValue}`;
    },
    transToType() {
      return (order) => {
        if (order === 0) {
          return 'structure';
        } if (order === 1) {
          return 'water';
        } if (order === 2) {
          return 'solid';
        } if (order === 3) {
          return 'vocalization';
        } if (order === 4) {
          return 'survey';
        }
      };
    },
  },
  components: {
    SideMenu,
    CalendarPopup,
    SurveyNavigation,
    Tabs,
    Tab,
  },
};
</script>
<style lang="scss" scoped>
.app-context {
  .wrap-context {
    width: 870px;
    margin: 22px 122px 0 auto;
    position: relative;
    .tab-wrapper {
      max-height: 440px;
      overflow-y: scroll;
      padding: 20px 10px;
    }
    .list-wrap {
      &.inquiry {
        display: flex;
        flex-direction: column;
        width: 100%;
        &.alone {
          display: block;
          border-bottom: 0;
          p {
            @include initfontKor(
              $font-size: 26px,
              $line-height: 66px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #292a2b,
              $letter-spacing: -1.04px,
              $text-align: left
            );
          }
        }
        .main-top {
          width: 100%;
          display: flex;
          background: #FFFFF8;
          h3 {
            border: 1px solid #f1f3f5;
            &:nth-of-type(1) {
              width: 10%;
            }
            &:nth-of-type(2) {
              border-left: 1px solid #f1f3f5;
              width: 60%;
            }
            &:nth-of-type(3) {
              border-left: 1px solid #f1f3f5;
              width: 30%;
            }
            @include initfontKor(
              $font-size: 20px,
              $line-height: 66px,
              $font-family: "AppleSDGothicNeoB00",
              $color: #292a2b,
              $letter-spacing: -1.04px,
              $text-align: center
            );
          }
        }
        .item {
          border: 1px solid #f1f3f5;
          display: flex;
          h3 {
            width: 10%;
            @include initfontKor(
              $font-size: 20px,
              $line-height: 66px,
              $font-family: "AppleSDGothicNeoB00",
              $color: #292a2b,
              $letter-spacing: -1.04px,
              $text-align: center
            );
          }
          h4 {
            &:nth-of-type(1) {
              width: 60%;
            }
            &:nth-of-type(2) {
              width: 30%;
            }
            &.header {
              border-left: 1px solid #f1f3f5;
              @include initfontKor(
                $font-size: 20px,
                $line-height: 66px,
                $font-family: "AppleSDGothicNeoB00",
                $color: #292a2b,
                $letter-spacing: -1.04px,
                $text-align: center
              );
            }
            &.content {
              border-left: 1px solid #f1f3f5;
              @include initfontKor(
                $font-size: 20px,
                $line-height: 66px,
                $font-family: "AppleSDGothicNeoB00",
                $color: #fd8b11,
                $letter-spacing: -1.04px,
                $text-align: center
              );
            }
          }
        }
      }
    }
  }
}
</style>
