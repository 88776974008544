import { render, staticRenderFns } from "./EtcModal.vue?vue&type=template&id=9f4c6480&scoped=true&"
import script from "./EtcModal.vue?vue&type=script&lang=js&"
export * from "./EtcModal.vue?vue&type=script&lang=js&"
import style0 from "./EtcModal.vue?vue&type=style&index=0&id=9f4c6480&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f4c6480",
  null
  
)

export default component.exports