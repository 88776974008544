<template>
  <div class="app-context flex">
    <side-menu
      :sideMenuOption="sideMenuOption"
      :sideMenuDeep="sideMenuDeep"
      @is-unauthorized="isUnAuthorized"
    ></side-menu>
    <div class="wrap-context">
      <notification
        class="low-margin"
        :notificationInfo="notificationInfo"
      ></notification>
      <div class="camera-context">
        <div class="item-box">
          <div
            class="animator-wrap"
            :class="{ active: animate.value !== null, pause: plyrValue.isViewMilestone }"
          >
            <div class="animator" v-if="plyrValue.isViewMilestone">
              <div class="btn-wrap">
                <div class="item">
                  <button type="button" @click="btnClick('prepare')">
                    연습 영상 다시보기
                  </button>
                </div>
                <div class="item" v-if="plyrValue.currentSetCount !== plyrValue.setCount">
                  <button type="button" @click="btnClick('training')">
                    다음 세트 시작하기
                  </button>
                </div>
              </div>
            </div>
            <div class="animator" v-else>
              <div class="half-circle-timer">
                <span v-text="animate.value" />
              </div>
              <div class="info-wrap">
                <div class="holder">
                  <h4
                    class="title"
                    v-text="
                      plyrValue.currentRepeatCount === 0 ||
                      plyrValue.currentRepeatCount === 4
                        ? '물'
                        : '침'
                    "
                  ></h4>
                  <div class="content">
                    <div class="img-wrap">
                      <img
                        src="@assets/images/training/cup.png"
                        v-if="
                          plyrValue.currentRepeatCount === 0 ||
                          plyrValue.currentRepeatCount === 4
                        "
                      />
                      <img src="@assets/images/training/mouse.png" v-else />
                    </div>
                    <p
                      v-if="
                        plyrValue.currentRepeatCount === 0 ||
                        plyrValue.currentRepeatCount === 4
                      "
                    >
                      5ml
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="video-player">
            <vue-plyr :options="plyrOption" ref="plyr">
              <!-- <video controls crossorigin playsinline data-poster="splash.png"> -->
              <video controls crossorigin playsinline>
                <source
                  size="1080"
                  :src="require(`@/assets/video/${plyrValue.src}`)"
                  type="video/mp4"
                />
              </video>
            </vue-plyr>
          </div>
        </div>
        <div class="item-box activated">
          <div class="camera-attach"></div>
          <div class="training-status">
            <div
              class="item"
              v-for="(set, setIndex) in plyrValue.setCount"
              :key="setIndex + 'a'"
            >
              <h3 class="i-header" v-text="setIndex + 1 + '세트'"></h3>
              <div class="i-content">
                <span
                  class="num"
                  v-for="(repeat, repeatIndex) in plyrValue.repeatCount"
                  :key="repeatIndex + 'a'"
                  v-text="repeatIndex + 1"
                  :class="{
                    active:
                      (plyrValue.currentRepeatCount !== null &&
                        repeatIndex + 1 <= plyrValue.currentRepeatCount &&
                        plyrValue.currentSetCount >= setIndex + 1) ||
                      setIndex + 1 < plyrValue.currentSetCount,
                  }"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import errorParser from '@/utils/error-parser';
import DefaultPopup from '@/components/modal/DefaultPopup';
import SideMenu from '@/components/SideMenu.vue';
import Notification from '@/components/Notification.vue';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  data() {
    return {
      plyrOption: {
        autoplay: false,
        /**
          controls: ['play', 'fullscreen'],
           */
        controls: [
          'play-large',
          'play',
          'progress',
          'current-time',
          'mute',
          'volume',
          'captions',
          'settings',
          'pip',
          'airplay',
          'fullscreen',
        ],
        fullscreen: {
          enabled: true,
          fallback: true,
          iosNative: true,
          container: 'video-player',
        },
      },
      plyrValue: {
        duration: '',
        player: '',
        src: 'swallow_practice.mp4',
        isPrepare: true,
        currentRepeatCount: null,
        repeatCount: 2,
        setCount: 1,
        currentSetCount: 1,
        isViewMilestone: false,
        setDone: false,
        allSetClear: false,
      },
      sideMenuDeep: true,
      notificationInfo: {
        title: '힘껏 오래 삼키기',
        btnSet: [],
      },
      sideMenuOption: [
        {
          img: 'sidebar_item01.png',
          img_active: 'sidebar_item01.png',
          title: '메인',
          isActive: false,
          link: '',
        },
        {
          img: 'sidebar_item02.png',
          img_active: 'sidebar_item02.png',
          title: '삼킴 평가',
          isActive: false,
          link: 'evaluate',
        },
        {
          img: 'sidebar_item03.png',
          img_active: 'sidebar_item03.png',
          title: '삼킴 훈련',
          isActive: true,
          link: 'trainingDate',
        },
        {
          img: 'sidebar_item05.png',
          img_active: 'sidebar_item05.png',
          title: '자세법ㆍ식이법',
          isActive: false,
          link: 'choiceType',
        },
        {
          img: 'sidebar_item06.png',
          img_active: 'sidebar_item06.png',
          title: '원격 상담',
          isActive: false,
          link: 'reservationType',
        },
        {
          img: 'sidebar_mypage.png',
          img_active: 'sidebar_mypage_active.png',
          title: '마이페이지',
          isActive: false,
          link: 'mypage',
        },
      ],
      popupSet: {},
      animate: {
        intervalObj: null,
        initValue: 5,
        value: null,
        isPause: false,
        isDone: false,
      },
      apiStatus: {
        detailId: null,
      },
    };
  },
  created() {
    console.log('type :', this.$route.query.type);
    console.log('time :', this.$route.query.time);
    console.log('day :', this.$route.query.day);
    console.log('number :', this.$route.query.number);
    console.log('set :', this.$route.query.set);
    console.log('isLastCount :', this.$route.query.isLastCount);
    if (history.pushState !== undefined) {
      history.pushState({}, '', location.href);
      window.onpopstate = () => {
        history.pushState({}, '', location.href);
        this.handleGoback();
      };
    }
    this.checkTraining(
      this.$route.query.type,
      this.$route.query.time,
      this.$route.query.day,
    );
  },
  mounted() {
    this.initVideo();
    this.initAttach();
    /** 시간업데이트 */
    this.$refs.plyr.player.on('timeupdate', (data) => {
      const value = data.detail.plyr;
      /**
      console.log("duration", value.currentTime);
       */
    });
    /** 비디오 초기시작 */
    this.$refs.plyr.player.on('playing', (data) => {
      console.log('비디오 초기시작함');
    });

    /** 비디오 재개 */
    this.$refs.plyr.player.on('play', (data) => {
      clearInterval(this.animate.intervalObj);
      this.animate.value = null;
      this.animate.isDone = false;
      this.$refs.plyr.player.play();
    });
    /** 비디오 중지 */
    this.$refs.plyr.player.on('pause', (data) => {
      clearInterval(this.animate.intervalObj);
    });

    /** 비디오 완료 */
    this.$refs.plyr.player.on('ended', (data) => {
      console.log('비디오 종료');
      const { currentRepeatCount } = this.plyrValue;
      const { currentSetCount } = this.plyrValue;
      console.log('카운트 체크 :', currentRepeatCount);
      if (currentRepeatCount !== null) {
        /** 동영상 준비  */
        if (currentRepeatCount < this.plyrValue.repeatCount) {
          this.plyrValue.currentRepeatCount = currentRepeatCount + 1;
        }
        if (this.plyrValue.currentRepeatCount < this.plyrValue.repeatCount) {
          if (this.plyrValue.currentRepeatCount === 4) {
            this.initSource('swallow_water.mp4');
          } else {
            this.initSource('swallow_split.mp4');
          }
          this.callCountAnimation();
        } else if (currentSetCount < this.plyrValue.setCount) {
          this.plyrValue.isViewMilestone = true;
          this.plyrValue.setDone = true;
          /** 다음세트 시작일 때 버튼 선택자를 부릅니다. */
        } else {
          this.plyrValue.setDone = true;
          if (!this.plyrValue.allSetClear) {
            this.SET_POPUP(true);
            this.popupSet.popType = 'endTraining';
            this.popupSet.title = `삼킴훈련 ${this.getCountToOrder(this.$route.query.time)}회`;
            this.popupSet.popImg = 'warning.svg';
            this.popupSet.cancelBtnText = null;
            this.popupSet.param = this.getCountToOrder(this.$route.query.time);
            this.popupSet.nextLink = '/trainingDate';
            this.popupSet.confirmBtnText = '확인';
            this.popupSet.destination = true;
            if (this.$route.query.isLastCount === 'true') {
              console.log('마지막 훈련종료');
              this.popupSet.isLastTrainingCount = true;
              this.popupSet.content = '오늘의 삼킴훈련을 모두마쳤어요.\n수고하셨습니다.\n삶의 의미 탐색 프로그램에서\n다시 뵙겠습니다.';
            } else {
              console.log('마지막 훈련종료X');
              if (this.getCountToOrder(this.$route.query.time) === '1') {
                console.log('첫 훈련종료');
                this.popupSet.content = '삼킴훈련 첫 번째 시간을 마쳤습니다. 다른 훈련도 진행 해 주세요.';
              } else if (this.getCountToOrder(this.$route.query.time) === '2') {
                console.log('두번째 훈련종료');
                this.popupSet.content = '삼킴훈련 두 번째 시간을 마쳤습니다. 다른 훈련도 진행 해 주세요.';
              }
            }
          } else {
            this.plyrValue.isViewMilestone = true;
          }
        }

        if (!this.plyrValue.allSetClear) {
          console.log('진입진입스');
          /**
          this.sendTrainingData(
            this.apiStatus.detailId,
            this.plyrValue.currentRepeatCount,
            this.plyrValue.currentSetCount
          );
          */
        }

        console.log('number: ', this.plyrValue.currentRepeatCount);
        console.log('set: ', this.plyrValue.currentSetCount);
      } else {
        this.plyrValue.currentRepeatCount = 0;
        this.initSource('swallow_water.mp4');
        this.callCountAnimation();
      }
      if (this.plyrValue.isPrepare) {
        this.initSource('swallow_water.mp4');
      }
    });
    /** 비디오 전체화면 시작 */
    this.$refs.plyr.player.on('enterfullscreen', (data) => {});

    /** 비디오 전체화면 중지 */
    this.$refs.plyr.player.on('exitfullscreen', (data) => {});
  },
  methods: {
    ...mapMutations('basic', ['SET_POPUP']),
    ...mapActions('training', [
      'CHECK_TRAINING',
      'START_TRAINING',
      'FETCH_TRAINING',
      'SEND_TRAINING_DATA',
    ]),
    handleGoback() {
      if (this.checkPopup) {
        this.SET_POPUP(false);
      } else {
        this.$router.push('/trainingDate');
      }
    },
    checkSetNum(number1, number2) {
      let numberVal;
      if (number1 !== number2) {
        numberVal = number1 - 1;
      } else {
        numberVal = number1;
      }
      return numberVal;
    },
    async sendTrainingData(detailId, num, set) {
      console.log('detailId :', detailId);
      console.log('num :', num);
      console.log('set :', set);
      try {
        const payload = {
          detailId,
          list: [
            {
              number: num,
              set,
            },
          ],
        };
        await this.SEND_TRAINING_DATA(payload).then((data) => {
          console.log(
            '서버저장완료',
            'number :',
            number,
            'set :',
            set,
            'isCompleted :',
            data.response.isCompleted,
          );
        });
      } catch (error) {
        console.log('error :', errorParser.parse(error));
        console.log('error :', error);
      }
    },
    setTrainingStatus(number, set) {
      console.log('training-status -number', number);
      console.log('training-status -set', set);
      this.plyrValue.repeatCount = Number(number);
      this.plyrValue.setCount = Number(set);
    },
    async fetchTraining(id) {
      try {
        await this.FETCH_TRAINING(id).then((data) => {
          /**  시간세팅 */
          this.setTrainingStatus(this.$route.query.number, this.$route.query.set);
          console.log('시간세팅-data :', data);
          console.log('시간 포문', data.response.list.length);
          for (let i = data.response.list.length - 1; i >= 0; i--) {
            console.log('회차 :', i);
            if (data.response.list[i].number > 0) {
              console.log('i 진입점 :', i, ' 현재 데이터', data.response.list[i]);
              this.plyrValue.currentSetCount = data.response.list[i].set;
              this.plyrValue.currentRepeatCount = data.response.list[i].number;
              break;
            }
          }
          if (this.plyrValue.currentRepeatCount === null) {
            this.initSource('swallow_practice.mp4');
          } else if (this.plyrValue.currentRepeatCount === this.plyrValue.repeatCount) {
            this.plyrValue.isViewMilestone = true;
            this.plyrValue.setDone = true;
          } else if (this.plyrValue.currentRepeatCount === 4) {
            this.initSource('swallow_water.mp4');
          } else {
            this.initSource('swallow_split.mp4');
          }
        });
      } catch (error) {
        console.log('error :', errorParser.parse(error));
        console.log('error :', error);
      }
    },
    async trainingStart(type, time, day) {
      const factor = {
        type: this.getTypeToOrder(type),
        time: this.getTimeToOrder(time),
        day,
      };
      try {
        await this.START_TRAINING(factor).then((data) => {
          this.setTrainingStatus(this.$route.query.number, this.$route.query.set);
          this.apiStatus.detailId = data.response.detailId;
        });
      } catch (error) {
        console.log('error :', errorParser.parse(error));
        console.log('error :', error);
      }
    },
    async checkTraining(type, time, day) {
      console.log('type', type);
      console.log('time', time);
      console.log('day', day);
      const factor = {
        type: this.getTypeToOrder(type),
        time: this.getTimeToOrder(time),
        day,
      };
      console.log('factor :', factor);
      try {
        await this.CHECK_TRAINING(factor).then((data) => {
          console.log('data :', data);
          this.apiStatus.detailId = data.response.detailId;
          if (!data.response.isExist) {
            this.trainingStart(
              this.$route.query.type,
              this.$route.query.time,
              this.$route.query.day,
            );
          } else {
            console.log('사용할 detail-id', data.response.detailId);
            /**
            this.fetchTraining(data.response.detailId);
             */
          }
        });
      } catch (error) {
        console.log('error :', errorParser.parse(error));
      }
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = '로그인 권한이 필요합니다.';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    btnClick(comment) {
      console.log('comment :', comment);
      if (comment === 'prepare') {
        this.initSource('swallow_practice.mp4');
        if (this.plyrValue.currentSetCount !== this.plyrValue.setCount) {
          this.plyrValue.currentRepeatCount = null;
        } else {
          this.plyrValue.allSetClear = true;
        }
        this.plyrValue.isPrepare = true;
        this.$refs.plyr.player.play();
      } else if (comment === 'training') {
        this.plyrValue.currentRepeatCount = 0;
        this.initSource('swallow_water.mp4');
        this.callCountAnimation();
      }
      const { currentSetCount } = this.plyrValue;
      if (!this.plyrValue.allSetClear) {
        this.plyrValue.currentSetCount = currentSetCount + 1;
      }
      this.plyrValue.isViewMilestone = false;
      this.plyrValue.setDone = false;
    },
    initSource(file) {
      console.log('들어온 file', file);
      this.$refs.plyr.player.source = {
        type: 'video',
        title: 'Example title',
        sources: [
          {
            src: require(`@/assets/video/${file}`),
            type: 'video/mp4',
            size: 480,
          },
        ],
      };
      this.plyrValue.isPrepare = false;
    },
    initVideo() {
      this.plyrValue.player = this.$refs.plyr.player;
    },
    initAttach() {
      const url = 'samkim://cameraSwallowPreviewStart';
      document.location.href = url;
    },
    callCountAnimation() {
      let currentValue;
      this.animate.intervalObj = setInterval(() => {
        currentValue = this.animate.value === null ? this.animate.initValue : this.animate.value;
        if (this.animate.value === null) {
          this.animate.value = this.animate.initValue;
        } else if (currentValue > 1) {
          this.animate.value = currentValue - 1;
        } else if (currentValue === 1) {
          this.animate.value = '준비';
        } else if (currentValue === '준비') {
          this.animate.value = '시작';
        } else if (currentValue === '시작') {
          this.animate.isDone = true;
          clearInterval(this.animate.intervalObj);
          this.$refs.plyr.player.play();
        }
      }, 1000);
    },
  },
  computed: {
    ...mapState('basic', ['checkPopup']),
    getTypeToOrder() {
      return (order) => {
        console.log('getTypeToOrder -order :', order);
        console.log('getTypeToOrder -type :', typeof order);
        if (Number(order) === 0) {
          return 'swallow';
        } if (Number(order) === 1) {
          return 'raising';
        } if (Number(order) === 2) {
          return 'training';
        }
      };
    },
    getTimeToOrder() {
      return (order) => {
        console.log('getTimeToOrder -order :', order);
        console.log('getTimeToOrder -type :', typeof order);
        if (Number(order) === 0) {
          return 'morning';
        } if (Number(order) === 1) {
          return 'lunch';
        } if (Number(order) === 2) {
          return 'evening';
        }
      };
    },
    getCountToOrder() {
      return (order) => {
        console.log('getCountToOrder -order :', order);
        console.log('getCountToOrder -type :', typeof order);
        if (Number(order) === 0) {
          return '1';
        } if (Number(order) === 1) {
          return '2';
        } if (Number(order) === 2) {
          return '3';
        }
      };
    },
  },
  beforeDestroy() {
    const url = 'samkim://cameraSwallowPreviewEnd';
    document.location.href = url;
  },
  components: {
    SideMenu,
    Notification,
    DefaultPopup,
  },
};
</script>
<style lang="scss" scoped>
.app-context {
  .wrap-context {
    width: 934px;
    margin: 22px 90px 0 auto;

    .low-margin {
      margin: 0 0 56px 0;
    }

    .directive.low {
      margin: 0 0 51px 0;
    }

    .camera-context {
      display: flex;

      .item-box {
        width: calc(50% - 5px);
        position: relative;
        min-height: 524px;
        background: #676767;
        .animator-wrap {
          z-index: 2;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: transparent;
          transition: 0.5s;
          display: none;
          &.active {
            display: block;
          }
          &.pause {
            display: block;
            background: rgba(54, 61, 65, 0.5);
          }
          .animator {
            .btn-wrap {
              display: flex;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              flex-wrap: wrap;
              .item {
                width: 100%;
                &:first-of-type {
                  margin: 0;
                  button {
                    background: #21978c;
                    &:after {
                      background-image: url("~@assets/images/util/icon_replay.svg");
                    }
                  }
                }
                margin: 20px 0 0 0;
                button {
                  width: 290px;
                  border: 0;
                  padding: 0 40px 0 17.5px;
                  @include initfontKor(
                    $font-size: 30px,
                    $line-height: 52px,
                    $font-family: "AppleSDGothicNeoM00",
                    $color: #fff,
                    $letter-spacing: -0.6px,
                    $text-align: left
                  );
                  position: relative;
                  background: #fd8b11;
                  cursor: pointer;
                  &:after {
                    content: "";
                    width: 36px;
                    height: 36px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 11px;
                    background-image: url("~@assets/images/util/icon_nextplay.svg");
                  }
                }
              }
            }
            .half-circle-timer {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 80px;
              height: 80px;
              border-radius: 50%;
              overflow: hidden;
              display: flex;
              align-items: center;
              justify-content: center;
              background: rgb(54, 61, 65);
              span {
                @include initfontKor(
                  $font-size: 19px,
                  $line-height: 24px,
                  $font-family: "AppleSDGothicNeoL00",
                  $color: #fff,
                  $letter-spacing: -0.6px,
                  $text-align: center
                );
              }
            }
            .info-wrap {
              width: 95px;
              height: 95px;
              background: rgb(54, 61, 65);
              border-radius: 50%;
              left: 0;
              position: absolute;
              top: 0;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: center;
              .holder {
              }
              h4.title {
                @include initfontKor(
                  $font-size: 19px,
                  $line-height: 24px,
                  $font-family: "AppleSDGothicNeoL00",
                  $color: #fff,
                  $letter-spacing: -0.6px,
                  $text-align: center
                );
                margin: 0 0 8px 0;
                width: 100%;
              }
              .content {
                display: flex;
                align-items: center;
                justify-content: center;
                .img-wrap {
                  img {
                    max-width: 34px;
                  }
                }
                p {
                  @include initfontKor(
                    $font-size: 19px,
                    $line-height: 24px,
                    $font-family: "AppleSDGothicNeoL00",
                    $color: #fff,
                    $letter-spacing: -0.6px,
                    $text-align: center
                  );
                  margin: 0 0 0 5px;
                }
              }
            }
          }
        }
        &:nth-of-type(2) {
          margin: 0 0 0 10px;
        }
        &.activated {
          .camera-attach {
            height: 100%;
            background: #676767;
          }
          .training-status {
            padding: 15px;
            background: rgb(54, 61, 65);
            position: absolute;
            width: 100%;
            bottom: 0;
            .item {
              margin: 10px 0 0 0;
              display: flex;
              border-radius: 4px;
              overflow: hidden;
              background: #e9edec;
              h3.i-header {
                width: 60px;
                @include initfontKor(
                  $font-size: 20px,
                  $line-height: 38px,
                  $font-family: "AppleSDGothicNeoB00",
                  $color: #315754,
                  $letter-spacing: -0.4px,
                  $text-align: center
                );
                background: #e9edec;
              }
              .i-content {
                width: calc(100% - 60px);
                display: flex;
                span.num {
                  display: block;
                  background: #e9edec;
                  border: 0px solid #fff;
                  border-width: 0 1px;
                  min-width: 37px;
                  @include initfontKor(
                    $font-size: 20px,
                    $line-height: 36px,
                    $font-family: "AppleSDGothicNeoM00",
                    $color: #315754,
                    $letter-spacing: -0.4px,
                    $text-align: center
                  );
                  &.active {
                    background: #315754;
                    color: #fff;
                    font-family: "AppleSDGothicNeoB00";
                  }
                }
              }
            }
            &:first-of-type {
            }
          }
        }
      }
    }
  }
}
.video-player {
  height: 100%;
}
</style>
