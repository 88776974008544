<template>
  <div class="stop-watch">
    <div class="retry" @click="reset()">
      <div class="img-wrap">
        <img src="@assets/images/evaluation/icon_refresh.svg" />
      </div>
      <p>다시시작</p>
    </div>
    <div class="watch">
      <div class="item">
        <h3 class="title">시간</h3>
        <div class="content" v-if="countRefresh">
          <countdown
            :left-time="leftTime"
            :auto-start="false"
            :ref="'countdown' + countStructure"
            @start="startCall"
            @finish="finishCall"
          >
            <template v-slot:process="countTimer">
              <span v-text="computedMath(Math.ceil(countTimer.timeObj.org.s))"></span>
            </template>

            <template v-slot:finish>
              <span>00 : 00</span>
            </template>
          </countdown>
        </div>
      </div>
      <div class="item">
        <h3 class="title">총 횟수</h3>
        <div class="content">
          <span v-text="activatedNum !== 0 ? activatedNum : ''"></span>
        </div>
      </div>
      <div class="item">
        <h3 class="title">초당 횟수</h3>
        <div class="content">
          <span v-text="persecond !== 0 ? persecond : ''"></span>
        </div>
      </div>
    </div>
    <div class="btn-wrap">
      <button type="button" v-text="btnStart" @click="start(startBtnState)"></button>
      <button type="button" class="stop" @click="stop(stopBtnState)" v-text="btnStop"></button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    startOrder: {
      type: Number,
      default: 0
    },
    isLastQuestion: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      startBtnState: 0,
      stopBtnState: 0,
      countStructure: 1,
      leftTime: 5000,
      activatedNum: 0,
      persecond: 0,
      countRefresh: true,
      btnStart: "Start",
      btnStop: "Stop",
      view: {
        isProceed: false
      }
    };
  },
  created() {},
  mounted() {},
  methods: {
    startCall() {
      /** this.startOrder 로 분기 */
      console.log("음성 리비서 온");
      if (!this.view.isProceed) {
        this.view.isProceed = true;
        this.$emit("getTimerReducerFlex", null, this.startOrder, "start");
      }
      this.startBtnState = 1;
    },
    finishCall() {
      /** this.startOrder 로 분기 */
      this.view.isProceed = false;
      this.startBtnState = 2;
      this.stopBtnState = 1;
      if (this.isLastQuestion) {
        this.btnStop = "평가제출";
      }
      this.persecond = this.calculatePersecond(this.activatedNum);
      console.log("총횟수 , 초당횟수");
      const obj = {
        duration: 5,
        count: this.activatedNum,
        persecond: Number(this.persecond)
      };
      this.$emit("getTimerReducerFlex", obj, this.startOrder, "stop");
    },
    async complexMethod() {
      this.countStructure += 1;
      await new Promise(resolve => {
        this.countRefresh = false;
        setTimeout(() => {
          this.countRefresh = true;
          console.log("플래시 끝");
          resolve();
        }, 1);
      });
      /**
      await new Promise(resolve => {
        this.$refs[`countdown${this.countStructure}`].startCountdown();
        setTimeout(() => {
          this.$refs[`countdown${this.countStructure}`].pauseCountdown();
          console.log("리로드 끝");
          resolve();
        }, 1);
      });
       */
    },
    async flash() {
      this.countRefresh = false;
      setTimeout(() => {
        this.countRefresh = true;
      }, 1);
    },
    async reload() {
      this.$refs[`countdown${this.countStructure}`].startCountdown();
      setTimeout(() => {
        this.$refs[`countdown${this.countStructure}`].pauseCountdown();
        console.log("리로드 끝");
      }, 1);
    },
    start(state) {
      if (state === 0) {
        if (this.view.isProceed) {
          this.$emit("getTimerReducerFlex", null, this.startOrder, "restart");
        }
        this.btnStart = "Count";
        this.$refs[`countdown${this.countStructure}`].startCountdown();
      } else if (state === 1) {
        this.activatedNum += 1;
        this.persecond = this.calculatePersecond(this.activatedNum);
      } else if (state === 2) {
        this.$emit("getTimerReducerFlex", null, this.startOrder, "overCount");
      }
    },
    reset() {
      this.$emit(
        "get-timer-reducer",
        false,
        "complexReset",
        null,
        this.startOrder,
        this.stopBtnState
      );
      this.btnStart = "Start";
      this.btnStop = "Stop";
      this.activatedNum = 0;
      this.persecond = 0;
      this.startBtnState = 0;
      this.stopBtnState = 0;
      this.view.isProceed = false;
      this.complexMethod();
    },
    stop(state) {
      if (state === 0) {
        this.startBtnState = 0;
        this.btnStart = "restart";
        this.$refs[`countdown${this.countStructure}`].pauseCountdown();
        this.$emit("getTimerReducerFlex", null, this.startOrder, "pause");
      } else {
        if (
          this.$refs[`countdown${this.countStructure}`].$el.childNodes[2].textContent.replace(
            /\s/gi,
            ""
          ) !== ""
        ) {
          this.persecond = this.calculatePersecond(this.activatedNum);
        }
        console.log("총횟수 , 초당횟수");
        const obj = {
          duration: 5,
          count: this.activatedNum,
          persecond: Number(this.persecond)
        };
        this.$emit("getTimerReducerFlex", null, this.startOrder, "stop");
      }
    }
  },
  computed: {
    computedMath() {
      return value => {
        /** 00 : 01 : 28 */
        const mutateValue = "0" + value + " : 00";
        return mutateValue;
      };
    },
    calculatePersecond() {
      return value => {
        console.log("누른횟수 :", value);
        const su = this.$refs[
          `countdown${this.countStructure}`
        ].$el.childNodes[2].textContent.replace(/\s/gi, "");
        const splitVal = su.split(":");
        const mutateVal = Number(splitVal[1].substr(1, 2));
        console.log("변경자 :", splitVal[1].substr(1, 2));
        const countAmount = this.startBtnState === 2 ? 5 : 5 - mutateVal;
        const remainTime = mutateVal;
        console.log("나눌재료 :", countAmount);
        console.log("남은시간 :", remainTime);
        const perSecond = (Number(value) / Number(countAmount)).toPrecision(2);
        console.log("perSecond: ", perSecond);
        if (perSecond === "Infinity") {
          return "";
        } else {
          return perSecond;
        }
      };
    }
  },
  components: {}
};
</script>
<style lang="scss" scoped>
.inNoti {
  .timer .whole-center .stop-watch {
    .retry {
      position: absolute;
      margin: 0;
      left: 0;
      cursor: pointer;
    }
  }
}
.stop-watch {
  width: 530px;
  margin: 0 auto;

  .watch {
    display: flex;
    margin: 0 0 52px 0;
    justify-content: center;
  }
}
.retry {
  margin: 0 0 40px 0;
  display: flex;
  position: relative;
  align-items: center;
  width: 95px;
  margin: 0 auto 40px auto;
  &:after {
    position: Absolute;
    width: calc(100% - 4px);
    bottom: 5px;
    height: 1px;
    background: #315754;
    content: "";
    left: 50%;
    transform: translateX(-50%);
  }
  .img-wrap {
    margin: 0 5px 0 0;
    img {
    }
  }
  p {
    @include initfontKor(
      $font-size: 20px,
      $line-height: 36px,
      $font-family: "AppleSDGothicNeoM00",
      $color: #315754,
      $letter-spacing: -0.4px,
      $text-align: left
    );
  }
}
.btn-wrap {
  display: flex;
  justify-content: center;

  button {
    width: 140px;
    height: 60px;
    border: 0;
    padding: 0;
    border-radius: 5px;
    box-shadow: 0 3px 10px 0 rgba(54, 55, 57, 0.15);
    background: #2d7d76;

    @include initfontKor(
      $font-size: 30px,
      $line-height: 36px,
      $font-family: "AppleSDGothicNeoB00",
      $color: #fff,
      $letter-spacing: -0.6px,
      $text-align: center
    );
    cursor: pointer;
    &.restart {
    }
    &.count {
      background: #15ab9d;
    }
    &.stop {
      background: #ec5e5e;
      margin: 0 0 0 16px;
    }
  }
}

.item {
  margin: 0 0 0 6px;
  border-radius: 5px;
  border: solid 1px #eaebf1;
  background-color: #ffffff;
  height: 130px;
  min-width: 130px;
  overflow: hidden;

  &:first-of-type {
    margin: 0;
    min-width: 220px;
  }

  h3.title {
    @include initfontKor(
      $font-size: 20px,
      $line-height: 32px,
      $font-family: "AppleSDGothicNeoB00",
      $color: #363739,
      $letter-spacing: -0.4px,
      $text-align: center
    );
    background: #ebf2f1;
  }

  span {
    @include initfontKor(
      $font-size: 40px,
      $line-height: 25px,
      $font-family: "AppleSDGothicNeoB00",
      $color: #363739,
      $letter-spacing: -2px,
      $text-align: center
    );
    min-width: 50px;

    &.separate {
      display: block;
      margin: 0 5px;
      min-width: auto;
    }
  }

  .content {
    display: flex;
    padding: 0 20px;
    height: calc(100% - 32px);
    justify-content: center;
    align-items: center;
  }
}
</style>
