import { join } from "@/api";

const state = {
  join: {
    /**
    drId: localStorage.getItem('drId') || '',
     */
    personalInfo: {},
    questionList1: [],
    questionList2: [],
    questionList3: [],
    questionList4: []
  }
};

const mutations = {
  SET_PERSONALINFO(state, info) {
    state.join.personalInfo = info;
  },
  SET_QUESTIONLIST1(state, listData) {
    state.join.questionList1 = listData;
  },
  SET_QUESTIONLIST2(state, listData) {
    state.join.questionList2 = listData;
  },
  SET_QUESTIONLIST3(state, listData) {
    state.join.questionList3 = listData;
  },
  SET_QUESTIONLIST4(state, listData) {
    state.join.questionList4 = listData;
  }
  /**
  HACK_TOKEN(state, token) {
    state.auth.token = token;
  }
  */
};

const getters = {
  GET_PERSONALINFO: state => state.join.personalInfo,
  GET_QUESTIONLIST1: state => state.join.questionList1,
  GET_QUESTIONLIST2: state => state.join.questionList2,
  GET_QUESTIONLIST3: state => state.join.questionList3,
  GET_QUESTIONLIST4: state => state.join.questionList4
  /**
  GET_CHECK_RESULT: (state) => state.auth.isCheck
   */
};

const actions = {
  CHECK_DUPLICATE({}, userInfo) {
    return join.checkDuplicate(userInfo);
  },
  FETCH_JOIN_QUESTIONLIST({}) {
    return join.fetchJoinQuestionList();
  },
  ACCOUNT_JOIN({}, joinData) {
    return join.accountJoin(joinData);
  },
  LOGIN({}, joinData) {
    return join.login(joinData);
  },
  LOGOUT({}) {
    return join.logOut();
  },
  ACCOUNT_INFO({}) {
    return join.accountInfo();
  }

  /**
  BRING_SMS_CODE({
    commit,
  }, payload) {
    return auth.bringSmsCode(payload).then((data) => {
      commit('SET_SMS_LIST', data);
    });
  },
   */
};
export default {
  strict: process.env.NODE_ENV !== "production",
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
