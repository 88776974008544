<template>
  <div class="consult-list">
    <div class="r-header">
      <div class="item" :class="resultListCate" v-for="(headerItem, index) in consultListSet.header" :key="index + 'A'">
        <h4>{{ headerItem }}</h4>
      </div>
    </div>
    <div class="r-content">
      <div
        class="list"
        :class="[{ activated: listItem.resvStatus === 0 },resultListCate]"
        v-for="(listItem, index) in consultListSet.list"
        :key="index + 'B'"
      >
        <div class="item" >
          <h4 class="order" v-text="consultListSet.list.length - index" />
        </div>
        <div class="item">
          <h4 v-text="dayName(listItem.consultationDate)"></h4>
        </div>
        <div class="item">
          <h4 v-text="splitTime(listItem.consultationTime)"></h4>
        </div>
        <div class="item on-wish" @click="callWishPop(listItem.requestContent)">
          <h4 class="wish">자세히 보기</h4>
        </div>
        <div class="item">
          <h4 v-text="listItem.consultant"></h4>
        </div>
        <div class="item">
          <div class="mini-btn">
            <button type="button" class="recruit" v-if="listItem.state === 0">
              예약완료
            </button>
            <button type="button" class="cancel" v-if="listItem.state === 1">
              예약취소
            </button>
            <button type="button" class="cancel" v-if="listItem.state === 2">
              관리자 취소
            </button>
            <button type="button" class="noneProgress" v-if="listItem.state === 3">
              미진행
            </button>
            <button type="button" class="recruit" v-if="listItem.state === 4">
              관리자 변경
            </button>
            <button type="button" class="consult" v-if="listItem.state === 5">
              상담완료
            </button>
          </div>
        </div>
        <div class="item">
          <div class="btn-wrap" v-if="listItem.state === 0">
            <button type="button" @click="cancelResv(index)">취소하기</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    consultList: {
      type: Object,
    },
    resultListCate: { type: String },
  },
  data() {
    return {
      consultListSet: {},
    };
  },
  created() {
    this.consultListSet = this.consultList;
  },
  mounted() {},
  methods: {
    cancelResv(order) {
      this.$emit('call-cancel-pop', order);
    },
    callWishPop(comment) {
      this.$emit('call-wish-pop', comment);
    },
    initResult(list) {
      console.log('list', list);
      this.consultListSet = list;
    },
  },
  computed: {
    dayName() {
      return (date) => {
        console.log('date', date);
        const week = [];
        week.push('일', '월', '화', '수', '목', '금', '토');
        const day = new Date(date);
        const dayName = day.getDay();
        return `${date.slice(2, 10)} ${week[dayName]}`;
      };
    },
    splitTime() {
      return (time) => `${time.slice(0, 5)}`;
    },
  },
  components: {},
};
</script>
<style lang="scss" scoped>
.consult-list {
  .r-header {
    display: flex;
    align-items: center;
    background-color: rgba(253, 139, 17, 0.1);
    padding: 0 20px;
    height: 50px;

    .item {
      margin: 0 0 0 20px;
      &:nth-of-type(1) {
        width: 70px;
        margin: 0;
      }
      &:nth-of-type(2) {
        width: calc((100% - 385px) / 3);
        h4 {
          text-align: center;
        }
      }

      &:nth-of-type(3) {
        width: 105px;
      }

      &:nth-of-type(4) {
        width: calc((100% - 385px) / 3);
      }

      &:nth-of-type(5) {
        width: 105px;
      }

      &:nth-of-type(6) {
        width: 105px;
      }

      &:nth-of-type(7) {
        width: calc((100% - 385px) / 3);
      }
      h4 {
        @include initfontKor(
          $font-size: 22px,
          $line-height: 30px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #363739,
          $letter-spacing: -0.48px,
          $text-align: center
        );
      }

    &.consultantList{

      &:nth-of-type(1) {
        width: 50px;
        margin: 0;
      }
      &:nth-of-type(3) {
        width: 80px;
        margin: 0;
      }
      &:nth-of-type(5) {
        width: 170px;
      }
    }
    }
  }
  .r-content {
    background: #fff;

    .list {
      display: flex;
      padding: 0 20px;
      border-top: 1px solid #e1e5ec;
      align-items: center;
      &:first-of-type {
        border-top: 0;
      }
      &.activated {
        .item {
          h4 {
            color: $pointcolor__orange;
            &.wish {
              color: $pointcolor__orange;
              &:after {
                background-color: $pointcolor__orange;
              }
            }
          }
        }
      }
      .item {
        margin: 0 0 0 20px;
        padding: 22.5px 0;
        &.on-wish {
          cursor: pointer;
        }
        &:nth-of-type(1) {
          width: 70px;
          margin: 0;
        }

        &:nth-of-type(2) {
          width: calc((100% - 385px) / 3);
          h4 {
            text-align: center;
          }
        }

        &:nth-of-type(3) {
          width: 105px;
        }

        &:nth-of-type(4) {
          width: calc((100% - 385px) / 3);
        }

        &:nth-of-type(5) {
          width: 105px;
        }

        &:nth-of-type(6) {
          width: 105px;
        }
        &:nth-of-type(7) {
          width: calc((100% - 385px) / 3);
        }

        h4 {
          @include initfontKor(
            $font-size: 22px,
            $line-height: 30px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #363739,
            $letter-spacing: -0.48px,
            $text-align: center
          );
          &.order {
            color: $pointcolor__orange;
          }
          &.wish {
            position: relative;
            color: #5b948f;
            &:after {
              position: absolute;
              bottom: 2px;
              left: 50%;
              transform: translateX(-50%);
              width: 100px;
              height: 1px;
              background-color: #5b948f;
              content: "";
            }
          }
        }
        .mini-btn {
          width: 80px;
          height: 30px;
          position: relative;
          button {
            width: 100%;
            height: inherit;
            border-radius: 16px;
            border: 0;
            padding: 0;
            background: transparent;
            @include initfontKor(
              $font-size: 17px,
              $line-height: 24px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #363739,
              $letter-spacing: -0.34px,
              $text-align: center
            );
            &.recruit {
              border: solid 1px $pointcolor__orange;
              color: $pointcolor__orange;
            }
            &.consult {
              background: rgba(15, 200, 28, 0.2);
              color: #08b815;
            }
            &.cancel {
              background: rgba(248, 84, 84, 0.2);
              color: #ff6a6a;
            }
            &.noneProgress {
              background: #eaebf1;
              color: #8b8d94;
            }
          }
        }
        .btn-wrap {
          border-radius: 2px;
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
          background-color: $pointcolor__orange;
          overflow: hidden;
          width: 110px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
          button {
            width: inherit;
            border: 0;
            padding: 0;
            background: transparent;
            cursor: pointer;
            @include initfontKor(
              $font-size: 22px,
              $line-height: 30px,
              $font-family: "AppleSDGothicNeoR00",
              $color: #fff,
              $letter-spacing: -0.48px,
              $text-align: center
            );
          }
        }
      }
      &.consultantList{
        .item{
          &:nth-of-type(1) {
            width:50px
          }
          &:nth-of-type(3) {
            margin:0;
            width: 80px;
          }
          &:nth-of-type(5) {
            width:170px;
            word-break: keep-all;
            h4{
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }

          &:nth-of-type(6) {
            display: flex;
            justify-content: end;
          }
        }
      }
    }
  }
}
</style>
