<template>
  <div class="app-context flex">
    <side-menu :sideMenuOption="sideMenuOption" @is-unauthorized="isUnAuthorized"></side-menu>
    <div class="wrap-context">
      <notification class="low-margin" :notificationInfo="notificationInfo"></notification>
      <swiper :swiperList="swiperList"></swiper>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import DefaultPopup from "@/components/modal/DefaultPopup.vue";
import SideMenu from "@/components/SideMenu.vue";
import Notification from "@/components/Notification.vue";
import Swiper from "@component/Swiper.vue";
import { mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      selectOrder: 0,
      swiperList: {},
      notificationInfo: {
        title: "삼킴구조와 기능",
        btnSet: [
          {
            img: "search.png",
            name: "평가조회",
            routerUrl: "record",
            isActionBtn: false
          }
        ]
      },
      sideMenuDeep: true,
      sideMenuOption: [
        {
          img: "sidebar_item01.png",
          img_active: "sidebar_menu1_active.png",
          img_active_deep: "sidebar_menu1_active_deep.png",
          title: "메인",
          isActive: true,
          link: ""
        },
        {
          img: "sidebar_item02.png",
          img_active: "sidebar_menu2_active.png",
          img_active_deep: "sidebar_menu2_active_deep.png",
          title: "삼킴 평가",
          isActive: false,
          link: "evaluate"
        },
        {
          img: "sidebar_item03.png",
          img_active: "sidebar_menu3_active.png",
          img_active_deep: "sidebar_menu3_active_deep.png",
          title: "삼킴 훈련",
          isActive: false,
          link: "trainingDate"
        },
        
        {
          img: "sidebar_item05.png",
          img_active: "sidebar_menu5_active.png",
          img_active_deep: "sidebar_menu5_active_deep.png",
          title: "자세법ㆍ식이법",
          isActive: false,
          link: "choiceType"
        },
        {
          img: "sidebar_item06.png",
          img_active: "sidebar_menu6_active.png",
          img_active_deep: "sidebar_menu6_active_deep.png",
          title: "원격 상담",
          isActive: false,
          link: "reservationType"
        },
        {
          img: "sidebar_mypage.png",
          img_active: "sidebar_mypage_active.png",
          img_active_deep: "sidebar_mypage_active.png",
          title: "마이페이지",
          isActive: false,
          link: "mypage"
        }
      ],
      popupSet: {}
    };
  },
  created() {
    this.getSwiperList();
  },
  mounted() {},
  methods: {
    ...mapMutations("basic", ["SET_POPUP"]),
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = "로그인 권한이 필요합니다.";
      this.popupSet.popImg = "warning.svg";
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = "/login";
      this.popupSet.confirmBtnText = "확인";
      this.popupSet.destination = true;
    },
    popSuccess() {
      this.SET_POPUP(true);
      this.popupSet.popType = "warn";
      this.popupSet.popImg = "success.svg";
      this.popupSet.content = "평가가 완료되었습니다.";
      this.popupSet.cancelBtnText = "취소";
      this.popupSet.nextLink = "/";
      this.popupSet.confirmBtnText = "완료";
      this.popupSet.destination = true;
    },
    getSwiperList() {
      const getList = {
        life: [
          {
            questionType: 3,
            title: "나는 지금 내 삶이 좋다",
            subTitle: null,
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다"
            ],
            selectedVal: "조금\n그렇다"
          },
          {
            questionType: 3,
            title: "나는 내가 가진 것을 나누는 삶을 살고 있다.",
            subTitle: null,
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다"
            ],
            selectedVal: "조금\n그렇다"
          },
          {
            questionType: 3,
            title: "내가 왜 살아야 하는지 모르겠다.",
            subTitle: null,
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다"
            ],
            selectedVal: "조금\n그렇다"
          },
          {
            questionType: 3,
            title: "나는 내가 원하던 삶을 살아왔다.",
            subTitle: null,
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다"
            ],
            selectedVal: "조금\n그렇다"
          },
          {
            questionType: 3,
            title: "나는 이만하면 잘 살아왔다고 생각한다.",
            subTitle: null,
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다"
            ],
            selectedVal: "조금\n그렇다"
          },
          {
            questionType: 3,
            title: "나는 나의 발전을 위해 노력한다.",
            subTitle: null,
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다"
            ],
            selectedVal: "조금\n그렇다"
          },
          {
            questionType: 3,
            title: "내 삶보다 다른 사람의 삶이 더 나아 보인다.",
            subTitle: null,
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다"
            ],
            selectedVal: "조금\n그렇다"
          },
          {
            questionType: 3,
            title: "지금까지 살아온 내 삶에 만족한다.",
            subTitle: null,
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다"
            ],
            selectedVal: "조금\n그렇다"
          },
          {
            questionType: 3,
            title: "나이가 들어서 생기는 나의 역할 변화를 받아들이기 힘들다",
            subTitle: null,
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다"
            ],
            selectedVal: "조금\n그렇다"
          },
          {
            questionType: 3,
            title: "내 삶은 공허하다.",
            subTitle: null,
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다"
            ],
            selectedVal: "조금\n그렇다"
          },
          {
            questionType: 3,
            title: "나는 우리 사회의 발전에 기여하는 삶을 살고 있다.",
            subTitle: null,
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다"
            ],
            selectedVal: "조금\n그렇다"
          },
          {
            questionType: 3,
            title: "내가 과거에 겪은 시련은 나를 성장시키는 원동력이 되었다.",
            subTitle: null,
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다"
            ],
            selectedVal: "조금\n그렇다"
          },
          {
            questionType: 3,
            title: "나는 삶의 목적이 분명하다.",
            subTitle: null,
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다"
            ],
            selectedVal: "조금\n그렇다"
          },
          {
            questionType: 3,
            title: "지금 이 순간에 최선을 다해 살아가고 있다.",
            subTitle: null,
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다"
            ],
            selectedVal: "조금\n그렇다"
          },
          {
            questionType: 3,
            title: "내 삶이 무의미하다고 생각한다.",
            subTitle: null,
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다"
            ],
            selectedVal: "조금\n그렇다"
          },
          {
            questionType: 3,
            title: "나는 남에게 도움이 되는 삶을 살고 있다.",
            subTitle: null,
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다"
            ],
            selectedVal: "조금\n그렇다"
          },
          {
            questionType: 3,
            title: "내가 살아온 삶은 후회로 가득하다.",
            subTitle: null,
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다"
            ],
            selectedVal: "조금\n그렇다"
          },
          {
            questionType: 3,
            title: "나는 내가 원하는 대로 스스로 결정하며 살고 있다.",
            subTitle: null,
            choiceVal: [
              "전혀\n그렇지 않다",
              "조금\n그렇다",
              "그렇다",
              "많이\n그렇다",
              "아주\n많이 그렇다"
            ],
            selectedVal: "조금\n그렇다"
          }
        ]
      };
      this.swiperList = getList;
    },
    popEscape() {
      this.SET_POPUP(true);
      this.popupSet.popType = "warn";
      this.popupSet.popImg = "warning.svg";
      this.popupSet.content = "평가가 완료되지 않았습니다.\n 평가를 종료하시겠습니까?";
      this.popupSet.cancelBtnText = "취소";
      this.popupSet.nextLink = "/";
      this.popupSet.confirmBtnText = "실행";
    }
  },
  computed: {
    ...mapState("basic", ["checkPopup"])
  },
  components: {
    SideMenu,
    DefaultPopup,
    Notification,
    Swiper
  }
};
</script>
<style lang="scss" scoped>
.app-context {
  .wrap-context {
    width: 870px;
    margin: 22px 122px 0 auto;
  }
}
</style>
