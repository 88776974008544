<template>
  <div class="app-context flex">
    <side-menu :sideMenuOption="sideMenuOption"></side-menu>
    <div class="wrap-context">
      <notification :notificationInfo="notificationInfo"></notification>
      <block-menu :menuList="menuList" :menuType="menuType"></block-menu>
    </div>
    <calendar-popup
      @selected-date="selectedDate"
      v-if="checkCalendarPopup"
      :popupSet="popupSet"
    ></calendar-popup>
  </div>
</template>

<script>
import CalendarPopup from '@/components/modal/CalendarPopup.vue';
import SideMenu from '@/components/SideMenu.vue';
import Notification from '@/components/Notification.vue';
import BlockMenu from '@component/BlockMenu.vue';
import { mapMutations, mapState } from 'vuex';

export default {
  data() {
    return {
      menuType: 3,
      notificationInfo: {
        title: '삼킴기능 임상평가',
        btnSet: [
          {
            img: 'mainItem01.png',
            name: '훈련안내',
            isActionBtn: false,
          },
          {
            img: 'mainItem02.png',
            name: '훈련기록',
            isActionBtn: false,
          },
        ],
      },
      menuList: [
        {
          /**
                img: 'clinical01.png',
                 */
          img: 'mainItem01.png',
          name: '삼킴구조 및\n기능',
          isActive: false,
        },
        {
          /**
                img: 'clinical02.png',
                */
          img: 'mainItem02.png',
          name: '물 삼키기',
          isActive: true,
        },
        {
          /**
                img: 'clinical03.png',
                */
          img: 'mainItem03.png',
          name: '고체 씹기',
          isActive: false,
        },
        {
          /**
                img: 'clinical04.png',
                */
          img: 'mainItem04.png',
          name: '터/아 발성',
          isActive: true,
        },
      ],
      sideMenuOption: [
        {
          img: 'sidebar_item01.png',
          img_active: 'sidebar_item01.png',
          title: '메인',
          isActive: true,
          link: '',
        },
        {
          img: 'sidebar_item02.png',
          img_active: 'sidebar_item02.png',
          title: '삼킴 평가',
          isActive: false,
          link: '',
        },
        {
          img: 'sidebar_item03.png',
          img_active: 'sidebar_item03.png',
          title: '삼킴 훈련',
          isActive: false,
          link: '',
        },
        {
          img: 'sidebar_item05.png',
          img_active: 'sidebar_item05.png',
          title: '자세법ㆍ식이법',
          isActive: false,
          link: '',
        },
        {
          img: 'sidebar_item06.png',
          img_active: 'sidebar_item06.png',
          title: '원격 상담',
          isActive: false,
          link: 'reservationType',
        },
        {
          img: 'sidebar_mypage.png',
          img_active: 'sidebar_mypage_active.png',
          title: '마이페이지',
          isActive: false,
          link: 'mypage',
        },
      ],
      popupSet: {},
    };
  },
  created() {},
  mounted() {
    this.popTest();
  },
  methods: {
    ...mapMutations('basic', ['SET_CALENDAR_POPUP']),
    selectedDate(date) {
      alert(`date: ${date}`);
    },
    popTest() {
      this.SET_CALENDAR_POPUP(true);
      this.popupSet.content = '테스트';
      this.popupSet.cancelBtnText = '취소';
      this.popupSet.nextLink = '/';
      this.popupSet.confirmBtnText = '실행';
    },
    linkFromMain(order) {},
    linkFromEvaluation(order) {},
    linkFromClinic(order) {},
  },
  computed: {
    ...mapState('basic', ['checkCalendarPopup']),
  },
  components: {
    SideMenu,
    Notification,
    BlockMenu,
    CalendarPopup,
  },
};
</script>
<style lang="scss" scoped>
.app-context {
  .wrap-context {
    width: 1055px;
    margin: 143px 90px 0 auto;
  }
}
</style>
