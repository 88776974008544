<template>
<div class="countTimer">
  <countdown :end-time="new Date().getTime() + 60000">
  <template
    v-slot:process="countTimer">
      <span>{{ `${countTimer.timeObj.ceil.s}` }}</span>
    </template>
  <template
    v-slot:finish>
      <span>0</span>
  </template>
</countdown>
</div>
</h1>
</template>

<script>
export default {
  data() {
    return {
    
    };
  },
  created() {},
  methods: {},
  computed: {},
  components: {},
};
</script>
<style lang="scss" scoped>
</style>
