<template>
  <div
    class="question-wrap"
    :class="{
      first: currentPage === 1,
      second: currentPage === 2,
      tripple: currentPage === 3,
      fourth: currentPage === 4,
    }"
  >
    <div
      class="question"
      v-for="(listItem, listIndex) in list"
      :key="listIndex + 'A'"
      :class="{
        hide:
          currentPage === 2 &&
          (listIndex === 4 || listIndex === 5 || listIndex === 6) &&
          !view.isDentureQuestionVisible,
        inSubTitle: currentPage === 2 && listIndex === 4 && view.isDentureQuestionVisible,
      }"
    >
      <p
        class="sub-title-ab"
        v-if="currentPage === 2 && listIndex === 4 && view.isDentureQuestionVisible"
      >
        틀니가 있는 경우
      </p>
      <h5
        class="q-title"
        v-text="listItem.questionText"
        :class="{
          hide: (listItem.listType === 7 && !view.isPeriod) || listItem.listType === 8,
        }"
      />
      <div class="q-content" v-if="listItem.listType === 1">
        <div class="radio-input-wrap">
          <div
            class="item"
            v-for="(answer, answerIndex) in listItem.answerList"
            :key="answerIndex + 'B'"
          >
            <input
              type="radio"
              :id="String(answerIndex) + String(listIndex)"
              v-model="listItem.selected"
              :value="answer.answerText"
              @input="radioDetail(listIndex, answerIndex, listItem.selected)"
            />
            <label
              :for="String(answerIndex) + String(listIndex)"
              v-text="answer.answerText"
            ></label>
          </div>
        </div>
      </div>
      <div class="q-content" v-if="listItem.listType === 2">
        <div class="radio-input-wrap">
          <div
            class="item"
            v-for="(answer, answerIndex) in listItem.answerList"
            :key="answerIndex + 'B'"
          >
            <input
              type="radio"
              :id="String(answerIndex) + String(listIndex)"
              v-model="listItem.selected"
              :value="answer.answerText"
              @input="radioDetail(listIndex, answerIndex, listItem.selected)"
            />
            <label
              :for="String(answerIndex) + String(listIndex)"
              v-text="answer.answerText"
            ></label>
            <div
              class="input-wrap"
              :class="{ focused: view.isEtcInputFocused }"
              v-if="listItem.selected === '기타' && answer.answerText === '기타'"
            >
              <input
                type="text"
                v-model="listItem.etcValue"
                :placeholder="listIndex === 0 ? '기타(중퇴포함)' : '내용을 입력해 주세요'"
                @click="enterInput()"
                @blur="exitInput()"
              />
            </div>
            <span
              v-if="
                listItem.question === 1 &&
                listItem.selected === '기타' &&
                answer.answerText === '기타'
              "
              class="added"
              >년</span
            >
          </div>
        </div>
      </div>
      <div class="q-content" v-if="listItem.listType === 3">
        <div class="input-wrap teeth">
          <input
            type="number"
            v-model="listItem.selected"
            maxlength="2"
            @blur="checkTeeth()"
            :disabled="listIndex === 2"
          />
          <span class="count">개</span>
        </div>
      </div>
      <div class="q-content layerd" v-if="listItem.listType === 4">
        <div class="input-wrap etc">
          <input
            type="text"
            v-model="listItem.selected"
            placeholder="내용을 입력해주세요"
          />
        </div>
        <div class="side-notification">
          <p>
            '순환기계, 호흡기계, 신경ㆍ감각계 질환, 내분비 대사성,<br />
            기타' 에 해당사항이 없는 경우 선택하지 않으셔도 됩니다.
          </p>
        </div>
      </div>
      <div class="q-content" v-if="listItem.listType === 5">
        <div class="scale-wrap">
          <div
            class="item"
            @click="choiceScale(listIndex, answer.answerText)"
            v-for="(answer, answerIndex) in listItem.answerList"
            :key="answerIndex + 'B'"
          >
            <div class="circle-wrap">
              <div
                class="circle"
                :class="{ active: listItem.selected === answer.answerText }"
              />
            </div>
            <h3 class="scale" v-html="transedLine(answer.answerText)" />
          </div>
        </div>
      </div>
      <div class="q-content" v-if="listItem.listType === 6">
        <div class="notification">
          <p>
            사회경제적 지위 (소득, 직업, 교육, 재산 등을 포함)를 점수로 나타낼 수 있다고
            가정해 보십시오. 가장 높은 지위는 '10'이고, 가장 낮은 지위는 '1'에 위치하게
            됩니다. 당신의 사회경제적 지위는 어디에 위치한다고 생각하십니까?
          </p>
        </div>
        <div class="scale-wrap wide">
          <div
            class="item"
            @click="choiceScale(listIndex, answer.answerText)"
            v-for="(answer, answerIndex) in listItem.answerList"
            :key="answerIndex + 'B'"
          >
            <div class="circle-wrap">
              <div
                class="circle"
                :class="{ active: listItem.selected === answer.answerText }"
              />
            </div>
            <h3 class="scale" v-html="transedLine(answer.answerText)" />
          </div>
        </div>
      </div>
      <div class="q-content" v-if="listItem.listType === 7 && view.isPeriod">
        <div class="diagnosis">
          <div class="date-wrap">
            <div class="item">
              <div class="input-wrap">
                <input
                  type="number"
                  v-model="periodOpt.year"
                  @blur="checkPeriod(0)"
                  placeholder="YYYY"
                  maxlength="4"
                />
              </div>
              <span>년</span>
            </div>
            <div class="item">
              <div class="input-wrap">
                <input
                  type="number"
                  v-model="periodOpt.month"
                  @blur="checkPeriod(1)"
                  placeholder="MM"
                  maxlength="2"
                />
              </div>
              <span>월</span>
            </div>
          </div>
          <p
            v-text="`진단이후 : ` + periodOpt.period"
            v-if="periodOpt.year !== '' && periodOpt.month !== ''"
          ></p>
        </div>
      </div>
      <!-- 체크박스로 변환 -->
      <div class="q-content" v-if="listItem.listType === 9">
        <div class="checkbox-input-wrap">
          <div
            class="item"
            v-for="(answer, answerIndex) in listItem.answerList"
            :key="answerIndex + 'B'"
          >
            <input
              type="checkbox"
              :id="String(answerIndex) + String(listIndex)"
              :value="answer.answerText"
              v-model="listItem.checkFlagArr[answerIndex].flag"
              @change="checkboxDetail(listIndex, answerIndex, answer.answerText)"
            />
            <label
              :for="String(answerIndex) + String(listIndex)"
              v-text="answer.answerText"
            ></label>
            <div
              class="input-wrap"
              :class="{ focused: view.isEtcInputFocused }"
              v-if="listItem.selected[0] === '기타' && answer.answerText === '기타'"
            >
              <input
                type="text"
                v-model="listItem.etcValue"
                :placeholder="listIndex === 0 ? '기타(중퇴포함)' : '내용을 입력해 주세요'"
                @click="enterInput()"
                @blur="exitInput()"
              />
            </div>
            <span
              v-if="listItem.selected === '기타' && answer.answerText === '기타'"
              class="added"
              >년</span
            >
          </div>
        </div>
      </div>
      <div class="q-content" v-if="listItem.listType === 10">
        <div class="checkbox-input-wrap">
          <div
            class="item"
            v-for="(answer, answerIndex) in listItem.answerList"
            :key="answerIndex + 'C'"
          >
            <input
              type="checkbox"
              :id="String(answerIndex) + String(listIndex)"
              :value="answer.answerText"
              v-model="listItem.checkFlagArr[answerIndex].flag"
              @change="checkboxDetail2(listIndex, answerIndex, answer.answerText)"
            />
            <label
              :for="String(answerIndex) + String(listIndex)"
              v-text="answer.answerText"
            ></label>
          </div>
        </div>
      </div>
    </div>
    <div class="second-bg" v-if="currentPage === 2">
      <img src="/information/img_teeth.png" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    questionList: {
      type: Array,
    },
    currentPage: {
      type: Number,
    },
    isVisibleDentureQuestion: {
      type: Boolean,
      default: false,
    },
    isPeriod: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      list: {},
      periodOpt: {
        year: "",
        month: "",
        period: "",
      },
      view: {
        isEtcInputFocused: false,
        isDentureQuestionVisible: this.isVisibleDentureQuestion,
        isPeriod: this.isPeriod,
      },
    };
  },
  created() {
    this.carryList();
  },
  methods: {
    enterInput() {
      this.view.isEtcInputFocused = true;
    },
    exitInput() {
      this.view.isEtcInputFocused = false;
    },
    checkTeeth() {
      if (this.list[0].selected !== "" && this.list[1].selected !== "") {
        this.list[2].selected =
          Number(this.list[0].selected) + Number(this.list[1].selected);
      } else {
        this.list[2].selected = "";
      }
    },
    checkPeriod(order) {
      if (this.periodOpt.year !== "" && this.periodOpt.month !== "") {
        const timeData = String(this.periodOpt.year) + "-" + String(this.periodOpt.month);
        const transData = this.$moment(timeData).format("YYYY-MM");
        const today = this.$moment(new Date()).format("YYYY-MM-DD");
        console.log("transData :", transData);
        console.log("today :", today);
        const diffYear = this.$moment(new Date(today)).diff(
          this.$moment(transData),
          "years"
        );
        console.log("diff-year :", diffYear);
        const differencesMonth = this.$moment(new Date(today)).diff(
          this.$moment(transData),
          "months"
        );
        const diffMonth = differencesMonth - diffYear * 12;
        console.log("diff-month :", diffMonth);
        this.periodOpt.period = diffYear + "년 " + diffMonth + "개월";
        this.list[6].selected = [this.periodOpt.year, this.periodOpt.month];
        this.list[7].selected = diffYear + "년 " + diffMonth + "개월";
      } else {
        console.log("년월 조건 불충분");
        this.list[6].selected = [];
        this.list[7].selected = "";
      }
    },
    carryList() {
      this.list = this.questionList;
      /** 프롭스가져온다음 vuex에 저장된 회원가입 정보를 가져온다 */
      /** checks */
      if (this.currentPage === 3) {
        if (
          this.questionList[6].selected[0] !== "" &&
          this.questionList[6].selected[1] !== ""
        ) {
          this.periodOpt.year = this.questionList[6].selected[0];
          this.periodOpt.month = this.questionList[6].selected[1];
        }
        if (this.questionList[7].selected !== "") {
          this.periodOpt.period = this.questionList[7].selected;
        }
      }
    },
    removeA(arr) {
      let what;
      const a = arguments;
      let L = a.length;
      let ax;
      while (L > 1 && arr.length) {
        what = a[--L];
        while ((ax = arr.indexOf(what)) !== -1) {
          arr.splice(ax, 1);
        }
      }
      return arr;
      /**
       * this.removeA(wholeArray, enabledArray[j]);
       */
    },
    checkboxDetail2(parentIndex, answerIndex, selectedTxt) {
      console.log("parentIndex :", parentIndex);
      console.log("answerIndex :", answerIndex);
      console.log("selectedTxt :", selectedTxt);
      console.log("기타와 없음이 아닙니다");
      const sampleArr = JSON.parse(JSON.stringify(this.list[parentIndex].selected));
      let existFlag = false;
      for (let i = 0; i < this.list[parentIndex].selected.length; i++) {
        /** 있는지 검증 후 넣어주고 있으면 뺴주고 */
        if (this.list[parentIndex].selected[i] === selectedTxt) {
          existFlag = true;
        }
      }
      if (existFlag) {
        this.removeA(sampleArr, selectedTxt);
      } else {
        sampleArr.push(selectedTxt);
      }
      this.list[parentIndex].etcValue = "";
      this.list[parentIndex].selected = sampleArr;
      /** this.list[parentIndex].etcValue = '';  */
    },
    checkboxDetail(parentIndex, answerIndex, selectedTxt) {
      console.log("parentIndex :", parentIndex);
      console.log("answerIndex :", answerIndex);
      console.log("selectedTxt :", selectedTxt);
      if (
        selectedTxt !==
          this.list[parentIndex].answerList[this.list[parentIndex].answerList.length - 1]
            .answerText &&
        selectedTxt !==
          this.list[parentIndex].answerList[this.list[parentIndex].answerList.length - 2]
            .answerText
      ) {
        console.log("기타와 없음이 아닙니다");
        const sampleArr = JSON.parse(JSON.stringify(this.list[parentIndex].selected));
        let existFlag = false;
        let hasEtc = false;
        let hasEmpty = false;
        this.list[parentIndex].checkFlagArr[
          this.list[parentIndex].checkFlagArr.length - 1
        ].flag = false;
        this.list[parentIndex].checkFlagArr[
          this.list[parentIndex].checkFlagArr.length - 2
        ].flag = false;
        for (let i = 0; i < this.list[parentIndex].selected.length; i++) {
          /** 있는지 검증 후 넣어주고 있으면 뺴주고 */
          if (this.list[parentIndex].selected[i] === selectedTxt) {
            existFlag = true;
          }
          if (
            this.list[parentIndex].selected[i] ===
            this.list[parentIndex].answerList[
              this.list[parentIndex].answerList.length - 1
            ].answerText
          ) {
            hasEtc = true;
          }
          if (
            this.list[parentIndex].selected[i] ===
            this.list[parentIndex].answerList[
              this.list[parentIndex].answerList.length - 2
            ].answerText
          ) {
            hasEmpty = true;
          }
        }
        if (existFlag) {
          this.removeA(sampleArr, selectedTxt);
        } else {
          sampleArr.push(selectedTxt);
        }
        if (hasEtc) {
          this.removeA(
            sampleArr,
            this.list[parentIndex].answerList[
              this.list[parentIndex].answerList.length - 1
            ].answerText
          );
        }
        if (hasEmpty) {
          this.removeA(
            sampleArr,
            this.list[parentIndex].answerList[
              this.list[parentIndex].answerList.length - 2
            ].answerText
          );
        }

        this.list[parentIndex].etcValue = "";
        this.list[parentIndex].selected = sampleArr;
        /** this.list[parentIndex].etcValue = '';  */
      } else if (
        selectedTxt ===
        this.list[parentIndex].answerList[this.list[parentIndex].answerList.length - 1]
          .answerText
      ) {
        console.log("기타입니다");
        for (let j = 0; j < this.list[parentIndex].checkFlagArr.length - 1; j++) {
          this.list[parentIndex].checkFlagArr[j].flag = false;
        }
        if (
          this.list[parentIndex].selected[0] !==
          this.list[parentIndex].answerList[this.list[parentIndex].answerList.length - 1]
            .answerText
        ) {
          const initArr = [];
          initArr.push(selectedTxt);
          this.list[parentIndex].selected = initArr;
        } else {
          const sampleArr2 = JSON.parse(JSON.stringify(this.list[parentIndex].selected));
          this.removeA(
            sampleArr2,
            this.list[parentIndex].answerList[
              this.list[parentIndex].answerList.length - 1
            ].answerText
          );
          this.list[parentIndex].selected = sampleArr2;
          this.list[parentIndex].etcValue = "";
        }
      } else if (
        selectedTxt ===
        this.list[parentIndex].answerList[this.list[parentIndex].answerList.length - 2]
          .answerText
      ) {
        console.log("없음입니다");
        for (let k = 0; k < this.list[parentIndex].checkFlagArr.length - 2; k++) {
          this.list[parentIndex].checkFlagArr[k].flag = false;
        }
        this.list[parentIndex].checkFlagArr[
          this.list[parentIndex].checkFlagArr.length - 1
        ].flag = false;
        this.list[parentIndex].etcValue = "";
        if (
          this.list[parentIndex].selected[0] !==
          this.list[parentIndex].answerList[this.list[parentIndex].answerList.length - 2]
            .answerText
        ) {
          const initArr = [];
          initArr.push(selectedTxt);
          this.list[parentIndex].selected = initArr;
        } else {
          const sampleArr2 = JSON.parse(JSON.stringify(this.list[parentIndex].selected));
          this.removeA(
            sampleArr2,
            this.list[parentIndex].answerList[
              this.list[parentIndex].answerList.length - 2
            ].answerText
          );
          this.list[parentIndex].selected = sampleArr2;
        }
      }
    },
    radioDetail(parentIndex, answerIndex, selectedTxt) {
      console.log("parentIndex :", parentIndex);
      console.log("answerIndex :", answerIndex);
      console.log("selectedTxt :", selectedTxt);
      if (selectedTxt !== "기타") {
        this.list[parentIndex].etcValue = "";
      }
      if (this.currentPage === 2 && parentIndex === 3) {
        if (answerIndex !== 0) {
          /** init - selected */
          this.list[4].selected = "";
          this.list[5].selected = "";
          this.list[6].selected = "";
        }
        if (answerIndex === 0) {
          this.view.isDentureQuestionVisible = true;
        } else {
          this.view.isDentureQuestionVisible = false;
        }
      } else if (this.currentPage === 3 && parentIndex === 5) {
        if (answerIndex !== 0) {
          /** init - selected */
          this.list[6].selected = [];
          this.list[7].selected = "";
          const initArr = {
            year: "",
            month: "",
            period: "",
          };
          this.periodOpt = initArr;
          this.view.isPeriod = false;
        } else {
          this.view.isPeriod = true;
        }
      }
    },
    choiceScale(parentIndex, selectedItem) {
      console.log("parentIndex :", parentIndex);
      console.log("selectedItem :", selectedItem);
      this.list[parentIndex].selected = selectedItem;
    },
  },
  computed: {
    transedLine() {
      return (text) => {
        if (text === "전혀그렇지않다") {
          return "전혀<br>그렇지 않다";
        } else if (text === "거의그렇지않다") {
          return "거의<br>그렇지 않다";
        } else if (text === "가끔그렇다") {
          return "가끔<br>그렇다";
        } else if (text === "자주그렇다") {
          return "자주<br>그렇다";
        } else if (text === "매우그렇다") {
          return "매우<br>그렇다";
        } else {
          return text;
        }
      };
    },
  },
  components: {},
};
</script>
<style lang="scss" scoped>
@import "@assets/css/join";
.app-context.join .wrap-context .join-context .container .question-wrap {
  &.first {
    .question {
      &:nth-of-type(1) .q-content .radio-input-wrap {
        .item:nth-of-type(6) {
          margin-left: 0px;
        }
        .item:nth-of-type(n + 6) {
          margin-top: 15px;
        }
      }
      &:nth-of-type(3) .q-content .radio-input-wrap {
        .item:nth-of-type(4),
        .item:nth-of-type(6) {
          margin-left: 0px;
        }
        .item:nth-of-type(n + 4) {
          margin-top: 15px;
        }
        .item:nth-of-type(6) {
          width: 100%;
        }
      }
      &:nth-of-type(4) .q-content .radio-input-wrap {
        .item:nth-of-type(6) {
          margin-left: 0px;
        }
        .item:nth-of-type(n + 6) {
          margin-top: 15px;
        }
      }
      &:nth-of-type(6) {
        .q-title {
          width: fit-content;
          position: relative;
          &:before {
            content: "*";
            position: absolute;
            color: #fe6967;
            right: -15px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        .q-content {
          .checkbox-input-wrap {
            .item:nth-of-type(4) {
              margin-right: 20px;
            }
            .item:nth-of-type(5) {
              margin-left: 0px;
            }
            .item:nth-of-type(n + 5) {
              margin-top: 15px;
            }
          }
        }
      }
      &:nth-of-type(7) .q-content .radio-input-wrap {
        .item:nth-of-type(4) {
          margin-right: 20px;
        }
        .item:nth-of-type(5) {
          margin-left: 0px;
          width: 100%;
        }
        .item:nth-of-type(n + 5) {
          margin-top: 15px;
        }
      }
      &:nth-of-type(8) {
        .q-title {
          width: fit-content;
          position: relative;
          &:before {
            content: "*";
            position: absolute;
            color: #fe6967;
            right: -15px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        .q-content .checkbox-input-wrap {
          .item:nth-of-type(4) {
            margin-right: 100px;
          }
          .item:nth-of-type(5) {
            margin-left: 0px;
          }
          .item:nth-of-type(n + 5) {
            margin-top: 15px;
          }
        }
      }
    }
  }
  &.tripple {
    .question:nth-of-type(1),
    .question:nth-of-type(2),
    .question:nth-of-type(3),
    .question:nth-of-type(4) {
      .q-title {
        width: fit-content;
        position: relative;
        &:before {
          content: "*";
          position: absolute;
          color: #fe6967;
          right: -15px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
</style>
