<template>
  <div class="info-area">
    <div class="information">
      <h3 class="question-title" v-text="information.question" />
      <h3 class="answer-title" v-text="information.answer" />
      <p class="content" v-html="computedLine(information.content)" />
      <p class="sub" v-if="identification.order === 17">
        * 구강이 청결하지 않으면 침이 증가하므로 구강위생관리에 주의해주세요.
      </p>
      <p class="sub" v-if="identification.order === 21">
        * 거즈에서 물이 흐르지 않도록 주의합니다.
      </p>
      <div
        class="info-gallery"
        :class="{ fourth: information.referenceImgList.length > 3 }"
        v-if="information.referenceImgList.length > 0 && information.tooltipList.length <= 0"
      >
        <div
          class="img-wrap"
          v-for="(infoGallery, infoGalleryIndex) in information.referenceImgList"
          :key="infoGalleryIndex + 'A'"
        >
          <img :src="`/information/posture/${infoGallery}`" />
        </div>
      </div>
      <div class="banner" v-if="information.tooltipList.length > 0">
        <ul class="posture">
          <li
            v-for="(tooltip, tooltipIndex) in information.tooltipList"
            :key="tooltipIndex + 'B'"
            v-text="tooltip"
            :class="{
              inMent1: identification.order === 3 && tooltipIndex === 1,
              inMent2: identification.order === 14 && tooltipIndex === 2,
              inMent3: identification.order === 15 && tooltipIndex === 1,
              inMent4: identification.order === 17 && tooltipIndex === 1
            }"
          />
        </ul>
        <div class="ab-bg">
          <img :src="`/information/posture/${information.referenceImgList[0]}`" />
        </div>
      </div>
      <p class="notification" v-text="information.notification" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    information: {
      title: {
        type: String
      },
      content: {
        type: String
      },
      referenceImgList: {
        type: Array
      },
      tooltipList: {
        type: Array
      },
      notification: {
        type: String
      }
    },
    identification: {
      type: Object
    }
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {
    computedLine() {
      return text => text.split("\n").join("<br />");
    }
  },
  components: {}
};
</script>
<style lang="scss" scoped>
@media screen and (min-width: 1024px) {
  .app-context.share {
  }
}
@media screen and (max-width: 1023px) {
  .app-context.share {
    .info-area {
      .information {
        width: 100%;
        .banner {
          flex-wrap: wrap;
          padding: 20px 20px 0 20px;
          ul.posture {
            width: 100%;
            margin: 0 0 30px 0;
          }
          .ab-bg {
            position: relative;
            width: 100%;
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .app-context.share {
    .wrap-context {
      .info-area {
        padding: 12px;
        .information {
          h3.question-title {
            font-size: 20px;
            line-height: 24px;
            padding: 0 40px;
            &:after {
              width: 31px;
              height: 31px;
              background-image: url("~@assets/images/util/question_small.svg");
            }
          }
          h3.answer-title {
            font-size: 20px;
            line-height: 24px;
            padding: 0 40px;
            &:after {
              width: 31px;
              height: 31px;
              background-image: url("~@assets/images/util/answer_small.svg");
            }
          }
          p.content {
            font-size: 18px;
            line-height: 24px;
            padding: 0 40px;
          }
          .banner {
            margin: 0 40px 10px 40px;
          }
          ul.posture {
            margin: 0 0 10px 0;
            li {
              padding: 0 0 0 28.5px;
              line-height: 24px;
              font-size: 18px;

              &:before {
                top: 2px;
                width: 18px;
                height: 18px;
                line-height: 18px;
                font-size: 16px;
              }
              &:after {
                line-height: 21px;
                font-size: 15px;
              }
            }
          }
          p.notification {
            line-height: 24px;
            font-size: 14px;
            padding: 0 40px;
          }
          p.sub {
            line-height: 21px;
            font-size: 15px;
            padding: 0 40px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .app-context.share .info-area .information .banner .ab-bg {
    img {
      max-width: 100%;
    }
  }
}
.info-area {
  display: flex;
  background: #fff;
  padding: 28px 20px;
  position: relative;
  min-height: 540px;
  .ab-notification {
    position: absolute;
    width: 100%;
    bottom: -40px;
    right: 0;
    p {
      @include initfontKor(
        $font-size: 18px,
        $line-height: 28px,
        $font-family: "AppleSDGothicNeoR00",
        $color: #315754,
        $letter-spacing: -0.36px,
        $text-align: right
      );
    }
  }
  .information {
    h3.question-title {
      position: relative;
      margin: 0 0 15px 0;
      padding: 0 0 0 55px;
      @include initfontKor(
        $font-size: 30px,
        $line-height: 46px,
        $font-family: "AppleSDGothicNeoB00",
        $color: #fd8b11,
        $letter-spacing: -0.6px,
        $text-align: left
      );
      &:after {
        content: "";
        position: absolute;
        width: 46px;
        height: 46px;
        background-image: url("~@assets/images/util/question.svg");
        left: 0;
        top: 0;
      }
    }
    h3.answer-title {
      position: relative;
      margin: 0 0 15px 0;
      padding: 0 0 0 55px;
      @include initfontKor(
        $font-size: 30px,
        $line-height: 46px,
        $font-family: "AppleSDGothicNeoB00",
        $color: #363739,
        $letter-spacing: -0.6px,
        $text-align: left
      );
      &:after {
        content: "";
        position: absolute;
        width: 46px;
        height: 46px;
        background-image: url("~@assets/images/util/answer.svg");
        left: 0;
        top: 0;
      }
    }
    p.sub {
      margin: -10px 0 20px 0;
      padding: 0 55px;
      @include initfontKor(
        $font-size: 18px,
        $line-height: 24px,
        $font-family: "AppleSDGothicNeoR00",
        $color: #fd8b11,
        $letter-spacing: -0.52px,
        $text-align: left
      );
    }
    p.content {
      margin: 0 0 20px 0;
      padding: 0 55px;
      @include initfontKor(
        $font-size: 26px,
        $line-height: 36px,
        $font-family: "AppleSDGothicNeoR00",
        $color: #363739,
        $letter-spacing: -0.52px,
        $text-align: left
      );
      b {
        font-weight: normal;
        color: #fd8b11;
      }
    }
    .info-gallery {
      display: flex;
      margin: 0 0 15px 0;
      padding: 0 0 0 55px;
      &.fourth {
        .img-wrap {
          margin: 0;
          max-width: 25%;
        }
      }
      .img-wrap {
        margin: 0 0 0 5px;
        max-width: calc((100% / 3) - 3.5px);
        &:first-of-type {
          margin: 0;
        }
        img {
          width: 100%;
        }
      }
    }
    .banner {
      position: relative;
      background: #ebf2f1;
      padding: 20px;
      min-height: 200px;
      margin: 0 55px 10px 55px;
      display: flex;
      align-items: center;
      .ab-bg {
        position: absolute;
        right: 0;
        bottom: 0;
        img {
        }
      }
    }
    ul.posture {
      margin: 0 0 10px 0;
      li {
        position: relative;
        margin: 10px 0 0 0;
        padding: 0 0 0 32.5px;
        display: flex;
        flex-wrap: wrap;
        &.inMent1 {
          &:after {
            content: "*마비가 있다면 음식을 온전한 쪽으로 넣어주도록 합니다.";
          }
        }
        &.inMent2 {
          &:after {
            content: "*입안에 음식이 남아 있는 한, 고개를 기울인 채로 계속 유지합니다.";
          }
        }
        &.inMent3 {
          &:after {
            content: "*베개로 몸을 받치면 고정이 편리합니다. (그림 참고)";
          }
        }
        &.inMent4 {
          &:after {
            content: "*한쪽 팔을 베개 밑에 두면 더 편합니다. (그림 참고)";
          }
        }
        &:after {
          margin: 5px 0 0 0;
          width: 100%;
          @include initfontKor(
            $font-size: 18px,
            $line-height: 24px,
            $font-family: "AppleSDGothicNeoR00",
            $color: #fd8b11,
            $letter-spacing: -0.52px,
            $text-align: left
          );
        }
        &:before {
          position: absolute;
          left: 0;
          top: 3px;
          width: 22px;
          height: 22px;
          border-radius: 50%;
          overflow: hidden;
          border: 1px solid #fd8b11;
          display: flex;
          align-items: center;
          justify-content: center;
          @include initfontKor(
            $font-size: 18px,
            $line-height: 22px,
            $font-family: "AppleSDGothicNeoB00",
            $color: #fd8b11,
            $letter-spacing: -0.52px,
            $text-align: left
          );
        }
        &:nth-of-type(1) {
          &:before {
            content: "1";
          }
        }
        &:nth-of-type(2) {
          &:before {
            content: "2";
          }
        }
        &:nth-of-type(3) {
          &:before {
            content: "3";
          }
        }
        &:nth-of-type(4) {
          &:before {
            content: "4";
          }
        }
        &:nth-of-type(5) {
          &:before {
            content: "5";
          }
        }
        @include initfontKor(
          $font-size: 24px,
          $line-height: 32px,
          $font-family: "AppleSDGothicNeoR00",
          $color: #363739,
          $letter-spacing: -0.52px,
          $text-align: left
        );
        &:first-of-type {
          margin: 0;
        }
      }
    }
    p.notification {
      padding: 0 55px 0 0;
      @include initfontKor(
        $font-size: 18px,
        $line-height: 28px,
        $font-family: "AppleSDGothicNeoR00",
        $color: #315754,
        $letter-spacing: -0.36px,
        $text-align: right
      );
    }
    .tooltip {
      margin: 0 0 20px 0;
      padding: 0 0 0 55px;
      p.hint {
        position: relative;
        padding: 0 0 0 15px;
        @include initfontKor(
          $font-size: 24px,
          $line-height: 34px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #363739,
          $letter-spacing: -0.48px,
          $text-align: left
        );
        &:after {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          background: #315754;
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }
      }
    }
    .mini-btn {
      position: relative;
      width: fit-content;
      padding: 0;
      margin: 0 0 0 55px;
      cursor: pointer;
      p {
        display: block;
        padding: 0 10px 0 35px;
        border-radius: 20px;
        border: solid 1.5px #fd8b11;
        @include initfontKor(
          $font-size: 22px,
          $line-height: 36px,
          $font-family: "AppleSDGothicNeoB00",
          $color: #fd8b11,
          $letter-spacing: -0.44px,
          $text-align: left
        );
        &:after {
          content: "";
          position: absolute;
          left: 5px;
          width: 26px;
          height: 26px;
          top: 50%;
          transform: translateY(-50%);
          background-image: url("/information/icon_information.svg");
        }
      }
    }
  }
  .gallery {
    width: 245px;
    h3.title {
      position: relative;
      margin: 0 0 20px 0;
      padding: 0 0 0 35px;
      @include initfontKor(
        $font-size: 26px,
        $line-height: 34px,
        $font-family: "AppleSDGothicNeoB00",
        $color: #363739,
        $letter-spacing: -0.6px,
        $text-align: left
      );
      &:after {
        content: "";
        position: absolute;
        width: 26px;
        height: 26px;
        background-image: url("~@assets/images/util/mark_color.svg");
        left: 0;
        top: 0;
      }
    }
    .item-wrap {
      .item-box {
        margin: 30px auto 0 0;
        &:first-of-type {
          margin: 0 auto 0 0;
        }
        input.hidden {
          display: none;
        }
        .item {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          width: 200px;
          height: 200px;
          position: relative;
          border-radius: 4px;
          overflow: hidden;
          .close {
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
            background: rgba(234, 234, 234, 0.75);
            border-radius: 4px;
            overflow: hidden;
            img {
            }
          }
          .img-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            width: inherit;
            height: inherit;
            &.cursor {
              cursor: pointer;
            }
          }
          &.example {
            border-radius: 5px;
            border: solid 1px #d2d4dc;
            background-color: #ffffff;
            align-items: inherit;
            .img-wrap {
              max-width: 200px;
              max-height: 200px;
              height: initial;
              display: flex;
              justify-content: center;

              img {
                width: initial;
                height: initial;
              }
            }
            button {
              margin: 15px 0 0 0;
              border: 0;
              background: #315754;
              width: 168px;
              height: 40px;
              cursor: pointer;
              @include initfontKor(
                $font-size: 22px,
                $line-height: 40px,
                $font-family: "AppleSDGothicNeoM00",
                $color: #fff,
                $letter-spacing: -0.44px,
                $text-align: center
              );
            }
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
