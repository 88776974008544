<template>
  <div class="app-context flex absolute">
    <side-menu :sideMenuOption="sideMenuOption"></side-menu>
    <div
      class="wrap-context infomation-page"
      :class="{
        first: commentDay < 11 === true,
        second: 10 < commentDay && commentDay < 21 === true,
        third: 20 < commentDay && commentDay < 31 === true,
        fourth: 30 < commentDay && commentDay < 41 === true,
      }"
      v-if="popType === 0 && view.isVisible"
    >
      <div class="audio-player" :class="{ activated: plyrValue.isPrepared }">
        <vue-plyr :options="plyrOption" ref="plyr">
          <audio controls crossorigin playsinline>
            <source
              size="1080"
              :src="require(`@/assets/audio/${plyrValue.src}`)"
              type="audio/aac"
            />
          </audio>
        </vue-plyr>
      </div>
      <div class="close" @click="closeInfo()">
        <img src="@/assets/images/util/closeX.svg" />
      </div>
      <div class="content-wrap">
        <h5 class="title">{{ commentDay }}일차</h5>
        <div
          class="audio-dummy"
          :class="{ narrow: this.commentDay === 1 }"
          @click="audioToggle()"
          v-if="view.isPlaying"
        >
          <img src="@/assets/images/util/icon_sound.svg" />
        </div>
        <div
          class="audio-dummy"
          :class="{ narrow: this.commentDay === 1 }"
          @click="audioToggle()"
          v-else
        >
          <img src="@/assets/images/util/icon_sound_mute.svg" />
        </div>
        <p
          class="paragraph"
          :class="{ small: this.commentDay === 1 }"
          v-html="computedLine(comment)"
        ></p>
        <div class="btn-wrap" v-if="commentTime === 'start'">
          <button type="button" @click="goTraining()">시작하기</button>
        </div>
<!--
        <div class="btn-wrap" v-else>
          <button
            type="button"
            class="share-btn"
            @click="share(view.shareItem)"
            v-if="commentType === 'life' && commentDay !== 1"
          >
            <img class="pop-talk-img" src="@/assets/images/popup/talk.svg" />
            카카오톡으로 공유
          </button>
          <button type="button" class="share-normal" @click="closeInfo()">닫기</button>
        </div> -->

        <!--
        <div class="btn-wrap" v-if="commentTime === 'start'">
          <button
            type="button"
            class="share-btn"
            @click="share(view.shareItem)"
            v-if="commentTime === 'start' && commentType === 'total' && commentDay !== 1"
          >
            <img src="@assets/images/util/talk.svg" />카카오톡으로 공유
          </button>
          <button type="button" class="share-normal" @click="closeInfo()">닫기</button>
        </div>
        <div class="btn-wrap" v-else>
          <button type="button" @click="goTraining()">시작하기</button>
        </div>
        -->
      </div>
    </div>
    <div
      class="wrap-context infomation-page"
      :class="{
        isStart: commentTime === 'start',
        isEnd: commentTime === 'end',
      }"
      v-if="popType === 1 && view.isVisible"
    >
      <div class="audio-player" :class="{ activated: plyrValue.isPrepared }">
        <vue-plyr :options="plyrOption" ref="plyr">
          <audio controls crossorigin playsinline>
            <source
              size="1080"
              :src="require(`@/assets/audio/${plyrValue.src}`)"
              type="audio/aac"
            />
          </audio>
        </vue-plyr>
      </div>
      <div class="close" @click="closeInfo()">
        <img src="@/assets/images/util/closeX.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import SideMenu from '@/components/SideMenu.vue';
import errorParser from '@/utils/error-parser';
import { mapActions, mapMutations } from 'vuex';

export default {
  props: {
    informationPopupSet: {
      shareUrl: {
        type: String,
      },
      isStart: {
        type: Boolean,
      },
      commentType: {
        type: String,
      },
      commentTime: {
        type: String,
      },
      commentDay: {
        type: Number,
      },
      commentTrainingTime: {
        type: String,
      },
      isApiCall: {
        type: Boolean,
        default: false,
      },
      isStartApiCall: {
        type: Boolean,
        default: false,
      },
      isEndApiCall: {
        type: Boolean,
        default: false,
      },
      popType: {
        type: Number,
        default: 0,
      },
    },
  },
  components: {
    SideMenu,
  },
  data() {
    return {
      plyrOption: {
        autoplay: false,
        controls: [
          'play-large',
          'play',
          'current-time',
          'mute',
          'volume',
          'captions',
          'settings',
          'pip',
          'airplay',
          'fullscreen',
        ],
        fullscreen: {
          enabled: true,
          fallback: true,
          iosNative: true,
          container: 'audio-player',
        },
      },
      plyrValue: {
        duration: '',
        player: '',
        src: 'sampleAudio.aac',
        isPrepared: false,
        isPlay: false,
      },
      comment: {},
      sideMenuOption: [
        {
          img: 'sidebar_item01.png',
          img_active: 'sidebar_menu1_active.png',
          img_active_deep: 'sidebar_menu1_active_deep.png',
          title: '메인',
          isActive: true,
          link: '',
        },
        {
          img: 'sidebar_item02.png',
          img_active: 'sidebar_menu2_active.png',
          img_active_deep: 'sidebar_menu2_active_deep.png',
          title: '삼킴 평가',
          isActive: false,
          link: 'evaluate',
        },
        {
          img: 'sidebar_item03.png',
          img_active: 'sidebar_menu3_active.png',
          img_active_deep: 'sidebar_menu3_active_deep.png',
          title: '삼킴 훈련',
          isActive: false,
          link: 'trainingDate',
        },
        {
          img: 'sidebar_item05.png',
          img_active: 'sidebar_menu5_active.png',
          img_active_deep: 'sidebar_menu5_active_deep.png',
          title: '자세법ㆍ식이법',
          isActive: false,
          link: 'choiceType',
        },
        {
          img: 'sidebar_item06.png',
          img_active: 'sidebar_menu6_active.png',
          img_active_deep: 'sidebar_menu6_active_deep.png',
          title: '원격 상담',
          isActive: false,
          link: 'reservationType',
        },
        {
          img: 'sidebar_mypage.png',
          img_active: 'sidebar_mypage_active.png',
          img_active_deep: 'sidebar_mypage_active.png',
          title: '마이페이지',
          isActive: false,
          link: 'mypage',
        },
      ],
      view: {
        isVisible: false,
        isPlaying: false,
        shareItem: {},
        isLock: false,
      },
      shareUrl: this.informationPopupSet.shareUrl,
      isStart: this.informationPopupSet.isStart,
      commentType: this.informationPopupSet.commentType,
      commentTime: this.informationPopupSet.commentTime,
      commentDay: this.informationPopupSet.commentDay,
      popType: this.informationPopupSet.popType,
      commentTrainingTime: this.informationPopupSet.commentTrainingTime,
      isApiCall: this.informationPopupSet.isApiCall,
      isStartApiCall: this.informationPopupSet.isStartApiCall,
      isEndApiCall: this.informationPopupSet.isEndApiCall,
    };
  },
  watch: {},
  mounted() {
    console.log('마운티드 발동');
    console.log('핵심 키 :', this.informationPopupSet);
    /** 시간업데이트 */
    /**
    sessionStorage.removeItem("infoPage");
     */
  },
  computed: {
    computedLine() {
      return (text) => {
        console.log('comment-text :', text);
        console.log('typeof comment-text :', typeof text);
        if (typeof text === 'string') {
          if (text.indexOf('\n') !== -1) {
            return text.split('\n').join('<br />');
          }
          return text;
        }
      };
    },
  },
  methods: {
    ...mapMutations('basic', ['SET_INFORMATION_POPUP']),
    ...mapActions('util', ['FETCH_COMMENT', 'FETCH_COMMENT_TRAINING']),
    ...mapActions('life', ['FETCH_LIFE_LIST_FULL']),

    async prePareShare() {
      try {
        await this.FETCH_LIFE_LIST_FULL().then((data) => {
          console.log('data :', data.response.list);
          for (let i = 0; i < data.response.list.length; i++) {
            if (data.response.list[i].day === this.commentDay) {
              this.view.shareItem = data.response.list[i];
            }
          }
        });
      } catch (error) {
        console.log('error: ', errorParser.parse(error));
        console.log('error :', error);
      }
    },
    share(item) {
      console.log('팝업에서의 쉐어에밋 :', item);
      this.$emit('share-pop-call', item);
      this.SET_INFORMATION_POPUP(false);
    },
    initSource(type, time, day, trainingTime) {
      console.log('trainingTime!! :', trainingTime);
      console.log('type :', type, 'time :', time, 'day :', day);
      if (trainingTime !== undefined) {
        console.log('준비중 :');
      } else {
        this.$refs.plyr.player.source = {
          type: 'audio',
          title: 'Example title',
          sources: [
            {
              src: require(`@/assets/audio/${type}/${time}/day${day}.mp3`),
            },
          ],
        };
      }
      this.plyrValue.isPrepared = true;
    },
    initAudio(trainingTime) {
      console.log('이닛오디오 발동');
      console.log('trainingTime :', trainingTime);
      this.plyrValue.player = this.$refs.plyr.player;
      this.$refs.plyr.player.volume = 1.0;
      this.$refs.plyr.player.on('timeupdate', (data) => {
        const value = data.detail.plyr;
        console.log('duration', value.currentTime);
      });
      /** 오디오 초기시작 */
      this.$refs.plyr.player.on('playing', (data) => {});

      /** 오디오 재개 */
      this.$refs.plyr.player.on('play', (data) => {});
      /** 오디오 중지 */
      this.$refs.plyr.player.on('pause', (data) => {});

      /** 오디오 완료 */
      this.$refs.plyr.player.on('ended', (data) => {
        if (commentType === 'life' && commentTime === 'start') {
          this.goTraining();
        } else if (commentType === 'life' && commentTime === 'end') {
        } else {
          this.closeInfo();
        }
      });
      /** 오디오 전체화면 시작 */
      this.$refs.plyr.player.on('enterfullscreen', (data) => {});

      /** 오디오 전체화면 중지 */
      this.$refs.plyr.player.on('exitfullscreen', (data) => {});
      if (trainingTime !== undefined) {
        this.initSource(
          this.commentType,
          this.commentTime,
          this.commentDay,
          trainingTime,
        );
      } else {
        this.initSource(this.commentType, this.commentTime, this.commentDay);
      }
    },
    async getInfoWord(type, time, day, trainingTime) {
      console.log('인자체크 :', type, time, day, trainingTime);
      try {
        const info = {
          type,
          time,
          day,
        };
        if (trainingTime !== null) {
          info.trainingTime = trainingTime;
          await this.FETCH_COMMENT_TRAINING(info).then((data) => {
            console.log('data in fetchComment : ', data);
            this.comment = data.response.comment;
            this.view.isVisible = true;
            console.log('isVisible');
            this.$nextTick((_) => {
              this.initAudio(trainingTime);
            });
          });
        } else {
          await this.FETCH_COMMENT(info).then((data) => {
            console.log('data in fetchComment : ', data);
            this.comment = data.response.comment;
            this.view.isVisible = true;
            console.log('isVisible');
            this.$nextTick((_) => {
              this.initAudio();
            });
          });
        }
      } catch (error) {
        console.log('error-parser :', errorParser.parse(error));
        console.log('error :', error);
      }
    },
    audioToggle() {
      if (this.$refs.plyr.player.playing) {
        this.view.isPlaying = false;
        this.$refs.plyr.player.pause();
      } else {
        this.view.isPlaying = true;
        this.$refs.plyr.player.play();
      }
    },
    goTraining() {
      if (this.$refs.plyr.player.playing) {
        this.$refs.plyr.player.stop();
      }
      if (this.isStartApiCall) {
        if (!this.view.isLock) {
          this.view.isLock = true;
          this.$emit('start-pop', this.commentDay);
        }
      }
      if (this.commentType === 'total') {
        localStorage.setItem(
          'passedDailyComment',
          this.$moment(new Date()).format('YYYY-MM-DD'),
        );
      }
      this.$emit('go-training', this.commentDay);
      this.SET_INFORMATION_POPUP(false);
    },
    closeInfo() {
      if (this.$refs.plyr.player.playing) {
        this.$refs.plyr.player.stop();
      }
      if (this.isApiCall) {
        this.$emit('close-pop', this.commentDay);
      } else if (this.commentType === 'total') {
        localStorage.setItem(
          'passedDailyComment',
          this.$moment(new Date()).format('YYYY-MM-DD'),
        );
      }
      if (this.isStartApiCall) {
        this.$emit('close-pop', this.commentDay);
      }
      this.SET_INFORMATION_POPUP(false);
      /** 정의할 부분 */
    },
    callAndroid() {
      /** 프로토콜에 day 인자를 넣어서 진행함 */
    },
  },
  created() {
    this.getInfoWord(
      this.informationPopupSet.commentType,
      this.informationPopupSet.commentTime,
      this.informationPopupSet.commentDay,
      this.informationPopupSet.commentTrainingTime,
    );
    console.log('공유 아이디 넣어주기');
    if (this.commentType === 'life' && this.commentTime === 'end') {
      this.prePareShare();
    }
  },
};
</script>

<style lang="scss" scoped>
.app-context {
  &.absolute {
    position: absolute;
    z-index: 2;
    width: 1280px;
  }
  .side-menu-wrap {
    display: none;
  }
  .wrap-context {
    width: 934px;
    margin: 22px 90px 0 auto;
    &.infomation-page {
      background-size: cover;
      width: 100%;
      margin: 0;
      &.isStart {
        background-image: url("~@assets/images/util/bg_start.svg");
      }
      &.isEnd {
        background-image: url("~@assets/images/util/bg_end.svg");
      }
      &.first {
        background-image: url("~@assets/images/util/informationDate_first.svg");
      }
      &.second {
        background-image: url("~@assets/images/util/informationDate_second.svg");
      }
      &.third {
        background-image: url("~@assets/images/util/informationDate_third.svg");
      }
      &.fourth {
        background-image: url("~@assets/images/util/informationDate_fourth.svg");
      }
    }
    .audio-player {
      position: absolute;
      opacity: 0;
      -webkit-tap-highlight-color: transparent;
      &:focus {
        outline: none;
      }
      &:active {
        outline: none;
      }
      &.activated {
        opacity: 0;
        position: relative;
      }
    }
    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 10px;
      cursor: pointer;
    }
    .content-wrap {
      position: absolute;
      /**
      top: calc(50% - 60px);
       */
      top: 50%;
      transform: translate(-50%, -50%);
      width: 1090px;
      left: 50%;
      h3.specific {
        @include initfontKor(
          $font-size: 29px,
          $line-height: 50px,
          $font-family: "AppleSDGothicNeoB00",
          $color: #8b8d94,
          $letter-spacing: -0.58px,
          $text-align: center
        );
      }
      .btn-wrap {
        margin: 0 auto;
        border-radius: 5px;
        background: transparent;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        display: flex;
        button {
          padding: 0 20px;
          border: 0;
          height: 56px;
          min-width: 200px;
          background: #315754;
          cursor: pointer;
          margin: 0 0 0 15px;
          border-radius: 5px;
          box-shadow: 0 3px 10px 0 rgba(54, 55, 57, 0.15);
          &:first-of-type {
            margin: 0;
          }
          &.share-normal {
            border-radius: 5px;
            border: solid 2px #315754;
            color: #315754;
            background: #fff;
            width: 160px;
          }
          &.share-btn {
            background: #315754;
            color: #fff;
            border-radius: 5px;
            width: 305px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              margin: 0 10px 0 0;
            }
          }
          @include initfontKor(
            $font-size: 30px,
            $line-height: 36px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #fff,
            $letter-spacing: -0.6px,
            $text-align: center
          );
        }
        .pop-talk-img {
        }
      }
      h5.title {
        @include initfontKor(
          $font-size: 48px,
          $line-height: 60px,
          $font-family: "BMJUA",
          $color: #315754,
          $letter-spacing: -0.96px,
          $text-align: center
        );
        margin: 0;
      }
      .audio-dummy {
        margin: 0 0 80px 0;
        text-align: center;
        cursor: pointer;
        padding: 10px;
        -webkit-tap-highlight-color: transparent;
        &:focus {
          outline: none;
        }
        &:active {
          outline: none;
        }
        &.narrow {
          margin: 0 0 30px 0;
        }
      }

      p.paragraph {
        margin: 0 0 80px 0;

        @include initfontKor(
          $font-size: 40px,
          $line-height: 60px,
          $font-family: "BMJUA",
          $color: #315754,
          $letter-spacing: -0.96px,
          $text-align: center
        );
        &.small {
          font-size: 30px;
          line-height: 44px;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.content-wrap p.paragraph > b {
  font-weight: normal;
  @include initfontKor(
    $font-size: 40px,
    $line-height: 60px,
    $font-family: "BMJUA",
    $color: #fd8b11,
    $letter-spacing: -0.96px,
    $text-align: center
  );
}
</style>
