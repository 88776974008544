import { life } from "@/api";

const state = {
  life: {
    /**
    drId: localStorage.getItem('drId') || '',
     */
  }
};

const mutations = {
  /**
  SET_PERSONALINFO(state, info) {
    state.join.personalInfo = info;
  }
   */
};

const getters = {
  /**
  GET_PERSONALINFO: state => state.join.personalInfo
   */
};

const actions = {
  FETCH_LIFE_LIST_SHARE({}, id) {
    return life.fetchShareLife(id);
  },
  FETCH_LIFE({}, id) {
    return life.fetchLife(id);
  },
  FETCH_LIFE_LIST({}) {
    return life.fetchLifeList();
  },
  FETCH_LIFE_LIST_PAGE({}, listOpt) {
    return life.fetchLifeListPage(listOpt);
  },
  FETCH_LIFE_LIST_FULL({}) {
    return life.fetchLifeListFull();
  }
};
export default {
  strict: process.env.NODE_ENV !== "production",
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
