var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-context flex"},[_c('side-menu',{attrs:{"sideMenuOption":_vm.sideMenuOption,"sideMenuDeep":_vm.sideMenuDeep},on:{"is-unauthorized":_vm.isUnAuthorized}}),_c('div',{staticClass:"wrap-context"},[_c('notification',{staticClass:"low-margin",attrs:{"notificationInfo":_vm.notificationInfo}}),_c('div',{staticClass:"camera-context"},[_c('div',{staticClass:"item-box"},[_c('div',{staticClass:"animator-wrap",class:{ active: _vm.animate.value !== null, pause: _vm.plyrValue.isViewMilestone }},[(_vm.plyrValue.isViewMilestone)?_c('div',{staticClass:"animator"},[_c('div',{staticClass:"btn-wrap"},[_c('div',{staticClass:"item"},[_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.btnClick('prepare')}}},[_vm._v(" 연습 영상 다시보기 ")])]),(_vm.plyrValue.currentSetCount !== _vm.plyrValue.setCount)?_c('div',{staticClass:"item"},[_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.btnClick('training')}}},[_vm._v(" 다음 세트 시작하기 ")])]):_vm._e()])]):_c('div',{staticClass:"animator"},[_c('div',{staticClass:"half-circle-timer"},[_c('span',{domProps:{"textContent":_vm._s(_vm.animate.value)}})]),_c('div',{staticClass:"info-wrap"},[_c('div',{staticClass:"holder"},[_c('h4',{staticClass:"title",domProps:{"textContent":_vm._s(
                    _vm.plyrValue.currentRepeatCount === 0 ||
                    _vm.plyrValue.currentRepeatCount === 4
                      ? '물'
                      : '침'
                  )}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"img-wrap"},[(
                        _vm.plyrValue.currentRepeatCount === 0 ||
                        _vm.plyrValue.currentRepeatCount === 4
                      )?_c('img',{attrs:{"src":require("@assets/images/training/cup.png")}}):_c('img',{attrs:{"src":require("@assets/images/training/mouse.png")}})]),(
                      _vm.plyrValue.currentRepeatCount === 0 ||
                      _vm.plyrValue.currentRepeatCount === 4
                    )?_c('p',[_vm._v(" 5ml ")]):_vm._e()])])])])]),_c('div',{staticClass:"video-player"},[_c('vue-plyr',{ref:"plyr",attrs:{"options":_vm.plyrOption}},[_c('video',{attrs:{"controls":"","crossorigin":"","playsinline":""}},[_c('source',{attrs:{"size":"1080","src":require(("@/assets/video/" + (_vm.plyrValue.src))),"type":"video/mp4"}})])])],1)]),_c('div',{staticClass:"item-box activated"},[_c('div',{staticClass:"camera-attach"}),_c('div',{staticClass:"training-status"},_vm._l((_vm.plyrValue.setCount),function(set,setIndex){return _c('div',{key:setIndex + 'a',staticClass:"item"},[_c('h3',{staticClass:"i-header",domProps:{"textContent":_vm._s(setIndex + 1 + '세트')}}),_c('div',{staticClass:"i-content"},_vm._l((_vm.plyrValue.repeatCount),function(repeat,repeatIndex){return _c('span',{key:repeatIndex + 'a',staticClass:"num",class:{
                  active:
                    (_vm.plyrValue.currentRepeatCount !== null &&
                      repeatIndex + 1 <= _vm.plyrValue.currentRepeatCount &&
                      _vm.plyrValue.currentSetCount >= setIndex + 1) ||
                    setIndex + 1 < _vm.plyrValue.currentSetCount,
                },domProps:{"textContent":_vm._s(repeatIndex + 1)}})}),0)])}),0)])])],1),(_vm.checkPopup)?_c('default-popup',{attrs:{"popupSet":_vm.popupSet}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }