import { consultation } from "@/api";

const state = {
  consultation: {
    /**
    drId: localStorage.getItem('drId') || '',
     */
  }
};

const mutations = {
  /**
  SET_FCMTOKEN(state, fcmToken) {
    state.auth.fcmToken = fcmToken;
  },
   */
};

const getters = {
  /**
  GET_FCMTOKEN: state => state.auth.fcmToken,
   */
};

const actions = {
  /**
  LOGIN_AUTO({}, loginInfo) {
    return auth.loginAuto(loginInfo);
  }
   */
  CANCLE_CONSULTATION({}, dataSet) {
    return consultation.cancleConsultation(dataSet);
  },
  CHECK_CONSULTATION({}) {
    return consultation.checkConsultation();
  },
  FETCH_CONSULTATION_LIST({}, listOpt) {
    return consultation.fetchConsultationList(listOpt);
  },
  FETCH_CONSULTATION_LIST_PAGE({}, listOpt) {
    return consultation.fetchConsultationListPage(listOpt);
  },
  FETCH_CONSULTATION_LIST_PAGE_DEFAULT({}, listOpt) {
    return consultation.fetchConsultationListPageDefault(listOpt);
  },
  FETCH_CONSULTATION_LIST_DEFAULT({}) {
    return consultation.fetchConsultationListDefault();
  },
  SUBMIT_CONSULTATION({}, dataSet) {
    return consultation.submitConsultation(dataSet);
  },
  CHECK_CONSULTATION_TIME({}, date) {
    return consultation.checkConsultationTime(date);
  }
};
export default {
  strict: process.env.NODE_ENV !== "production",
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
