<template>
  <div class="stop-watch">
    <div class="retry" @click="reset()" v-if="!noneRetry">
      <div class="img-wrap">
        <img src="@assets/images/evaluation/icon_refresh.svg" />
      </div>
      <p>다시입력</p>
    </div>
    <div class="watch" v-if="!isOnlyTime">
      <div class="item">
        <h3 class="title">시간</h3>
        <div class="content">
          <span v-text="min !== 0 ? min : '00'" v-if="min !== 0 && min !== '00'"></span
          ><!-- 분 -->
          <span class="separate" v-if="min !== 0 && min !== '00'">:</span>
          <span v-text="sec !== 0 ? sec : '00'"></span>
          <span class="separate">:</span>
          <span v-text="milisec !== 0 ? milisec : '00'"></span>
        </div>
      </div>
      <div class="item">
        <h3 class="title">총 횟수</h3>
        <div class="content">
          <span v-text="chewNum"></span>
        </div>
      </div>
      <div class="item">
        <h3 class="title">초당 횟수</h3>
        <div class="content">
          <span v-text="persecond !== 0 ? persecond : ''"></span>
        </div>
      </div>
    </div>
    <div class="watch" v-else>
      <div class="item">
        <h3 class="title">시간</h3>
        <div class="content">
          <span v-text="min !== 0 ? min : '00'"></span
          ><!-- 분 -->
          <span class="separate">:</span>
          <span v-text="sec !== 0 ? sec : '00'"></span>
          <span class="separate">:</span>
          <span v-text="milisec !== 0 ? milisec : '00'"></span>
        </div>
      </div>
    </div>
    <div class="btn-wrap" v-if="!isOnlyTime">
      <button
        type="button"
        v-text="btnStart"
        @click="start()"
        :class="{
          count: this.btnStart === 'Count',
          restart: this.btnStart === 'Restart',
        }"
      ></button>
      <button type="button" class="stop" @click="stop()" v-text="btnStop"></button>
    </div>
    <div class="btn-wrap" v-else>
      <button type="button" v-text="btnStart" @click="startOnlySecond()"></button>
      <button type="button" class="stop" @click="stopOnlySecond()">Stop</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOnlyTime: {
      type: Boolean,
      default: false,
    },
    isLastQuestion: {
      type: Boolean,
      default: false,
    },
    noneRetry: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      stTime: 0,
      endTime: 0,
      timerStart: null,
      chewNum: 0,
      persecond: 0,
      min: 0,
      sec: 0,
      milisec: 0,
      btnStart: "Start",
      btnStop: "Stop",
      isTimer: false,
      view: {
        isPrepared: false,
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    getResult(boolean) {
      const timerInfo = {
        min: this.min,
        sec: this.sec,
        milisec: this.milisec,
        chewNum: this.chewNum,
        persecondChewNum: this.persecond,
      };
      if (this.isLastQuestion && boolean) {
        console.log("초기 진행방향입니다");
        this.view.isPrepared = true;
        this.btnStop = "평가제출";
        this.$emit("get-timer-info", timerInfo, boolean);
      } else if (this.persecond !== "Infinity") {
        console.log("this.persecond :", this.persecond);
        this.$emit("get-timer-info", timerInfo, boolean);
      }
    },
    addZero(num) {
      return num < 10 ? `0${num}` : `${num}`;
    },
    startOnlySecond() {
      if (this.stTime === 0) {
        this.stTime = Date.now(); // 최초 START
      } else if (!this.isTimer) {
        this.stTime += Date.now() - this.endTime; // RESTART
      }
      if (!this.isTimer) {
        this.isTimer = true;
        this.btnStart = "Starting";
        this.timerStart = setInterval(() => {
          const nowTime = new Date(Date.now() - this.stTime);
          const min = this.addZero(nowTime.getMinutes());
          const sec = this.addZero(nowTime.getSeconds());
          const milisec = this.addZero(Math.floor(nowTime.getMilliseconds() / 10));
          this.min = min;
          this.sec = sec;
          this.milisec = milisec;
        }, 1);
      }
    },
    start() {
      if (this.stTime === 0) {
        this.stTime = Date.now(); // 최초 START
      } else if (!this.isTimer) {
        this.stTime += Date.now() - this.endTime; // RESTART
      } else {
        this.chewNum += 1;
      }
      if (!this.isTimer) {
        this.view.isPrepared = false;
        this.btnStop = "Stop";
        this.isTimer = true;
        this.btnStart = "Count";
        this.timerStart = setInterval(() => {
          const nowTime = new Date(Date.now() - this.stTime);
          const min = this.addZero(nowTime.getMinutes());
          const sec = this.addZero(nowTime.getSeconds());
          const milisec = this.addZero(Math.floor(nowTime.getMilliseconds() / 10));
          this.min = min;
          this.sec = sec;
          this.milisec = milisec;
        }, 1);
      }
    },
    reset() {
      this.stTime = 0;
      this.min = 0;
      this.sec = 0;
      this.milisec = 0;
      this.chewNum = 0;
      this.btnStart = "Start";
      if (this.view.isPrepared) {
        this.view.isPrepared = false;
        this.btnStop = "Stop";
      }
      clearInterval(this.timerStart);
      this.timerStart = null;
      this.isTimer = false;
      this.persecond = 0;
      this.getResult(false);
    },
    calculatePersecond() {
      const count = this.chewNum;
      let entireSecond;
      if (Number(this.min) > 0) {
        entireSecond = 60 * Number(this.min) + Number(this.sec);
      } else {
        entireSecond = Number(this.sec);
      }
      console.log("엔타세컨 :", entireSecond);
      if (count !== 0) {
        let perSecond = count / entireSecond;
        console.log("가공전", perSecond);
        perSecond = perSecond.toPrecision(2);
        this.persecond = perSecond;
        if (perSecond > 0) {
          this.getResult(true);
        }
      }
    },
    stop() {
      console.log("timerCheck :", this.isTimer);
      if (this.isTimer) {
        this.isTimer = false;
        clearInterval(this.timerStart);
        this.btnStart = "Finish";
        this.endTime = Date.now();
        this.calculatePersecond();
      } else if (this.view.isPrepared) {
        console.log("원하는 방향입니다");
        if (this.persecond !== "Infinity") {
          const timerInfo = {
            min: this.min,
            sec: this.sec,
            milisec: this.milisec,
            chewNum: this.chewNum,
            persecondChewNum: this.persecond,
          };
          this.$emit("get-timer-info", timerInfo, true, this.isLastQuestion);
        } else {
          alert("초당 횟수를 확인해주세요");
        }
      }
    },
    stopOnlySecond() {
      if (this.isTimer) {
        this.isTimer = false;
        clearInterval(this.timerStart);
        this.btnStart = "Finish";
        this.endTime = Date.now();
        this.getResult(true);
      }
    },
  },
  computed: {},
  components: {},
};
</script>
<style lang="scss" scoped>
.stop-watch {
  width: 530px;
  margin: 0 auto;

  .watch {
    display: flex;
    margin: 0 0 52px 0;
    justify-content: center;
  }
}
.retry {
  margin: 0 0 40px 0;
  display: flex;
  position: relative;
  align-items: center;
  width: 95px;
  margin: 0 auto 40px auto;
  &:after {
    position: Absolute;
    width: calc(100% - 4px);
    bottom: 5px;
    height: 1px;
    background: #315754;
    content: "";
    left: 50%;
    transform: translateX(-50%);
  }
  .img-wrap {
    margin: 0 5px 0 0;
    img {
    }
  }
  p {
    @include initfontKor(
      $font-size: 20px,
      $line-height: 36px,
      $font-family: "AppleSDGothicNeoM00",
      $color: #315754,
      $letter-spacing: -0.4px,
      $text-align: left
    );
  }
}
.btn-wrap {
  display: flex;
  justify-content: center;

  button {
    width: 140px;
    height: 60px;
    border: 0;
    padding: 0;
    border-radius: 5px;
    box-shadow: 0 3px 10px 0 rgba(54, 55, 57, 0.15);
    background: #2d7d76;

    @include initfontKor(
      $font-size: 30px,
      $line-height: 36px,
      $font-family: "AppleSDGothicNeoB00",
      $color: #fff,
      $letter-spacing: -0.6px,
      $text-align: center
    );
    cursor: pointer;
    &.restart {
    }
    &.count {
      background: #15ab9d;
    }
    &.stop {
      background: #ec5e5e;
      margin: 0 0 0 16px;
    }
  }
}

.item {
  margin: 0 0 0 6px;
  border-radius: 5px;
  border: solid 1px #eaebf1;
  background-color: #ffffff;
  height: 130px;
  min-width: 130px;
  overflow: hidden;

  &:first-of-type {
    margin: 0;
    min-width: 220px;
  }

  h3.title {
    @include initfontKor(
      $font-size: 20px,
      $line-height: 32px,
      $font-family: "AppleSDGothicNeoB00",
      $color: #363739,
      $letter-spacing: -0.4px,
      $text-align: center
    );
    background: #ebf2f1;
  }

  span {
    @include initfontKor(
      $font-size: 40px,
      $line-height: 25px,
      $font-family: "AppleSDGothicNeoB00",
      $color: #363739,
      $letter-spacing: -2px,
      $text-align: center
    );
    min-width: 50px;

    &.separate {
      display: block;
      margin: 0 5px;
      min-width: auto;
    }
  }

  .content {
    display: flex;
    padding: 0 20px;
    height: calc(100% - 32px);
    justify-content: center;
    align-items: center;
  }
}
</style>
