<template>
  <div class="block-menu-wrap">
    <div class="block-menu main" v-if="menuType === 0">
      <div
        class="item"
        v-for="(mItem, index) in menuList"
        :key="index + 'A'"
        :class="{ active: mItem.isActive }"
        @click="link(mItem.routerUrl)"
      >
        <div class="img-wrap">
          <img :src="require(`@/assets/images/main/${mItem.img}`)" />
        </div>
        <h3 class="title" v-html="computedLine(mItem.name)"></h3>
      </div>
    </div>
    <div
      class="block-menu evaluation"
      v-if="menuType === 1"
      :class="{ special: menuList.length === 3 }"
    >
      <div
        class="item"
        v-for="(mItem, index) in menuList"
        :key="index + 'B'"
        :class="{ active: mItem.isActive }"
        @click="checkLink(index)"
      >
        <div class="img-wrap">
          <img :src="require(`@/assets/images/evaluation/${mItem.img}`)" />
        </div>
        <h3 class="title" v-text="mItem.name"></h3>
      </div>
    </div>
    <div class="block-menu evaluation diet" v-if="menuType === 2">
      <div
        class="item"
        v-for="(mItem, index) in menuList"
        :key="index + 'C'"
        :class="{ active: mItem.isActive }"
        @click="link(mItem.routerUrl)"
      >
        <div class="img-wrap">
          <img :src="require(`@/assets/images/diet/${mItem.img}`)" />
        </div>
        <h3 class="title" v-text="mItem.name"></h3>
      </div>
    </div>
    <div class="block-menu evaluation-detail" v-if="menuType === 3">
      <div
        class="item"
        v-for="(mItem, index) in menuList"
        :key="index + 'D'"
        :class="{ active: mItem.isActive }"
        @click="checkLink(index)"
      >
        <h3 class="title" v-html="computedLine(mItem.name)"></h3>
        <div class="img-wrap">
          <img :src="require(`@/assets/images/evaluation/${mItem.img}`)" />
        </div>
      </div>
    </div>
    <div class="block-menu training" v-if="menuType === 4">
      <div class="item" v-for="(mItem, index) in menuList" :key="index + 'E'">
        <div class="i-header">
          <h3 class="title" v-text="mItem.name"></h3>
        </div>
        <div class="i-content">
          <div class="img-wrap">
            <img :src="require(`@/assets/images/training/${mItem.img}`)" />
          </div>

          <p class="sub point" v-text="mItem.content"></p>
          <div class="btn-wrap">
            <button type="button" @click="goDetail(index)">자세히</button>
          </div>
        </div>
      </div>
    </div>
    <div class="block-menu training" v-if="menuType === 5">
      <div
        class="item header-fit"
        v-for="(mItem, index) in menuList"
        :key="index + 'F'"
        v-if="
          (tabsIndex === 0 && mItem.isMorning) ||
            (tabsIndex === 1 && mItem.isLunch) ||
            (tabsIndex === 1 && mItem.isEvening) ||
            (tabsIndex === 2 && mItem.isEvening)
        "
      >
        <div class="i-header active">
          <h3 class="title" v-text="mItem.name"></h3>
        </div>
        <div class="i-content">
          <div class="img-wrap">
            <img :src="require(`@/assets/images/training/${mItem.img}`)" />
          </div>

          <p class="sub" v-text="mItem.content"></p>
          <div class="btn-wrap">
            <button type="button" @click="start(index)">시작</button>
          </div>
        </div>
      </div>
    </div>
    <div class="block-menu evaluation resv" v-if="menuType === 6">
      <div
        class="item"
        v-for="(mItem, index) in menuList"
        :key="index + 'C'"
        :class="{
          active: mItem.isActive
        }"
        @click="checkLink(index)"
      >
        <div class="img-wrap">
          <img :src="require(`@/assets/images/diet/${mItem.img}`)" />
        </div>
        <h3 class="title" v-text="mItem.name"></h3>
      </div>
      <div class="speech-wrap">
        <div class="img-wrap">
          <img src="@assets/images/util/speech.svg" />
        </div>
        <p v-text="speech.concatResv" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menuList: {
      type: Array
    },
    menuType: {
      type: Number,
      default: 0
    },
    menuDistinct: {
      type: Boolean,
      default: false
    },
    tabsIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      speech: {
        date: null,
        time: null,
        concatResv: null
      }
    };
  },
  created() {},
  methods: {
    checkLink(index) {
      this.$emit("check-link-flag", index);
    },
    goDetail(index) {
      console.log("순번 :", index);
      if (index === 0) {
        this.$router.push("/infoSwallow");
      } else if (index === 1) {
        this.$router.push("/infoRaising");
      } else if (index === 2) {
        this.$router.push("/infoTogue");
      }
    },
    getDayInfo(date) {
      const week = [];
      week.push("일", "월", "화", "수", "목", "금", "토");
      const day = new Date(date);
      const dayName = day.getDay();
      return week[dayName];
    },
    drawSpeech(index, context) {
      console.log("index :", index);
      console.log("context :", context);
      if (index === 0) {
        document.querySelector(".block-menu").classList.add("left-speech-activated");
      } else {
        document.querySelector(".block-menu").classList.add("right-speech-activated");
      }
      this.speech.date = context.date;
      this.speech.time = context.time;
      const week = this.getDayInfo(context.date);
      const timeStatus = context.time.split(":")[0] <= 12 ? "오전 " : "오후 ";
      this.speech.concatResv = `${context.date} (${week}) ${timeStatus}${context.time}`;
    },
    link(url) {
      if (url !== null) {
        this.$router.push(url);
      }
    },
    start(order) {
      console.log("라우터 이동");
      this.$emit("router-call", order);
    }
  },
  computed: {
    computedLine() {
      return text => {
        if (text.indexOf("\n") !== -1) {
          return text.split("\n").join("<br />");
        }
        return text;
      };
    }
  },
  components: {}
};
</script>
<style lang="scss" scoped>
.wrap-context {
  .block-menu-wrap {
    .block-menu {
      &.left-speech-activated {
        margin: 73px auto 0 auto;
        position: relative;
        .speech-wrap {
          display: block;
          left: 0;
          top: -73px;
          position: absolute;
        }
      }
      &.right-speech-activated {
        margin: 73px auto 0 auto;
        position: relative;
        .speech-wrap {
          display: block;
          right: 0;
          top: -73px;
          position: absolute;
        }
      }
      &.resv {
        width: 870px;
        .item {
          cursor: pointer;
          margin-right: 70px;
          &:nth-of-type(2) {
            background: #97a7a2;
            margin-right: 0;
            h3.title {
              @include initfontKor(
                $font-size: 36px,
                $line-height: 42px,
                $font-family: "AppleSDGothicNeoM00",
                $color: #363739,
                $letter-spacing: -0.72px,
                $text-align: right
              );
              color: #fff;
            }
            &.active {
              background: #315754;
            }
          }
        }
      }
      .speech-wrap {
        display: none;
        p {
          position: absolute;
          top: calc(50% - 13.5px);
          left: 50%;
          width: 100%;
          transform: translate(-50%, -50%);
          @include initfontKor(
            $font-size: 20px,
            $line-height: 27px,
            $font-family: "AppleSDGothicNeoSB00",
            $color: #fff,
            $letter-spacing: -0.4px,
            $text-align: center
          );
        }
      }
    }
  }
}
.block-menu-wrap {
  .block-menu {
    display: flex;
    justify-content: center;
    .item {
      cursor: pointer;
    }

    &.main {
      .item {
        width: calc((100% / 4) - 30px);
        height: 280px;
        padding: 12px 0 20px 0;
        margin: 0 0 0 20px;
        border-radius: 5px;
        box-shadow: 0 3px 10px 0 rgba(44, 57, 55, 0.1);
        background-color: #ffffff;
        position: relative;

        &.active {
          background: #315754;

          h3.title {
            @include initfontKor(
              $font-size: 36px,
              $line-height: 42px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #363739,
              $letter-spacing: -0.72px,
              $text-align: right
            );
            color: #fff;
          }
        }

        &:first-of-type {
          margin: 0;
        }

        .img-wrap {
          margin: 0;

          img {
          }
        }

        h3.title {
          @include initfontKor(
            $font-size: 36px,
            $line-height: 42px,
            $font-family: "AppleSDGothicNeoB00",
            $color: #363739,
            $letter-spacing: -0.72px,
            $text-align: right
          );
          padding: 0 20px 0 0;
          position: absolute;
          bottom: 20px;
          right: 0;
        }
      }
    }
    &.evaluation {
      width: 810px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      &.special {
        width: 882px;
        .item {
          width: calc((100% / 3) - 32px);
          height: 350px;
          margin-left: 48px;
          padding: 20px 0;
          position: relative;
          background: #fff;
          border-radius: 5px;
          margin-right: 0;
          box-shadow: 0 3px 20px 0 rgba(54, 55, 57, 0.15);
          &:first-of-type {
            margin-left: 0;
          }
          &:nth-of-type(n + 3) {
            margin-top: 0;
          }
          h3.title {
            @include initfontKor(
              $font-size: 36px,
              $line-height: 36px,
              $font-family: "AppleSDGothicNeoB00",
              $color: #363739,
              $letter-spacing: -0.6px,
              $text-align: right
            );
            position: absolute;
            bottom: 20px;
            right: 20px;
          }
        }
      }
      &.mypage {
        width: 870px;
      }
      &.diet {
        width: 870px;
        .item {
          margin-right: 70px;
        }
      }
      .item {
        width: 390px;
        height: 242.5px;
        margin-top: 0;
        margin-right: 30px;
        padding: 20px 0;
        position: relative;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0 3px 20px 0 rgba(54, 55, 57, 0.15);

        &:nth-of-type(n + 3) {
          margin-top: 30px;
        }

        &:nth-of-type(2n) {
          margin-right: 0;
        }
        &.isRemote {
          background: #315754;
        }
        &.active {
          background: #315754;

          h3.title {
            @include initfontKor(
              $font-size: 36px,
              $line-height: 42px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #363739,
              $letter-spacing: -0.72px,
              $text-align: right
            );
            color: #fff;
          }
        }

        .img-wrap {
          margin: 0;

          img {
          }
        }

        h3.title {
          @include initfontKor(
            $font-size: 36px,
            $line-height: 36px,
            $font-family: "AppleSDGothicNeoB00",
            $color: #363739,
            $letter-spacing: -0.6px,
            $text-align: right
          );
          position: absolute;
          bottom: 20px;
          right: 20px;
        }
      }
    }

    &.training {
      width: inherit;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;

      .item {
        width: calc((100% / 3) - 20px);
        height: 360px;
        padding: 16px 22px;
        position: relative;
        margin: 0 0 0 30px;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0 3px 20px 0 rgba(54, 55, 57, 0.15);
        border: solid 0.5px #bbbcc1;
        background-color: #ffffff;

        &:first-of-type {
          margin: 0;
        }
        &.header-fit {
          padding: 0;
          box-shadow: none;
          .i-header {
            height: 62px;
            background-color: rgba(49, 87, 84, 0.1);
            h3.title {
              margin: 0;
              line-height: 62px;
            }
          }
          .i-content {
            padding: 0 22px 16px 22px;
          }
        }
        .i-header {
          h3.title {
            @include initfontKor(
              $font-size: 30px,
              $line-height: 36px,
              $font-family: "AppleSDGothicNeoB00",
              $color: #363739,
              $letter-spacing: -0.6px,
              $text-align: center
            );
            margin: 0 0 10.5px 0;
          }

          border-bottom: 1px solid #bbbcc1;
          margin: 0 0 18.5px 0;
        }

        .i-content {
          .img-wrap {
            margin: 0 0 12px 0;
            display: flex;
            justify-content: center;

            img {
            }
          }
          p.sub {
            &.active {
              color: #fd8b11;
            }
            &.point {
              @include initfontKor(
                $font-size: 26px,
                $line-height: 34px,
                $font-family: "AppleSDGothicNeoB00",
                $color: #fd8b11,
                $letter-spacing: -0.52px,
                $text-align: center
              );
            }
            @include initfontKor(
              $font-size: 26px,
              $line-height: 34px,
              $font-family: "AppleSDGothicNeoSB00",
              $color: #413528,
              $letter-spacing: -0.52px,
              $text-align: center
            );
            margin: 0 0 10px 0;
          }
          .btn-wrap {
            width: 100%;
            button {
              padding: 0;
              background: #315754;
              width: inherit;
              border: 0;
              height: 52px;
              border-radius: 4px;
              @include initfontKor(
                $font-size: 30px,
                $line-height: 31px,
                $font-family: "AppleSDGothicNeoM00",
                $color: #fff,
                $letter-spacing: -0.48px,
                $text-align: center
              );
              cursor: pointer;
            }
          }
        }
      }
    }

    &.evaluation-detail {
      .item {
        width: calc((100% / 4) - 13.5px);
        height: 285px;
        padding: 20px;
        margin: 0 0 0 18px;
        border-radius: 5px;
        box-shadow: 0 3px 10px 0 rgba(44, 57, 55, 0.1);
        background-color: #ffffff;
        position: relative;

        &.active {
          background: #315754;

          h3.title {
            @include initfontKor(
              $font-size: 36px,
              $line-height: 42px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #363739,
              $letter-spacing: -0.72px,
              $text-align: right
            );
            color: #fff;
          }
        }

        &:first-of-type {
          margin: 0;
        }

        .img-wrap {
          bottom: 12px;
          right: 12px;
          position: absolute;

          img {
          }
        }

        h3.title {
          @include initfontKor(
            $font-size: 36px,
            $line-height: 36px,
            $font-family: "AppleSDGothicNeoB00",
            $color: #363739,
            $letter-spacing: -0.6px,
            $text-align: left
          );
          min-height: 72px;
          padding: 0;
        }
      }
    }
  }
}
</style>
