<template>
  <div class="calendar-navigator">
    <div class="navigator">
      <div
        class="item"
        @click="prev('hyper')"
        :class="{ hide: this.status.currentOrder <= 1 || this.list.length < 1 }"
      >
        <img src="@assets/images/util/prev_month.svg" />
      </div>
      <div
        class="item"
        @click="prev()"
        :class="{ hide: this.status.currentOrder === 0 || this.list.length < 1 }"
      >
        <img src="@assets/images/util/prev_date.svg" />
      </div>
    </div>
    <div class="date-info">
      <h3 class="date" v-text="status.currentDay" v-if="status.currentDay !== null"></h3>
      <h3 class="date" v-else>훈련일자 없음</h3>
      <div class="action" @click="callCalendar()">
        <img src="@assets/images/util/calendar.svg" />
        <p>달력으로 조회</p>
      </div>
    </div>
    <div class="navigator">
      <div
        class="item"
        @click="next()"
        :class="{ hide: this.status.currentOrder === this.list.length - 1 || this.list.length < 1 }"
      >
        <img src="@assets/images/util/next_date.svg" />
      </div>
      <div
        class="item"
        @click="next('hyper')"
        :class="{ hide: this.status.currentOrder >= this.list.length - 2 || this.list.length < 1 }"
      >
        <img src="@assets/images/util/next_month.svg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dateList: {
      type: Array,
    },
    recordType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      status: {
        currentOrder: null,
        today: null,
        currentDay: null,
      },
      list: [],
    };
  },
  created() {
    console.log('네비게이션 진입');
    this.getToday();
    this.prePareList();
  },
  methods: {
    setStatusCurrentOrder(order) {
      this.status.currentOrder = order;
      this.status.currentDay = this.orderToDate(order);
    },
    calculateNavigator() {
      let compareNum = null;
      let differences;
      let goalIndex;
      for (let i = 0; i < this.list.length; i++) {
        differences = Math.abs(
          this.$moment(new Date(this.status.currentDay)).diff(
            this.$moment(this.list[i].date),
            'days',
          ),
        );
        if (compareNum !== null) {
          if (compareNum >= differences) {
            compareNum = differences;
            goalIndex = i;
          }
          console.log('현재 순번 : ', i);
          console.log('현재 today와 차이 :', differences);
          console.log('현재 비교수 : ', compareNum);
        } else {
          console.log('비교수가 null일 때 차이', differences);
          compareNum = differences;
          goalIndex = i;
        }
      }
      console.log('today와 비교시 가장 적었던 격차', compareNum);
      console.log('목표 인덱스 :', goalIndex);
    },
    getToday() {
      this.status.today = this.$moment(new Date()).format('YYYY-MM-DD');
    },
    prePareList() {
      const sampleArr = [];
      let sampleObj;
      for (let i = 0; i < this.dateList.length; i++) {
        if (this.dateList[i].isExist) {
          sampleObj = {
            date: this.dateList[i].date,
            id: this.dateList[i].id,
          };
          sampleArr.push(sampleObj);
        }
      }
      console.log('sampleArr', sampleArr);
      console.log('sampleArr.length', sampleArr.length);
      if (sampleArr.length < 1) {
        this.$emit('call-list-fail');
      } else {
        this.list = sampleArr;
        let compareNum = null;
        let differences;
        let goalIndex;
        for (let j = 0; j < this.list.length; j++) {
          differences = Math.abs(
            this.$moment(new Date(this.status.today)).diff(this.$moment(this.list[j].date), 'days'),
          );
          if (compareNum !== null) {
            if (compareNum >= differences) {
              compareNum = differences;
              goalIndex = j;
            }
            console.log('현재 순번 : ', j);
            console.log('현재 today와 차이 :', differences);
            console.log('현재 비교수 : ', compareNum);
          } else {
            console.log('비교수가 null일 때 차이', differences);
            compareNum = differences;
            goalIndex = j;
          }
        }
        console.log('today와 비교시 가장 적었던 격차', compareNum);
        console.log('목표 인덱스 :', goalIndex);
        this.status.currentOrder = goalIndex;
        const info = {
          date: this.orderToDate(this.status.currentOrder),
          id: this.orderToId(this.status.currentOrder),
        };
        this.status.currentDay = this.orderToDate(this.status.currentOrder);
        console.log('보낼 인포정보 :', info);
        this.$emit('call-list-date', info);
      }
    },
    initList(listData, goalDate) {
      console.log('가져온 날짜 데이터 :', listData);
      console.log('목표 날짜 :', goalDate);
      if (goalDate !== undefined) {
        const sampleArr = [];
        let sampleObj;
        for (let i = 0; i < listData.length; i++) {
          if (listData[i].isExist) {
            sampleObj = {
              date: listData[i].date,
              id: listData[i].id,
            };
            sampleArr.push(sampleObj);
          }
        }
        console.log('sampleArr', sampleArr);
        console.log('sampleArr.length', sampleArr.length);
        if (sampleArr.length < 1) {
          this.$emit('call-list-fail');
        } else {
          this.list = sampleArr;
          let compareNum = null;
          let differences;
          let goalIndex;
          for (let j = 0; j < this.list.length; j++) {
            if (this.list[j].date === goalDate) {
              goalIndex = j;
              break;
            } else {
              differences = Math.abs(
                this.$moment(new Date(this.status.today)).diff(
                  this.$moment(this.list[j].date),
                  'days',
                ),
              );
              if (compareNum !== null) {
                if (compareNum >= differences) {
                  compareNum = differences;
                  goalIndex = j;
                }
                console.log('현재 순번 : ', j);
                console.log('현재 today와 차이 :', differences);
                console.log('현재 비교수 : ', compareNum);
              } else {
                console.log('비교수가 null일 때 차이', differences);
                compareNum = differences;
                goalIndex = j;
              }
            }
          }
          console.log('목표 인덱스 :', goalIndex);
          this.status.currentOrder = goalIndex;
          /** 사쯔아~~ 이전 가져온거 인덱스로 물자 오더를 */
          const info = {
            date:
              this.status.currentOrder !== null
                ? this.orderToDate(this.status.currentOrder)
                : goalDate,
            id: this.status.currentOrder !== null ? this.orderToId(this.status.currentOrder) : null,
          };
          if (this.status.currentOrder === null) {
            this.status.today = goalDate;
            this.status.currentDay = goalDate;
          } else {
            this.status.currentDay = this.orderToDate(this.status.currentOrder);
          }
          console.log('보낼 인포정보(init에서) :', info);
          this.$emit('call-list-date', info);
        }
      } else {
        const sampleArr = [];
        let sampleObj;
        for (let i = 0; i < listData.length; i++) {
          if (listData[i].isExist) {
            sampleObj = {
              date: listData[i].date,
              id: listData[i].id,
            };
            sampleArr.push(sampleObj);
          }
        }
        console.log('sampleArr', sampleArr);
        console.log('sampleArr.length', sampleArr.length);
        if (sampleArr.length < 1) {
          this.$emit('call-list-fail');
        } else {
          this.list = sampleArr;
          let compareNum = null;
          let differences;
          let goalIndex;
          for (let j = 0; j < this.list.length; j++) {
            differences = Math.abs(
              this.$moment(new Date(this.status.today)).diff(
                this.$moment(this.list[j].date),
                'days',
              ),
            );
            if (compareNum !== null) {
              if (compareNum >= differences) {
                compareNum = differences;
                goalIndex = j;
              }
              console.log('현재 순번 : ', j);
              console.log('현재 today와 차이 :', differences);
              console.log('현재 비교수 : ', compareNum);
            } else {
              console.log('비교수가 null일 때 차이', differences);
              compareNum = differences;
              goalIndex = j;
            }
          }
          console.log('today와 비교시 가장 적었던 격차', compareNum);
          console.log('목표 인덱스 :', goalIndex);
          this.status.currentOrder = goalIndex;
          const info = {
            date: this.orderToDate(this.status.currentOrder),
            id: this.orderToId(this.status.currentOrder),
          };
          console.log('보낼 인포정보(init에서) :', info);
          this.$emit('call-list-date', info);
          this.status.currentDay = this.orderToDate(this.status.currentOrder);
        }
      }
    },
    getDayInfo(day) {
      console.log('day :', day);
      let sampleObj;
      /**
       * 해당 월의 순번을 알아내고
       * currentOrder도 변경함
       */
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].date === day) {
          sampleObj = this.list[i];
          this.setStatusCurrentOrder(i);
          break;
        }
      }
      this.$emit('trans-date', sampleObj);
    },
    prev(string) {
      const { currentOrder } = this.status;
      console.log('prev 접근');
      if (string !== 'hyper') {
        if (currentOrder > 0) {
          this.status.currentOrder = currentOrder - 1;
        } else if (currentOrder === null && this.list.length > 0) {
          /** 기준일과 가까운 일자로 뽑아줘야함 */
          this.status.currentOrder = 0;
        }
      } else {
        this.status.currentOrder = 0;
      }
      console.log('처리결과값 : ', this.status.currentOrder);
      this.status.currentDay = this.orderToDate(this.status.currentOrder);
      this.$emit('call-list-date', this.list[this.status.currentOrder]);
    },
    next(string) {
      const { currentOrder } = this.status;
      if (string !== 'hyper') {
        if (currentOrder < this.list.length - 1) {
          this.status.currentOrder = currentOrder + 1;
        } else if (currentOrder === null && this.list.length > 0) {
          this.status.currentOrder = this.list.length - 1;
        }
      } else {
        this.status.currentOrder = this.list.length - 1;
      }
      this.status.currentDay = this.orderToDate(this.status.currentOrder);
      this.$emit('call-list-date', this.list[this.status.currentOrder]);
    },
    callCalendar() {
      this.$emit('open-calendar');
    },
  },
  computed: {
    orderToDate() {
      return (order) => {
        if (order !== null) {
          console.log('orderToDate - order :', order);
          console.log('orderToDate - list: ', this.list);
          if (this.list.length > 0) {
            return this.list[order].date;
          }
        }
      };
    },
    orderToId() {
      return (order) => {
        if (order !== null) {
          console.log('order :', order);
          if (this.list.length > 0 && order !== null) {
            return this.list[order].id;
          }
        }
      };
    },
  },
  components: {},
};
</script>

<style lang="scss" scoped>
.calendar-navigator {
  display: flex;
  justify-content: space-between;
  width: 390px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 25px;
  .navigator {
    display: flex;
    .item {
      margin: 0 0 0 12px;
      cursor: pointer;
      &:first-of-type {
        margin: 0;
      }
      &.hide {
        opacity: 0;
        cursor: default;
      }
    }
  }
  .date-info {
    margin: 0 40px;
    h3.date {
      @include initfontKor(
        $font-size: 26px,
        $line-height: 36px,
        $font-family: "AppleSDGothicNeoB00",
        $color: #363739,
        $letter-spacing: -0.52px,
        $text-align: center
      );
      margin: 0 0 0px 0;
    }
    .action {
      display: flex;
      position: relative;
      cursor: pointer;
      &:after {
        content: "";
        width: 100%;
        height: 1px;
        bottom: 5px;
        position: absolute;
        background: #315754;
        left: 0;
      }
      img {
        margin: 0 5px 0 0;
      }
      p {
        @include initfontKor(
          $font-size: 20px,
          $line-height: 36px,
          $font-family: "AppleSDGothicNeoR00",
          $color: #315754,
          $letter-spacing: -0.4px,
          $text-align: left
        );
      }
    }
  }
}
</style>
