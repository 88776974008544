<template>
  <div class="app-context flex">
    <side-menu
      :sideMenuOption="sideMenuOption"
      :sideMenuDeep="sideMenuDeep"
      @is-unauthorized="isUnAuthorized"
    ></side-menu>
    <div class="wrap-context">
      <h5 class="page-title">예약하기</h5>
      <div class="resv-context">
        <div class="article flex">
          <h3 class="title">상담 유형<br /><b>(선택해 주세요.)</b></h3>
          <div class="content flex">
            <!--
            <div class="item">
              <div class="bg-absolute">
                <img src="@assets/images/resv/icon_voice.svg" />
              </div>
              <h5 class="i-title">음성</h5>
              <p class="i-content">전화하듯 대화하며<br>음성으로 상담</p>
            </div>
            <div class="item">
              <div class="bg-absolute">
                <img src="@assets/images/resv/icon_video.svg" />
              </div>
              <h5 class="i-title">영상</h5>
              <p class="i-content">화면을 통해 얼굴을<br>마주보며 영상상담</p>
            </div>
            -->
            <div
              class="item"
              v-for="(typeItem, index) in resvType"
              :key="index"
              @click="choiceType(index)"
              :class="{ active: typeItem.flag }"
            >
              <div class="bg-absolute">
                <img
                  :src="require(`@/assets/images/resv/${typeItem.bgUrl}`)"
                  v-if="!typeItem.flag"
                />
                <img
                  :src="require(`@/assets/images/resv/${typeItem.activebgUrl}`)"
                  v-else
                />
              </div>
              <h5 class="i-title" v-text="typeItem.title" />
              <p class="i-content" v-html="computedLine(typeItem.content)" />
            </div>
          </div>
        </div>
        <div class="article">
          <h3 class="title">예약 일시</h3>
          <div class="content">
            <div class="select-resv" @click="calendarPop()">
              <p
                v-text="resvDate.exposeData"
                :class="{ activated: resvDate.exposeData !== '' }"
              ></p>
            </div>
          </div>
        </div>
        <div class="article">
          <h3 class="title">상담 희망 내용<br /><b>(100자 이내)</b></h3>
          <div class="content">
            <div class="textarea-wrap">
              <textarea
                placeholder="내용을 입력해주세요."
                maxlength="100"
                v-model="resvContent.value"
                @input="writing()"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-wrap">
        <button
          type="button"
          :class="{
            active:
              resvContent.flag && resvDate.flag && (resvType[0].flag || resvType[1].flag),
          }"
          @click="resv()"
        >
          예약
        </button>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" @resv-call="doResv" />
    <calendar-popup
      @selected-resv-data="selectedResv"
      v-if="checkCalendarPopup"
      :popupSetCalendar="popupSetCalendar"
    ></calendar-popup>
  </div>
</template>

<script>
import errorParser from '@/utils/error-parser';
import SideMenu from '@/components/SideMenu.vue';
import CalendarPopup from '@/components/modal/CalendarPopup';
import DefaultPopup from '@/components/modal/DefaultPopup';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  data() {
    return {
      sideMenuOption: [
        {
          img: 'sidebar_item01.png',
          img_active: 'sidebar_menu1_active.png',
          img_active_deep: 'sidebar_menu1_active_deep.png',
          title: '메인',
          isActive: false,
          link: '',
        },
        {
          img: 'sidebar_item02.png',
          img_active: 'sidebar_menu2_active.png',
          img_active_deep: 'sidebar_menu2_active_deep.png',
          title: '삼킴 평가',
          isActive: false,
          link: 'evaluate',
        },
        {
          img: 'sidebar_item03.png',
          img_active: 'sidebar_menu3_active.png',
          img_active_deep: 'sidebar_menu3_active_deep.png',
          title: '삼킴 훈련',
          isActive: false,
          link: 'trainingDate',
        },
        {
          img: 'sidebar_item05.png',
          img_active: 'sidebar_menu5_active.png',
          img_active_deep: 'sidebar_menu5_active_deep.png',
          title: '자세법ㆍ식이법',
          isActive: false,
          link: 'choiceType',
        },
        {
          img: 'sidebar_item06.png',
          img_active: 'sidebar_menu6_active.png',
          img_active_deep: 'sidebar_menu6_active_deep.png',
          title: '원격 상담',
          isActive: true,
          link: 'reservationType',
        },
        {
          img: 'sidebar_mypage.png',
          img_active: 'sidebar_mypage_active.png',
          img_active_deep: 'sidebar_mypage_active.png',
          title: '마이페이지',
          isActive: false,
          link: 'mypage',
        },
      ],
      sideMenuDeep: true,
      resvType: [
        {
          flag: false,
          bgUrl: 'icon_voice.svg',
          activebgUrl: 'icon_voice_active.svg',
          title: '음성',
          content: '전화하듯 \n음성으로 상담',
        },
        {
          flag: false,
          bgUrl: 'icon_video.svg',
          activebgUrl: 'icon_video_active.svg',
          title: '영상',
          content: '화면으로 \n얼굴보며 상담',
        },
      ],
      resvDate: {
        flag: false,
        value: '',
        exposeData: '',
        date: '',
        dayName: '',
        time: '',
      },
      resvContent: {
        flag: false,
        value: '',
      },
      popupSet: {},
      popupSetCalendar: {},
    };
  },
  created() {
    if (history.pushState !== undefined) {
      history.pushState({}, '', location.href);
      window.onpopstate = () => {
        history.pushState({}, '', location.href);
        this.handleGoback();
      };
    }
  },
  mounted() {
    /**
    this.popTest();
     */
  },
  methods: {
    ...mapMutations('basic', ['SET_POPUP', 'SET_CALENDAR_POPUP']),
    ...mapActions('consultation', ['SUBMIT_CONSULTATION']),
    handleGoback() {
      if (this.checkPopup) {
        this.SET_POPUP(false);
      } else {
        this.$router.push('/reservationType');
      }
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = '로그인 권한이 필요합니다.';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    async doResv() {
      try {
        const payload = {
          consultationDate: this.resvDate.date,
          consultationTime: `${this.resvDate.time}:00`,
          requestContent: this.resvContent.value,
        };
        if (this.resvType[0].flag) {
          payload.type = 0;
        } else if (this.resvType[1].flag) {
          payload.type = 1;
        }
        await this.SUBMIT_CONSULTATION(payload).then(() => {
          this.SET_POPUP(true);
          this.popupSet.popType = 'warn';
          this.popupSet.popImg = 'success.svg';
          this.popupSet.content = '예약이 완료되었습니다.';
          this.popupSet.cancelBtnText = null;
          this.popupSet.nextLink = '/reservationType';
          this.popupSet.confirmBtnText = '확인';
          this.popupSet.destination = true;
        });
      } catch (error) {
        console.log('error :', errorParser.parse(error));
        console.log('error :', error);
      }
    },
    checkFlag() {
      if (
        (!this.resvType[0].flag && !this.resvType[1].flag)
        || !this.resvDate.flag
        || !this.resvContent.flag
      ) {
        if (!this.resvType[0].flag && !this.resvType[1].flag) {
          this.popupSet.content = '상담 유형을 선택해 주세요.';
        } else if (!this.resvDate.flag) {
          this.popupSet.content = '상담 날짜 / 시간을 선택해주세요.';
        } else if (!this.resvContent.flag) {
          this.popupSet.content = '상담 희망 내용을 입력해주세요.';
        }
        this.SET_POPUP(true);
        this.popupSet.popType = 'warn';
        this.popupSet.popImg = 'warning.svg';
        this.popupSet.cancelBtnText = '확인';
        this.popupSet.nextLink = null;
        this.popupSet.confirmBtnText = null;
        return false;
      }
      return true;
    },
    resv() {
      const splitTime = this.resvDate.time.split(':');
      console.log('splitTime:', splitTime[0]);
      const isAfterNoon = Number(splitTime[0]) <= 12 ? '오전 ' : '오후 ';
      if (this.checkFlag()) {
        this.SET_POPUP(true);
        this.popupSet.popType = 'resv';
        this.popupSet.popImg = 'success.svg';
        this.popupSet.cancelBtnText = '아니오';
        this.popupSet.content = '예약하시겠습니까?';
        this.popupSet.nextLink = null;
        this.popupSet.confirmBtnText = '예';
        const transObj = {
          type: this.resvType[0].flag ? '음성' : '영상',
          date: `${this.resvDate.date} (${this.resvDate.dayName})`,
          time: isAfterNoon + this.resvDate.time,
        };
        this.popupSet.resvConts = transObj;
      }
    },
    writing() {
      this.resvContent.flag = false;
      const content = this.resvContent.value.replace(/\s/gi, '');
      if (content === '') {
        this.resvContent.flag = false;
        return false;
      }
      this.resvContent.flag = true;
      return true;
    },
    choiceType(order) {
      const result = !this.resvType[order].flag;
      for (let j = 0; j < this.resvType.length; j++) {
        this.resvType[j].flag = false;
      }
      this.resvType[order].flag = result;
    },
    selectedResv(dataSet) {
      this.resvDate.flag = true;
      this.resvDate.value = dataSet.concatTime;
      this.resvDate.date = dataSet.date;
      this.resvDate.dayName = dataSet.dayName;
      this.resvDate.time = dataSet.time;
      this.resvDate.exposeData = `${dataSet.date} (${dataSet.dayName}) ${dataSet.time}`;
    },
    calendarPop() {
      this.SET_CALENDAR_POPUP(true);
      this.popupSetCalendar.calendarType = 2;
      this.popupSetCalendar.notificationType = 1;
      this.popupSetCalendar.isTimeTable = true;
    },
    popTest() {
      this.SET_POPUP(true);
      this.popupSet.content = '테스트';
      this.popupSet.cancelBtnText = '취소';
      this.popupSet.nextLink = '/';
      this.popupSet.confirmBtnText = '실행';
    },
    linkFromMain(order) {},
    linkFromEvaluation(order) {},
    linkFromClinic(order) {},
  },
  computed: {
    ...mapState('basic', ['checkPopup', 'checkCalendarPopup']),
    computedLine() {
      return (text) => {
        if (text.indexOf('\n') !== -1) {
          return text.split('\n').join('<br />');
        }
        return text;
      };
    },
  },
  components: {
    SideMenu,
    DefaultPopup,
    CalendarPopup,
  },
};
</script>
<style lang="scss" scoped>
.app-context {
  background: #ebf2f1;
  .wrap-context {
    width: 870px;
    margin: 22px 122px 0 auto;
    h5.page-title {
      position: relative;
      padding: 20px 0 0 0px;
      @include initfontKor(
        $font-size: 30px,
        $line-height: 40px,
        $font-family: "AppleSDGothicNeoB00",
        $color: #315754,
        $letter-spacing: -0.6px,
        $text-align: left
      );
      margin: 0 0 38px 0;
      &:after {
        content: "";
        background-image: url("~@assets/images/util/mark.png");
        position: absolute;
        width: 26px;
        height: 26px;
        top: 0;
        left: -20px;
      }
    }
    .resv-context {
      border-radius: 5px;
      border: solid 1px #eaebf1;
      background-color: #ffffff;
      padding: 30px 50px;
      margin: 0 0 20px 0;
      .article {
        display: flex;
        padding: 30px 0;
        border-bottom: 1px solid #eaebf1;
        h3.title {
          @include initfontKor(
            $font-size: 30px,
            $line-height: 36px,
            $font-family: "AppleSDGothicNeoB00",
            $color: #363739,
            $letter-spacing: -0.6px,
            $text-align: left
          );
          padding: 0 0 0 45px;
          position: relative;
          width: 270px;
          &:after {
            position: absolute;
            width: 30px;
            height: 30px;
            top: 3px;
            left: 0;
            background: #fd8b11;
            @include initfontKor(
              $font-size: 24px,
              $line-height: 32px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #fff,
              $letter-spacing: -0.48px,
              $text-align: center
            );
            border-radius: 50%;
          }
          b {
            @include initfontKor(
              $font-size: 24px,
              $line-height: 32px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #8b8d94,
              $letter-spacing: -0.48px,
              $text-align: left
            );
          }
        }
        .content {
          width: calc(100% - 270px);
          &.flex {
            display: flex;
            .item {
              width: calc(50% - 10px);
              margin: 0 0 0 20px;
            }
          }
          .item {
            border-radius: 5px;
            border: solid 1px #315754;
            padding: 20px 0 0 20px;
            min-height: 145px;
            position: relative;
            cursor: pointer;
            &.active {
              border: solid 2px #fd8b11;
              background: rgba(253, 139, 17, 0.1);
              h5.i-title {
                color: #fd8b11;
              }
            }
            h5.i-title {
              @include initfontKor(
                $font-size: 26px,
                $line-height: 36px,
                $font-family: "AppleSDGothicNeoB00",
                $color: #615754,
                $letter-spacing: -0.52px,
                $text-align: left
              );
              margin: 0 0 10px 0;
            }
            p.i-content {
              @include initfontKor(
                $font-size: 24px,
                $line-height: 32px,
                $font-family: "AppleSDGothicNeoR00",
                $color: #363739,
                $letter-spacing: -0.48px,
                $text-align: left
              );
            }
            .bg-absolute {
              position: absolute;
              top: 0;
              right: 0;
              max-width: 57px;
              img {
                width: 100%;
              }
            }
            &:first-of-type {
              margin: 0;
            }
          }
          .select-resv {
            border-radius: 5px;
            border: solid 1px #315754;
            width: 100%;
            height: 60px;
            position: relative;
            padding: 15px 20px;
            cursor: pointer;
            p {
              @include initfontKor(
                $font-size: 24px,
                $line-height: 32px,
                $font-family: "AppleSDGothicNeoM00",
                $color: #8b8d94,
                $letter-spacing: -0.48px,
                $text-align: left
              );
              &.activated {
                color: #fd8b11;
              }
            }
            &:after {
              content: "";
              position: absolute;
              width: 30px;
              height: 30px;
              background-image: url("~@assets/images/util/icon_calendar.svg");
              right: 20px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          .textarea-wrap {
            height: 95px;
            width: 100%;
            position: relative;
            border-radius: 5px;
            border: solid 1px #315754;
            overflow: hidden;
            textarea {
              width: 100%;
              padding: 15px 20px;
              resize: none;
              @include initfontKor(
                $font-size: 24px,
                $line-height: 32px,
                $font-family: "AppleSDGothicNeoM00",
                $color: #8b8d94,
                $letter-spacing: -0.48px,
                $text-align: left
              );
            }
          }
        }
        &:nth-of-type(1) {
          padding: 0 0 30px 0;
          h3.title:after {
            content: "1";
          }
        }
        &:nth-of-type(2) {
          h3.title:after {
            content: "2";
          }
        }
        &:nth-of-type(3) {
          padding: 30px 0 0 0;
          border-bottom: 0;
          h3.title:after {
            content: "3";
          }
        }
      }
    }
    .btn-wrap {
      width: 260px;
      margin: 0 auto;
      height: 60px;
      background: transparent;
      box-shadow: 0 3px 10px 0 rgba(54, 55, 57, 0.3);
      border-radius: 4px;
      button {
        width: 100%;
        height: 100%;
        border: 0;
        background: rgba(253, 139, 17, 0.5);
        border-radius: 4px;
        @include initfontKor(
          $font-size: 30px,
          $line-height: 36px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #fff,
          $letter-spacing: -0.6px,
          $text-align: center
        );
        cursor: pointer;
        &.active {
          background: #fd8b11;
        }
      }
    }
  }
}
</style>
