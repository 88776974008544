<template>
  <modal>
    <template v-slot:body>
      <div class="default-pop frame" v-if="popType === 'warn'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
       <div class="pop-img">
         <img :src="require(`@/assets/images/popup/${popImg}`)" />
       </div>
        <div class="content">
          <p v-html="contentMutated"></p>
        </div>
        <div class="btn-wrap">
          <button v-text="cancelBtnText" type="button" @click="close()" />
          <button class="active" v-text="confirmBtnText" type="button" @click="action()" />
        </div>
      </div>
      <div class="write-pop frame" v-if="popType === 'write'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
        <div class="content">
          <p v-html="contentMutated"></p>
        </div>
        <div class="input-wrap" :class="{active: writing}" @click="doWrite()">
          <input
            type="text"
            v-model="dataValue"
             @blur="highlighted(false)"
            @keyup.enter="write()"
          />
        </div>
        <div class="btn-wrap">
          <button v-text="cancelBtnText" type="button" @click="close()" />
          <button class="active" v-text="confirmBtnText" type="button" @click="write()" />
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import {
  mapMutations,
} from 'vuex';
import Modal from './SwiperModal.vue';

export default {
  props: {
    popupSet: {
      content: {
        type: String,
      },
      nextLink: {
        type: String,
      },
      confirmBtnText: {
        type: String,
      },
      cancelBtnText: {
        type: String,
      },
      popType: {
        type: String,
      },
      storedInputData: {
        type: String,
      },
      popImg: {
        type: String,
        default: 'warning.svg',
      },
    },
  },
  components: {
    Modal,
  },
  data() {
    return {
      popType: this.popupSet.popType || 'warn',
      content: this.popupSet.content,
      nextLink: this.popupSet.nextLink,
      cancelBtnText: this.popupSet.cancelBtnText,
      confirmBtnText: this.popupSet.confirmBtnText,
      dataValue: this.popupSet.storedInputData,
      writing: false,
    };
  },
  watch: {},
  mounted() {},
  computed: {
    contentMutated() {
      if (this.content !== undefined && this.content.indexOf('\n') !== 1) {
        return this.content.split('\n').join('<br />');
      }
      return this.content;
    },
  },
  methods: {
    ...mapMutations('basic', ['SET_SWIPER_POPUP']),
    doWrite() {
      this.writing = true;
    },
    highlighted(bool) {
      this.writing = bool;
    },
    write() {
      this.$emit('transed-pop-data', this.dataValue);
      this.SET_SWIPER_POPUP(false);
    },
    action() {
      this.SET_SWIPER_POPUP(false);
      if (this.nextLink !== undefined) {
        this.$router.push(this.nextLink);
      }
    },
    close() {
      this.SET_SWIPER_POPUP(false);
    },
  },
  created() {
    console.log('가져온 팝업아이템', this.popupSet);
    if (this.popupSet.storedInputData !== undefined) {
      console.log('사용할 데이터', this.dataValue);
    }
  },
};
</script>

<style lang="scss" scoped>
.frame {
  padding: 30px;
  min-height: 300px;
}
 .close {
        position:absolute;
        top:20px;
        right:20px;
        cursor:pointer;
        z-index: 1;
        &.hide {
          opacity:0;
        }
      }
      .content {
      p {
        @include initfontKor($font-size:24px, $line-height:30px, $font-family:'AppleSDGothicNeoM00', $color:#363739, $letter-spacing:-0.6px, $text-align:right);
        text-align: center;
      }
    }
      .btn-wrap {
      display: flex;
      justify-content: center;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      button {
        width: 50%;
        height: 60px;
        margin: 0;
        background: #eaebf1;
        border:0;
        cursor: pointer;
        @include initfontKor($font-size:26px, $line-height:36px, $font-family:'AppleSDGothicNeoM00', $color:#363739, $letter-spacing:-0.52px, $text-align:center);
        &:first-of-type {
          margin: 0;
        }
        &.active {
          background: $pointcolor__orange;
          color:#fff;
        }
      }
    }
.default-pop {
  position: relative;
  width: inherit;
    .pop-img {
        max-width: 105px;
        margin: 0 auto 15px auto;
      img {

      }
    }
    .content {
      p {
        margin: 0 0 20px 0;
      }
    }
}

.write-pop {
  .content {
      p {
        margin: 0 0 41px 0;
      }
    }
     .input-wrap {
         border-radius: 4px;
         background-color: rgb(235, 242, 241);
         padding: 12px 15px;
         margin: 0 0 42px 0;
         &.active {
            border-radius: 4px;
            box-shadow: 0 3px 6px 0 rgba(54, 55, 57, 0.15);
            border: solid 2px #fd8b12;
    background: transparent;
         }
      input {
         width: 100%;
         background: transparent;
         padding: 0;
         @include initfontKor($font-size:20px, $line-height:29px, $font-family:'AppleSDGothicNeoL00', $color:#363739, $letter-spacing:-0.6px, $text-align:center);
      }
    }
}
</style>
