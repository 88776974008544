<template>
  <div class="join-header">
    <h5 class="title">회원가입</h5>
    <div class="step-status">
      <div class="item-wrap" :class="{ active: currentOrder === 1 }">
        <div class="item">
          <h5 class="order">1</h5>
          <p v-if="currentOrder === 1">인적사항</p>
        </div>
        <div class="dot-bg">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>
      <div class="item-wrap" :class="{ active: currentOrder === 2 }">
        <div class="item">
          <h5 class="order">2</h5>
          <p v-if="currentOrder === 2">구강 건강</p>
        </div>
        <div class="dot-bg">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>
      <div class="item-wrap" :class="{ active: currentOrder === 3 }">
        <div class="item">
          <h5 class="order">3</h5>
          <p v-if="currentOrder === 3">의사 진단 병력 정보</p>
        </div>
        <div class="dot-bg">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>
      <div class="item-wrap" :class="{ active: currentOrder === 4 }">
        <div class="item">
          <h5 class="order">4</h5>
          <p v-if="currentOrder === 4">추가 문진</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentOrder: {
      type: Number,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
  computed: {},
  components: {},
};
</script>
<style lang="scss" scoped>
.join-header {
  display: flex;
  justify-content: space-between;
  margin: 0 0 10px 0;
  padding: 15px 0 0 0;
  h5.title {
    position: relative;
    margin: 0;
    padding: 0;
    @include initfontKor(
      $font-size: 26px,
      $line-height: 34px,
      $font-family: "AppleSDGothicNeoB00",
      $color: #315754,
      $letter-spacing: -0.6px,
      $text-align: left
    );
    &:after {
      content: "";
      position: absolute;
      width: 26px;
      height: 26px;
      background-image: url("~@assets/images/util/mark.png");
      left: -30px;
      top: -15px;
    }
  }
  .step-status {
    display: flex;
    .item-wrap {
      display: flex;
      align-items: center;
      margin: 0 0px 0 10px;
      &.active {
        .item {
          h5.order {
            background: rgb(49, 87, 84);
          }
        }
        .dot-bg {
          .dot {
            background: rgb(49, 87, 84);
          }
        }
      }
      &:first-of-type {
        margin: 0;
      }
      .item {
        display: flex;
        margin: 0 8px 0 0;
        h5.order {
          width: 34px;
          height: 34px;
          margin: 0 10px 0 0;
          border-radius: 50%;
          background: rgba(49, 87, 84, 0.5);
          @include initfontKor(
            $font-size: 26px,
            $line-height: 36px,
            $font-family: "AppleSDGothicNeoB00",
            $color: #fff,
            $letter-spacing: -0.6px,
            $text-align: center
          );
        }
        p {
          @include initfontKor(
            $font-size: 26px,
            $line-height: 34px,
            $font-family: "AppleSDGothicNeoB00",
            $color: #315754,
            $letter-spacing: -0.52px,
            $text-align: left
          );
        }
      }
      .dot-bg {
        display: flex;
        align-items: center;
        .dot {
          &:first-of-type {
            margin: 0;
          }
          margin: 0 0 0 3px;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background: rgba(49, 87, 84, 0.5);
        }
      }
    }
  }
}
</style>
