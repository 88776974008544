<template>
  <div class="app-context flex">
    <side-menu :sideMenuOption="sideMenuOption" @is-unauthorized="isUnAuthorized"></side-menu>
    <div class="wrap-context">
      <h5 class="page-title">자세법 활동목록</h5>
      <result-list
        :resultList="resultList"
        :resultFor="'posture'"
        :resultType="2"
        :currentPage="listStatus.currentPage"
        @share-pop-call="sharePopCall"
        v-if="view.isListLoaded"
      ></result-list>
      <pagination
        @btn-click="paginationClick"
        :paginationInfo="paginationInfo"
        v-if="view.isListLoaded"
      ></pagination>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import errorParser from '@/utils/error-parser';
import DefaultPopup from '@/components/modal/DefaultPopup';
import SideMenu from '@/components/SideMenu.vue';
import ResultList from '@/components/ResultList.vue';
import Pagination from '@/components/Pagination.vue';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  data() {
    return {
      listStatus: {
        currentPage: 1,
      },
      paginationInfo: {
        totalDataCnt: null,
        dataOnPage: null,
        totalPage: null,
        currentPage: null,
        prevApiUrl: null,
        nextApiUrl: null,
      },
      resultList: {
        list: [],
        header: ['번호', '제목', '활동하기', '결과공유', '최종 활동일'],
      },
      sideMenuOption: [
        {
          img: 'sidebar_item01.png',
          img_active: 'sidebar_menu1_active.png',
          img_active_deep: 'sidebar_menu1_active_deep.png',
          title: '메인',
          isActive: false,
          link: '',
        },
        {
          img: 'sidebar_item02.png',
          img_active: 'sidebar_menu2_active.png',
          img_active_deep: 'sidebar_menu2_active_deep.png',
          title: '삼킴 평가',
          isActive: false,
          link: 'evaluate',
        },
        {
          img: 'sidebar_item03.png',
          img_active: 'sidebar_menu3_active.png',
          img_active_deep: 'sidebar_menu3_active_deep.png',
          title: '삼킴 훈련',
          isActive: false,
          link: 'trainingDate',
        },

        {
          img: 'sidebar_item05.png',
          img_active: 'sidebar_menu5_active.png',
          img_active_deep: 'sidebar_menu5_active_deep.png',
          title: '자세법ㆍ식이법',
          isActive: true,
          link: 'choiceType',
        },
        {
          img: 'sidebar_item06.png',
          img_active: 'sidebar_menu6_active.png',
          img_active_deep: 'sidebar_menu6_active_deep.png',
          title: '원격 상담',
          isActive: false,
          link: 'reservationType',
        },
        {
          img: 'sidebar_mypage.png',
          img_active: 'sidebar_mypage_active.png',
          img_active_deep: 'sidebar_mypage_active.png',
          title: '마이페이지',
          isActive: false,
          link: 'mypage',
        },
      ],
      popupSet: {},
      view: {
        isListLoaded: false,
      },
    };
  },
  created() {
    if (history.pushState !== undefined) {
      history.pushState({}, '', location.href);
      window.onpopstate = () => {
        history.pushState({}, '', location.href);
        this.handleGoback();
      };
    }
    this.fetchResult();
  },
  mounted() {
    /**
    this.popTest();
     */
  },
  methods: {
    ...mapMutations('basic', ['SET_POPUP']),
    ...mapActions('feeding', ['FETCH_POSITION_LIST', 'FETCH_POSITION_LIST_PAGE']),
    sharePopCall(item) {
      const param = `${window.location.href.replace('postureList', 'share/posture')
      }?questionOrder=${
        item.questionOrder
      }&id=${
        item.id}`;
      console.log('param :', param);
      this.SET_POPUP(true);
      this.popupSet.popType = 'share';
      this.popupSet.paramObj = item;
      this.popupSet.param = param;
    },
    handleGoback() {
      if (this.checkPopup) {
        this.SET_POPUP(false);
      } else {
        this.$router.push('/choiceType');
      }
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = '로그인 권한이 필요합니다.';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    async fetchResult(page) {
      console.log('page!!!:', page);
      // **  **/
      if (page !== undefined) {
        try {
          await this.FETCH_POSITION_LIST_PAGE(page).then((data) => {
            console.log('data :', data.response.list);
            this.paginationInfo.totalDataCnt = data.response.totalCount;
            this.paginationInfo.dataOnPage = 5;
            this.paginationInfo.totalPage = data.response.endPage;
            this.paginationInfo.currentPage = Number(page);
            this.paginationInfo.prevApiUrl = data.response.previous;
            this.paginationInfo.nextApiUrl = data.response.next;
            this.resultList.list = data.response.list;
            this.view.isListLoaded = true;
          });
        } catch (error) {
          console.log('error: ', errorParser.parse(error));
          console.log('error :', error);
        }
      } else {
        try {
          await this.FETCH_POSITION_LIST().then((data) => {
            console.log('data :', data.response.list);
            this.paginationInfo.totalDataCnt = data.response.totalCount;
            this.paginationInfo.dataOnPage = 5;
            this.paginationInfo.totalPage = data.response.endPage;
            this.paginationInfo.currentPage = 1;
            this.paginationInfo.prevApiUrl = data.response.previous;
            this.paginationInfo.nextApiUrl = data.response.next;
            this.resultList.list = data.response.list;
            this.view.isListLoaded = true;
          });
        } catch (error) {
          console.log('error: ', errorParser.parse(error));
          console.log('error :', error);
        }
      }
    },
    paginationClick(pageNum) {
      console.log('페이지네이션 클릭수신 :', pageNum);
      this.listStatus.currentPage = pageNum;
      this.fetchResult(pageNum);
    },
    popTest() {
      this.SET_POPUP(true);
      this.popupSet.content = '테스트';
      this.popupSet.cancelBtnText = '취소';
      this.popupSet.nextLink = '/';
      this.popupSet.confirmBtnText = '실행';
    },
  },
  computed: {
    ...mapState('basic', ['checkPopup']),
  },
  components: {
    SideMenu,
    DefaultPopup,
    ResultList,
    Pagination,
  },
};
</script>
<style lang="scss" scoped>
.app-context {
  .wrap-context {
    width: 934px;
    margin: 22px 90px 0 auto;
    h5.page-title {
      margin: 0 0 37px 0;
    }
  }
}
</style>
