<template>
  <div class="app-context flex">
    <side-menu
      :sideMenuOption="sideMenuOption"
      @is-unauthorized="isUnAuthorized"
    ></side-menu>
    <div class="wrap-context">
      <notification
        class="low-margin"
        :notificationInfo="notificationInfo"
      ></notification>
      <directive :directiveTxt="directiveTxt" class="directive low"></directive>
      <block-menu
        class="diet"
        :menuList="menuList"
        :menuType="menuType"
        :menuDistinct="menuDistinct"
      ></block-menu>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import DefaultPopup from '@/components/modal/DefaultPopup';
import SideMenu from '@/components/SideMenu.vue';
import Notification from '@/components/Notification.vue';
import Directive from '@/components/Directive.vue';
import BlockMenu from '@component/BlockMenu.vue';
import { mapMutations, mapState } from 'vuex';

export default {
  data() {
    return {
      menuDistinct: true,
      menuType: 2,
      menuList: [
        {
          img: 'posture.png',
          name: '자세법',
          isActive: false,
          routerUrl: '/postureList',
        },
        {
          img: 'diet.png',
          name: '식이법',
          isActive: true,
          routerUrl: '/dietList',
        },
      ],
      directiveTxt: {
        point: '자세법과 식이법',
        normal: '을 참고하여 안전하게 삼켜보세요.',
        small: null,
      },
      notificationInfo: {
        title: '자세법과 식이법',
        btnSet: [],
      },
      sideMenuOption: [
        {
          img: 'sidebar_item01.png',
          img_active: 'sidebar_menu1_active.png',
          img_active_deep: 'sidebar_menu1_active_deep.png',
          title: '메인',
          isActive: false,
          link: '',
        },
        {
          img: 'sidebar_item02.png',
          img_active: 'sidebar_menu2_active.png',
          img_active_deep: 'sidebar_menu2_active_deep.png',
          title: '삼킴 평가',
          isActive: false,
          link: 'evaluate',
        },
        {
          img: 'sidebar_item03.png',
          img_active: 'sidebar_menu3_active.png',
          img_active_deep: 'sidebar_menu3_active_deep.png',
          title: '삼킴 훈련',
          isActive: false,
          link: 'trainingDate',
        },
        {
          img: 'sidebar_item05.png',
          img_active: 'sidebar_menu5_active.png',
          img_active_deep: 'sidebar_menu5_active_deep.png',
          title: '자세법ㆍ식이법',
          isActive: true,
          link: 'choiceType',
        },
        {
          img: 'sidebar_item06.png',
          img_active: 'sidebar_menu6_active.png',
          img_active_deep: 'sidebar_menu6_active_deep.png',
          title: '원격 상담',
          isActive: false,
          link: 'reservationType',
        },
        {
          img: 'sidebar_mypage.png',
          img_active: 'sidebar_mypage_active.png',
          img_active_deep: 'sidebar_mypage_active.png',
          title: '마이페이지',
          isActive: false,
          link: 'mypage',
        },
      ],
      popupSet: {},
    };
  },
  created() {
    if (history.pushState !== undefined) {
      history.pushState({}, '', location.href);
      window.onpopstate = () => {
        history.pushState({}, '', location.href);
        this.handleGoback();
      };
    }
  },
  mounted() {},
  methods: {
    ...mapMutations('basic', ['SET_POPUP']),
    handleGoback() {
      if (this.checkPopup) {
        this.SET_POPUP(false);
      } else {
        this.$router.push('/');
      }
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = '로그인 권한이 필요합니다.';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    popTest() {
      this.SET_POPUP(true);
      this.popupSet.content = '테스트';
      this.popupSet.cancelBtnText = '취소';
      this.popupSet.nextLink = '/';
      this.popupSet.confirmBtnText = '실행';
    },
    linkFromMain(order) {},
    linkFromEvaluation(order) {},
    linkFromClinic(order) {},
  },
  computed: {
    ...mapState('basic', ['checkPopup']),
  },
  components: {
    SideMenu,
    Notification,
    DefaultPopup,
    Directive,
    BlockMenu,
  },
};
</script>
<style lang="scss" scoped>
.app-context {
  .wrap-context {
    width: 934px;
    margin: 22px 90px 0 auto;
    .low-margin {
      margin: 0 0 56px 0;
    }
    .directive.low {
      margin: 0 0 51px 0;
    }
  }
}
</style>
