<template>
  <div class="app-context flex">
    <side-menu :sideMenuOption="sideMenuOption" @is-unauthorized="isUnAuthorized"></side-menu>
    <div class="wrap-context">
      <notification
        class="low-margin"
        :notificationInfo="notificationInfo"
        v-if="view.trainingDay !== null"
      ></notification>
      <p class="notice-message">
        훈련을 통해 삼킴 능력을 강화할 수 있어요. 매주 5일, 총 8주 동안 진행해주세요.
      </p>
      <!-- 탭메뉴 시작 -->
      <tabs ref="tabs" @tab-selected="tabSelected" v-if="view.isTabOn" :stopAuto="true">
        <tab :tabData="tab[0]">
          <block-menu
            :menuList="menuList"
            :menuType="menuType"
            :tabs-index="selectOrder"
            @router-call="routerCall"
          ></block-menu>
        </tab>
        <tab :tabData="tab[1]" v-if="!view.limitLunch">
          <block-menu
            :menuList="menuList"
            :menuType="menuType"
            :tabs-index="selectOrder"
            @router-call="routerCall"
          ></block-menu>
        </tab>
        <tab :tabData="tab[2]" v-if="!view.limitEvening">
          <block-menu
            :menuList="menuList"
            :menuType="menuType"
            :tabs-index="selectOrder"
            @router-call="routerCall"
          ></block-menu>
        </tab>
      </tabs>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import errorParser from "@/utils/error-parser";
import DefaultPopup from "@/components/modal/DefaultPopup.vue";
import SideMenu from "@/components/SideMenu.vue";
import Notification from "@/components/Notification.vue";
import BlockMenu from "@component/BlockMenu.vue";
import Tabs from "@/components/Tabs.vue";
import Tab from "@/components/Tab.vue";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      selectOrder: 0,
      menuType: 5,
      notificationInfo: {
        title: "삼킴 훈련",
        btnSet: [
          {
            img: "icon_information.svg",
            name: "훈련안내",
            routerUrl: "trainingInfo",
            isActionBtn: false
          },
          {
            img: "icon_record.svg",
            name: "훈련기록",
            routerUrl: "trainingRecord",
            isActionBtn: false
          }
        ]
      },
      tab: [
        {
          title: "아침"
        },
        {
          title: "점심"
        },
        {
          title: "저녁"
        }
      ],
      menuList: [
        {
          img: "infoItem01.png",
          name: " 힘껏 오래 삼키기",
          content: "10번씩 2세트",
          isActive: false,
          isLunch: false,
          isMorning: false,
          isEvening: false,
          morning: null,
          lunch: null,
          evening: null,
          set: null,
          number: null
        },
        {
          img: "infoItem02.png",
          name: "힘껏 음도 높이기",
          content: "10번씩 2세트",
          isActive: true,
          isLunch: false,
          isMorning: false,
          isEvening: false,
          morning: null,
          lunch: null,
          evening: null,
          set: null,
          number: null,
          time: null
        },
        {
          img: "infoItem03.png",
          name: "힘껏 혀 돌리기",
          content: "10번씩 2세트",
          isActive: false,
          isLunch: false,
          isMorning: false,
          isEvening: false,
          morning: null,
          lunch: null,
          evening: null,
          set: null,
          number: null,
          speed: null
        }
      ],
      sideMenuOption: [
        {
          img: "sidebar_item01.png",
          img_active: "sidebar_menu1_active.png",
          img_active_deep: "sidebar_menu1_active_deep.png",
          title: "메인",
          isActive: false,
          link: ""
        },
        {
          img: "sidebar_item02.png",
          img_active: "sidebar_menu2_active.png",
          img_active_deep: "sidebar_menu2_active_deep.png",
          title: "삼킴 평가",
          isActive: false,
          link: "evaluate"
        },
        {
          img: "sidebar_item03.png",
          img_active: "sidebar_menu3_active.png",
          img_active_deep: "sidebar_menu3_active_deep.png",
          title: "삼킴 훈련",
          isActive: true,
          link: "trainingDate"
        },
        {
          img: "sidebar_item05.png",
          img_active: "sidebar_menu5_active.png",
          img_active_deep: "sidebar_menu5_active_deep.png",
          title: "자세법ㆍ식이법",
          isActive: false,
          link: "choiceType"
        },
        {
          img: "sidebar_item06.png",
          img_active: "sidebar_menu6_active.png",
          img_active_deep: "sidebar_menu6_active_deep.png",
          title: "원격 상담",
          isActive: false,
          link: "reservationType"
        },
        {
          img: "sidebar_mypage.png",
          img_active: "sidebar_mypage_active.png",
          img_active_deep: "sidebar_mypage_active.png",
          title: "마이페이지",
          isActive: false,
          link: "mypage"
        }
      ],
      popupSet: {},
      view: {
        trainingDay: null,
        // trainingNumber: null,
        // trainingSet: null,
        trainingTime: null,
        trainingWord: null,
        trainingCompleted: null,
        warn: false,
        limitMorning: false,
        limitLunch: false,
        limitEvening: false,
        isFemale: null,
        isTabOn: false,
        swallowIsCompleted: false,
        turnIsCompleted: false,
        raisingIsCompleted: false,
        vocalizeTime: null
      }
    };
  },
  created() {
    /** select-tab-check
    if (this.$route.query.order !== undefined) {
      console.log("order :", this.$route.query.order);
      this.$nextTick(_ => {
        this.$refs.tabs.selectTab(this.$route.query.order);
      });
    }
    */
    if (history.pushState !== undefined) {
      history.pushState({}, "", location.href);
      window.onpopstate = () => {
        history.pushState({}, "", location.href);
        this.handleGoback();
      };
    }
    setTimeout(() => {
      this.loadComplex();
    }, 700);
  },
  mounted() {
    /**
    this.popTest();
     */
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP"]),
    ...mapActions("util", ["FETCH_ENTRY", "FETCH_USER_INFO"]),
    ...mapActions("training", ["FETCH_TRAINING_INFO", "CHECK_TRAINING"]),
    async getUserInfo() {
      try {
        await this.FETCH_USER_INFO().then(data => {
          console.log("성별정보 :", data.response.gender);
          if (data.response.gender === "남성") {
            this.view.isFemale = false;
          } else if (data.response.gender === "여성") {
            this.view.isFemale = true;
          }
          console.log("t-v", this.view.isFemale);
        });
      } catch (error) {
        console.log("error-parser :", errorParser.parse(error));
        console.log("error :", error);
      }
    },
    handleGoback() {
      if (this.checkPopup) {
        this.SET_POPUP(false);
      } else {
        this.$router.push("/");
      }
    },
    async loadComplex() {
      await this.getTrainingDate();
      await this.checkTraining(this.view.trainingTime, this.view.trainingDay);
      await this.fetchTrainingInfo();
      await this.getUserInfo();
    },
    async checkTraining(time, day) {
      try {
        const factor = {
          time,
          day
        };
        await this.CHECK_TRAINING(factor).then(data => {
          console.log("data :", data);
          this.view.swallowIsCompleted = data.response.swallow.isCompleted;
          this.view.raisingIsCompleted = data.response.raising.isCompleted;
          this.view.turnIsCompleted = data.response.turn.isCompleted;
        });
      } catch (error) {
        console.log("error :", error);
      }
    },
    async fetchTrainingInfo() {
      try {
        await this.FETCH_TRAINING_INFO().then(data => {
          // this.view.trainingNumber = data.response.number;
          // this.view.trainingSet = data.response.set;
          this.view.vocalizeTime = data.response.pitch.time;
          const { swallow, pitch, tongue } = data.response;

          console.log(swallow, pitch, tongue);
          console.log(data.response, "here !!! ");

          this.menuList[0] = {
            ...this.menuList[0],
            content: `${swallow.number}번씩 ${swallow.set}세트`,
            isLunch: swallow.isLunch,
            isEvening: swallow.isEvening,
            isMorning: swallow.isMorning,
            morning: swallow.morning,
            lunch: swallow.lunch,
            evening: swallow.evening,
            set: swallow.set,
            number: swallow.number
          };

          this.menuList[1] = {
            ...this.menuList[1],
            content: `${pitch.number}번씩 ${pitch.set}세트`,
            isLunch: pitch.isLunch,
            isEvening: pitch.isEvening,
            isMorning: pitch.isMorning,
            morning: pitch.morning,
            lunch: pitch.lunch,
            evening: pitch.evening,
            set: pitch.set,
            number: pitch.number
          };

          this.menuList[2] = {
            ...this.menuList[2],
            content: `${tongue.number}번씩 ${tongue.set}세트`,
            isLunch: tongue.isLunch,
            isMorning: tongue.isMorning,
            isEvening: tongue.isEvening,
            morning: tongue.morning,
            lunch: tongue.lunch,
            evening: tongue.evening,
            set: tongue.set,
            number: tongue.number
          };

          // if (swallow.isLunch === true || picth.isLunch === true || tongue.isLunch === true) {
          //   this.view.limitLunch = true;
          // }
          //
          // if (swallow.isEvening === true || picth.isEvening === true || tongue.isEvening === true) {
          //   this.view.limitEvening = true;
          // }
          // for (let i = 0; i < this.menuList.length; i += 1) {
          //   this.menuList[i].content = `${data.response[i].number}번씩 ${data.response[i].set}세트`;
          //   if (data.response[i].swallow.isLunch) {
          //     this.menuList[i].isLunch = true;
          //   }
          //
          //   if (data.response[i].swallow.isEvening) {
          //     this.menuList[i].isEvening = true;
          //   }
          //
          //   if (data.response[i].pitch.isEvening) {
          //     this.menuList[i].isEvening = true;
          //   }
          //
          //   if (data.response[i].tongue.isEvening) {
          //     this.menuList[i].isEvening = true;
          //   }
          //   this.menuList[i].morning = data.response[i].morning;
          //   this.menuList[i].lunch = data.response[i].lunch;
          //   this.menuList[i].evening = data.response[i].evening;
          //   this.menuList[i].set = data.response[i].set;
          //   this.menuList[i].number = data.response[i].number;
          // }

          // if (!data.response.isLunch) {
          //   this.view.limitLunch = true;
          // }
          // if (!data.response.isEvening) {
          //   this.view.limitEvening = true;
          // }

          // console.log('data :', data.response);
          // console.log('검증 :', data.response.evening);

          if (
            !this.menuList[0].isMorning &&
            !this.menuList[1].isMorning &&
            !this.menuList[2].isMorning
          ) {
            console.log(" 리미트 morning 은 트루다 들어옴!");
            this.view.limitMorning = true;
          }
          if (!this.menuList[0].isLunch && !this.menuList[1].isLunch && !this.menuList[2].isLunch) {
            console.log(" 리미트 lunch 은 트루다 들어옴!");
            this.view.limitLunch = true;
          }
          if (
            !this.menuList[0].isEvening &&
            !this.menuList[1].isEvening &&
            !this.menuList[2].isEvening
          ) {
            console.log(" 리미트 evening 은 트루다 들어옴!");
            this.view.limitEvening = true;
          }
          this.view.isTabOn = true;
          this.$nextTick(_ => {
            console.log("탭 셀렉트 - 넥스틱 실행");
            const index = this.getOrderToTime(this.view.trainingTime);
            let str;
            if (index == 0) {
              str = "아침";
            } else if (index == 1) {
              str = "점심";
            } else if (index == 2) {
              str = "저녁";
            }
            this.tabSelected(index, str);
          });
        });
      } catch (error) {
        console.log("error-parser :", errorParser.parse(error));
        console.log("error :", error);
      }
    },
    async getTrainingDate() {
      try {
        await this.FETCH_ENTRY().then(data => {
          /**
           * 05-20 조건 삭제
          if (data.response.trainingCompleted) {
            this.view.trainingDone = true;
          }
          if (data.response.realDay > data.response.day) {
            if (data.response.trainingDay > data.response.lifeDay) {
              this.view.warn = true;
            }
          } else if (data.response.trainingCompleted && !data.response.lifeCompleted) {
            this.view.warn = true;
          }
          */
          this.notificationInfo.title = `삼킴 훈련 | ${data.response.trainingDay}일차`;
          this.view.trainingDay = data.response.trainingDay;
          this.view.trainingTime = data.response.trainingTime;
          this.view.trainingCompleted = data.response.trainingCompleted;
          /** 컴퓨티드 메서드 */
        });
      } catch (error) {
        console.log("error-parser :", errorParser.parse(error));
        console.log("error :", error);
      }
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = "로그인 권한이 필요합니다.";
      this.popupSet.popImg = "warning.svg";
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = "/login";
      this.popupSet.confirmBtnText = "확인";
      this.popupSet.destination = true;
    },
    async beforeEnterUserInfo() {
      try {
        await this.FETCH_USER_INFO().then(data => {
          console.log("성별정보 :", data.response.gender);
          if (data.response.gender === "남성") {
            this.view.isFemale = false;
          } else if (data.response.gender === "여성") {
            this.view.isFemale = true;
          }
          console.log("t-v", this.view.isFemale);
        });
      } catch (error) {
        console.log("error-parser :", errorParser.parse(error));
        console.log("error :", error);
      }
    },
    beforeEnterTrainingInfo(order) {
      switch (order) {
        case 0:
          this.$router.push({
            name: "TrainingSwallow",
            query: {
              type: order,
              time: this.selectOrder,
              day: this.view.trainingDay,
              number: this.menuList[0].number,
              set: this.menuList[0].set
            }
          });
          break;
        case 1:
          this.$router.push({
            name: "TrainingRaising",
            query: {
              type: order,
              time: this.selectOrder,
              day: this.view.trainingDay,
              number: this.menuList[1].number,
              set: this.menuList[1].set,
              vocalizeTime: this.view.vocalizeTime,
              isFemale: this.view.isFemale
            }
          });
          break;
        default:
          this.$router.push({
            name: "TongueTurn",
            query: {
              type: order,
              time: this.selectOrder,
              day: this.view.trainingDay,
              number: this.menuList[2].number,
              set: this.menuList[2].set
            }
          });
      }
    },
    async routerCall(order) {
      await this.getTrainingDate();
      await this.checkTraining(this.view.trainingTime, this.view.trainingDay);
      await this.beforeEnterUserInfo();
      await this.beforeEnterTrainingInfo(order);
      /** 이전일의 삶의 의미를 진행한 경우 */
    },
    tabSelected(order, title) {
      console.log(title);
      const swallowTraining = this.view.swallowIsCompleted ? "" : "힘껏 오래 삼키기";
      const raisingTraining = this.view.raisingIsCompleted ? "" : "힘껏 음도 높이기";
      const turnTraining = this.view.turnIsCompleted ? "" : "힘껏 혀 돌리기";
      if (title === "아침") {
        this.view.trainingTime = "morning";
      } else if (title === "점심") {
        this.view.trainingTime = "lunch";
      } else if (title === "저녁") {
        this.view.trainingTime = "evening";
      }
      console.log("selected-order :", order);
      console.log("trainingTime :", this.view.trainingTime);
      // if (!this.view.trainingCompleted) {
      //   if (this.view.limitLunch) {
      //     if (order === 1 || order === 2) {
      //       this.SET_POPUP(true);
      //       this.popupSet.popImg = 'warning.svg';
      //       this.popupSet.cancelBtnText = '확인';
      //       this.popupSet.nextLink = null;
      //       this.popupSet.confirmBtnText = null;
      //       if (order === 1) {
      //         this.popupSet.content = '예정된 점심 훈련이 없습니다.';
      //       } else {
      //         this.popupSet.content = '예정된 저녁 훈련이 없습니다.';
      //       }
      //     } else {
      //       this.selectOrder = order;
      //       this.$refs.tabs.refSelectTab(order);
      //     }
      //   } else if (this.view.limitEvening) {
      //     if (order === 2) {
      //       this.SET_POPUP(true);
      //       this.popupSet.content = '예정된 저녁 훈련이 없습니다.';
      //       this.popupSet.popImg = 'warning.svg';
      //       this.popupSet.cancelBtnText = '확인';
      //       this.popupSet.nextLink = null;
      //       this.popupSet.confirmBtnText = null;
      //       this.$refs.tabs.refSelectTab(this.selectOrder);
      //     } else if (this.view.trainingTime === 'morning' && order === 1) {
      //       this.SET_POPUP(true);
      //       this.popupSet.content = `[아침]\n${swallowTraining} ${raisingTraining} ${turnTraining}\n 훈련을 진행해주세요.`;
      //       this.popupSet.popImg = 'warning.svg';
      //       this.popupSet.cancelBtnText = '확인';
      //       this.popupSet.nextLink = null;
      //       this.popupSet.confirmBtnText = null;
      //       this.$refs.tabs.refSelectTab(this.selectOrder);
      //     } else if (this.view.trainingTime === 'lunch' && order === 0) {
      //       this.SET_POPUP(true);
      //       this.popupSet.content = '아침 훈련은 이미 완료했습니다. \n 점심 훈련을 진행해주세요.';
      //       this.popupSet.popImg = 'warning.svg';
      //       this.popupSet.cancelBtnText = '확인';
      //       this.popupSet.nextLink = null;
      //       this.popupSet.confirmBtnText = null;
      //       this.$refs.tabs.refSelectTab(this.selectOrder);
      //     } else {
      //       this.selectOrder = order;
      //       this.$refs.tabs.refSelectTab(order);
      //     }
      //   } else if (this.view.trainingTime === 'morning') {
      //     if (order === 0) {
      //       this.selectOrder = order;
      //       this.$refs.tabs.refSelectTab(order);
      //     } else {
      //       this.SET_POPUP(true);
      //       this.popupSet.content = `[아침]\n${swallowTraining} ${raisingTraining} ${turnTraining}\n 훈련을 진행해주세요.`;
      //       this.popupSet.popImg = 'warning.svg';
      //       this.popupSet.cancelBtnText = '확인';
      //       this.popupSet.nextLink = null;
      //       this.popupSet.confirmBtnText = null;
      //       this.$refs.tabs.refSelectTab(this.selectOrder);
      //     }
      //   } else if (this.view.trainingTime === 'lunch') {
      //     if (order === 1) {
      //       console.log('특이점 진입');
      //       this.selectOrder = order;
      //       this.$refs.tabs.refSelectTab(order);
      //     } else {
      //       this.SET_POPUP(true);
      //       this.popupSet.popImg = 'warning.svg';
      //       this.popupSet.cancelBtnText = '확인';
      //       this.popupSet.nextLink = null;
      //       this.popupSet.confirmBtnText = null;
      //       if (order === 0) {
      //         this.popupSet.content = '아침 훈련은 이미 완료했습니다. \n 점심 훈련을 진행해주세요.';
      //       } else {
      //         this.popupSet.content = `[점심]\n${swallowTraining} ${raisingTraining} ${turnTraining}\n 훈련을 진행해주세요.`;
      //       }
      //       this.$refs.tabs.refSelectTab(this.selectOrder);
      //     }
      //   } else if (this.view.trainingTime === 'evening') {
      //     if (order === 2) {
      //       this.selectOrder = order;
      //       this.$refs.tabs.refSelectTab(order);
      //     } else {
      //       this.SET_POPUP(true);
      //       this.popupSet.popImg = 'warning.svg';
      //       this.popupSet.cancelBtnText = '확인';
      //       this.popupSet.nextLink = null;
      //       this.popupSet.confirmBtnText = null;
      //       if (order === 0) {
      //         this.popupSet.content = '아침 훈련은 이미 완료했습니다. \n 저녁 훈련을 진행해주세요.';
      //       } else {
      //         this.popupSet.content = '점심 훈련은 이미 완료했습니다. \n 저녁 훈련을 진행해주세요.';
      //       }
      //       this.$refs.tabs.refSelectTab(this.selectOrder);
      //     }
      //   }
      // } else {
      this.selectOrder = order;
      this.$refs.tabs.refSelectTab(order);
      // }
    }
  },
  beforeDestroy() {
    this.SET_POPUP(false);
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
    getOrderToTime() {
      return time => {
        if (time === "morning") {
          return 0;
        }
        if (time === "lunch") {
          return 1;
        }
        if (time === "evening") {
          return 2;
        }
      };
    }
  },
  components: {
    SideMenu,
    BlockMenu,
    DefaultPopup,
    Notification,
    Tabs,
    Tab
  }
};
</script>
<style lang="scss" scoped>
.app-context {
  .wrap-context {
    width: 870px;
    margin: 22px 122px 0 auto;
    h5.page-title {
      position: relative;
      padding: 20px 0 0 0px;
      @include initfontKor(
        $font-size: 30px,
        $line-height: 40px,
        $font-family: "AppleSDGothicNeoB00",
        $color: #315754,
        $letter-spacing: -0.6px,
        $text-align: left
      );
      margin: 0 0 38px 0;
      &:after {
        content: "";
        background-image: url("~@assets/images/util/mark.png");
        position: absolute;
        width: 26px;
        height: 26px;
        top: 0;
        left: -20px;
      }
    }
    p.notice-message {
      @include initfontKor(
        $font-size: 24px,
        $line-height: 32px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #fd8b11,
        $letter-spacing: -0.48px,
        $text-align: left
      );
      position: relative;
      padding: 0 0 0 20px;
      margin: 10px 0 30px 0;
      &:after {
        content: "-";
        position: absolute;
        left: 0;
      }
    }
    .notificaton-box {
      display: flex;
      width: 100%;
      height: 100px;
      background: #fff;
      padding: 24px 22px 24px 40px;
      justify-content: space-between;
      border-radius: 5px;
      box-shadow: 0 3px 20px 0 rgba(54, 55, 57, 0.15);
      border: solid 0.5px #bbbcc1;
      margin: 0 0 30px 0;
      h4.title {
        @include initfontKor(
          $font-size: 30px,
          $line-height: 52px,
          $font-family: "AppleSDGothicNeoB00",
          $color: #363739,
          $letter-spacing: -0.6px,
          $text-align: left
        );
      }
      button {
        width: 226px;
        height: 52px;
        padding: 0;
        border: 0;
        border-radius: 4px;
        background: #315754;
        @include initfontKor(
          $font-size: 24px,
          $line-height: 31px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #fff,
          $letter-spacing: -0.48px,
          $text-align: center
        );
      }
    }
  }
}
</style>
