<template>
  <div class="app-context join">
    <div class="wrap-context">
      <join-header :currentOrder="Number(this.$route.params.code)"></join-header>
      <div class="join-context mini">
        <div
          class="ab-noti"
          v-if="
            Number(this.$route.params.code) === 3 || Number(this.$route.params.code) === 1
          "
        >
          <p>중복선택 가능</p>
        </div>
        <div class="container">
          <div
            class="personal question-wrap"
            v-if="Number(this.$route.params.code) === 1"
          >
            <div class="question">
              <h5 class="q-title">이름</h5>
              <div class="q-content">
                <div class="input-wrap">
                  <input
                    type="text"
                    v-model="personalInfo.userName.value"
                    @input="checkName()"
                    @blur="checkDuplicate()"
                  />
                </div>
              </div>
            </div>
            <div class="question">
              <h5 class="q-title">성별</h5>
              <div class="q-content">
                <div class="radio-input-wrap">
                  <div class="item">
                    <input
                      type="radio"
                      id="man"
                      v-model="personalInfo.gender"
                      value="0"
                    />
                    <label for="man">남성</label>
                  </div>
                  <div class="item">
                    <input
                      type="radio"
                      id="women"
                      v-model="personalInfo.gender"
                      value="1"
                    />
                    <label for="women">여성</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="question">
              <h5 class="q-title">생년월일</h5>
              <div class="q-content">
                <div class="birth-complex">
                  <!-- birthDate -->
                  <div class="input-wrap">
                    <input
                      type="number"
                      v-model="personalInfo.birthDate.year.value"
                      @input="checkBirthYear()"
                      placeholder="YYYY"
                    /><span>년</span>
                    <input
                      type="number"
                      v-model="personalInfo.birthDate.month.value"
                      @input="checkBirthMonth()"
                      @blur="leaveBirthMonth()"
                      placeholder="MM"
                    /><span>월</span>
                    <input
                      type="number"
                      v-model="personalInfo.birthDate.day.value"
                      @input="checkBirthDay()"
                      @blur="leaveBirthDay()"
                      placeholder="DD"
                    /><span>일</span>
                  </div>
                  <div class="radio-input-wrap">
                    <div class="item">
                      <input
                        type="radio"
                        id="solar"
                        v-model="personalInfo.calendar"
                        value="0"
                      />
                      <label for="solar">양력</label>
                    </div>
                    <div class="item">
                      <input
                        type="radio"
                        id="lunar"
                        v-model="personalInfo.calendar"
                        value="1"
                      />
                      <label for="lunar">음력</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="question">
              <h5 class="q-title">연락처</h5>
              <div class="q-content">
                <div class="input-wrap">
                  <input
                    type="number"
                    v-model="personalInfo.tel.value"
                    @input="checkPhone()"
                    @blur="checkDuplicate()"
                  />
                </div>
              </div>
            </div>
            <div class="question">
              <h5 class="q-title">보호자 연락처</h5>
              <div class="q-content">
                <div class="input-wrap">
                  <input
                    type="number"
                    v-model="personalInfo.guardianTel.value"
                    @input="checkGuardianPhone()"
                  />
                </div>
              </div>
            </div>
            <div class="question">
              <h5 class="q-title">키/몸무게</h5>
              <div class="q-content">
                <div class="physical">
                  <div class="input-wrap">
                    <input
                      type="number"
                      v-model="personalInfo.height.value"
                      maxlength="3"
                      @input="checkHeight()"
                    />
                    <span>cm</span>
                  </div>
                  <div class="input-wrap">
                    <input
                      type="number"
                      v-model="personalInfo.weight.value"
                      maxlength="3"
                      @input="checkWeight()"
                    />
                    <span>kg</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <join-question
            :questionList="list"
            :currentPage="Number(this.$route.params.code)"
            :isVisibleDentureQuestion="view.isVisibleDentureQuestion"
            :isPeriod="view.isPeriod"
            v-if="view.isPrepared"
          >
          </join-question>
        </div>
      </div>
      <div
        class="navigation-btn"
        :class="{ onlyNext: this.$route.params.code === '1' }"
        v-if="view.isPrepared"
      >
        <button type="button" @click="prev()" v-if="this.$route.params.code !== '1'">
          이전
        </button>
        <button
          type="button"
          @click="next()"
          v-text="this.$route.params.code !== '4' ? '다음' : '저장'"
        >
          저장
        </button>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import errorParser from "@/utils/error-parser";
import DefaultPopup from "@/components/modal/DefaultPopup.vue";
import JoinHeader from "@/components/JoinHeader.vue";
import JoinQuestion from "@/components/JoinQuestion.vue";
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";

export default {
  props: {},
  data() {
    return {
      popupSet: {},
      personalInfo: {
        userName: {
          flag: false,
          value: "",
        },
        birthDate: {
          year: {
            flag: false,
            value: "",
          },
          month: {
            flag: false,
            value: "",
          },
          day: {
            flag: false,
            value: "",
          },
          entire: {
            flag: false,
            value: "",
          },
        },
        tel: {
          flag: false,
          value: "",
        },
        guardianTel: {
          flag: false,
          value: "",
        },
        gender: "0",
        calendar: "0",
        height: {
          flag: false,
          value: "",
        },
        weight: {
          flag: false,
          value: "",
        },
      },
      list: [],
      view: {
        isPrepared: false,
        isVisibleDentureQuestion: false,
        isPeriod: false,
        isStoreIng: false,
      },
    };
  },
  created() {
    this.getQuestionList();
    if (history.pushState !== undefined) {
      history.pushState({}, "", location.href);
      window.onpopstate = () => {
        history.pushState({}, "", location.href);
        this.handleGoback();
      };
    }
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        this.getQuestionList();
      }
    },
  },
  methods: {
    ...mapActions("join", ["FETCH_JOIN_QUESTIONLIST", "ACCOUNT_JOIN", "CHECK_DUPLICATE"]),
    ...mapMutations("basic", ["SET_POPUP"]),
    ...mapMutations("join", [
      "SET_PERSONALINFO",
      "SET_QUESTIONLIST1",
      "SET_QUESTIONLIST2",
      "SET_QUESTIONLIST3",
      "SET_QUESTIONLIST4",
    ]),
    leaveBirthMonth() {
      console.log("month - flag :", this.personalInfo.birthDate.month.flag);
      console.log(
        "month - length === 1 :",
        this.personalInfo.birthDate.month.value.length === 1
      );
      console.log("month - value !== 1 :", this.personalInfo.birthDate.month.value !== 1);
      if (
        this.personalInfo.birthDate.month.flag &&
        this.personalInfo.birthDate.month.value.length === 1
      ) {
        this.personalInfo.birthDate.month.value =
          "0" + this.personalInfo.birthDate.month.value;
      }
    },
    leaveBirthDay() {
      console.log("day - flag :", this.personalInfo.birthDate.day.flag);
      console.log(
        "day - length === 1 :",
        this.personalInfo.birthDate.day.value.length === 1
      );
      console.log("day - value !== 1 :", this.personalInfo.birthDate.day.value !== 1);
      if (
        this.personalInfo.birthDate.day.flag &&
        this.personalInfo.birthDate.day.value.length === 1
      ) {
        this.personalInfo.birthDate.day.value =
          "0" + this.personalInfo.birthDate.day.value;
      }
    },
    handleGoback() {
      if (this.checkPopup) {
        this.SET_POPUP(false);
      } else {
        this.$router.push("/login");
      }
    },
    makeAnswerList() {
      const sampleArray = [];
      let arrayWrap;
      for (let i = 0; i < this.GET_QUESTIONLIST1.length; i++) {
        arrayWrap = {
          question: this.GET_QUESTIONLIST1[i].question,
          answer: this.returnChoiceOrder(this.GET_QUESTIONLIST1[i]),
          etcText:
            this.GET_QUESTIONLIST1[i].etcValue !== ""
              ? this.GET_QUESTIONLIST1[i].etcValue
              : null,
        };
        sampleArray.push(arrayWrap);
        console.log("1차 샘플 :", sampleArray);
      }
      for (let j = 0; j < this.GET_QUESTIONLIST2.length; j++) {
        arrayWrap = {
          question: this.GET_QUESTIONLIST2[j].question,
          answer: this.returnChoiceOrder(this.GET_QUESTIONLIST2[j]),
          etcText:
            this.GET_QUESTIONLIST2[j].etcValue !== ""
              ? this.GET_QUESTIONLIST2[j].etcValue
              : null,
        };
        sampleArray.push(arrayWrap);
        console.log("2차 샘플 :", sampleArray);
      }
      for (let k = 0; k < this.GET_QUESTIONLIST3.length; k++) {
        arrayWrap = {
          question: this.GET_QUESTIONLIST3[k].question,
          answer: this.returnChoiceOrder(this.GET_QUESTIONLIST3[k]),
          etcText:
            this.GET_QUESTIONLIST3[k].etcValue !== ""
              ? this.GET_QUESTIONLIST3[k].etcValue
              : null,
        };
        sampleArray.push(arrayWrap);
      }
      for (let l = 0; l < this.GET_QUESTIONLIST4.length; l++) {
        arrayWrap = {
          question: this.GET_QUESTIONLIST4[l].question,
          answer: this.returnChoiceOrder(this.GET_QUESTIONLIST4[l]),
          etcText:
            this.GET_QUESTIONLIST4[l].etcValue !== ""
              ? this.GET_QUESTIONLIST4[l].etcValue
              : null,
        };
        sampleArray.push(arrayWrap);
      }
      /** 마지막 담아주기 */
      console.log("메이크 리스트 끝", sampleArray);
      sampleArray.sort((a, b) => a.question - b.question);
      return sampleArray;
    },
    checkFlag(type) {
      if (type !== "prev" && !this.view.isStoreIng) {
        if (this.$route.params.code === "4") {
          this.view.isStoreIng = true;
        }
        this.popupSet.popImg = "warning.svg";
        this.popupSet.cancelBtnText = "확인";
        this.popupSet.nextLink = null;
        this.popupSet.confirmBtnText = null;
        if (this.$route.params.code === "1") {
          if (
            !this.personalInfo.userName.flag ||
            !this.personalInfo.birthDate.year.flag ||
            !this.personalInfo.birthDate.month.flag ||
            !this.personalInfo.birthDate.day.flag ||
            !this.personalInfo.tel.flag ||
            !this.personalInfo.guardianTel.flag ||
            !this.personalInfo.height.flag ||
            !this.personalInfo.weight.flag
          ) {
            if (!this.personalInfo.userName.flag) {
              this.popupSet.content = "이름을 점검해주세요";
            } else if (!this.personalInfo.birthDate.year.flag) {
              this.popupSet.content = "생년월일 연도를 점검해주세요";
            } else if (!this.personalInfo.birthDate.month.flag) {
              this.popupSet.content = "생년월일 월을 점검해주세요";
            } else if (!this.personalInfo.birthDate.day.flag) {
              this.popupSet.content = "생년월일 일을 점검해주세요";
            } else if (!this.personalInfo.tel.flag) {
              this.popupSet.content = "연락처를 점검해주세요";
            } else if (!this.personalInfo.guardianTel.flag) {
              this.popupSet.content = "보호자 연락처를 점검해주세요";
            } else if (!this.personalInfo.height.flag) {
              this.popupSet.content = "키를 점검해주세요";
            } else if (!this.personalInfo.weight.flag) {
              this.popupSet.content = "몸무게를 점검해주세요";
            }
            this.SET_POPUP(true);
            return false;
          }
        }

        for (let i = 0; i < this.list.length; i++) {
          console.log("check:", this.$route.params.code);
          if (Number(this.$route.params.code) === 1) {
            if (i !== 5 && i !== 7) {
              if (this.list[i].selected === "기타" && this.list[i].etcValue === "") {
                this.popupSet.content = this.makeErrTxt(
                  Number(this.$route.params.code),
                  i
                );
                this.SET_POPUP(true);
                return false;
              }
              if (this.list[i].selected === "") {
                this.popupSet.content = this.makeErrTxt(
                  Number(this.$route.params.code),
                  i
                );
                this.SET_POPUP(true);
                return false;
              }
            } else {
              if (this.list[i].selected[0] === "기타" && this.list[i].etcValue === "") {
                this.popupSet.content = this.makeErrTxt(
                  Number(this.$route.params.code),
                  i
                );
                this.SET_POPUP(true);
                return false;
              }
              if (this.list[i].selected.length < 1) {
                this.popupSet.content = this.makeErrTxt(
                  Number(this.$route.params.code),
                  i
                );
                this.SET_POPUP(true);
                return false;
              }
            }
          } else if (Number(this.$route.params.code) === 2) {
            if (i !== 4 && i !== 5 && i !== 6) {
              if (this.list[i].selected === "") {
                this.popupSet.content = this.makeErrTxt(
                  Number(this.$route.params.code),
                  i
                );
                this.SET_POPUP(true);
                return false;
              }
            } else if (this.list[3].selected === "있음") {
              if (this.list[i].selected === "") {
                this.popupSet.content = this.makeErrTxt(
                  Number(this.$route.params.code),
                  i
                );
                this.SET_POPUP(true);
                return false;
              }
            }
          } else if (Number(this.$route.params.code) === 3) {
            if (this.list[5].selected === "") {
              this.popupSet.content = this.makeErrTxt(Number(this.$route.params.code), 5);
              this.SET_POPUP(true);
              return false;
            }
            if (this.list[5].selected === this.list[5].answerList[0].answerText) {
              if (this.list[6].selected.length < 2) {
                console.log("6번 오류 문항 진입");
                this.popupSet.content = this.makeErrTxt(
                  Number(this.$route.params.code),
                  6
                );
                this.SET_POPUP(true);
                return false;
              }
            }
          } else if (Number(this.$route.params.code) === 4) {
            if (this.list[i].selected === "") {
              this.popupSet.content = this.makeErrTxt(Number(this.$route.params.code), i);
              this.SET_POPUP(true);
              return false;
            }
          }
          /**
          if (this.list[i].selected === "기타" && this.list[i].etcValue === "") {
            this.popupSet.content = this.list[i].questionText + "를 점검해주세요";
            this.SET_POPUP(true);
            return false;
          } else if (this.list[i].selected === "") {
            this.popupSet.content = this.list[i].questionText + "를 점검해주세요";
            this.SET_POPUP(true);
            return false;
          }
           */
        }
      }
      if (this.$route.params.code === "1") {
        this.SET_PERSONALINFO(this.personalInfo);
        this.SET_QUESTIONLIST1(this.list);
      } else if (this.$route.params.code === "2") {
        this.SET_QUESTIONLIST2(this.list);
      } else if (this.$route.params.code === "3") {
        this.SET_QUESTIONLIST3(this.list);
      } else if (this.$route.params.code === "4") {
        this.SET_QUESTIONLIST4(this.list);
      }

      /** mutation 로직 */

      return true;
    },
    loadedData() {
      this.view.isPrepared = true;
    },
    async next() {
      console.log("[next] param :", this.$route.params.code);
      if (this.$route.params.code !== "4") {
        if (this.checkFlag("next")) {
          const url = `/join/${Number(this.$route.params.code) + 1}`;
          this.$router.push(url);
        }
      } else if (!this.view.isStoreIng) {
        if (this.checkFlag("next")) {
          try {
            console.log("플래그 통과 진입");
            const payload = {
              userName: this.personalInfo.userName.value,
              birthDate:
                String(this.personalInfo.birthDate.year.value) +
                String(this.personalInfo.birthDate.month.value) +
                String(this.personalInfo.birthDate.day.value),
              tel: this.personalInfo.tel.value,
              protectorTel: this.personalInfo.guardianTel.value,
              gender: this.personalInfo.gender === "0" ? "남성" : "여성",
              calendar: this.personalInfo.calendar === "0" ? "양력" : "음력",
              height: this.personalInfo.height.value,
              weight: this.personalInfo.weight.value,
              answerList: this.makeAnswerList(),
            };
            console.log("payload :", payload);
            await this.ACCOUNT_JOIN(payload).then((data) => {
              if (data.result) {
                const sampleArray = [];
                this.SET_PERSONALINFO(sampleArray);
                this.SET_QUESTIONLIST1(sampleArray);
                this.SET_QUESTIONLIST2(sampleArray);
                this.SET_QUESTIONLIST3(sampleArray);
                this.SET_QUESTIONLIST4(sampleArray);
                this.SET_POPUP(true);
                this.popupSet.popType = "warn";
                this.popupSet.popImg = "success.svg";
                this.popupSet.content = "회원가입이 완료되었습니다.";
                this.popupSet.cancelBtnText = null;
                this.popupSet.nextLink = "/";
                this.popupSet.confirmBtnText = "확인";
                this.popupSet.destination = true;
              }
              console.log("data :", data);
              this.view.isStoreIng = false;
            });
          } catch (error) {
            this.SET_POPUP(true);
            this.popupSet.content = String(errorParser.parse(error));
            this.popupSet.popImg = "warning.svg";
            this.popupSet.cancelBtnText = "확인";
            this.popupSet.nextLink = null;
            this.popupSet.confirmBtnText = null;
            this.view.isStoreIng = false;
          }
        }
      }
    },
    prev() {
      console.log("[prev] param :", this.$route.params.code);
      if (this.checkFlag("prev")) {
        const url = `/join/${Number(this.$route.params.code) - 1}`;
        this.$router.push(url);
      }
    },
    initViewFlag() {
      this.view.isPrepared = false;
      this.list = [];
    },
    checkHeight() {
      if (this.personalInfo.height.value !== null) {
        this.personalInfo.height.flag = false;
        const height = this.personalInfo.height.value.replace(/\s/gi, "");
        if (height === "") {
          this.personalInfo.height.flag = false;
          return false;
        }
        const isHeight = /^[0-9]{3}$/;
        if (!isHeight.test(height)) {
          this.personalInfo.height.flag = false;
          return false;
        }
        this.personalInfo.height.flag = true;
        return true;
      }
    },
    checkWeight() {
      if (this.personalInfo.weight.value !== null) {
        this.personalInfo.weight.flag = false;
        const weight = this.personalInfo.weight.value.replace(/\s/gi, "");
        if (weight === "") {
          this.personalInfo.weight.flag = false;
          return false;
        }
        const isWeight = /^[0-9]{2,3}$/;
        if (!isWeight.test(weight)) {
          this.personalInfo.weight.flag = false;
          return false;
        }
        this.personalInfo.weight.flag = true;
        return true;
      }
    },
    checkName() {
      if (this.personalInfo.userName.value !== null) {
        this.personalInfo.userName.flag = false;
        const name = this.personalInfo.userName.value.replace(/\s/gi, "");
        if (name === "") {
          this.personalInfo.userName.flag = false;
          return false;
        }
        const isHan = /^[ㄱ-ㅎ가-힣|a-z|A-Z]{2,21}$/;
        if (!isHan.test(name)) {
          this.personalInfo.userName.errorTxt = "한글 및 영문만 입력할 수 있습니다.";
          this.personalInfo.userName.flag = false;
          return false;
        }
        this.personalInfo.userName.flag = true;
        return true;
      }
    },
    checkBirthYear() {
      if (this.personalInfo.birthDate.year.value !== null) {
        this.personalInfo.birthDate.year.flag = false;
        const year = this.personalInfo.birthDate.year.value.replace(/\s/gi, "");
        if (year === "") {
          this.personalInfo.birthDate.year.flag = false;
          return false;
        }
        const isYear = /^[0-9]{4}$/;
        if (!isYear.test(year)) {
          this.personalInfo.birthDate.year.flag = false;
          return false;
        }
        if (Number(year) <= 1900 || Number(year) > this.$moment().format("YYYY")) {
          this.personalInfo.birthDate.year.flag = false;
          return false;
        }
        this.personalInfo.birthDate.year.flag = true;
        return true;
      }
    },
    checkBirthMonth() {
      console.log("비교수", this.personalInfo.birthDate.month.value);
      if (this.personalInfo.birthDate.month.value !== null) {
        this.personalInfo.birthDate.month.flag = false;
        const month = this.personalInfo.birthDate.month.value.replace(/\s/gi, "");
        if (month === "") {
          this.personalInfo.birthDate.month.flag = false;
          return false;
        }
        const monthDate = this.personalInfo.birthDate.month.value;
        if (Number(monthDate) === 0) {
          console.log("0에 진입");
          this.personalInfo.birthDate.month.value = "";
        } else if (monthDate.length > 1 && this.personalInfo.birthDate.month.value < 10) {
          console.log("이쪽에 진입");
          this.personalInfo.birthDate.month.value = this.personalInfo.birthDate.month.value.substr(
            0,
            1
          );
        }
        const ismonth = /^[0-9]{1,2}$/;
        if (!ismonth.test(month)) {
          this.personalInfo.birthDate.month.flag = false;
          return false;
        }
        console.log("논리검증 :", Number(month) <= 0 || Number(month) > 12);
        console.log("뭔대? :", month);
        console.log("모몬스", Number(month) <= 0 || Number(month) > 12);
        if (Number(month) <= 0 || Number(month) > 12) {
          this.personalInfo.birthDate.month.flag = false;
          return false;
        }
        this.personalInfo.birthDate.month.flag = true;
        return true;
      }
    },
    checkBirthDay() {
      if (this.personalInfo.birthDate.day.value !== null) {
        this.personalInfo.birthDate.day.flag = false;
        const day = this.personalInfo.birthDate.day.value.replace(/\s/gi, "");
        if (day === "") {
          this.personalInfo.birthDate.day.flag = false;
          return false;
        }
        const isDay = /^[0-9]{1,2}$/;
        if (!isDay.test(day)) {
          this.personalInfo.birthDate.day.flag = false;
          return false;
        }
        const dayt = `${this.personalInfo.birthDate.year.value}-${this.personalInfo.birthDate.month.value}`;
        /** 2019-07 */
        console.log("dayt", dayt);
        const dayt2 = new Date(dayt);
        console.log(
          "moments: ",
          this.$moment(new Date(this.$moment(dayt2).endOf("month"))).format("YYYY-MM-DD")
        );
        if (
          Number(day) <= 0 ||
          day >
            Number(
              this.$moment(new Date(this.$moment(dayt2).endOf("month")))
                .format("YYYY-MM-DD")
                .split("-")[2]
            )
        ) {
          this.personalInfo.birthDate.day.flag = false;
          return false;
        }
        this.personalInfo.birthDate.day.flag = true;
        return true;
      }
    },
    async checkDuplicate() {
      if (this.personalInfo.tel.flag && this.personalInfo.userName.flag) {
        /** 중복가입 로직 시작 */
        try {
          const payload = {
            userName: this.personalInfo.userName.value,
            tel: this.personalInfo.tel.value,
          };
          await this.CHECK_DUPLICATE(payload).then((data) => {
            console.log("중복Boolean :", data.response.dupCheck);
            if (data.response.dupCheck) {
              this.SET_POPUP(true);
              this.personalInfo.userName.flag = false;
              this.personalInfo.userName.value = "";
              this.personalInfo.tel.flag = false;
              this.personalInfo.tel.value = "";
              this.popupSet.popImg = "warning.svg";
              this.popupSet.content = "이미 가입된 계정입니다.";
              this.popupSet.cancelBtnText = "확인";
              this.popupSet.nextLink = null;
              this.popupSet.confirmBtnText = null;
            }
          });
        } catch (error) {
          this.SET_POPUP(true);
          this.popupSet.content = String(errorParser.parse(error));
          this.popupSet.popImg = "warning.svg";
          this.popupSet.cancelBtnText = "확인";
          this.popupSet.nextLink = null;
          this.popupSet.confirmBtnText = null;
        }
      }
    },
    checkPhone() {
      if (this.personalInfo.tel.value !== null) {
        this.personalInfo.tel.flag = false;
        const tel = this.personalInfo.tel.value.replace(/\s/gi, "");
        if (tel === "") {
          this.personalInfo.tel.flag = false;
          return false;
        }
        const isTel = /^[0-9]{8,11}$/;
        if (!isTel.test(tel)) {
          this.personalInfo.tel.flag = false;
          return false;
        }
        this.personalInfo.tel.flag = true;
        return true;
      }
    },
    checkGuardianPhone() {
      if (this.personalInfo.guardianTel.value !== null) {
        this.personalInfo.guardianTel.flag = false;
        const guardianTel = this.personalInfo.guardianTel.value.replace(/\s/gi, "");
        if (guardianTel === "") {
          this.personalInfo.guardianTel.flag = false;
          return false;
        }
        const isGuardianTel = /^[0-9]{8,11}$/;
        if (!isGuardianTel.test(guardianTel)) {
          this.personalInfo.guardianTel.flag = false;
          return false;
        }
        this.personalInfo.guardianTel.flag = true;
        return true;
      }
    },
    async getQuestionList() {
      this.initViewFlag();
      const param = this.$route.params.code;
      try {
        await this.FETCH_JOIN_QUESTIONLIST().then((data) => {
          for (let i = 0; i < data.response.list.length; i++) {
            if (
              i !== 5 &&
              i !== 6 &&
              i !== 14 &&
              i !== 15 &&
              i !== 16 &&
              i !== 17 &&
              i !== 24
            ) {
              data.response.list[i].selected = "";
            } else if (i === 24) {
              data.response.list[i].selected = [];
            } else {
              data.response.list[i].selected = [];
              data.response.list[i].checkFlagArr = [];
              for (let j = 0; j < data.response.list[i].answerList.length; j++) {
                const sampleObj = {
                  flag: false,
                };
                data.response.list[i].checkFlagArr.push(sampleObj);
              }
            }
            data.response.list[i].etcValue = "";
            if (i === 2 || i === 4 || i === 11 || i === 19) {
              data.response.list[i].listType = 1;
            } else if (i === 0 || i === 1 || i === 3 || i === 29) {
              data.response.list[i].listType = 2;
            } else if (i === 8 || i === 9 || i === 10) {
              data.response.list[i].listType = 3;
            } else if (i === 18) {
              data.response.list[i].listType = 4;
            } else if (
              i === 12 ||
              i === 13 ||
              i === 20 ||
              i === 21 ||
              i === 22 ||
              i === 23 ||
              i === 26 ||
              i === 27 ||
              i === 28
            ) {
              data.response.list[i].listType = 5;
            } else if (i === 7) {
              data.response.list[i].listType = 6;
            } else if (i === 24) {
              data.response.list[i].listType = 7;
            } else if (i === 25) {
              data.response.list[i].listType = 8;
            } else if (i === 5 || i === 6) {
              data.response.list[i].listType = 9;
            } else if (i === 14 || i === 15 || i === 16 || i === 17) {
              data.response.list[i].listType = 10;
            }
          }
          console.log("data: ", data);
          if (param === "1") {
            if (Object.keys(this.GET_PERSONALINFO).length > 0) {
              this.personalInfo = this.GET_PERSONALINFO;
            }
            if (this.GET_QUESTIONLIST1.length > 0) {
              this.list = this.GET_QUESTIONLIST1;
            } else {
              const sampleArr2 = data.response.list.slice(0, 6);
              sampleArr2.push(data.response.list[29]);
              sampleArr2.push(data.response.list[6]);
              sampleArr2.push(data.response.list[7]);
              this.list = sampleArr2;
            }
          } else if (param === "2") {
            if (this.GET_QUESTIONLIST2.length > 0) {
              this.list = this.GET_QUESTIONLIST2;
              if (this.list[3].selected === this.list[3].answerList[0].answerText) {
                this.view.isVisibleDentureQuestion = true;
              }
            } else {
              const sampleArr2 = data.response.list.slice(8, 12);
              sampleArr2.push(data.response.list[26]);
              sampleArr2.push(data.response.list[27]);
              sampleArr2.push(data.response.list[28]);
              sampleArr2.push(data.response.list[12]);
              sampleArr2.push(data.response.list[13]);
              this.list = sampleArr2;
            }
          } else if (param === "3") {
            if (this.GET_QUESTIONLIST3.length > 0) {
              this.list = this.GET_QUESTIONLIST3;
              if (this.list[5].selected === this.list[5].answerList[0].answerText) {
                this.view.isPeriod = true;
                const sampleArr = {
                  year: this.list[6].selected[0],
                  month: this.list[6].selected[1],
                  period: this.list[7].selected,
                };
                console.log("sampleArr ! :", sampleArr);
              }
            } else {
              const sampleArr2 = data.response.list.slice(14, 20);
              sampleArr2.push(data.response.list[24]);
              sampleArr2.push(data.response.list[25]);
              this.list = sampleArr2;
            }
          } else if (param === "4") {
            if (this.GET_QUESTIONLIST4.length > 0) {
              this.list = this.GET_QUESTIONLIST4;
            } else {
              this.list = data.response.list.slice(20, 24);
            }
          }
          console.log("list: ", this.list);
          this.view.isPrepared = true;
        });
      } catch (error) {
        console.log("error: ", error);
      }
    },
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
    ...mapGetters("join", [
      "GET_PERSONALINFO",
      "GET_QUESTIONLIST1",
      "GET_QUESTIONLIST2",
      "GET_QUESTIONLIST3",
      "GET_QUESTIONLIST4",
    ]),
    makeErrTxt() {
      return (section, order) => {
        if (section === 1) {
          if (order === 0) {
            return "최종학력을 선택해 주세요";
          }
          if (order === 1) {
            return "주거형태를 선택해 주세요";
          }
          if (order === 2) {
            return "월 평균 가구 소득을 선택해 주세요";
          }
          if (order === 3) {
            return "직업을 선택해 주세요";
          }
          if (order === 4) {
            return "결혼 상태를 선택해 주세요";
          }
          if (order === 5) {
            return "현재 가족 구성원을 선택해 주세요";
          }
          if (order === 6) {
            return "종교를 선택해 주세요";
          }
          if (order === 7) {
            return "공적 연금 및 기초생활 급여를 선택해 주세요";
          }
          if (order === 8) {
            return "사회경제적 지위를 선택해 주세요";
          }
        } else if (section === 2) {
          if (order === 0) {
            return "어금니 항목을 입력해 주세요";
          }
          if (order === 1) {
            return "앞니 항목을 입력해 주세요";
          }
          if (order === 2) {
            return "총 결손치 항목을 입력해 주세요";
          }
          if (order === 3) {
            return "틀니 항목을 선택해 주세요";
          }
          if (order === 4) {
            return "질문에 대한 답을 선택해 주세요";
          }
          if (order === 5) {
            return "질문에 대한 답을 선택해 주세요";
          }
          if (order === 6) {
            return "질문에 대한 답을 선택해 주세요";
          }
          if (order === 7) {
            return "질문에 대한 답을 선택해 주세요";
          }
          if (order === 8) {
            return "질문에 대한 답을 선택해 주세요";
          }
        } else if (section === 3) {
          if (order === 0) {
          } else if (order === 1) {
          } else if (order === 2) {
          } else if (order === 3) {
          } else if (order === 4) {
          } else if (order === 5) {
            return "삼킴장애 진단 여부를 선택해 주세요";
          } else if (order === 6) {
            return "삼킴장애 진단 시기를 입력해 주세요";
          } else if (order === 7) {
            return "삼킴장애 진단 시기를 입력해 주세요";
          }
        } else if (section === 4) {
          if (order === 0) {
            return "질문에 대한 답을 선택해 주세요";
          }
          if (order === 1) {
            return "질문에 대한 답을 선택해 주세요";
          }
          if (order === 2) {
            return "질문에 대한 답을 선택해 주세요";
          }
          if (order === 3) {
            return "질문에 대한 답을 선택해 주세요";
          }
        }
      };
    },
    returnChoiceOrder() {
      return (text) => {
        console.log("text :", text);
        if (
          text.question === 9 ||
          text.question === 10 ||
          text.question === 11 ||
          text.question === 19 ||
          text.question === 25 ||
          text.question === 26
        ) {
          return text.selected;
        }
        if (
          text.question === 6 ||
          text.question === 7 ||
          text.question === 15 ||
          text.question === 16 ||
          text.question === 17 ||
          text.question === 18
        ) {
          if (text.selected.length === 0) {
            return "";
          }
          if (text.selected.length < 2) {
            for (let i = 0; i < text.answerList.length; i++) {
              if (text.answerList[i].answerText.indexOf(text.selected[0]) !== -1) {
                return i;
              }
            }
          } else {
            const sampleArr = [];
            for (let i = 0; i < text.answerList.length; i++) {
              for (let j = 0; j < text.selected.length; j++) {
                if (text.answerList[i].answerText.indexOf(text.selected[j]) !== -1) {
                  sampleArr.push(i);
                }
              }
            }
            return sampleArr;
            /**
            return text.selected;
             */
          }
        } else {
          for (let i = 0; i < text.answerList.length; i++) {
            console.log("text.answerList :", text.answerList[i]);
            if (text.answerList[i].answerText.indexOf(text.selected) !== -1) {
              return i;
            }
          }
        }
      };
    },
  },
  components: {
    JoinHeader,
    DefaultPopup,
    JoinQuestion,
  },
};
</script>
<style lang="scss" scoped>
@import "@assets/css/join";
</style>
