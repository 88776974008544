<template>
  <div class="range-date">
    <div
      class="set-calendar"
      @click="callCalendarPop(1)"
      :class="{ existData: this.beginDate !== '' && this.beginDate !== '시작일' }"
    >
      <span class="date" v-text="beginDate" />
    </div>
    <span class="separate">~</span>
    <div
      class="set-calendar"
      @click="callCalendarPop(2)"
      :class="{ existData: this.endDate !== '' && this.endDate !== '종료일' }"
    >
      <span class="date" v-text="endDate" />
    </div>
    <div class="btn-wrap" @click="reloadResult()">
      <button type="button"></button>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      beginDate: "" || "시작일",
      endDate: "" || "종료일"
    };
  },
  created() {},
  methods: {
    initBeginDate(date) {
      this.beginDate = date;
    },
    initEndDate(date) {
      this.endDate = date;
    },
    callCalendarPop(order) {
      this.$emit("call-calendar-pop", order);
    },
    reloadResult() {
      if (
        this.beginDate === "" ||
        this.endDate === "" ||
        this.beginDate === "시작일" ||
        this.endDate === "종료일"
      ) {
        this.$emit("reload-result");
      } else {
        const sampleObj = {
          gte: this.beginDate,
          lte: this.endDate
        };
        this.$emit("reload-result", sampleObj);
      }
    }
  },
  computed: {},
  components: {}
};
</script>
<style lang="scss" scoped>
.range-date {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 20px 0;
  .set-calendar {
    position: relative;
    padding: 0 10px;
    border-radius: 2px;
    width: 150px;
    border: solid 1px #eaebf1;
    background-color: #ffffff;
    cursor: pointer;
    &:after {
      content: "";
      background-image: url("~@assets/images/util/icon_calendar_color.svg");
      width: 30px;
      height: 30px;
      right: 10px;
      top: calc(50% - 1.5px);
      transform: translateY(-50%);
      position: absolute;
    }
    &.existData {
      span {
        @include initfontKor(
          $font-size: 18px,
          $line-height: 30px,
          $font-family: "AppleSDGothicNeoR00",
          $color: #fd8b11,
          $letter-spacing: -0.44px,
          $text-align: left
        );
      }
    }
  }
  .btn-wrap {
    width: 40px;
    height: 40px;
    margin: 0 0 0 10px;
    position: relative;
    background: $pointcolor__orange;
    cursor: pointer;
    button {
      border: 0;
      padding: 0;
      background-image: url("~@assets/images/util/icon_search.svg");
      width: 40px;
      height: 40px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      cursor: pointer;
    }
  }
  span {
    @include initfontKor(
      $font-size: 22px,
      $line-height: 36px,
      $font-family: "AppleSDGothicNeoR00",
      $color: #363739,
      $letter-spacing: -0.44px,
      $text-align: left
    );
    &.date {
      color: $pointcolor__orange;
    }
    &.separate {
      display: block;
      margin: 0 15px;
    }
  }
}
</style>
