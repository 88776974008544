<template>
  <div class="notification">
    <p class="title" v-text="notificationInfo.title"></p>
    <div class="btn-wrap">
      <div
        class="item"
        v-for="(btnItem, index) in notificationInfo.btnSet"
        :key="index + 'A'"
        @click="goDetail(btnItem.routerUrl, btnItem.isActionBtn)"
      >
        <div class="img-wrap">
          <img :src="require(`@/assets/images/util/${btnItem.img}`)" />
        </div>
        <p class="name" v-text="btnItem.name"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    notificationInfo: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    goDetail(url, isActionBtn) {
      if (isActionBtn) {
        this.$emit('share-pop-call');
      } else {
        this.$router.push(url);
      }
    },
  },
  computed: {},
  components: {},
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 768px) {
  .app-context.share .wrap-context .notification {
    p.title {
      font-size: 26px;
      line-height: 22px;
    }
    @media screen and (max-width: 480px) {
      p.title {
        font-size: 16px;
      }

    }
  }
}
.notification {
  display: flex;
  justify-content: space-between;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  background: #fff;
  overflow: hidden;
  padding: 10px 20px;
  margin: 0 0 145px 0;
  &.low-margin {
    margin: 0;
  }
  p.title {
    @include initfontKor(
      $font-size: 26px,
      $line-height: 42px,
      $font-family: "AppleSDGothicNeoB00",
      $color: #363739,
      $letter-spacing: -0.52px,
      $text-align: left
    );
    /** padding: 0 0 0 15px; */
    padding: 0;
  }
  .btn-wrap {
    display: flex;
    align-items: center;
    .item {
      margin: 0 0 0 20px;
      background: #fd8b11;
      padding: 0 20px 0 10px;
      display: flex;
      cursor: pointer;
      align-items: center;
      height: 40px;
      border-radius: 2px;
      &:first-of-type {
        margin: 0;
      }
      .img-wrap {
        margin: 0 8px 0 0;
        img {
        }
      }
      p.name {
        @include initfontKor(
          $font-size: 22px,
          $line-height: 36px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #fff,
          $letter-spacing: -0.44px,
          $text-align: center
        );
      }
    }
  }
}
</style>
