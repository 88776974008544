var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-context flex deep"},[_c('side-menu',{attrs:{"sideMenuOption":_vm.sideMenuOption,"sideMenuDeep":_vm.sideMenuDeep},on:{"is-unauthorized":_vm.isUnAuthorized}}),_c('div',{staticClass:"wrap-context"},[_c('notification',{staticClass:"low-margin",attrs:{"notificationInfo":_vm.notificationInfo}}),(_vm.view.dataLoaded)?_c('div',{staticClass:"camera-context"},[_c('div',{staticClass:"training-notification",class:{
          active: _vm.animate.value !== null,
          prepare: _vm.animate.value === '준비',
          start: _vm.animate.value === '시작',
        }},[_c('span',{domProps:{"textContent":_vm._s(_vm.animate.value)}})]),_c('div',{staticClass:"item-box holder"},[_c('div',{staticClass:"animator-wrap",class:{ active: _vm.animate.value !== null, pause: _vm.plyrValue.isViewMilestone }},[(_vm.plyrValue.isViewMilestone)?_c('div',{staticClass:"animator"},[_c('div',{staticClass:"btn-wrap"},[_c('div',{staticClass:"item"},[_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.btnClick('prepare')}}},[_vm._v(" 연습 음성 듣기 ")])]),(
                  _vm.plyrValue.currentSetCount !== _vm.plyrValue.setCount ||
                  _vm.plyrValue.currentRepeatCount !== _vm.plyrValue.repeatCount
                )?_c('div',{staticClass:"item"},[(_vm.view.isChoiceMileStone)?_c('button',{attrs:{"type":"button"},on:{"click":function($event){return _vm.btnClick('training')}}},[_vm._v("훈련 시작하기")]):_c('button',{attrs:{"type":"button"},domProps:{"textContent":_vm._s(
                    _vm.view.pastCurrentRepeat !== null
                      ? '훈련 시작하기'
                      : '다음 세트 시작하기'
                  )},on:{"click":function($event){return _vm.btnClick('training')}}})]):_vm._e()])]):_c('div',{staticClass:"animator"})]),_c('div',{staticClass:"video-player2 inAudio",staticStyle:{"display":"none"}},[_c('vue-plyr',{ref:"plyr2",attrs:{"options":_vm.plyrOption2}},[_c('audio',{attrs:{"controls":"","crossorigin":"","playsinline":""}},[_c('source',{attrs:{"size":"1080","src":require("@/assets/audio/bellsound.mp3"),"type":"audio/mp3"}})])])],1),(_vm.view.loadDone)?_c('div',{staticClass:"video-player inAudio"},[_c('vue-plyr',{ref:"plyr",attrs:{"options":_vm.plyrOption}},[_c('audio',{attrs:{"controls":"","crossorigin":"","playsinline":""}},[_c('source',{attrs:{"size":"1080","src":require(("@/assets/audio/raising/" + (_vm.plyrValue.src))),"type":"audio/mp3"}})])])],1):_vm._e()]),_c('div',{staticClass:"item-box activated"},[_vm._m(0),_c('div',{staticClass:"training-status"},[_vm._m(1),_vm._l((_vm.plyrValue.setCount),function(set,setIndex){return _c('div',{key:setIndex + 'a',staticClass:"item"},[_c('h3',{staticClass:"i-header",domProps:{"textContent":_vm._s(setIndex + 1 + '세트')}}),_c('div',{staticClass:"i-content"},_vm._l((_vm.plyrValue.repeatCount),function(repeat,repeatIndex){return _c('span',{key:repeatIndex + 'a',staticClass:"num",class:{
                  active:
                    (_vm.plyrValue.currentRepeatCount !== null &&
                      repeatIndex + 1 <= _vm.plyrValue.currentRepeatCount &&
                      _vm.plyrValue.currentSetCount >= setIndex + 1) ||
                    setIndex + 1 < _vm.plyrValue.currentSetCount,
                },domProps:{"textContent":_vm._s(repeatIndex + 1)}})}),0)])})],2)])]):_vm._e()],1),(_vm.checkPopup)?_c('default-popup',{attrs:{"popupSet":_vm.popupSet},on:{"start-protocol":_vm.initProtocol}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"info"},[_c('div',{staticClass:"bg-img"},[_c('img',{attrs:{"src":require("@assets/images/util/information.svg")}})]),_c('div',{staticClass:"item-wrap"},[_c('div',{staticClass:"item"},[_c('h3',{staticClass:"title"},[_vm._v("주의사항")]),_c('div',{staticClass:"orderList"},[_c('p',[_vm._v("빨간 선에 맞추지 않아도 됩니다.")]),_c('p',[_vm._v("최대한 본인이 낼 수 있는 높은 음을 내세요.")])])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item"},[_c('h3',{staticClass:"i-header"},[_vm._v("음도")]),_c('div',{staticClass:"i-content relative"},[_c('div',{staticClass:"input-wrap"}),_c('p',{staticClass:"unit"},[_vm._v("Hz")])])])}]

export { render, staticRenderFns }