<template>
  <div class="info-area">
    <div class="ab-notification">
      <p v-text="information.notification" />
    </div>
    <div class="information">
      <h3 class="question-title" v-text="information.question" />
      <h3 class="answer-title" v-text="information.answer" />
      <p class="content" v-html="computedLine(information.content)" />
      <div
        class="info-gallery"
        :class="{ fourth: information.referenceImgList.length > 3 }"
      >
        <div
          class="img-wrap"
          v-for="(infoGallery, infoGalleryIndex) in information.referenceImgList"
          :key="infoGalleryIndex + 'A'"
        >
          <img :src="`/information/diet/${infoGallery}`" v-if="!readOnly" />
          <img :src="`/information/diet/big_${infoGallery}`" v-else />
        </div>
      </div>
      <div
        class="mini-btn"
        v-if="information.btnTxt !== null && !this.readOnly"
        @click="callInforPop(information.btnTxt)"
      >
        <p v-text="information.btnTxt" />
      </div>
    </div>
    <div class="gallery" v-if="!view.limitedGallery">
      <h3 class="title" v-text="information.galleryTitle" />
      <!-- 있다 없다 분기 -->
      <div class="item-wrap">
        <div
          class="item-box"
          v-for="(galleyItem, index) in galleryAttachment"
          :key="index + 'A'"
        >
          <div class="item" v-if="galleyItem.url !== ''">
            <div class="close" @click="initImg(index)" v-if="!readOnly">
              <img src="@/assets/images/util/icon_delete.svg" />
            </div>
            <div class="img-wrap" v-if="galleyItem.origin">
              <img :src="'http://20.194.31.39:8000' + galleyItem.url.image" />
              <!-- <img :src="galleryAttachment[index].url" /> -->
            </div>
            <div class="img-wrap" v-else>
              <img :src="galleyItem.url" />
              <!-- <img :src="galleryAttachment[index].url" /> -->
            </div>
          </div>
          <div class="item example" @click="attachmentFile(index)" v-else>
            <div class="img-wrap" v-if="!readOnly">
              <img src="/information/icon_camera.svg" />
            </div>
            <button type="button" @click="attachmentFile(index)" v-if="!readOnly">
              사진선택
            </button>
          </div>
          <input
            class="hidden file-upload"
            type="file"
            multiple=""
            @change="onFileChange(index, $event)"
          />
        </div>
      </div>
      <div class="trans-btn" v-if="!readOnly">
        <button type="button" @click="transBtn()">사진 저장하기</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    information: {
      type: Object,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      galleryAttachment: [
        {
          flag: false,
          source: null,
          url: '',
          filename: '',
          isDeleted: false,
        },
        {
          flag: false,
          source: null,
          url: '',
          filename: '',
          isDeleted: false,
        },
      ],
      view: {
        limitedGallery: false,
      },
    };
  },
  created() {
    this.initGalleryImgList();
  },
  mounted() {},
  methods: {
    callInforPop(btnName) {
      this.$emit('call-info-pop', btnName);
    },
    initImg(order) {
      const isStoredServer = this.galleryAttachment[order].origin;
      this.galleryAttachment[order].origin = false;
      this.galleryAttachment[order].flag = false;
      this.galleryAttachment[order].source = null;
      this.galleryAttachment[order].url = '';
      this.galleryAttachment[order].filename = '';
      this.galleryAttachment[order].isDeleted = true;
      document
        .querySelector('.gallery .item-wrap')
        .childNodes[order].querySelector('input.file-upload').value = '';
      if (isStoredServer) {
        const transObj = {
          id: this.galleryAttachment[order].originId,
          order: order + 1,
          deleteFlag: true,
        };

        this.$emit('delete-img-call', transObj);
      }
    },
    initGalleryImgList() {
      console.log('배열의 길이 :', this.information.galleryImgList.length);
      for (let i = 0; i < this.information.galleryImgList.length; i++) {
        if (this.information.galleryImgList[i].order === 1) {
          this.galleryAttachment[0].origin = true;
          this.galleryAttachment[0].flag = true;
          this.galleryAttachment[0].url = this.information.galleryImgList[i];
          this.galleryAttachment[0].originId = this.information.galleryImgList[i].imageId;
        } else if (this.information.galleryImgList[i].order === 2) {
          this.galleryAttachment[1].origin = true;
          this.galleryAttachment[1].flag = true;
          this.galleryAttachment[1].url = this.information.galleryImgList[i];
          this.galleryAttachment[1].originId = this.information.galleryImgList[i].imageId;
        }
      }
      if (this.information.galleryImgList.length < 1 && this.readOnly) {
        this.view.limitedGallery = true;
      }
    },
    attachmentFile(order) {
      let parentNode;
      if (order === 0) {
        parentNode = document.querySelector(
          '.gallery .item-wrap .item-box:nth-of-type(1)',
        );
        parentNode.querySelector('input.file-upload').click();
      } else if (order === 1) {
        parentNode = document.querySelector(
          '.gallery .item-wrap .item-box:nth-of-type(2)',
        );
        parentNode.querySelector('input.file-upload').click();
      }
    },
    transBtn() {
      this.$emit('img-uploaded', this.galleryAttachment);
    },
    onFileChange(order, event) {
      console.log('order :', order);
      const getFile = event.target.files;
      const getFileUrl = URL.createObjectURL(event.target.files[0]);
      this.galleryAttachment[order].origin = false;
      this.galleryAttachment[order].flag = true;
      this.galleryAttachment[order].source = event.target.files[0];
      this.galleryAttachment[order].filename = getFile[0].name;
      this.galleryAttachment[order].url = getFileUrl;
      this.galleryAttachment[order].isDeleted = false;
    },
  },
  computed: {
    computedLine() {
      return (text) => text.split('\n').join('<br />');
    },
  },
  components: {},
};
</script>
<style lang="scss" scoped>
@media screen and (min-width: 1024px) {
  .app-context.share {
    .info-area {
      .gallery {
        .item-wrap {
          .item-box {
            .item {
              width: 380px;
              height: 430px;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .app-context.share {
    .info-area {
      .gallery {
        .item-wrap {
          .item-box {
            .item {
              width: 310px;
              height: 280px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .app-context.share {
    .info-area {
      .ab-notification {
        p {
          font-size: 14px;
          line-height: 24px;
          white-space: nowrap;
        }
      }
      .information {
        h3.question-title {
          font-size: 20px;
          line-height: 24px;
          padding: 0 40px;
          &:after {
            width: 31px;
            height: 31px;
            background-image: url("~@assets/images/util/question_small.svg");
          }
        }
        h3.answer-title {
          font-size: 20px;
          line-height: 24px;
          padding: 0 40px;
          &:after {
            width: 31px;
            height: 31px;
            background-image: url("~@assets/images/util/answer_small.svg");
          }
        }
        p.content {
          font-size: 18px;
          line-height: 24px;
          padding: 0 40px;
        }
        .info-gallery {
          padding: 0 40px;
          flex-wrap: wrap;
          .img-wrap {
            max-width: 100%;
            margin: 16px 0 0 0;
            &:first-of-type {
              margin: 0;
            }
          }
        }
        .mini-btn {
          margin: 0 0 0 40px;
          p {
            font-size: 15px;
            line-height: 34px;
            padding: 0 10px 0 30px;
            &:after {
              width: 21px;
              height: 21px;
              background-size: cover;
            }
          }
        }
      }
      .gallery {
        h3.title {
          font-size: 18px;
        }
        .item-wrap {
          flex-wrap: wrap;
          .item-box {
            width: 100%;
            margin: 20px 0 0 0;
            padding: 10px;
            &:first-of-type {
              margin: 0;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .app-context.share {
    .info-area {
      .gallery {
        .item-wrap {
          .item-box {
            .item {
              width: 290px;
              height: 255px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .app-context.share {
    .info-area {
      .information {
        padding: 12px;
      }
      .gallery {
        .item-wrap {
          .item-box {
            .item {
              width: 190px;
              height: 172px;
            }
          }
        }
      }
    }
  }
}
.share {
  .info-area {
    display: block;
    padding: 0;
    background: transparent;
    .information {
      width: 100%;
      padding: 20px 20px;
      background: #fff;
      border: 1px solid #eaebf1;
      border-radius: 5px;
      p.content,
      .info-gallery {
        padding: 0 55px;
      }
      &:after {
        display: none;
      }
    }
    .gallery {
      width: 100%;
      margin: 105px 0 0 0;

      .item-wrap {
        display: flex;
        padding: 20px 20px;
        background: #fff;
        border: 1px solid #eaebf1;
        border-radius: 5px;
        .item-box {
          width: calc(50% - 7px);
          margin: 0 0 0 14px;
          display: flex;
          justify-content: center;
          padding: 25px;
          border: 1px solid #eaebf1;
          border-radius: 5px;
          overflow: hidden;
          &:first-of-type {
            margin: 0;
          }
          .item {
            width: inherit;
            height: inherit;
            border: 0;
            border-radius: 0;
          }
        }
      }
      h3.title {
        margin: 0 0 10px 0;
      }
    }
  }
}
.info-area {
  display: flex;
  background: #fff;
  padding: 28px 20px;
  position: relative;
  .ab-notification {
    position: absolute;
    width: 100%;
    bottom: -40px;
    right: 0;
    p {
      @include initfontKor(
        $font-size: 18px,
        $line-height: 28px,
        $font-family: "AppleSDGothicNeoR00",
        $color: #315754,
        $letter-spacing: -0.36px,
        $text-align: right
      );
    }
  }
  .information {
    width: calc(100% - 245px);
    padding: 0 72px 0 0;
    position: relative;
    &:after {
      content: "";
      width: 1px;
      height: 100%;
      background-repeat: repeat-y;
      position: absolute;
      right: 39.5px;
      background-image: url("~@assets/images/util/dottedline-short.svg");
      top: 0;
    }
    h3.question-title {
      position: relative;
      margin: 0 0 15px 0;
      padding: 0 0 0 55px;
      @include initfontKor(
        $font-size: 30px,
        $line-height: 46px,
        $font-family: "AppleSDGothicNeoB00",
        $color: #fd8b11,
        $letter-spacing: -0.6px,
        $text-align: left
      );
      &:after {
        content: "";
        position: absolute;
        width: 46px;
        height: 46px;
        background-image: url("~@assets/images/util/question.svg");
        left: 0;
        top: 0;
      }
    }
    h3.answer-title {
      position: relative;
      margin: 0 0 15px 0;
      padding: 0 0 0 55px;
      @include initfontKor(
        $font-size: 30px,
        $line-height: 46px,
        $font-family: "AppleSDGothicNeoB00",
        $color: #363739,
        $letter-spacing: -0.6px,
        $text-align: left
      );
      &:after {
        content: "";
        position: absolute;
        width: 46px;
        height: 46px;
        background-image: url("~@assets/images/util/answer.svg");
        left: 0;
        top: 0;
      }
    }
    p.content {
      margin: 0 0 20px 0;
      padding: 0 0 0 55px;
      @include initfontKor(
        $font-size: 26px,
        $line-height: 36px,
        $font-family: "AppleSDGothicNeoR00",
        $color: #363739,
        $letter-spacing: -0.52px,
        $text-align: left
      );
      b {
        font-weight: normal;
        color: #fd8b11;
      }
    }
    .info-gallery {
      display: flex;
      margin: 0 0 15px 0;
      padding: 0 0 0 55px;
      &.fourth {
        .img-wrap {
          margin: 0;
          max-width: 25%;
        }
      }
      .img-wrap {
        margin: 0 0 0 5px;
        max-width: calc((100% / 3) - 3.5px);
        &:first-of-type {
          margin: 0;
        }
        img {
          width: 100%;
        }
      }
    }
    .tooltip {
      margin: 0 0 20px 0;
      padding: 0 0 0 55px;
      p.hint {
        position: relative;
        padding: 0 0 0 15px;
        @include initfontKor(
          $font-size: 24px,
          $line-height: 34px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #363739,
          $letter-spacing: -0.48px,
          $text-align: left
        );
        &:after {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          background: #315754;
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }
      }
    }
    .mini-btn {
      position: relative;
      width: fit-content;
      padding: 0;
      margin: 0 0 0 55px;
      cursor: pointer;
      p {
        display: block;
        padding: 0 10px 0 35px;
        border-radius: 20px;
        border: solid 1.5px #fd8b11;
        @include initfontKor(
          $font-size: 22px,
          $line-height: 36px,
          $font-family: "AppleSDGothicNeoB00",
          $color: #fd8b11,
          $letter-spacing: -0.44px,
          $text-align: left
        );
        &:after {
          content: "";
          position: absolute;
          left: 5px;
          width: 26px;
          height: 26px;
          top: 50%;
          transform: translateY(-50%);
          background-image: url("/information/icon_information.svg");
        }
      }
    }
  }
  .gallery {
    width: 245px;
    h3.title {
      position: relative;
      margin: 0 0 20px 0;
      padding: 0 0 0 35px;
      @include initfontKor(
        $font-size: 26px,
        $line-height: 34px,
        $font-family: "AppleSDGothicNeoB00",
        $color: #363739,
        $letter-spacing: -0.6px,
        $text-align: left
      );
      &:after {
        content: "";
        position: absolute;
        width: 26px;
        height: 26px;
        background-image: url("~@assets/images/util/mark_color.svg");
        left: 0;
        top: 0;
      }
    }
    .item-wrap {
      margin: 0 0 10px 0;
      .item-box {
        margin: 10px auto 0 0;
        &:first-of-type {
          margin: 0 auto 0 0;
        }
        input.hidden {
          display: none;
        }
        .item {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          width: 200px;
          height: 200px;
          position: relative;
          border-radius: 4px;
          border: 1px solid #d2d4dc;
          overflow: hidden;
          .close {
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
            background: rgba(234, 234, 234, 0.75);
            border-radius: 4px;
            overflow: hidden;
            img {
            }
          }
          .img-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            width: inherit;
            height: inherit;
            &.cursor {
              cursor: pointer;
            }
          }
          &.example {
            border-radius: 5px;
            border: solid 1px #d2d4dc;
            background-color: #ffffff;
            align-items: inherit;
            .img-wrap {
              max-width: 200px;
              max-height: 200px;
              height: initial;
              display: flex;
              justify-content: center;

              img {
                width: initial;
                height: initial;
              }
            }
            button {
              margin: 0;
              border: 0;
              height: 36px;
              background: transparent;
              padding: 0;
              cursor: pointer;
              @include initfontKor(
                $font-size: 22px,
                $line-height: 36px,
                $font-family: "AppleSDGothicNeoM00",
                $color: #315754,
                $letter-spacing: -0.44px,
                $text-align: center
              );
            }
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .trans-btn {
      width: 200px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #315754;
      border-radius: 4px;
      overflow: hidden;
      margin: 0;
      button {
        cursor: pointer;
        width: 100%;
        height: 100%;
        border: 0;
        padding: 0;
        background: transparent;
        @include initfontKor(
          $font-size: 22px,
          $line-height: 40px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #fff,
          $letter-spacing: -0.44px,
          $text-align: center
        );
      }
    }
  }
}
</style>
