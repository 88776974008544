<template>
  <div class="app-context flex deep">
    <side-menu
      :sideMenuOption="sideMenuOption"
      :sideMenuDeep="sideMenuDeep"
      @is-unauthorized="isUnAuthorized"
    ></side-menu>
    <div class="wrap-context">
      <notification
        class="low-margin"
        :notificationInfo="notificationInfo"
      ></notification>
      <swiper
        :swiperList="swiperList"
        :isVocalRecord="true"
        @question-registered="askSubmit()"
        @app-protocol-vocalize="protoVocalize"
        @app-protocol-complex="protoVocalizeComplex"
        @next-lock-vocalize="nextLock"
        @inspect-pop="inspectPop"
        @return-currentpage="returnCurrentPage"
        ref="mySwiperParent"
      ></swiper>
    </div>
    <default-popup
      v-if="checkPopup"
      :popupSet="popupSet"
      @api-called="questionRegistered"
      @record-api-called="recordApiCall"
      @record-api-called-cancle="recordApiCancle"
      @history-called="historyCall"
    />
  </div>
</template>

<script>
import errorParser from '@/utils/error-parser';
import DefaultPopup from '@/components/modal/DefaultPopup';
import SideMenu from '@/components/SideMenu.vue';
import Notification from '@/components/Notification.vue';
import Swiper from '@component/Swiper.vue';
import { mapMutations, mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      selectOrder: 0,
      dummyTxt: [
        {
          value: null,
        },
        {
          value: null,
        },
        {
          value: null,
        },
        {
          value: null,
        },
        {
          value: null,
        },
      ],
      swiperList: {
        vocalization: [
          {
            questionType: 22,
            title: '아~',
            isOnlyTime: true,
            selectedVal: '' /** 특수한 경우로 swiper-makeResult를 수정해야함 */,
            isLast: true,
          },
          {
            questionType: 8,
            title: '퍼',
            selectedVal: '' /** 특수한 경우로 swiper-makeResult를 수정해야함 */,
            startOrder: 1,
          },
          {
            questionType: 8,
            title: '터',
            selectedVal: '' /** 특수한 경우로 swiper-makeResult를 수정해야함 */,
            startOrder: 2,
          },
          {
            questionType: 8,
            title: '커',
            selectedVal: '' /** 특수한 경우로 swiper-makeResult를 수정해야함 */,
            startOrder: 3,
          },
          {
            questionType: 30,
            title: '특이사항을 기입해주세요',
            selectedVal: '' /** 특수한 경우로 swiper-makeResult를 수정해야함 */,
            startOrder: 4,
            isLast: true,
          },
        ],
      },
      notificationInfo: {
        title: '아~/퍼/터/커',
        btnSet: [
          // {
          //   img: 'search.png',
          //   name: '평가조회',
          //   routerUrl: 'evaluateRecord',
          //   isActionBtn: false,
          // },
        ],
      },
      sideMenuDeep: true,
      sideMenuOption: [
        {
          img: 'sidebar_item01.png',
          img_active: 'sidebar_menu1_active.png',
          img_active_deep: 'sidebar_menu1_active_deep.png',
          title: '메인',
          isActive: false,
          link: '',
        },
        {
          img: 'sidebar_item02.png',
          img_active: 'sidebar_menu2_active.png',
          img_active_deep: 'sidebar_menu2_active_deep.png',
          title: '삼킴 평가',
          isActive: true,
          link: 'evaluate',
        },
        {
          img: 'sidebar_item03.png',
          img_active: 'sidebar_menu3_active.png',
          img_active_deep: 'sidebar_menu3_active_deep.png',
          title: '삼킴 훈련',
          isActive: false,
          link: 'trainingDate',
        },

        {
          img: 'sidebar_item05.png',
          img_active: 'sidebar_menu5_active.png',
          img_active_deep: 'sidebar_menu5_active_deep.png',
          title: '자세법ㆍ식이법',
          isActive: false,
          link: 'choiceType',
        },
        {
          img: 'sidebar_item06.png',
          img_active: 'sidebar_menu6_active.png',
          img_active_deep: 'sidebar_menu6_active_deep.png',
          title: '원격 상담',
          isActive: false,
          link: 'reservationType',
        },
        {
          img: 'sidebar_mypage.png',
          img_active: 'sidebar_mypage_active.png',
          img_active_deep: 'sidebar_mypage_active.png',
          title: '마이페이지',
          isActive: false,
          link: 'mypage',
        },
      ],
      popupSet: {},
      maxPage: 1,
      currentPage: 1,
      view: {
        isStop: false,
        isPaused: false,
        canStored: true,
        startFlag: {
          a: false,
          p: false,
          t: false,
          k: false,
          f: false,
        },
        stopFlag: {
          a: false,
          p: false,
          t: false,
          k: false,
          f: false,
        },
        isStoreDone: false,
      },
    };
  },
  created() {
    if (history.pushState !== undefined) {
      history.pushState({}, '', location.href);
      window.onpopstate = () => {
        history.pushState({}, '', location.href);
        this.handleGoback();
      };
    }
  },
  mounted() {},
  methods: {
    ...mapMutations('basic', ['SET_POPUP']),
    ...mapActions('evaluate', ['SUBMIT_EVALUATE']),
    historyCall() {
      this.$router.push('/clinical');
    },
    recordApiCancle() {
      console.log('켄슬수신!!');
      this.swiperList.vocalization[this.currentPage - 1].value = this.dummyTxt[
        this.currentPage - 1
      ].value;
    },
    recordApiCall() {
      this.pushRetry(this.currentPage);
    },
    pushRetry(currentOrder) {
      this.swiperList.vocalization[currentOrder - 1].value = '';
      setTimeout(() => {
        document.querySelector('.retry').click();
      }, 5);
      /**
      this.$refs.mySwiperParent.pushRetry();
       */
    },
    returnCurrentPage(pageOrder) {
      if (pageOrder > this.maxPage) {
        this.maxPage = pageOrder;
        this.currentPage = pageOrder;
      } else {
        this.currentPage = pageOrder;
      }
      console.log(this.dummyTxt, ' : this.dummyTxt ');
      console.log(this.swiperList.vocalization[this.currentPage - 1].value, ' : swiperlist. . . . value');
      this.dummyTxt[this.currentPage - 1].value = this.swiperList.vocalization[
        this.currentPage - 1
      ].value;
    },
    nextLock() {
      const url = 'samkim://vocalizationStop';
      document.location.href = url;
      this.$refs.mySwiperParent.slideCallParent();
    },
    protoVocalizeComplex(string, order, stopBtnState) {
      console.log('protoVocalize_Complex :');
      console.log('string :', string);
      console.log('order :', order);
      let type;
      /** 아퍼터커 a p t k */
      if (order === 1) {
        type = 'p';
      } else if (order === 2) {
        type = 't';
      } else if (order === 3) {
        type = 'k';
      } else if (order === 4) {
        type = 'f';
      }
      if (string === 'start') {
        console.log('start :');
        const url = `samkim://vocalizationStart?type=${type}`;
        document.location.href = url;
      } else if (string === 'restart') {
        console.log('restart :');
        if (this.view.isPaused) {
          this.view.isPaused = false;
        }
        const url = 'samkim://vocalizationPlay';
        document.location.href = url;
      } else if (string === 'overCount') {
        console.log('오버카운트에서 호출됨');
        this.SET_POPUP(true);
        this.popupSet.content = '이미 저장을 완료하였습니다.';
        this.popupSet.popImg = 'warning.svg';
        this.popupSet.cancelBtnText = '취소';
        this.popupSet.nextLink = null;
        this.popupSet.confirmBtnText = null;
      } else if (string === 'pause') {
        if (!this.view.isPaused) {
          this.view.isPaused = true;
          const url = 'samkim://vocalizationPause';
          document.location.href = url;
        }
      } else if (string === 'reset') {
        console.log('리셋감지');
        console.log('현재의 타입', type);
        console.log('이것찾았지 :', stopBtnState);
        /**
        if (stopBtnState === 0) {
          const url = "samkim://vocalizationStop";
          document.location.href = url;
        }
         */
        if (type === 'p') {
          this.view.startFlag.p = false;
          this.view.stopFlag.p = false;
        } else if (type === 't') {
          this.view.startFlag.t = false;
          this.view.stopFlag.t = false;
        } else if (type === 'k') {
          this.view.startFlag.k = false;
          this.view.stopFlag.k = false;
        } else if (type === 'f') {
          this.view.startFlag.f = false;
          this.view.stopFlag.f = false;
        }
      } else if (string === 'stop') {
        console.log('목적지 스탑에 진입 :', this.view.stopFlag);
        if (type === 'p') {
          if (!this.view.stopFlag.p) {
            this.view.stopFlag.p = true;
            this.view.startFlag.p = true;
            const url = 'samkim://vocalizationStop';
            document.location.href = url;
          } else {
            this.SET_POPUP(true);
            this.popupSet.content = '이미 저장을 완료하였습니다.';
            this.popupSet.popImg = 'warning.svg';
            this.popupSet.cancelBtnText = '취소';
            this.popupSet.nextLink = null;
            this.popupSet.confirmBtnText = null;
          }
        } else if (type === 't') {
          if (!this.view.stopFlag.t) {
            this.view.stopFlag.t = true;
            this.view.startFlag.t = true;
            const url = 'samkim://vocalizationStop';
            document.location.href = url;
          } else {
            this.SET_POPUP(true);
            this.popupSet.content = '이미 저장을 완료하였습니다.';
            this.popupSet.popImg = 'warning.svg';
            this.popupSet.cancelBtnText = '취소';
            this.popupSet.nextLink = null;
            this.popupSet.confirmBtnText = null;
          }
        } else if (type === 'k') {
          if (!this.view.stopFlag.k) {
            this.view.stopFlag.k = true;
            this.view.startFlag.k = true;
            const url = 'samkim://vocalizationStop';
            document.location.href = url;
          } else {
            this.SET_POPUP(true);
            this.popupSet.content = '이미 저장을 완료하였습니다.';
            this.popupSet.popImg = 'warning.svg';
            this.popupSet.cancelBtnText = '취소';
            this.popupSet.nextLink = null;
            this.popupSet.confirmBtnText = null;
          }
        } else if (type === 'f') {
          if (!this.view.stopFlag.f) {
            this.view.stopFlag.f = true;
            this.view.startFlag.f = true;
            const url = 'samkim://vocalizationStop';
            document.location.href = url;
          }
          this.askSubmit();
        }
        /**
        if (!this.view.isStop) {
          const url = "samkim://vocalizationStop";
          document.location.href = url;
          this.view.isStop = true;
        }
         */

        /**
        this.$refs.mySwiperParent.slideCallParent();
         */
      }
    },
    protoVocalize(string, type, currentPage, isStoredState) {
      console.log('protoVocalize :');
      console.log('string :', string);
      console.log('type :', type);
      console.log('currentPage!!! :', currentPage);
      if (string === 'start') {
        const url = `samkim://vocalizationStart?type=${type}`;
        document.location.href = url;
        this.view.startFlag.a = true;
        this.view.stopFlag.a = false;
      } else if (string === 'restart') {
        this.view.pastData = null;
        if (
          this.swiperList.vocalization[this.currentPage - 1].value !== ''
          && this.maxPage > this.currentPage
        ) {
          this.SET_POPUP(true);
          this.popupSet.content = '이미 저장을 완료하였습니다.';
          this.popupSet.popImg = 'warning.svg';
          this.popupSet.cancelBtnText = '취소';
          this.popupSet.nextLink = null;
          this.popupSet.confirmBtnText = null;
          setTimeout(() => {
            document.querySelector('.stop').click();
          }, 5);
          /**
           *
          this.popupSet.content = "이미 저장이 완료된 녹음입니다. \n 재입력 하시겠습니까?";
          this.popupSet.popImg = "warning.svg";
          this.popupSet.cancelBtnText = "취소";
          this.popupSet.nextLink = null;
          this.popupSet.confirmBtnText = "확인";
          this.popupSet.isRecordCall = true;
          */
        } else {
          const url = 'samkim://vocalizationPlay';
          document.location.href = url;
        }
      } else if (string === 'reset') {
        this.view.canStored = true;
        console.log('최종리셋에서 온 :', isStoredState);
        /**
        if (this.view.startFlag.a) {
          this.view.startFlag.a = false;
          this.view.stopFlag.a = true;
          const url = "samkim://vocalizationStop";
          document.location.href = url;
        }
        */
      } else if (string === 'pause') {
        const url = 'samkim://vocalizationPause';
        document.location.href = url;
      } else if (string === 'stop') {
        if (
          this.swiperList.vocalization[this.currentPage - 1].value !== ''
          && this.maxPage > this.currentPage
        ) {
          /**
          console.log("호이짜 :", document.querySelector(".content").childNodes[2]);
          this.view.pastData = document.querySelector(".content").childNodes[2].textContent;
           */
          if (!this.view.canStored) {
            this.SET_POPUP(true);
            this.popupSet.content = '이미 저장을 완료하였습니다.';
            this.popupSet.popImg = 'warning.svg';
            this.popupSet.cancelBtnText = '취소';
            this.popupSet.nextLink = null;
            this.popupSet.confirmBtnText = null;
          } else {
            this.view.startFlag.a = false;
            this.view.stopFlag.a = true;
            const url = 'samkim://vocalizationStop';
            document.location.href = url;
            this.$refs.mySwiperParent.slideCallParent();
          }
        } else {
          this.view.startFlag.a = false;
          this.view.stopFlag.a = true;
          const url = 'samkim://vocalizationStop';
          document.location.href = url;
          this.$refs.mySwiperParent.slideCallParent();
          this.view.canStored = false;
        }
      }
    },
    inspectPop() {
      this.SET_POPUP(true);
      this.popupSet.content = '입력해주세요';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = '확인';
      this.popupSet.nextLink = null;
      this.popupSet.confirmBtnText = null;
    },
    handleGoback() {
      /**
      if (this.checkPopup) {
        this.SET_POPUP(false);
      } else {
        this.$router.push("/clinical");
      }
       */
      this.SET_POPUP(true);
      this.popupSet.popType = 'warn';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '평가가 완료되지 않았습니다. \n종료하시겠습니까?';
      this.popupSet.cancelBtnText = '아니오';
      this.popupSet.confirmBtnText = '예';
      this.popupSet.nextLink = null;
      this.popupSet.historyCall = true;
    },
    askSubmit() {
      this.SET_POPUP(true);
      this.popupSet.popType = 'warn';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '평가를 제출할까요?';
      this.popupSet.cancelBtnText = '아니오';
      this.popupSet.nextLink = null;
      this.popupSet.isApiCall = true;
      this.popupSet.confirmBtnText = '예';
    },
    transArray() {
      const sampleArray = [];
      let sampleObj;
      sampleObj = {
        aTime:
          `${this.swiperList.vocalization[0].value.sec
          }:${
            this.swiperList.vocalization[0].value.milisec}`,
        tTime: `${this.swiperList.vocalization[1].value.duration}:` + '00',
        tNumber: Number(this.swiperList.vocalization[1].value.count),
        tNumberPerSecond: Number(this.swiperList.vocalization[1].value.persecond),
        pTime: `${this.swiperList.vocalization[2].value.duration}:` + '00',
        pNumber: Number(this.swiperList.vocalization[2].value.count),
        pNumberPerSecond: Number(this.swiperList.vocalization[2].value.persecond),
        kTime: `${this.swiperList.vocalization[3].value.duration}:` + '00',
        kNumber: Number(this.swiperList.vocalization[3].value.count),
        kNumberPerSecond: Number(this.swiperList.vocalization[3].value.persecond),
      };
      console.log('만든 재료는?', sampleObj);
      sampleArray.push(sampleObj);
      return sampleArray;
    },
    async questionRegistered() {
      console.log('이곳에 진입함');
      try {
        console.log('try 에 진입');
        const payload = {
          type: 'vocalization',
          list: this.transArray(),
        };
        const payload2 = {
          type: 'evaluation_note',
          list: [{
            note_type: 0,
            evaluation_note: this.swiperList.vocalization[4].value,
          }],
        };
        await this.SUBMIT_EVALUATE(payload2).then((data) => {
          console.log(data, ' : payload2 API data 확인 ');
        });
        await this.SUBMIT_EVALUATE(payload).then((data) => {
          console.log('정상진행');
          setTimeout(() => {
            const url = `samkim://vocalizationEnd?detailId=${data.response.id}`;
            document.location.href = url;
            this.view.isStoreDone = true;
          }, 150);
          this.SET_POPUP(true);
          this.popupSet.popType = 'warn';
          this.popupSet.popImg = 'success.svg';
          this.popupSet.content = '평가가 완료되었습니다.';
          this.popupSet.cancelBtnText = null;
          this.popupSet.nextLink = '/clinical';
          this.popupSet.confirmBtnText = '확인';
          this.popupSet.destination = true;
          this.popupSet.isApiCall = false;
        });
      } catch (error) {
        this.SET_POPUP(true);
        this.popupSet.content = String(errorParser.parse(error));
        this.popupSet.popImg = 'warning.svg';
        this.popupSet.cancelBtnText = '확인';
        this.popupSet.nextLink = null;
        this.popupSet.confirmBtnText = null;
      }
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = '로그인 권한이 필요합니다.';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    popSuccess() {
      this.SET_POPUP(true);
      this.popupSet.popType = 'warn';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '평가가 완료되었습니다.';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/clinical';
      this.popupSet.confirmBtnText = '완료';
      this.popupSet.destination = true;
    },
    popEscape() {
      this.SET_POPUP(true);
      this.popupSet.popType = 'warn';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.content = '평가가 완료되지 않았습니다.\n 평가를 종료하시겠습니까?';
      this.popupSet.cancelBtnText = '취소';
      this.popupSet.nextLink = '/clinical';
      this.popupSet.confirmBtnText = '종료';
    },
  },
  computed: {
    ...mapState('basic', ['checkPopup']),
  },
  components: {
    SideMenu,
    DefaultPopup,
    Notification,
    Swiper,
  },
  beforeDestroy() {
    if (!this.view.isStoreDone) {
      const url = 'samkim://vocalizationStop';
      document.location.href = url;
    }
  },
};
</script>
<style lang="scss" scoped>
.app-context {
  .wrap-context {
    width: 870px;
    margin: 22px 122px 0 auto;
  }
}
</style>
