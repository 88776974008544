import { evaluate } from '@/api';

const state = {
  evaluate: {
    /**
    drId: localStorage.getItem('drId') || '',
     */
    evaluateList: [],
  },
};

const mutations = {
  SET_PERSONALINFO(state, info) {
    state.join.personalInfo = info;
  },
};

const getters = {
  GET_PERSONALINFO: (state) => state.join.personalInfo,
};

const actions = {
  FETCH_EVALUATE_LIST({}, info) {
    return evaluate.fetchEvaluateList(info);
  },
  CHECKING_EVALUATE({}, info) {
    return evaluate.checkingEvaluate(info);
  },
  SUBMIT_EVALUATE({}, list) {
    return evaluate.submitEvaluate(list);
  },
  MODIFY_EVALUATE({}, list) {
    return evaluate.modifyEvaluate(list);
  },
  FETCH_MONTHLIST({}, dateOpt) {
    return evaluate.fetchMonthList(dateOpt);
  },
  FETCH_EXPLAINLIST({}, payload) {
    return evaluate.fetchExplainList(payload);
  },
  // FETCH_SURVEY_DETAIL({}, payload) {
  //   return evaluate.fetchSurveyDetail(payload);
  // },
};
export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
