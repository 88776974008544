import Vue from "vue";
import VueHead from "vue-head";
import vueMoment from "vue-moment";
import VuePlyr from "vue-plyr";
import Clipboard from "v-clipboard";
import "vue-plyr/dist/vue-plyr.css";
import "swiper/swiper-bundle.css";
import { Swiper as SwiperClass, Pagination, Mousewheel, Autoplay } from "swiper/core";
import AirbnbStyleDatepicker from "vue-airbnb-style-datepicker";
import "vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
import CircularCountDownTimer from "vue-circular-count-down-timer";
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";
import vueAwesomeCountdown from "vue-awesome-countdown";
import router from "./router";
import App from "./App.vue";
import { store } from "./store/index";

// Swiper modules
SwiperClass.use([Pagination, Mousewheel, Autoplay]);

// datepicker 옵션
const datepickerOptions = {
  sundayFirst: true,
  dateLabelFormat: "dddd, MMMM D, YYYY",
  days: ["월요일", "화요일", "수요일", "목요일", "금요일", "토요일", "일요일"],
  daysShort: ["월", "화", "수", "목", "금", "토", "일"],
  monthNames: [
    "1월",
    "2월",
    "3월",
    "4월",
    "5월",
    "6월",
    "7월",
    "8월",
    "9월",
    "10월",
    "11월",
    "12월"
  ],
  colors: {
    selected: "#315754",
    inRange: "rgb(255, 219, 219)",
    text: "#403f3f",
    selectedText: "#403f3f",
    disabled: "transparent",
    inRangeBorder: "#e4e7e7",
    hoveredInRange: "rgb(255, 219, 219)"
  }
};

Vue.use(VuePlyr);
Vue.use(VueHead);
Vue.use(CircularCountDownTimer);
Vue.use(Clipboard);
Vue.use(vSelect);
Vue.use(AirbnbStyleDatepicker, datepickerOptions);
Vue.config.productionTip = false;
Vue.use(vueAwesomeCountdown, "vac");
Kakao.init("f5c94a807090c9f25a150a972cd6c7b6");
Vue.use(vueMoment);
Vue.use(getAwesomeSwiper(SwiperClass));
const vm = new Vue({
  router,
  store,
  vueMoment,
  datepickerOptions,
  render: h => h(App)
}).$mount("#app");
window.app = vm;
