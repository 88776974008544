<template>
  <div class="app-context flex">
    <side-menu :sideMenuOption="sideMenuOption" @is-unauthorized="isUnAuthorized"></side-menu>
    <div class="wrap-context">
      <notification
        class="low-margin"
        :notificationInfo="notificationInfo"
        @share-pop-call="logout"
      ></notification>
      <directive :directiveTxt="directiveTxt" class="directive low"></directive>
      <block-menu class="diet" :menuList="menuList" :menuType="menuType"></block-menu>
      <!--
      <p class="share" @click="share()">test</p>
       -->
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import errorParser from '@/utils/error-parser';
import DefaultPopup from '@/components/modal/DefaultPopup';
import SideMenu from '@/components/SideMenu.vue';
import Notification from '@/components/Notification.vue';
import Directive from '@/components/Directive.vue';
import BlockMenu from '@component/BlockMenu.vue';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  data() {
    return {
      menuType: 2,
      menuList: [
        {
          img: 'alerm.png',
          name: '알림',
          isActive: false,
          routerUrl: '/alermList',
        },
        {
          img: 'information.png',
          name: '공지사항',
          isActive: true,
          routerUrl: '/noticeList',
        },
      ],
      directiveTxt: {
        point: '알림과 공지사항',
        normal: '을 확인해 주세요.',
        small: null,
      },
      notificationInfo: {
        title: '마이페이지',
        btnSet: [
          {
            img: 'icon_logout.svg',
            name: '로그아웃',
            routerUrl: null,
            isActionBtn: true,
          },
        ],
      },
      ogTag: {
        title: null,
        description: null,
        image: null,
        url: null,
      },
      sideMenuOption: [
        {
          img: 'sidebar_item01.png',
          img_active: 'sidebar_menu1_active.png',
          img_active_deep: 'sidebar_menu1_active_deep.png',
          title: '메인',
          isActive: false,
          link: '',
        },
        {
          img: 'sidebar_item02.png',
          img_active: 'sidebar_menu2_active.png',
          img_active_deep: 'sidebar_menu2_active_deep.png',
          title: '삼킴 평가',
          isActive: false,
          link: 'evaluate',
        },
        {
          img: 'sidebar_item03.png',
          img_active: 'sidebar_menu3_active.png',
          img_active_deep: 'sidebar_menu3_active_deep.png',
          title: '삼킴 훈련',
          isActive: false,
          link: 'trainingDate',
        },
        {
          img: 'sidebar_item05.png',
          img_active: 'sidebar_menu5_active.png',
          img_active_deep: 'sidebar_menu5_active_deep.png',
          title: '자세법ㆍ식이법',
          isActive: false,
          link: 'choiceType',
        },
        {
          img: 'sidebar_item06.png',
          img_active: 'sidebar_menu6_active.png',
          img_active_deep: 'sidebar_menu6_active_deep.png',
          title: '원격 상담',
          isActive: false,
          link: 'reservationType',
        },
        {
          img: 'sidebar_mypage.png',
          img_active: 'sidebar_mypage_active.png',
          img_active_deep: 'sidebar_mypage_active.png',
          title: '마이페이지',
          isActive: true,
          link: 'mypage',
        },
      ],
      popupSet: {},
    };
  },
  head: {
    meta() {
      return [
        { property: 'og:title', content: this.ogTag.title },
        { property: 'og:description', content: this.ogTag.description },
        { property: 'og:image', content: this.ogTag.image },
        { property: 'og:url', content: this.ogTag.url },
        { name: 'description', content: this.ogTag.description },
      ];
    },
  },
  created() {
    if (history.pushState !== undefined) {
      history.pushState({}, '', location.href);
      window.onpopstate = () => {
        history.pushState({}, '', location.href);
        this.handleGoback();
      };
    }
    this.ogTag.title = 'ogTag2';
    this.ogTag.description = 'ogTag2 디스크립션';
    this.ogTag.image = 'https://www.ci-at.com/resources/images/ciat_og.png';
    this.ogTag.url = 'http://20.194.31.39:8000';
  },
  mounted() {},
  methods: {
    ...mapActions('join', ['LOGOUT']),
    ...mapMutations('basic', ['SET_POPUP']),
    share() {
      Kakao.Link.sendDefault({
        objectType: 'feed',
        content: {
          title: this.ogTag.title,
          imageUrl: this.ogTag.image,
          description: this.$router.currentRoute.path,
          link: {
            mobileWebUrl: this.ogTag.url + this.$router.currentRoute.path,
            webUrl: this.ogTag.url + this.$router.currentRoute.path,
          },
        },
      });
    },
    handleGoback() {
      if (this.checkPopup) {
        this.SET_POPUP(false);
      } else {
        this.$router.push('/');
      }
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = '로그인 권한이 필요합니다.';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    async logout() {
      try {
        await this.LOGOUT().then(() => {
          localStorage.removeItem('token');
          localStorage.removeItem('id');
          localStorage.removeItem('userName');
          localStorage.removeItem('storeId');
          const url = 'samkim://logout';
          document.location.href = url;

          this.SET_POPUP(true);
          this.popupSet.popType = 'warn';
          this.popupSet.popImg = 'success.svg';
          this.popupSet.content = '로그아웃이 되었습니다.';
          this.popupSet.cancelBtnText = null;
          this.popupSet.nextLink = '/';
          this.popupSet.confirmBtnText = '확인';
          this.popupSet.destination = true;
        });
      } catch (error) {
        this.SET_POPUP(true);
        this.popupSet.content = String(errorParser.parse(error));
        this.popupSet.popImg = 'warning.svg';
        this.popupSet.cancelBtnText = '확인';
        this.popupSet.nextLink = null;
        this.popupSet.confirmBtnText = null;
      }
    },
    popTest() {
      this.SET_POPUP(true);
      this.popupSet.content = '테스트';
      this.popupSet.cancelBtnText = '취소';
      this.popupSet.nextLink = '/';
      this.popupSet.confirmBtnText = '실행';
    },
    linkFromMain(order) {},
    linkFromEvaluation(order) {},
    linkFromClinic(order) {},
  },
  computed: {
    ...mapState('basic', ['checkPopup']),
  },
  components: {
    SideMenu,
    Notification,
    DefaultPopup,
    Directive,
    BlockMenu,
  },
};
</script>
<style lang="scss" scoped>
.app-context {
  .wrap-context {
    width: 934px;
    margin: 22px 90px 0 auto;
    .low-margin {
      margin: 0 0 56px 0;
    }
    .directive.low {
      margin: 0 0 51px 0;
    }
  }
}
</style>
