<template>
  <div class="app-context flex deep">
    <side-menu
      :sideMenuOption="sideMenuOption"
      :sideMenuDeep="true"
      @is-unauthorized="isUnAuthorized"
    ></side-menu>
    <div class="wrap-context directiveType1">
      <directive
        :directiveTxt="directiveTxt"
        class="directive low"
        :directiveType="1"
      ></directive>
      <div class="info-complex flex">
        <div class="video-player">
          <vue-plyr :options="plyrOption" ref="plyr">
            <!-- <video controls crossorigin playsinline data-poster="splash.png"> -->
            <video controls crossorigin playsinline>
              <source
                size="1080"
                :src="require(`@/assets/video/${plyrValue.src}`)"
                type="video/mp4"
              />
            </video>
          </vue-plyr>
        </div>
        <div class="info">
          <div class="bg-img">
            <img src="@assets/images/util/information.svg" />
          </div>
          <div class="item-wrap">
            <div class="item">
              <h3 class="title">훈련절차</h3>
              <div class="orderList">
                <p>한 숟갈 정도의 물을 입에 머금으세요</p>
                <p>혀를 입천장 윗니 바로 뒤에 대어 보세요</p>
                <p>혀와 목 주변의 모든 근육에 힘을 주어 힘껏 삼키세요</p>
                <p>3초 동안 힘을 주세요</p>
              </div>
            </div>
            <div class="item">
              <h3 class="title">준비물</h3>
              <div class="orderList">
                <p>물 또는 걸쭉한 액체 5ml</p>
                <p>컵</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import DefaultPopup from '@/components/modal/DefaultPopup';
import SideMenu from '@/components/SideMenu.vue';
import Directive from '@/components/Directive.vue';
import { mapMutations, mapState } from 'vuex';

export default {
  data() {
    return {
      plyrValue: {
        duration: '',
        player: '',
        src: 'information_swallow_mix.mp4',
      },
      plyrOption: {
        autoplay: false,
        /**
          controls: ['play', 'fullscreen'],
           */
        fullscreen: {
          enabled: true,
          fallback: true,
          iosNative: true,
          container: 'video-player',
        },
      },
      directiveTxt: {
        point: '힘껏 오래 삼키기',
        normal: null,
        small: '인후두 운동',
      },
      sideMenuOption: [
        {
          img: 'sidebar_item01.png',
          img_active: 'sidebar_menu1_active.png',
          img_active_deep: 'sidebar_menu1_active_deep.png',
          title: '메인',
          isActive: false,
          link: '',
        },
        {
          img: 'sidebar_item02.png',
          img_active: 'sidebar_menu2_active.png',
          img_active_deep: 'sidebar_menu2_active_deep.png',
          title: '삼킴 평가',
          isActive: false,
          link: 'evaluate',
        },
        {
          img: 'sidebar_item03.png',
          img_active: 'sidebar_menu3_active.png',
          img_active_deep: 'sidebar_menu3_active_deep.png',
          title: '삼킴 훈련',
          isActive: true,
          link: 'trainingDate',
        },

        {
          img: 'sidebar_item05.png',
          img_active: 'sidebar_menu5_active.png',
          img_active_deep: 'sidebar_menu5_active_deep.png',
          title: '자세법ㆍ식이법',
          isActive: false,
          link: 'choiceType',
        },
        {
          img: 'sidebar_item06.png',
          img_active: 'sidebar_menu6_active.png',
          img_active_deep: 'sidebar_menu6_active_deep.png',
          title: '원격 상담',
          isActive: false,
          link: 'reservationType',
        },
        {
          img: 'sidebar_mypage.png',
          img_active: 'sidebar_mypage_active.png',
          img_active_deep: 'sidebar_mypage_active.png',
          title: '마이페이지',
          isActive: false,
          link: 'mypage',
        },
      ],
      popupSet: {},
    };
  },
  created() {
    if (history.pushState !== undefined) {
      history.pushState({}, '', location.href);
      window.onpopstate = () => {
        history.pushState({}, '', location.href);
        this.handleGoback();
      };
    }
  },
  mounted() {
    this.initVideo();
    /** 시간업데이트 */
    this.$refs.plyr.player.on('timeupdate', (data) => {
      const value = data.detail.plyr;
      console.log('duration', value.currentTime);
    });
    /** 비디오 초기시작 */
    this.$refs.plyr.player.on('playing', (data) => {});

    /** 비디오 재개 */
    this.$refs.plyr.player.on('play', (data) => {
      this.$refs.plyr.player.play();
    });
    /** 비디오 중지 */
    this.$refs.plyr.player.on('pause', (data) => {});

    /** 비디오 완료 */
    this.$refs.plyr.player.on('ended', (data) => {});
    /** 비디오 전체화면 시작 */
    this.$refs.plyr.player.on('enterfullscreen', (data) => {});

    /** 비디오 전체화면 중지 */
    this.$refs.plyr.player.on('exitfullscreen', (data) => {});
  },
  methods: {
    ...mapMutations('basic', ['SET_POPUP']),
    handleGoback() {
      if (this.checkPopup) {
        this.SET_POPUP(false);
      } else {
        this.$router.push('/trainingInfo');
      }
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = '로그인 권한이 필요합니다.';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    initVideo() {
      this.plyrValue.player = this.$refs.plyr.player;
    },
    popTest() {
      this.SET_POPUP(true);
      this.popupSet.content = '테스트';
      this.popupSet.cancelBtnText = '취소';
      this.popupSet.nextLink = '/';
      this.popupSet.confirmBtnText = '실행';
    },
    linkFromMain(order) {},
    linkFromEvaluation(order) {},
    linkFromClinic(order) {},
  },
  computed: {
    ...mapState('basic', ['checkPopup']),
  },
  components: {
    SideMenu,
    DefaultPopup,
    Directive,
  },
};
</script>
<style lang="scss" scoped>
@import "@assets/css/information";
.app-context {
  .wrap-context {
    width: 934px;
    margin: 22px 90px 0 auto;
    .low-margin {
      margin: 0 0 56px 0;
    }
    .directive.low {
      margin: 0 0 51px 0;
    }
  }
}
</style>
