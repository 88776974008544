const state = {
  checkPopup: false,
  checkEtcPopup: false,
  checkCalendarPopup: false,
  checkSwiperPopup: false,
  checkInformationPopup: false,
};

const getters = {
  GET_POPUP: (state) => state.checkPopup,
  GET_ETC_POPUP: (state) => state.checkEtcPopup,
  GET_SWIPER_POPUP: (state) => state.checkSwiperPopup,
  GET_CALENDAR_POPUP: (state) => state.checkCalendarPopup,
  GET_INFORMATION_POPUP: (state) => state.checkInformationPopup,
};

const mutations = {
  SET_POPUP(state, currentState) {
    state.checkPopup = currentState;
  },
  SET_ETC_POPUP(state, currentState) {
    state.checkEtcPopup = currentState;
  },
  SET_CALENDAR_POPUP(state, currentState) {
    state.checkCalendarPopup = currentState;
  },
  SET_SWIPER_POPUP(state, currentState) {
    state.checkSwiperPopup = currentState;
  },
  SET_INFORMATION_POPUP(state, currentState) {
    state.checkInformationPopup = currentState;
  },
};

const actions = {};

export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
