<template>
  <modal
    :destination="destination"
    :huge="huge"
    :versionApiCall="versionApiCall"
    @forced-nextlink="goNextLink()"
  >
    <template v-slot:body>
      <div class="share-pop" v-if="popType === 'share'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/close.svg" />
        </div>
        <div class="content">
          <!--
          <div class="img-wrap">
            <img src="@assets/images/util/x.svg" />
          </div>
          -->
          <div class="sample"></div>
        </div>
        <div class="btn-wrap">
          <button type="button" @click="close()">취소</button>
          <button class="activate" @click="share()">
            <img src="@assets/images/util/kakao.png" />공유하기
          </button>
        </div>
      </div>
      <div class="default-pop frame" v-if="popType === 'warn'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
        <div class="pop-img">
          <img :src="require(`@/assets/images/popup/${popImg}`)" />
        </div>
        <div class="content">
          <p v-html="contentMutated"></p>
        </div>
        <div class="btn-wrap" :class="{ alone: confirmBtnText === null || cancelBtnText === null }">
          <button
            v-text="cancelBtnText"
            type="button"
            @click="close()"
            v-if="cancelBtnText !== null"
          />
          <button
            class="active"
            v-text="confirmBtnText"
            type="button"
            @click="action()"
            v-if="confirmBtnText !== null"
          />
        </div>
      </div>
      <div class="default-pop frame" v-if="popType === 'write'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
        <div class="content">
          <p v-html="contentMutated"></p>
        </div>
        <div class="input-wrap">
          <input type="text" v-model="dataValue" @keyup.enter="write()" />
        </div>
        <div class="btn-wrap">
          <button v-text="cancelBtnText" type="button" @click="close()" />
          <button class="active" v-text="confirmBtnText" type="button" @click="write()" />
        </div>
      </div>
      <div class="default-pop frame resv" v-if="popType === 'resv'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
        <div class="pop-img">
          <img :src="require(`@/assets/images/popup/${popImg}`)" />
        </div>
        <div class="content">
          <p v-html="contentMutated"></p>
        </div>
        <div class="resv-info">
          <div class="article">
            <p>상담 유형</p>
            <p class="bold" v-text="resvConts.type"></p>
          </div>
          <div class="article">
            <p>상담 날짜</p>
            <p class="bold" v-text="resvConts.date"></p>
          </div>
          <div class="article">
            <p>상담 시간</p>
            <p class="bold" v-text="resvConts.time"></p>
          </div>
        </div>
        <div class="btn-wrap" :class="{ alone: confirmBtnText === null || cancelBtnText === null }">
          <button
            v-text="cancelBtnText"
            type="button"
            @click="close()"
            v-if="cancelBtnText !== null"
          />
          <button
            class="active"
            v-text="confirmBtnText"
            type="button"
            @click="reservation()"
            v-if="confirmBtnText !== null"
          />
        </div>
      </div>
      <div class="default-pop frame wish" v-if="popType === 'wish'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
        <h3 class="title">상담 희망 내용</h3>
        <div class="textarea-wrap">
          <textarea v-text="wishContent"></textarea>
        </div>
        <div class="btn-wrap" :class="{ alone: confirmBtnText === null || cancelBtnText === null }">
          <button
            v-text="cancelBtnText"
            type="button"
            @click="close()"
            v-if="cancelBtnText !== null"
          />
          <button
            class="active"
            v-text="confirmBtnText"
            type="button"
            @click="action()"
            v-if="confirmBtnText !== null"
          />
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import Modal from "./EtcModal.vue";

export default {
  props: {
    popupSet: {
      content: {
        type: String
      },
      nextLink: {
        type: String
      },
      confirmBtnText: {
        type: String
      },
      cancelBtnText: {
        type: String
      },
      popType: {
        type: String,
        default: "warn"
      },
      popImg: {
        type: String,
        default: "warning.svg"
      },
      destination: {
        type: Boolean
      },
      huge: {
        type: Boolean,
        default: false
      },
      param: {
        type: String
      },
      resvConts: {
        type: Object
      },
      wishContent: {
        type: String
      },
      isApiCall: {
        type: Boolean
      },
      versionApiCall: {
        type: Boolean
      },
      mobileLink: {
        type: String
      }
    }
  },
  components: {
    Modal
  },
  data() {
    return {
      popType: this.popupSet.popType || "warn",
      popImg: this.popupSet.popImg || "warning.svg",
      content: this.popupSet.content,
      nextLink: this.popupSet.nextLink,
      cancelBtnText: this.popupSet.cancelBtnText,
      confirmBtnText: this.popupSet.confirmBtnText,
      destination: this.popupSet.destination,
      mobileLink: this.popupSet.mobileLink,
      huge: this.popupSet.huge,
      param: this.popupSet.param,
      resvConts: this.popupSet.resvConts,
      isApiCall: this.popupSet.isApiCall,
      versionApiCall: this.popupSet.versionApiCall,
      dataValue: "",
      wishContent: this.popupSet.wishContent
    };
  },
  watch: {},
  mounted() {},
  computed: {
    ...mapState("basic", ["checkEtcPopup"]),
    contentMutated() {
      console.log("코멘트 검색 :", this.content);
      if (this.content !== undefined && this.content.indexOf("\n") !== 1) {
        return this.content.split("\n").join("<br />");
      }
      return this.content;
    }
  },
  methods: {
    ...mapMutations("basic", ["SET_ETC_POPUP"]),
    updateIng(text) {
      this.content = text;
      this.confirmBtnText = null;
      this.cancelBtnText = null;
    },
    reservation() {
      this.$emit("resv-call");
      this.SET_ETC_POPUP(false);
    },
    share() {
      console.log("api - param", this.param);
      alert("준비중");
    },
    goNextLink() {
      if (this.destination && this.versionApiCall) {
        console.log("이곳에 진입");
      } else if (this.destination && this.nextLink !== undefined) {
        console.log("destination - nextLink 진입");
        this.$router.push(this.nextLink);
        this.SET_ETC_POPUP(false);
      } else {
        this.SET_ETC_POPUP(false);
      }
    },
    write() {
      this.$emit("transed-pop-data", this.dataValue);
      this.SET_ETC_POPUP(false);
    },
    action() {
      if (this.isApiCall) {
        this.$emit("api-called");
        this.SET_ETC_POPUP(false);
      } else if (this.nextLink !== undefined) {
        this.$router.push(this.nextLink);
        this.SET_ETC_POPUP(false);
      } else if (this.mobileLink !== undefined) {
        /**
        location.href = this.mobileLink;
        this.SET_ETC_POPUP(false);
        */
        this.$emit("version-api-called", this.mobileLink);
      }
    },
    close() {
      console.log("클로스 진입");
      console.log(
        "요소 검증",
        "nextLink :",
        this.nextLink,
        "destination :",
        this.destination,
        "mobileLink :",
        this.mobileLink
      );
      if (this.destination && this.versionApiCall) {
        const url = "samkim://appClose";
        document.location.href = url;
        localStorage.removeItem("passedDailyComment");
      } else if (this.destination && this.nextLink !== undefined) {
        console.log("destination - nextLink 진입");
        this.$router.push(this.nextLink);
        this.SET_ETC_POPUP(false);
      } else {
        this.SET_ETC_POPUP(false);
      }
      /** 
      else if (this.destination && this.mobileLink !== undefined) {
        console.log("destination - mobileLink 진입");
        location.href = this.mobileLink;
        this.SET_ETC_POPUP(false);
      }
       */
    }
  },
  created() {
    console.log("가져온 팝업아이템", this.popupSet);
  }
};
</script>

<style lang="scss" scoped>
.frame {
  padding: 30px;
  min-height: 300px;
}
.share-pop {
  width: inherit;
  .content {
    padding: 20px;
    margin: 0 0 60px 0;
    .sample {
      width: 100%;
      margin: 0 auto;
      min-height: 524px;
      background: #676767;
    }
  }
  .close {
    position: absolute;
    top: -5px;
    right: -55px;
    cursor: pointer;
    z-index: 1;
    &.hide {
      opacity: 0;
    }
  }
  .btn-wrap {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    button {
      width: 50%;
      height: 60px;
      margin: 0;
      background: #eaebf1;
      border: 0;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      &.activate {
        background: #fd8b11;
        img {
          max-width: 32px;
          height: auto;
          margin: 0 20px 0 0;
        }
      }
      @include initfontKor(
        $font-size: 26px,
        $line-height: 36px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #363739,
        $letter-spacing: -0.52px,
        $text-align: center
      );
      &:first-of-type {
        margin: 0;
      }
      &.active {
        background: $pointcolor__orange;
        color: #fff;
      }
    }
  }
}
.default-pop {
  width: inherit;
  &.wish {
    padding: 25px 40px;
    h3.title {
      @include initfontKor(
        $font-size: 26px,
        $line-height: 36px,
        $font-family: "AppleSDGothicNeoB00",
        $color: #363739,
        $letter-spacing: -0.52px,
        $text-align: center
      );
      margin: 0 0 12px 0;
    }
    .textarea-wrap {
      width: 100%;
      min-height: 270px;
      border-radius: 5px;
      border: solid 1px #eaebf1;
      margin: 0 0 85px 0;
      textarea {
        padding: 20px;
        width: inherit;
        min-height: 270px;
        display: block;
        resize: none;
        @include initfontKor(
          $font-size: 24px,
          $line-height: 32px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #363739,
          $letter-spacing: -0.48px,
          $text-align: left
        );
      }
    }
  }
  &.resv {
    padding: 30px 65px;
    .pop-img {
      margin: 0 auto 45px auto;
    }
    .content {
      margin: 0 0 20px 0;
    }
    .resv-info {
      margin: 0 0 80px 0;
      .article {
        padding: 20px 0;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(49, 87, 84, 0.2);
        &:last-of-type {
          border-bottom: 0;
        }
        p {
          @include initfontKor(
            $font-size: 26px,
            $line-height: 34px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #363739,
            $letter-spacing: -0.52px,
            $text-align: left
          );
          &.bold {
            font-family: "AppleSDGothicNeoB00";
          }
        }
      }
    }
  }
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 1;
    &.hide {
      opacity: 0;
    }
  }
  .pop-img {
    max-width: 105px;
    margin: 0 auto 32px auto;
    display: flex;
    justify-content: center;
    img {
    }
  }
  .content {
    p {
      @include initfontKor(
        $font-size: 24px,
        $line-height: 30px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #363739,
        $letter-spacing: -0.6px,
        $text-align: right
      );
      text-align: center;
      margin: 0 0 20px 0;
    }
  }
  .btn-wrap {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    &.alone {
      button {
        width: 100%;
        background: #fd8b11;
        color: #fff;
      }
    }
    button {
      width: 50%;
      height: 60px;
      margin: 0;
      background: #eaebf1;
      border: 0;
      cursor: pointer;
      @include initfontKor(
        $font-size: 26px,
        $line-height: 36px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #363739,
        $letter-spacing: -0.52px,
        $text-align: center
      );
      &:first-of-type {
        margin: 0;
      }
      &.active {
        background: $pointcolor__orange;
        color: #fff;
      }
    }
  }
}
</style>
