<template>
  <div
    class="side-menu-wrap"
    :class="{ collapsed: isCollapse, hideTxt: isHideText, isDeep: sideMenuDeep }"
  >
    <div class="side-header" v-if="isCollapse">
      <div class="btn-img-wrap" @click="collapse()">
        <img src="@assets/images/sidemenu/sidebar_menu_open.svg" />
      </div>
    </div>
    <div class="side-header" v-else>
      <p class="name" v-if="name !== ''">
        <b>{{ name }}</b>
      </p>
      <div class="btn-img-wrap" @click="collapse()">
        <img src="@assets/images/sidemenu/sidebar_menu_fold.svg" />
      </div>
    </div>
    <div class="side-menu" v-if="isCollapse">
      <div
        class="item"
        v-for="(menu, index) in sideMenuOption"
        :key="index + 'A'"
        :class="{ active: menu.isActive }"
      >
        <router-link :to="'/' + menu.link" v-if="sideMenuDeep">
          <img
            :src="require(`@/assets/images/sidemenu/${menu.img_active_deep}`)"
            v-if="menu.isActive"
          />
          <img :src="require(`@/assets/images/sidemenu/${menu.img}`)" v-else />
        </router-link>
        <router-link :to="'/' + menu.link" v-else>
          <img :src="require(`@/assets/images/sidemenu/${menu.img_active}`)" v-if="menu.isActive" />
          <img :src="require(`@/assets/images/sidemenu/${menu.img}`)" v-else />
        </router-link>
      </div>
    </div>
    <div class="side-menu" v-else>
      <div
        class="item"
        v-for="(menu, index) in sideMenuOption"
        :key="index + 'B'"
        :class="{ active: menu.isActive }"
      >
        <router-link :to="'/' + menu.link">
          <img :src="require(`@/assets/images/sidemenu/${menu.img_active}`)" v-if="menu.isActive" />
          <img :src="require(`@/assets/images/sidemenu/${menu.img}`)" v-else />
          <p>{{ menu.title }}</p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sideMenuOption: {
      type: Array,
    },
    sideMenuDeep: {
      type: Boolean,
      default: false,
    },
    isCollapsed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      name:
        localStorage.getItem('userName') !== null ? `${localStorage.getItem('userName')}님` : '',
      isHideText: false,
      isCollapse: this.isCollapsed,
    };
  },
  created() {},
  methods: {
    callInUnAuth() {
      this.$emit('is-unauthorized');
    },
    collapse() {
      if (this.isCollapse) {
        this.isCollapse = false;
        this.isHideText = true;
        setTimeout(() => {
          this.isHideText = false;
        }, 300);
      } else {
        this.isCollapse = true;
      }
    },
  },
  computed: {},
  components: {},
};
</script>
<style lang="scss" scoped>
.side-menu-wrap {
  width: 196px;
  border-top-right-radius: 27.5px;
  border-bottom-right-radius: 27.5px;
  overflow: hidden;
  box-shadow: 0 3px 12px 0 rgba(44, 57, 55, 0.16);
  transition: 0.5s;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  height: inherit;
  &.isDeep {
    .side-header {
      background: $pointcolor__olympus;
      .btn-img-wrap {
        background: #264442;
      }
    }
    .side-menu {
      .item {
        &.active {
          a {
            &:after {
              content: "";
              width: 8px;
              height: 100%;
              background: $pointcolor__orange;
              position: absolute;
              right: 0;
            }
          }
          p {
            color: $pointcolor__orange;
          }
        }
        &:last-of-type {
          background: $pointcolor__olympus !important;
        }
      }
    }
  }
  &.collapsed {
    width: 74px;
    .side-header {
      justify-content: center;
      .btn-img-wrap {
        width: auto;
        background: transparent;
        img {
        }
      }
    }
    .side-menu {
      .item {
        justify-content: center;
        padding: 0;
        &.active {
          &:after {
          }
        }
        img {
          margin: 0;
        }
        p {
        }
      }
    }
  }
  &.hideTxt {
    .side-menu {
      .item {
        p {
          opacity: 0;
        }
      }
    }
  }
  .side-header {
    height: 80px;
    display: flex;
    background: #fd8b11;
    p.name {
      @include initfontKor(
        $font-size: 20px,
        $line-height: 80px,
        $font-family: "AppleSDGothicNeoL00",
        $color: #fff,
        $text-align: left
      );
      padding: 0 0 0 18px;
      width: 122px;
      b {
        font-family: "AppleSDGothicNeoB00;";
        font-weight: normal;
      }
    }
    a.name {
      @include initfontKor(
        $font-size: 20px,
        $line-height: 80px,
        $font-family: "AppleSDGothicNeoL00",
        $color: #fff,
        $text-align: left
      );
      padding: 0 0 0 18px;
      width: 122px;
      b {
        font-family: "AppleSDGothicNeoB00;";
        font-weight: normal;
      }
    }
    .btn-img-wrap {
      width: calc(100% - 122px);
      background: #f36900;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      img {
      }
    }
  }
  .side-menu {
    height: calc(100% - 80px);
    .item {
      width: 100%;
      border: 0px solid #acb3c9;
      border-width: 1px 0 0 0;
      cursor: pointer;
      height: calc(100% / 6);
      background: #fff;

      &.active {
        a {
          &:after {
            content: "";
            width: 8px;
            height: 100%;
            background: #315754;
            position: absolute;
            right: 0;
          }
        }
        p {
          color: #315754;
          font-family: "AppleSDGothicNeoB00";
        }
      }
      &:last-of-type {
        background: #fd8b12;
        p {
          color: #fff;
        }
        &.active {
          a {
            &:after {
              display: none;
            }
          }
        }
      }
      &:first-of-type {
        border-width: 0;
      }
      a {
        display: flex;
        width: inherit;
        padding: 0 0 0 18px;
        height: 100%;
        position: relative;
        align-items: center;
      }
      img {
        margin: 0 5px 0 0;
      }
      p {
        @include initfontKor(
          $font-size: 20px,
          $line-height: 26px,
          $font-family: "AppleSDGothicNeoL00",
          $color: #363739,
          $letter-spacing: -1px
        );
      }
    }
  }
}
</style>
